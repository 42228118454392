import { render, staticRenderFns } from "./RefUnloadingPointPlaces.vue?vue&type=template&id=7a5b2b98&"
import script from "./RefUnloadingPointPlaces.vue?vue&type=script&lang=js&"
export * from "./RefUnloadingPointPlaces.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QTable from 'quasar/src/components/table/QTable.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QTable,QInput,QIcon,QSpace,QBtn,QTooltip,QCardActions,QDialog,QCard,QCardSection,QTd,QTr,QToolbar,QToolbarTitle});qInstall(component, 'directives', {ClosePopup});
