const perms_ref = {
    name: 'perms',
    title: 'Права',
    url: '/rbac/perms',
    perms: {
        list: 'v1.perms.list',
        read: 'v1.perms.read',
        create: 'v1.perms.create',
        update: 'v1.perms.update',
        delete: 'v1.perms.delete',
    },
    listColumns: [
        {
            name: 'name',
            required: true,
            label: 'Имя',
            align: 'left',
            field: 'name',
            sortable: true,
        },
        {
            name: 'comment',
            required: true,
            label: 'Комментарий',
            align: 'left',
            field: 'comment',
            sortable: true,
        },
    ],
    listEdit: [
        {
            name: 'name',
            required: true,
            label: 'Имя',
            align: 'left',
            field: 'name',
            sortable: true,
        },
        {
            name: 'comment',
            required: true,
            label: 'Комментарий',
            align: 'left',
            field: 'comment',
            sortable: true,
        },
    ],
};

export {
    perms_ref,
};
