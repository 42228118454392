<template>
  <div>
    <entity-manager
        v-if="!loading && ref"
      :name="ref.name"
      :title="ref.title"
      :entity-url="ref.url"
      :perms="ref.perms"
      :filter-by="ref.filterBy"
      :list-columns="ref.listColumns"
      :list-create="ref.createColumns"
      :key="tableKey"
    >
      <template #top-right>
        <q-btn
            @click="reportDialog = true"
            v-if="hasAccess['v1.report.user.registration']"
        >
          Скачать отчёт
        </q-btn>
      </template>

      <template #body="props">
        <q-tr>
          <q-td
            v-for="column in ref.listColumns"
            :key="column.name"
          >
            <div
              v-if="column.map != null">
              {{ props.row[column.field] && props.row[column.field][column.map] || '[Не задано]' }}
            </div>
            <div
              v-else-if="column.type ==='checkbox'">
              <q-checkbox
                :value="props.row[column.field]"
              ></q-checkbox>
            </div>
            <div
              v-else-if="column.meta && column.meta.subtype && column.meta.subtype === 'date'">
              {{ formatDateStr(props.row[column.field]) }}
            </div>
            <div
              v-else>
              {{ props.row[column.field] || '[Не задано]' }}
            </div>
          </q-td>

          <!-- Колонка с действиями -->
          <q-td>
            <q-btn
              round flat
              text-color="primary"
              icon="done"
              @click="showDialog(props.row)"
            >
              <q-tooltip>Принять</q-tooltip>
            </q-btn>

            <q-btn
              round flat
              text-color="red"
              icon="close"
              @click="deny(props.row.id)"
            >
              <q-tooltip>Отклонить</q-tooltip>
            </q-btn>
          </q-td>
        </q-tr>
      </template>
    </entity-manager>

    <q-dialog v-model="reportDialog" v-if="reportDialog">
      <q-card style="width: 300px">
        <q-card-section>
          <q-input
              v-model="reportsStart"
              label="Начало периода"
              filled
              class="q-mb-md"
          >
            <template #append>
              <q-icon
                  name="event"
                  class="cursor-pointer"
              >
                <q-popup-proxy
                    transition-show="scale"
                    transition-hide="scale"
                >
                  <q-date
                      v-model="reportsStart"
                      mask="DD/MM/YYYY"
                  >
                    <div class="row items-center justify-end">
                      <q-btn
                          v-close-popup
                          label="Close"
                          color="primary"
                          flat
                      />
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>

          <q-input
              v-model="reportsEnd"
              label="Окончание периода"
              filled
          >
            <template #append>
              <q-icon
                  name="event"
                  class="cursor-pointer"
              >
                <q-popup-proxy
                    transition-show="scale"
                    transition-hide="scale"
                >
                  <q-date
                      v-model="reportsEnd"
                      mask="DD/MM/YYYY"
                  >
                    <div class="row items-center justify-end">
                      <q-btn
                          v-close-popup
                          label="Close"
                          color="primary"
                          flat
                      />
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>

          <q-card-actions align="right">
            <q-btn
                flat
                label="Отмена"
                :loading="report.loading"
                v-close-popup
            ></q-btn>

            <q-space />

            <q-btn
                flat
                label="Скачать"
                @click="getReport"
                :loading="report.loading"
            ></q-btn>
          </q-card-actions>
        </q-card-section>
      </q-card>
    </q-dialog>

    <q-dialog
        v-if="showAllowDialog"
        v-model="showAllowDialog"
    >
      <q-card v-if="allowItem" style="width: 300px">
        <!-- Заголовок -->
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">Создание записи</div>

          <q-space/>

          <q-btn
            icon="close"
            flat
            round
            dense
            v-close-popup
          />
        </q-card-section>

        <q-card-section>
          <DadataSelect
            v-model="editedItem.tin"
            v-bind="ref.createColumns[0].meta"
            :meta="ref.createColumns[0].meta"
            label="ИНН"
            ref="nDaData"
            @onSelect="onInput"
          ></DadataSelect>

          <q-input
            label="Наименование"
            v-model="editedItem.title"
            :hint="innHint"
            @input="(val) => !val.length || (innHint = '')"
          ></q-input>

          <ref-select
            label="Тип ТС"
            option-label="title"
            multiple
            url="/refs/vehicle_type"
            v-model="editedItem.vehicle_types"
          ></ref-select>

          <q-checkbox
            label="Является перевозчиком"
            v-model="editedItem.isTransportation"
          ></q-checkbox>

          <div>
            <q-input
              label="Имя пользователя"
              v-model="editedItem.user_name"
            ></q-input>

            <q-input
              label="Email пользователя"
              v-model="editedItem.user_email"
              type="email"
            ></q-input>

            <q-input
              label="Код авторизации"
              v-model="editedItem.confirmation_code"
              maxlength="4"
            ></q-input>

            <q-input
              label="Телефон пользователя"
              v-model="editedItem.user_phone"
              type="tel"
            ></q-input>
          </div>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn
            flat
            label="OK"
            color="primary"
            @click="register"
            :loading="allowLoading"
          ></q-btn>

          <q-btn
            flat
            label="Отмена"
            color="negative"
            v-close-popup
            :disable="allowLoading"
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { getData, METHODS, downloadBlob } from '@/utils/http';
import EntityManager from '@/components/entity_manager/EntityManager';
import DadataSelect from '@/components/fields/DadataSelect';
import RefSelect from '@/components/fields/RefSelect';
import { formatDate, FORMAT_FOR_USER } from '@/utils/date';
import HasPerms from '@/components/guard/mixins/HasPerms';

export default {
  name: 'RegistrationOrders',
  components: { RefSelect, DadataSelect, EntityManager },
  mixins: [HasPerms],
  data() {
    return {
      orders: [],
      loading: false,
      allowLoading: false,
      showAllowDialog: false,
      reportDialog: false,
      editedItem: {},
      allowItem: null,
      reportsStart: '',
      reportsEnd: '',
      innHint: '',
      tableKey: (new Date()).getTime(),
      report: {
        url: '/reports/user_registration',
        fileName: 'user_registration.xlsx',
        loading: false,
      },
      name: 'carriers',
      ref: null,
    };
  },
  created() {
    this.loadData();

    import('@/structures/carrier_application')
        .then((res) => {
          this.ref = res['carrier_application_ref'];
          this.loading = false;
        });

    if (this.$route.params.id) {
      this.loadDetailData(this.$route.params.id);
    }

    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const monthDays = this.getMonthDays(year, month - 1);

    this.reportsStart = this.getCurrentDate(year, month, 1);
    this.reportsEnd = this.getCurrentDate(year, month, monthDays);
  },
  methods: {
    getCurrentDate(year, month, day) {
      return `${_setZero(day)}/${_setZero(month)}/${year}`;
    },

    // Возвращает дату в формате для отправки на бэк
    getDateToAPiFormat(dateStr) {
      const dateArr = dateStr.replaceAll('/', '.').split('.');
      return dateArr.reverse().join('-');
    },

    getMonthDays(year, month) {
      return (new Date(year, month + 1, 0)).getDate();
    },

    onInput(value) {
      this.$set(this.editedItem, 'title', value.data.value);
      this.$set(this.editedItem, 'tin', value.data.inn);
      this.innHint = '';
    },

    loadDetailData(id) {
      this.loading = true;

      getData('/rbac/user_registrations/' + id, METHODS.GET)
          .then((res) => {
            this.showDialog(res.result);
          })
          .finally(() => {
            this.loading = false;
          });
    },

    loadData() {
      this.loading = true;

      getData('/rbac/user_registrations', METHODS.GET)
        .then((res) => {
          this.orders = res.result.items;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showDialog(item) {
      this.allowItem = item;
      this.editedItem = _copy(item);
      this.editedItem.user_phone = this.editedItem.phone;

      if (this.allowItem.tin != null) {
        this.$set(this.editedItem, 'tin', this.allowItem.tin);

        setTimeout(() => {
          this.$nextTick(async () => {
            if (this.$refs['nDaData'] != null) {
              const options = await this.$refs['nDaData'].rebuild(this.allowItem.tin);

              if (options.length === 1) {
                this.$set(this.editedItem, 'title', options[0].data.value);
              } else {
                this.innHint = 'Невозможно определить наименование';
              }
            }
          });
        }, 300);
      } else {
        this.$set(this.editedItem, 'title', '');
      }

      this.showAllowDialog = true;
    },
    formatDateStr(date) {
      return formatDate(date, FORMAT_FOR_USER.ONLY_DATE);
    },
    register() {
      this.allowLoading = true;
      // const ID = this.allowItem.id;

      const params = {
        ...this.editedItem,
        registration: this.allowItem,
      };

      if (params.tin == null && this.allowItem.tin != null) {
        params.tin = this.allowItem.tin;
      }

      getData('/carriers/from/bid', METHODS.POST, {
        params: params,
      })
        .then(() => {
          // this.closeBid(ID, 'ALLOWED');
          this.editedItem = {};
          this.allowItem = {};
          this.showAllowDialog = false;
        })
        .catch((res) => {
          let errMsg = 'Не удалось создать нового перевозчика';

          if (res && res.details && Object.keys(res.details).length) {
            errMsg = res.details[Object.keys(res.details)[0]][0];
          }

          this.$q.notify({
            message: errMsg,
            type: 'negative',
          });
        })
        .finally(() => {
          this.allowLoading = false;
        });
    },
    deny(id) {
      if (confirm('Хотите убрать заявку на регистрацию?')) {
        this.closeBid(id);
      }
    },
    getReport() {
      this.report.loading = true;
      const {url, fileName = 'report.xlsx'} = this.report;
      const startDt = this.getDateToAPiFormat(this.reportsStart);
      const endDt = this.getDateToAPiFormat(this.reportsEnd);
      let fullUrl = process.env.VUE_APP_HOST + url + `?start_date=${startDt}`;
      fullUrl += `&end_date=${endDt}`;

      fetch(fullUrl, {
        method: METHODS.GET,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.$store.getters['auth/getToken'],
          'X-Auth-Token': this.$store.getters['auth/getToken'],
        },
      }).then((res) => {
        if (res.ok)
          return res.blob();
        else
          throw new Error(`Ошибка при запросе отчёта: ${res.status} ${res.statusText}`);
      }).then((res) => {
        downloadBlob(res, fileName);
        this.$q.notify({
          message: 'Отчёт успешно построен',
          type: 'positive',
        });

        this.reportDialog = false;
      }).catch(() => {
        this.$q.notify({
          message: 'Ошибка при построении отчёта',
          type: 'negative',
        });
      }).finally(() => {
        this.report.loading = false;
      });
    },
    closeBid(id, status) {
      this.loading = true;

      getData('/rbac/user_registrations/' + id, METHODS.PUT, {
        params: {
          status: status || 'CLOSED',
        },
      })
        .then(() => {
          this.tableKey = (new Date()).getTime();
        })
        .catch((res) => {
          let errMsg = 'Не удалось закрыть заявку на регистрацию';

          if (res && res.details && Object.keys(res.details).length) {
            errMsg = res.details[Object.keys(res.details)[0]][0];
          }

          this.$q.notify({
            message: errMsg,
            type: 'negative',
          });
        })
        .finally(() => {
          this.loading = false;
          this.editedItem = {};
          this.allowItem = {};
        });
    },
    // @override
    getPerms_() {
      return [
        'v1.report.user.registration',
      ];
    },
  },
};
</script>
