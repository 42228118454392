<template>
  <div class="q-pa-sm q-gutter-sm">
    <q-table
      binary-state-sort
      row-key="name"
      :title="title"
      :data="data"
      :columns="columns"
      :loading="loading"
      :pagination.sync="paginationData"
      :filter="filterStr"
      @request="onRequest"
    >
      <!-- Поиск -->
      <template #top-left="props">
        <slot name="top-left" v-bind="props">
          <div class="flex">
            <q-input
              dense
              debounce="300"
              v-model="filterStr"
              placeholder="Поиск"
              v-if="canFilter"
            >
              <template #append>
                <q-icon name="search"/>
              </template>
            </q-input>

            <q-space/>

            <q-btn @click="refreshData"
                   class="q-ml-lg"
                   icon="refresh"
                   round
            >
              <q-tooltip>Обновить</q-tooltip>
            </q-btn>
          </div>
        </slot>
      </template>

      <!-- Верхняя часть таблицы-->
      <template #top-right="props">
        <slot name="top-right" v-bind="props">
          <!--Диалог создания/редактирования-->
          <div class="q-pa-md">
            <slot name="cu-dialog">
              <q-dialog v-model="showDialog">
                <q-card>
                  <!-- Заголовок -->
                  <q-card-section class="row items-center q-pb-none">
                    <div class="text-h6">
                      Создание / редактирование
                    </div>

                    <q-space/>

                    <q-btn flat round dense v-close-popup
                      icon="close"
                      class="q-ml-md"
                      @click="closeDialog"
                    ></q-btn>
                  </q-card-section>

                  <!-- Формируем поля для редактирования-->
                  <q-card-section style="max-height: 500px; overflow: auto">
                    <div style="max-height: 500px">
                      <div
                        v-for="column in dialogColumns"
                        :key="column.name"
                      >
                        <field-generator
                          v-model="editedItem[column.field]"
                          :label="column.label"
                          :type="column.type"
                          :meta="column.meta"
                        ></field-generator>
                      </div>
                    </div>
                  </q-card-section>

                  <!-- Действия -->
                  <q-card-actions align="right">
                    <q-btn
                      flat
                      label="OK"
                      color="primary"
                      :loading="editLoading"
                      @click="addRow"
                    ></q-btn>

                    <q-btn
                      flat
                      label="Отмена"
                      color="negative"
                      :disable="editLoading"
                      @click="closeDialog"
                    ></q-btn>
                  </q-card-actions>
                </q-card>
              </q-dialog>
            </slot>
          </div>

          <!-- Кнопка добавить -->
          <q-card-actions align="right">
            <q-btn
              flat outline
              align="right"
              color="primary"
              label="Добавить"
              icon="add"
              v-if="canAdd === true"
              @click="onShowDialog"
            ></q-btn>
          </q-card-actions>
        </slot>
      </template>

      <!-- Основная часть -->
      <template #body="props">
        <slot name="body" v-bind="props">
          <q-tr
            :props="props"
            :class="rowClass(props.row)"
            @click="onRowClick(props)"
          >
            <!-- Формирование колонок вручную и попапы для редактируемых полей -->
            <q-td
              v-for="column in listColumns"
              :key="column.name"
              :props="props"
            >

              <!-- Выборка определенного поля из объекта -->
              <div v-if="column.map != null">
                <!-- multiple -->
                <template v-if="column.type === 'multiple'">
                  <span
                    class="block"
                    v-for="it in props.row[column.field]"
                    :key="it[column.field]"
                  >
                    {{ it[column.map] || '[Не задано]' }}
                  </span>
                </template>

                <!-- Function -->
                <template v-else>
                  <template v-if="column.field.constructor.name === 'Function'">
                    {{ column.field(props.row) }}
                  </template>

                  <template v-else>
                    <template v-if="props.row[column.field]">
                      <template v-if="column.map">
                        {{ props.row[column.field][column.map] || '[Не задано]' | truncate(50) }}
                      </template>

                      <template v-else>
                        {{ props.row[column.field] | truncate(50) }}
                      </template>
                    </template>

                    <template v-else>
                      [Не задано]
                    </template>
                  </template>
                </template>
              </div>

              <!-- date -->
              <div v-else-if="column.type ==='date'">
                <template v-if="props.row[column.field]">
                  {{formatStrDate(props.row[column.field])}}
                </template>
              </div>

              <!-- checkbox -->
              <div v-else-if="column.type ==='checkbox'">
                <q-checkbox
                  :value="props.row[column.field]"
                ></q-checkbox>
              </div>

              <!-- enum -->
              <div v-else-if="column.type ==='enum'">
                <select-enum
                  :is-field="true"
                  :options="column.meta.options"
                  :value="props.row[column.field]"
                ></select-enum>
              </div>

              <div v-else>
                <!-- Function -->
                <template v-if="column.field.constructor.name === 'Function'">
                  {{ column.field(props.row) }}
                </template>

                <template v-else>
                  <template v-if="props.row[column.field]">
                    <template v-if="column.map">
                      {{ props.row[column.field][column.map] || '[Не задано]' | truncate(50) }}
                    </template>

                    <template v-else>
                      {{ props.row[column.field] | truncate(50) }}
                    </template>
                  </template>

                  <template v-else>
                    [Не задано]
                  </template>
                </template>
              </div>

              <!-- Если поле помечено как редактируемое, то его можно редактировать в  самой табличке -->
              <div v-if="column['editable'] === true">
                <q-popup-edit
                  v-if="editColumns.findIndex(it => it.field === column.field) > -1"
                  v-model="props.row[column.field]"
                  @save="savePopup(props.row)"
                >
                  <q-input
                    v-model="props.row[column.field]"
                    dense autofocus counter
                  ></q-input>
                </q-popup-edit>
              </div>

            </q-td>

            <!-- Колонка с действиями -->
            <q-td
              v-if="canEdit || canDelete"
              key="actions"
              :props="props"
            >
              <q-btn
                round flat
                text-color="primary"
                icon="edit"
                size="sm"
                v-if="canEdit"
                @click.stop.prevent="openDialog(props.row)"
              >
                <q-tooltip>
                  Редактировать
                </q-tooltip>
              </q-btn>

              <q-btn
                round flat
                icon="delete"
                size="sm"
                text-color="red"
                v-if="canDelete"
                :disable="!canDelete"
                @click.stop.prevent="deleteItem(props.row)"
              >
                <q-tooltip>
                  Удалить
                </q-tooltip>
              </q-btn>

              <slot name="actions" v-bind="props"></slot>
            </q-td>
          </q-tr>
        </slot>
      </template>
    </q-table>
  </div>
</template>

<script>
import FieldGenerator from '@/components/entity_manager/FieldGenerator';
import SelectEnum from '@/components/fields/SelectEnum';
import {FORMAT_FOR_USER, formatDate} from '@/utils/date';

export default {
  name: 'CrudTable',
  components: { SelectEnum, FieldGenerator },
  props: {
    title: String,
    data: Array,
    listColumns: Array,
    editColumns: Array,
    createColumns: Array,
    rowClass: {
      type: Function,
      default: () => {
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    defaultItem: {
      type: Object,
      default: () => ({}),
    },
    canAdd: {
      type: Boolean,
    },
    canEdit: {
      type: Boolean,
    },
    canDelete: {
      type: Boolean,
    },
    pagination: Object,
    filter: String,
    canFilter: {
      type: Boolean,
      default: false,
    },
    // Использовать встроенный генератор формы создания
    useNativeAdd: {
      type: Boolean,
      required: false,
      default: false,
    },
    // Использовать встроенный генератор формы редактирования
    useNativeEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showDialog: false,
      isEdit: false,
      editedIndex: -1,
      editedItem: {},
      paginationData: {},
      filterStr: '',
      model: null,
      editLoading: false,
    };
  },
  computed: {
    // Колонки для отображения
    columns() {
      let columns = _copy(this.listColumns);

      if (this.canEdit || this.canDelete) {
        columns.push({
          name: 'actions',
          label: 'Действия',
          field: 'actions',
        });
      }

      return columns;
    },
    // Колонки для создания/редактирования
    dialogColumns() {
      if (this.isEdit) {
        return this.editColumns ?? this.createColumns;
      } else {
        return this.createColumns ?? this.editColumns;
      }
    },
  },
  watch: {
    pagination(value) {
      this.paginationData = value;
    },
    paginationData(value) {
      this.$emit('update:pagination', value);
    },
  },
  created() {
    this.paginationData = this.pagination;
    // this.filterData = this.filter;
  },
  methods: {
    // Метод перезагрузки таблицы
    refreshData() {
      this.$emit('onRefresh');
    },

    onRowClick(props) {
      this.$emit('onRowClick', props.row, props['pageIndex']);
    },

    onRequest(props) {
      this.$emit('update:pagination', props.pagination);
      this.$emit('onRequest', props);
    },

    addRow() {
      this.editLoading = true;

      if (this.editedIndex > -1) {
        if (this.data[this.editedIndex]) {
          this.$emit('onEdit', this.editedItem, () => {
            this.editLoading = false;

            this.closeDialog();
          }, (err) => {
            this.editLoading = false;
            let error = err?.error || 'Ошибка при сохранении записи. Обратитесь к администратору';
            this.$q.notify({
              message: error,
              type: 'negative',
            });
          });
        }

        Object.assign(this.data[this.editedIndex], this.editedItem);
      } else {
        this.$emit('onAdd', this.editedItem, () => {
          this.editLoading = false;

          this.closeDialog();
        }, (err) => {
          this.editLoading = false;
          let error = err?.error || 'Ошибка при сохранении записи. Обратитесь к администратору';
          this.$q.notify({
            message: error,
            type: 'negative',
          });
        });
      }
    },

    deleteItem(item) {
      if (confirm('Вы точно хотите удалить запись?')) {
        this.$emit('onDelete', item);
      }
    },

    editItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
    },

    savePopup(item) {
      this.editItem(item);
      Object.assign(this.data[this.editedIndex], this.editedItem);
      this.addRow();
    },

    openDialog(item) {
      if (item) {
        this.isEdit = true;
      }

      this.editItem(item);

      if (this.useNativeEdit) {
        this.showDialog = true;
      }

      this.$emit('onEditClick', _copy(this.editedItem));
    },

    closeDialog() {
      this.showDialog = false;
      this.isEdit = false;

      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    onShowDialog() {
      if (this.useNativeAdd) {
        this.showDialog = true;
      }

      this.$emit('onAddClick', {});
    },

    formatStrDate(date) {
      return formatDate(date, FORMAT_FOR_USER.ONLY_DATE);
    },
  },
};
</script>

<style scoped>
.q-dialog__inner--minimized > div {
  max-width: 700px;
}
</style>
