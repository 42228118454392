/**
 * Сервис для работы с датой
 */

// Дни недели
const daysOfWeek = ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'];

function dayOfWeekToFormat(date) {
  return daysOfWeek[new Date(date).getDay()];
}

const FORMAT_FOR_SERVER = {
  ONLY_DATE: 'yyyy-MM-dd',
  DATE_TIME: 'yyyy-MM-dd HH:mm:ss',
  START_DAY_DATE: 'yyyy-MM-dd 00:00:00',
  END_DAY_DATE: 'yyyy-MM-dd 23:59:59',
};

const FORMAT_FOR_USER = {
  ONLY_DATE: 'dd/MM/yyyy',
  DATE_TIME: 'dd/MM/yyyy HH:mm:ss',
};

function prepareDate(dateStr) {
  const str = String(dateStr).replace(/-/g, '');
  let dt_year = str.substring(0, 4);
  let dt_month = str.substring(4, 6);
  let dt_date = str.substring(6, 8);

  return [dt_year, dt_month, dt_date];
}
/**
 * Приведение даты к определенному формату
 * @param date - дата, может быть как ISO, так и Date
 * @param format - формат к которому будет приведена дата
 * @param isReturnNull - вернуть null если дата пустая, иначе будет выбрана текущая дата
 * @returns {String | Null} formattedDate
 */
function formatDate(date, format, isReturnNull = false) {
  if (isReturnNull && !date) return null;

  // Если date в форматированном виде (для вывода пользователю), то преобразовать к ISO
  if (typeof date === 'string') {
    // TODO: доделать (переделать) функцию, которая из форматированного вида приводила к ISO
    let countSlash = 0;
    for (const char of date) {
      if (char === '/') {
        countSlash++;
      }
    }
    if (countSlash === 2) {
      date = date.replaceAll('/', '-');
    }
  }

  const d = date != null ? new Date(date) : new Date();

  const day = d.getDate();
  const month = d.getMonth() + 1;
  const year = d.getFullYear();
  const hours = d.getHours();
  const minutes = d.getMinutes();
  const seconds = d.getSeconds();

  let result = format;

  result = result.replace(/dd/, setPadding(day));
  result = result.replace(/MM/, setPadding(month));
  result = result.replace(/yyyy/, year);
  result = result.replace(/HH/, setPadding(hours));
  result = result.replace(/mm/, setPadding(minutes));
  result = result.replace(/ss/, setPadding(seconds));

  return result;
}

// Приведение даты в удобочитаемый формат
function parseDateFromStr(str) {
  const dt = prepareDate(str);

  return `${dt[0]}/${dt[1]}/${dt[2]}`;
}

// Возвращает дату в формате для отправки на бэк
function getDateToAPiFormat(dateStr) {
  const dateArr = dateStr.replaceAll('/', '.').split('.');

  // Если первый элемент является годом
  // Такое происходит из-за элемента выбора даты
  // Иногда он может менять даты
  if (dateArr[0].length === 4) {
    return dateArr.join('-');
  }

  return dateArr.reverse().join('-');
}

/**
 * @param {String|Number} value
 * @param {Number} size
 * @return {String}
 */
function setPadding(value, size = 2) {
  let s = `${value}`;

  while (s.length < size) {
    s = `0${s}`;
  }

  return s;
}

function getTodayDate() {
  return new Date().toISOString().substr(0, 10);
}

function getTodayDateShift(shift = 0) {
  const date = new Date();
  date.setDate(date.getDate() + shift);
  return date.toISOString().substr(0,10);
}

function getYear() {
  return parseInt(new Date().toISOString().substr(0, 4));
}

function calculateDateDiff(beginDate, endDate) {
  const targetDate = new Date(beginDate);
  const currentDate = endDate == null ? new Date() : new Date(endDate);
  const differenceDate = currentDate > targetDate
    ? new Date(currentDate - targetDate)
    : new Date(targetDate - currentDate);

  return ((differenceDate.getUTCDate() - 1) + 'д.,'
    + differenceDate.getUTCHours() + 'ч.,'
    + differenceDate.getUTCMinutes() + 'м.');
}

export {
  FORMAT_FOR_SERVER,
  FORMAT_FOR_USER,
  dayOfWeekToFormat,
  formatDate,
  parseDateFromStr,
  getDateToAPiFormat,
  getTodayDate,
  getTodayDateShift,
  getYear,
  prepareDate,
  calculateDateDiff,
};
