<template>
  <div class="q-pt-md">
    <div class="row q-mb-md items-center">
      <h6 class="q-ma-none">Данные за {{ currentDate }}</h6>

      <q-btn @click="loadData"
             round
             icon="refresh"
             class="q-ml-md"
             :disable="loading"
      >
        <q-tooltip>Обновить</q-tooltip>
      </q-btn>
    </div>

    <q-table
        class="my-sticky-column-table"
        :data="vehicles_data"
        :columns="columns"
        row-key="vehicle"
        :pagination.sync="pagination"
        :loading="loading"
    >
      <template #body="props">
        <tr
            :props="props"
            :class="getWeightIndicator(props.row)"
        >
          <q-td
              v-for="column in props.cols"
              :key="`${column.name}_${props.rowIndex}`"
          >
            <template v-if="column.name === 'vehicle'">
              {{ column.value.vehicle }} /
              <template v-if="column.value.trailers">
                {{ column.value.trailers }}
              </template>
              <q-icon v-else color="red" name="warning"></q-icon>
            </template>

            <template v-else-if="column.name === 'diff_weight'">
              {{ column.value.diff }}
              <template v-if="column.value.percent > 3">
                (-{{ column.value.percent }}%)
              </template>

              <template v-else-if="column.value.percent < 0">
                (+{{ -column.value.percent }}%)
              </template>
            </template>

            <template v-else>
              {{ column.value }}
            </template>
          </q-td>
        </tr>
      </template>
    </q-table>
  </div>
</template>

<script>
import {getData, METHODS} from '@/utils/http';
import {DateTime} from 'luxon';

export default {
  name: 'ReportByStatuses',
  data() {
    return {
      loading: false,
      vehicleDetailDialog: false,
      vehicles_data: [],
      currentVehicle: null,
      currentDate: DateTime.now().toLocaleString(DateTime.DATE_SHORT),
      pagination: {
        rowsPerPage: 0,
      },
      columns: [
        {
          name: 'index',
          label: 'П/п',
          align: 'left',
          field: 'index',
        },
        {
          name: 'vehicle',
          label: 'Транспорт/прицеп',
          align: 'left',
          field: (row) => {
            return {
              vehicle: row.reg_number,
              trailers: row.trailers.map((it) => it.reg_number).join(', '),
            };
          },
        },
        {
          name: 'vehicle_status',
          label: 'Статус ТС',
          align: 'left',
          field: (row) => row?.active_status?.title || '',
        },
        {
          name: 'contragent_name',
          label: 'Контрагент',
          align: 'left',
          field: (row) => {
            return row.last_vehicle_order.contractor?.title;
          },
        },
        {
          name: 'drivers',
          label: 'Водитель',
          align: 'left',
          field: (row) => {
            return row.drivers.map((d) => d.user.full_name).join(', ');
          },
        },
        {
          name: 'date_status_change',
          label: 'Дата и время изменения статуса',
          align: 'left',
          field: (row) => {
            if (row?.active_status?.pivot?.start_date) {
              return DateTime.fromSQL(row?.active_status?.pivot?.start_date)
                  .toLocaleString(DateTime.DATETIME_SHORT);
            }
            return '';
          },
        },
        {
          name: 'time_status_work',
          label: 'Кол-во час. и мин. действия статуса',
          align: 'left',
          field: (row) => {
            if (row.active_status) {
              const startDate = DateTime.fromSQL(row.active_status.pivot.start_date);
              const endDate = DateTime.now();
              const diff = endDate.diff(startDate, ['days', 'hours', 'minutes']);
              return `${diff.days} дн., ${diff.hours} ч., ${Math.floor(diff.minutes)} мин.`;
            }
            return '';
          },
        },
        {
          name: 'capacity',
          label: 'Макс. полезная нагрузка сцепки, кг.',
          align: 'left',
          field: (row) => {
            if (row.trailers.length === 0) {
              return 'Нет активной сцепки';
            }
            const weight = row.tmp.capacityWeight !== 0 ? (row.tmp.capacity) / 1000 : 0;
            return _roundHundredths(weight);
          },
        },
        {
          name: 'loaded',
          label: 'Загруженный вес',
          align: 'left',
          field: (row) => row?.active_status?.pivot?.vehicle_order?.loaded || '',
        },
        {
          name: 'diff_weight',
          label: 'Отклонение',
          align: 'left',
          field: (row) => {
            if (row.trailers.length === 0) {
              return {
                diff: 'Нет активной сцепки',
                percent: 0,
              };
            }

            if (!row.active_status) {
              return {
                diff: 'Не загружен',
                percent: 0,
              };
            }

            const loaded = row?.active_status?.pivot?.vehicle_order?.loaded;

            if (loaded == null) {
              return {
                diff: '',
                percent: 0,
              };
            }

            if (loaded === 0) {
              if (row.active_status.code >= 4) {
                return {
                  diff: 'Водитель не указал вес',
                  percent: 0,
                };
              }

              return {
                diff: '',
                percent: 0,
              };
            }
            let capacity = (row.tmp.capacity > 0 ? row.tmp.capacity : 1) / 1000;
            const diff = capacity - loaded;

            return {
              diff: _roundHundredths(-diff),
              percent: _roundHundredths(diff * 100 / capacity),
            };
          },
        },
      ],
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true;
      const curr_date = DateTime.now().toISODate();
      const URL = `/reports/by/status?start_date=${curr_date}&end_date=${curr_date}`;

      getData(URL, METHODS.GET, {})
          .then((res) => {
            this.vehicles_data = res.result.map((it, index) => {
              const capacityWeight = parseInt(it?.trailers[0]?.pivot?.vehicle_load_capacity?.weight || 0, 10);
              const trailerWeight = it?.trailers[0]?.weight || 0;
              const vehicleWeight = it?.weight || 0;

              let capacity = capacityWeight - trailerWeight - vehicleWeight;


              return {
                ...it,
                index: index + 1,
                // Временные параметр упрощающий расчёты в field
                tmp: {
                  capacityWeight,
                  trailerWeight,
                  vehicleWeight,
                  capacity,
                },
              };
            });
          })
          .catch((res) => {
            this.$q.notify({
              message: res.error,
              type: 'negative',
            });
          })
          .finally(() => {
            this.loading = false;
          });
    },
    getWeightIndicator(row) {
      if (row?.active_status && !row.active_status.is_vehicle_active) {
        return 'bg-grey-2';
      }

      if (row.last_vehicle_order.length && row.trailers.length) {
        let capacity = row.tmp.capacity > 0 ? row.tmp.capacity : 1;
        const diff = (capacity - (row.orders[0].loaded || 0)) * 100;

        if (diff / capacity >= 3) {
          return 'bg-orange-2';
        } else if (diff / capacity < 0) {
          return 'bg-red-2';
        }
      }

      if (!row.drivers.length) {
        return 'bg-orange-2';
      }

      return '';
    },
  },
};
</script>

<style lang="sass">
.my-sticky-column-table
  /* specifying max-width so the example can
    highlight the sticky column on any browser window */
  max-width: 100%

  th:nth-child(2),
  td:nth-child(2)
    position: sticky
    left: 0
    z-index: 1

  th:nth-child(2)
    background: #fff

</style>
