import Vue from 'vue';

import '@/styles/quasar.sass';
import iconSet from 'quasar/icon-set/mdi-v4.js';
import lang from 'quasar/lang/ru.js';
import '@quasar/extras/mdi-v4/mdi-v4.css';
import '@quasar/extras/material-icons/material-icons.css';
import Notify from 'quasar/src/plugins/Notify.js';import Quasar from 'quasar/src/vue-plugin.js';;

const isProd = process.env.VUE_APP_ENV === 'prod';

Vue.use(Quasar, {
  plugins: {
    Notify,
  },
  config: {
    brand: {
      primary: isProd ? '#0288d1' : '#258f49',
      negative: 'red',
    },
    notify: {
      color: 'primary',
      position: 'bottom',
    },
  },
  lang: lang,
  iconSet: iconSet,
});
