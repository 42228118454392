import Vue from 'vue';
import App from './App.vue';
import router from './router/router';
import store from './store';

import './plugins/quasar';
import './plugins/functions';

import { Settings } from 'luxon';
Settings.defaultLocale = 'fr';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
