<template>
  <div>
    <entity-manager
        :name="ref.name"
        :title="ref.title"
        :entity-url="ref.url"
        :list-columns="ref.listColumns"
        :list-edit="ref.editColumns"
        :include="ref.include"
        :filterBy="ref.filterBy"
        :perms="ref.perms"
        :access="ref.access"
        :pagination_rules="pagination"
        :key="tableKey"
    >
      <template #body="props">
        <q-tr :props="props">
          <q-td
              v-for="column in ref.listColumns"
              :key="column.name"
              :props="props"
          >
            <!-- Выборка определенного поля из объекта -->
            <div
                v-if="column.map != null">
              <template v-if="column.type === 'multiple'">
                  <span
                      class="block"
                      v-for="it in props.row[column.field]"
                      :key="it[column.field]"
                  >
                    {{ it[column.map] || '[Не задано]' }}
                  </span>
              </template>

              <template v-else>
                {{ props.row[column.field] && props.row[column.field][column.map] || '[Не задано]' | truncate(50) }}
              </template>
            </div>

            <div
                v-else-if="column.type ==='checkbox'">
              <q-checkbox
                  :value="props.row[column.field]"
              ></q-checkbox>
            </div>

            <div
                v-else>
              {{ props.row[column.field] || '[Не задано]' }}
            </div>
          </q-td>

          <!-- Колонка с действиями -->
          <q-td key="actions">
            <q-btn round flat
                   text-color="primary"
                   icon="edit"
                   @click="editDialog(props)"
            >
              <q-tooltip>Изменить</q-tooltip>
            </q-btn>

            <q-btn v-if="true"
                   round flat
                   text-color="red"
                   icon="delete"
                   @click="remove(props.row)"
            >
              <q-tooltip>Удалить</q-tooltip>
            </q-btn>
          </q-td>
        </q-tr>
      </template>
    </entity-manager>

    <q-dialog v-if="showEditDialog && editItem" v-model="showEditDialog">
      <q-card>
        <!-- Заголовок -->
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">
            Редактирование записи
          </div>

          <q-space/>

          <q-btn flat round dense
                 v-close-popup
                 icon="close"
                 class="q-ml-md"
          ></q-btn>
        </q-card-section>

        <!-- Формируем поля для редактирования-->
        <q-card-section style="max-height: 500px; overflow: auto">
          <div style="max-height: 500px">
            <q-input
                label="Имя"
                v-model="editItem.user.full_name"
            />

            <q-input
                label="Номер телефона"
                v-model="editItem.user.phone"
            />

            <q-input
                label="Код подтверждения"
                v-model="editItem.user.confirmation_code"
            />
          </div>
        </q-card-section>

        <!-- Действия -->
        <q-card-actions align="right">
          <q-btn
              flat
              label="OK"
              color="primary"
              :loading="editLoading"
              @click="sendEditData"
          ></q-btn>

          <q-btn
              flat
              label="Отмена"
              color="negative"
              :disable="editLoading"
              v-close-popup
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import EntityManager from '@/components/entity_manager/EntityManager';
import {getData, METHODS} from '@/utils/http';
import {drivers_ref} from '@/structures/drivers';

export default {
  name: 'Drivers',
  components: {EntityManager},
  props: {
    title: String,
    entity: String,
  },
  data() {
    return {
      showAllowDialog: false,
      showEditDialog: false,
      fields: [],
      edit: [],
      data: [],
      editItem: null,
      loading: false,
      editLoading: false,
      tableKey: (new Date()).getTime(),
      pagination: {
        rowsPerPage: 1000,
      },
      ref: drivers_ref,
    };
  },
  methods: {
    onInput(value) {
      this.editItem.title = value.data.value;
    },

    sendData() {
      this.loading = true;
      const method = this.editItem.id ? METHODS.PUT : METHODS.POST;
      const url = '/drivers' + (this.editItem.id ? '/' + this.editItem.id : '');

      getData(url, method, {
        params: this.editItem,
      })
          .then(() => {
            this.tableKey = (new Date()).getTime();
            this.showAllowDialog = false;
            this.editItem = {};
          })
          .catch(() => {
            this.$q.notify({
              message: 'Не удалось создать запись',
              type: 'negative',
            });
          })
          .finally(() => {
            this.loading = false;
          });
    },

    remove(item) {
      if (confirm('Уверены, что хотите удалить водителя?')) {
        this.loading = true;

        getData('/drivers/' + item.id, METHODS.DELETE)
            .then(() => {
              this.tableKey = (new Date()).getTime();
            })
            .catch(() => {
              this.$q.notify({
                message: 'Не удалось удалить запись',
                type: 'negative',
              });
            })
            .finally(() => {
              this.loading = false;
            });
      }
    },

    editDialog(props) {
      this.showEditDialog = true;
      this.editItem = _copy(props.row);
    },

    sendEditData() {
      this.editLoading = true;
      const url = '/rbac/users/' + this.editItem.user.id;

      getData(url, METHODS.PUT, {
        params: {
          full_name: this.editItem.user.full_name,
          phone: this.editItem.user.phone,
          confirmation_code: this.editItem.user.confirmation_code,
        },
      })
          .then(() => {
            this.tableKey = (new Date()).getTime();
            this.editItem = null;
            this.showEditDialog = false;
          })
          .catch(() => {
            this.$q.notify({
              message: 'Не удалось создать запись',
              type: 'negative',
            });
          })
          .finally(() => {
            this.editLoading = false;
          });
    },
  },
};
</script>
