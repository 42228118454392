<template>
  <q-splitter
      v-model="splitterModel"
      style="height: 100%"
  >
    <template #before>
      <q-tabs
          vertical inline-label
          v-model="tab"
          :breakpoint="0"
          class="text-primary bg-white shadow-2"
          @input="changeKey"
      >
        <q-route-tab
            v-for="ref in refs"
            v-show="hasAccess[ref.perms.create]"
            :key="ref.name"
            :name="ref.name"
            :label="ref.title"
            style="justify-content:initial"
            :to="`/organization/finance/${ref.name}`"
        ></q-route-tab>
      </q-tabs>
    </template>

    <template #after>
      <router-view :key="routerKey" />
    </template>
  </q-splitter>
</template>

<script>
import HasPerms from '@/components/guard/mixins/HasPerms';

export default {
  name: 'Finance',
  mixins: [HasPerms],
  data() {
    return {
      routerKey: (new Date()).getTime(),
      tab: 'expenses',
      splitterModel: 20,
      // Содержит список доступных прав от entity manager
      refs: [
        {
          name: 'expenses',
          title: 'Учет расходов',
          perms: {
            create: 'v1.expenses.create',
          },
        },
        {
          name: 'expenses_types',
          title: 'Статьи расходов',
          perms: {
            create: 'v1.expenses.create',
          },
        },
        {
          name: 'incomes',
          title: 'Выплаты',
          perms: {
            create: 'v1.incomes.list',
          },
        },
      ],
    };
  },
  methods: {
    getPerms_() {
      const perms = this.refs.map((ref) => ref.perms.create);

      return perms;
    },
    changeKey() {
      this.routerKey = (new Date()).getTime();
    },
  },
};
</script>
