<template>
  <q-select
    v-model="model"
    :label="label"
    :options="options"
    :rules="rules"
    use-input fill-input hide-selected
    behavior="menu"
    input-debounce="1000"
    @filter="loadDadata"
    v-close-popup="false"
    @input="onInput"
  >
    <template #no-option>
      <q-item>
        <q-item-section class="text-grey">
          <span v-if="numberCharacterDadata > 2">
            Отсутствует результат
          </span>

          <span v-else>
            Вы ввели меньше 3 символов
          </span>
        </q-item-section>
      </q-item>
    </template>
  </q-select>
</template>

<script>
let _build = false;

export default {
  name: 'DadataSelect',
  props: {
    value: null,
    label: String,
    meta: Object,
    rules: Object,
    map: null,
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      model: {},
      options: [],
      numberCharacterDadata: Number,
    };
  },
  watch: {
    model(selected) {
      if (selected) {
        this.$emit('input', selected.value);
      }
    },
  },
  created() {
    this.model = this.value;
  },
  methods: {
    async rebuild(tin) {
      if (tin != null && tin !== '' && tin.length > 2) {
        // TODO: вынести в env
        const token = '552b15ee51ba61b10624d3009bf6bac764842df2';
        const response = await fetch(
          'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party',
          {
            method: 'POST',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': 'Token ' + token,
            },
            body: JSON.stringify({ query: tin }),
          },
        );

        const res = await response.json();
        let suggestions = res.suggestions || [];

        let options = [];

        suggestions = suggestions.filter((opt) => {
          if (opt.data.state && opt.data.state.status) {
            return opt.data.state.status === 'ACTIVE';
          }

          return true;
        });

        if (this.map == null) {
          options = suggestions.map((row) => (
              { label: row.data.inn + ` (${row.value})`, value: row.data.inn, data: row }
            ),
          );
        } else {
          options = suggestions.map((row) => (
              { label: row.data[this.map] + ` (${row.value})`, value: row.data[this.map], data: row }
            ),
          );
        }

        return _copy(options);
      }
    },
    // Интеграция с Dadata
    loadDadata(val, update) {
      if (_build) {
        update(() => {
          this.options = _copy(this.options);
        });

        _build = false;
      } else {
        this.numberCharacterDadata = val.length || 0;

        update(() => {
          if (val !== '' && val.length > 2) {
            // TODO: вынести в env
            const token = '552b15ee51ba61b10624d3009bf6bac764842df2';
            fetch(
              'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party',
              {
                method: 'POST',
                mode: 'cors',
                headers: {
                  'Content-Type': 'application/json',
                  'Accept': 'application/json',
                  'Authorization': 'Token ' + token,
                },
                body: JSON.stringify({ query: val }),
              },
            ).then((res) => res.json())
              .then((res) => {
                let suggestions = res.suggestions || [];

                suggestions = suggestions.filter((opt) => {
                  if (opt.data.state && opt.data.state.status) {
                    return opt.data.state.status === 'ACTIVE';
                  }

                  return true;
                });

                if (this.map == null) {
                  this.options = suggestions.map((row) => (
                      { label: row.data.inn + ` (${row.value})`, value: row.data.inn, data: row }
                    ),
                  );
                } else {
                  this.options = suggestions.map((row) => (
                      { label: row.data[this.map] + ` (${row.value})`, value: row.data[this.map], data: row }
                    ),
                  );
                }
              })
              .catch(() => {
                this.dadataVar = [];
              });
          }
        });
      }
    },

    onInput() {
      this.$emit('onSelect', this.model);
    },
  },
};
</script>
