<template>
  <q-dialog v-model="show"
            maximized>
    <div class="bg-white column">
      <q-header class="bg-primary col-auto">
        <q-toolbar>
          <q-toolbar-title style="line-height: 110%">
            Карта
          </q-toolbar-title>

          <!-- Закрыть -->
          <q-btn
            color="red"
            class="q-ml-md"
            :loading="loading"
            @click="$emit('close')"
          >
            Закрыть
          </q-btn>
        </q-toolbar>
      </q-header>

      <!-- Карта -->
      <div class="col full-height">
        <div id="map_select_vehicle"
             class="full-width full-height"
        />
      </div>
    </div>
  </q-dialog>
</template>

<script>
import Map from '@/utils/map';
import {calculateDateDiff, FORMAT_FOR_USER, formatDate} from '@/utils/date';
import {METHODS} from '@/utils/http';

const mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');

export default {
  name: 'MapWithSelectVehicle',
  data() {
    return {
      show: true,
      loading: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.initMap();
    }, 300);
  },
  beforeDestroy() {
    Map.clear();
  },
  methods: {
    async initMap() {
      const map = await Map.init({
        container: document.getElementById('map_select_vehicle'),
      });

      map.loadImage('https://i.imgur.com/LcIng3L.png', (e, img) => {
        if (e) {
          _err('err image', e);

          return;
        }

        map.addImage('arrow', img);

        Map.createOrDataLayer('arrow-layer', {
          id: 'arrow-layer',
          type: 'symbol',
          source: 'vehicles',
          layout: {
            'visibility': 'visible',
            // icon
            'icon-allow-overlap': true,
            'icon-image': 'arrow',
            'icon-size': 0.045,
            'icon-rotate': ['get', 'heading'],
            // text
            'text-field': ['get', 'govnum'],
            'text-font': [
              'Open Sans Semibold',
              'Arial Unicode MS Bold',
            ],
            'text-offset': [0, 0.65],
            'text-anchor': 'top',
            'text-allow-overlap': true,
          },
        });
      });

      // Источник с машинками
      Map.createOrDataSource('vehicles', {
        type: 'FeatureCollection',
        features: [],
      });

      // Слой текста машинок
      Map.createOrDataLayer('vehicles_govnum', {
        id: 'vehicles_govnum',
        type: 'symbol',
        source: 'vehicles',
        layout: {
          'text-field': ['get', 'govnum'],
          'text-font': [
            'Open Sans Semibold',
            'Arial Unicode MS Bold',
          ],
          'text-offset': [0, 0.65],
          'text-anchor': 'top',
        },
      });

      map.on('click', 'arrow-layer', (e) => {
        var coordinates = e.features[0].geometry.coordinates.slice();
        const props = e.features[0].properties;

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }


        const user = JSON.parse(props.user);
        const innerHtmlContent = `
              <div style="min-width: 600px; font-size: large; color: black;">
                  Гос. номер: ${props.govnum}<br />
                  Водитель: ${user?.full_name || 'Не назначено'}<br />
                  Последняя телеметрия:
                  ${formatDate(props.pastTime, FORMAT_FOR_USER.DATE_TIME)} / ${calculateDateDiff(props.pastTime)}
              </div>
            `;
        const divElement = document.createElement('div');
        const btn = document.createElement('div');
        // eslint-disable-next-line max-len
        btn.innerHTML = '<button class="btn btn-success btn-simple" style="margin-top: 10px; height: 40px; width: 140px; font-size: large;">Выбрать ТС</button>';
        divElement.innerHTML = innerHtmlContent;
        divElement.appendChild(btn);
        btn.addEventListener('click', () => {
          this.$emit('selectVehicle', JSON.parse(props.vehicle));
        });

        new mapboxgl.Popup()
          .setMaxWidth(600)
          .setLngLat(coordinates)
          .setDOMContent(divElement)
          .addTo(map);
      });

      this.allVehicles();
    },

    allVehicles() {
      this.loading = true;
      fetch(process.env.VUE_APP_HOST_TELEMETRY, {
        method: METHODS.POST,
        headers: {
          'Content-Type': 'application/json',
          'X-Auth-Token': this.$store.getters['auth/getToken'],
        },
        body: JSON.stringify({
          jsonrpc: '2.0',
          id: 1,
          method: 'telemetry.points.vehicles',
          params: {},
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          const result = response?.result ?? [];

          if (result.length) {
            Map.createOrDataSource('vehicles', {
              type: 'FeatureCollection',
              features: result.map((it) => ({
                type: 'Feature',
                properties: {
                  govnum: it.extra?.vehicle?.reg_number ?? 'No Data',
                  heading: it.heading + 100,
                  user: it.extra?.user ?? {},
                  vehicle: it.extra?.vehicle ?? {},
                  pastTime: it?.telemetry_created_at,
                },
                geometry: {
                  type: 'Point',
                  coordinates: [
                    it.lng, it.lat,
                  ],
                },
              })),
            });

            const coordinates = [
              result[0].lng, result[0].lat,
            ];

            const bounds = result.reduce(function(bounds, item) {
              return bounds.extend([
                item.lng, item.lat,
              ]);
            }, new mapboxgl.LngLatBounds(coordinates, coordinates));

            Map.getMap().fitBounds(bounds, {
              padding: 20,
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
