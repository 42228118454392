const providers = {
    name: 'ref_providers',
    title: 'Поставщики',
    url: '/refs/providers',
    include: [],
    filterBy: ['title'],
    perms: {
        list: 'v1.providers.list',
        read: 'v1.providers.read',
        create: 'v1.providers.create',
        update: 'v1.providers.update',
        delete: 'v1.providers.delete',
    },
    listColumns: [
        {
            name: 'title',
            required: true,
            label: 'Наименование',
            align: 'left',
            field: 'title',
            sortable: true,
        },
    ],
    editColumns: [
        {
            name: 'title',
            required: true,
            label: 'Наименование',
            align: 'left',
            field: 'title',
            sortable: true,
        },
    ],
};

export {
    providers,
};
