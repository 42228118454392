<template>
  <div>
    <div class="flex justify-center">
      <div
          class="col-auto q-pa-md bg-grey-1"
          style="width: 250px;"
      >
        <div
            v-if="!has_vehicle"
            class="q-mb-md"
        >
          <ref-select
              label="Транспорт"
              url="/vehicles"
              v-model="vehicle"
              option-label="reg_number"
              option-value="id"
              :autocomplete="true"
              @input="getVehicleData"
          ></ref-select>

          <div v-if="!vehicle">Выберите ТС для получения информации</div>
        </div>

        <div v-else>
          <template v-if="!vehicleAllData">
            <q-spinner/>
          </template>

          <template v-else>
            <h6 class="q-ma-none q-mb-md">Текущие данные о ТС</h6>

            <div class="q-mb-md">
              <div class="flex">
                <span class="text-subtitle1">Прицеп</span>

                <q-btn round
                       size="sm"
                       class="q-ml-md"
                       icon="edit"
                       @click="trailersDialog = !trailersDialog"
                >
                  <q-tooltip>Изменить</q-tooltip>
                </q-btn>
              </div>

              <template v-if="!vehicleDataLoading">
                <div v-if="vehicleAllData && vehicleAllData.trailers.length">
                  <div>
                    <div>{{ vehicleAllData.trailers[0].reg_number }}</div>
                    <span class="text-caption">c {{ formatDateStr(vehicleAllData.trailers[0].pivot.start_date) }}
                      <template v-if="vehicleAllData.trailers[0].pivot.end_date">
                        до {{ formatDateStr(vehicleAllData.trailers[0].pivot.end_date) }}
                      </template>
                    </span>
                  </div>
                </div>

                <span v-else>Не установлен</span>
              </template>

              <q-spinner v-else/>
            </div>

            <div class="q-mb-md">
              <div class="flex">
                <span class="text-subtitle1">Водители</span>

                <q-btn
                    round
                    size="sm"
                    class="q-ml-md"
                    icon="edit"
                    @click="driversDialog = !driversDialog"
                >
                  <q-tooltip>Изменить</q-tooltip>
                </q-btn>
              </div>

              <template v-if="!vehicleDataLoading">
                <div v-if="vehicleAllData && vehicleAllData.drivers.length">
                  <div
                      v-for="(driver, index) in vehicleAllData.drivers"
                      :key="`driver_${driver.id}_${index}`"
                  >
                    <q-separator v-if="index !== 0"/>
                    <div>{{ driver.user.full_name }}</div>
                    <span class="text-caption">
                      c {{ formatDateStr(driver.pivot.start_date) }}
                      <template v-if="driver.pivot.end_date">
                        до {{ formatDateStr(driver.pivot.end_date) }}
                      </template>
                    </span>
                  </div>
                </div>

                <span v-else>Не назначен</span>
              </template>

              <q-spinner v-else/>
            </div>

            <div class="q-mb-md">
              <div class="flex">
                <span class="text-subtitle1">Пробег</span>

                <q-btn
                    round
                    size="sm"
                    class="q-ml-md"
                    icon="edit"
                    @click="odometerDialog = !odometerDialog"
                >
                  <q-tooltip>Изменить</q-tooltip>
                </q-btn>
              </div>

              <template v-if="!vehicleDataLoading">
                <div v-if="vehicleAllData && vehicleAllData.odometers.length">
                  <div>
                    <div>{{ vehicleAllData.odometers[0].distance }} км</div>
                    <span class="text-caption">
                      Дата отметки: {{ formatDateStr(vehicleAllData.odometers[0].date) }}
                    </span>
                  </div>
                </div>

                <span v-else>Не установлен</span>
              </template>

              <q-spinner v-else/>
            </div>

            <div class="q-mb-md">
              <div class="flex">
                <span class="text-subtitle1">Остаток топлива</span>

                <q-btn
                    round
                    size="sm"
                    class="q-ml-md"
                    icon="edit"
                    @click="fuelDialog = !fuelDialog"
                >
                  <q-tooltip>Изменить</q-tooltip>
                </q-btn>
              </div>

              <template v-if="!vehicleDataLoading">
                <div v-if="vehicleAllData &&  vehicleAllData.fuel_remains_journal.length">
                  <div>
                    <div>
                      {{ vehicleAllData.fuel_remains_journal[0].absolute_remain }} литров
                      ({{ fuelVolume }}),

                      всего {{ vehicleAllData.fuel_capacity }} л

                    </div>
                  </div>
                </div>

                <span v-else>Не установлен</span>
              </template>


              <q-spinner v-else/>

            </div>


            <div v-if="vehicleAllData.description">
              <q-input
                  label="Примечание"
                  type="textarea"
                  readonly
                  v-model="vehicleAllData.description"
              />
            </div>
          </template>
        </div>
      </div>

      <div class="col q-pa-sm">
        <div v-if="vehicle === null" class="q-pa-md">
          Выберите транспортное средства для получения информации о графике его работы
        </div>

        <div v-else>
          <q-tabs
              v-model="tab"
              align="left"
          >
            <q-tab name="calendar">Календарь</q-tab>
            <q-tab name="orders">Рейсы</q-tab>
            <q-tab name="odometer">Пробег</q-tab>
          </q-tabs>

          <q-tab-panels
              v-model="tab"
              :key="key"
          >
            <q-tab-panel
                name="calendar"
            >
              <Calendar
                  v-model="vehicle"
                  @onRefresh="getVehicleData"
              />
            </q-tab-panel>

            <q-tab-panel
                name="orders"
            >
              <Orders
                  :drivers="vehicleAllData"
                  :vehicle="vehicle"
              />
            </q-tab-panel>

            <q-tab-panel
                name="odometer"
            >
              <Odometer
                  :vehicle="vehicle"
              />
            </q-tab-panel>
          </q-tab-panels>
        </div>
      </div>
    </div>

    <AddTrailer
        v-if="vehicle && trailersDialog"
        :vehicle="vehicle"
        :trailer="vehicleAllData.trailers"
        :trailersDialog="trailersDialog"
        @input="(state) => {trailersDialog = state}"
        @onRefresh="getVehicleData(vehicle)"
    ></AddTrailer>

    <AddDriver
        v-if="vehicle && driversDialog"
        :vehicle="vehicle"
        :driver="vehicleAllData.drivers"
        :driversDialog="driversDialog"
        @input="(state) => {driversDialog = state}"
        @onRefresh="getVehicleData(vehicle)"
    ></AddDriver>

    <AddOdometer
        v-if="vehicle && odometerDialog"
        :vehicle="vehicle"
        :odometerDialog="odometerDialog"
        @input="(state) => {odometerDialog = state}"
        @onRefresh="getVehicleData(vehicle)"
    ></AddOdometer>

    <AddFuel
        v-if="vehicle && fuelDialog"
        :fuelDialog="fuelDialog"
        :vehicle="vehicle"
        :trailer="vehicleAllData.trailers"
        @input="(state) => {fuelDialog = state}"
        @onRefresh="getVehicleData(vehicle)"
    ></AddFuel>
  </div>
</template>

<script>
import Calendar from '@/modules/organization/components/Calendar';
import Orders from '@/modules/organization/components/Orders';
import Odometer from '@/modules/organization/components/Odometer';
import AddTrailer from '@/modules/organization/components/AddTrailer';
import AddDriver from '@/modules/organization/components/AddDriver';
import AddOdometer from '@/modules/organization/components/AddOdometer';
import AddFuel from '@/modules/organization/components/AddFuel';
import {getData, METHODS} from '@/utils/http';
import {FORMAT_FOR_USER, formatDate} from '@/utils/date';
import RefSelect from '@/components/fields/RefSelect';

export default {
  name: 'Staffing',
  components: {RefSelect, AddOdometer, AddDriver, AddTrailer, Odometer, Orders, Calendar, AddFuel},
  props: {
    vehicle_origin: {
      type: Object,
    },
  },
  data() {
    return {
      tab: 'calendar',
      has_vehicle: null,
      vehicle: null,
      vehicleAllData: null,
      fuelVolume: '',
      vehicleDataLoading: true,
      trailer: {},
      trailersDialog: false,
      driversDialog: false,
      fuelDialog: false,
      odometer: null,
      odometerDialog: false,
      comment: null,
      statusDialog: false,
      key: (new Date()).getTime(),
    };
  },
  created() {
    if (this.vehicle_origin) {
      this.getVehicleData(this.vehicle_origin);
    }

    this.has_vehicle = !!this.vehicle;

  },
  methods: {
    getVehicleData(val) {
      if (!val || !val.id) {
        return false;
      }

      this.$emit('onUpdate');

      this.vehicle = val;

      this.vehicleDataLoading = true;

      getData('/vehicles/' + this.vehicle.id + '?include=drivers.user', METHODS.GET)
          .then((res) => {
            this.vehicleAllData = res.result;
            this.formatFuelVolume();

            this.key = (new Date()).getTime();
          })
          .finally(() => {
            this.vehicleDataLoading = false;
          });
    },
    formatDateStr(date) {
      return formatDate(date, FORMAT_FOR_USER.ONLY_DATE);
    },
    formatFuelVolume() {
      let fuelVolume = this.vehicleAllData.fuel_capacity;
      let currentVolume = this.vehicleAllData.fuel_remains_journal[0].absolute_remain;

      if (fuelVolume * 0.25 === currentVolume) {
        this.fuelVolume = '1/4';
      } else if (fuelVolume * 0.5 === currentVolume) {
        this.fuelVolume = '1/2';
      } else if (fuelVolume * 0.75 === currentVolume) {
        this.fuelVolume = '3/4';
      } else {
        this.fuelVolume = '1';
      }
    },
  },
};
</script>
