<template>
  <div class="q-gutter-md">
    <q-select
      v-model="model"
      :options="filtered_options"
      :label="label"
      :option-label="optionLabel"
      :option-value="optionValue"
      :multiple="multiple"
      :use-chips="multiple"
      :disable="disable"
      :loading="loading"
      :hint="hint"
      :use-input="autocomplete"
      :input-debounce="autocomplete ? 100 : 0"
      :clearable="clearable"
      @filter="filterFn"
    >
      <template #option="scope">
        <q-item v-bind="scope.itemProps" @click="scope.toggleOption(scope.opt)">
          <q-item-section>
            <q-item-label v-html="getLabelValue(scope.opt)" />
            <q-item-label v-if="optionSubtitle" caption>
              {{ getSubtitleValue(scope.opt) }}
            </q-item-label>
          </q-item-section>
        </q-item>
      </template>
    </q-select>
  </div>
</template>

<script>
import {getData, METHODS} from '@/utils/http';

export default {
  name: 'RefSelect',
  props: {
    value: null,
    label: {
      type: String,
    },
    // URL по которому запрашиваются данные
    url: {
      type: String,
    },
    // Поле значение которого будет выведено для выбранного пункта
    optionLabel: {
      default: 'title',
    },
    optionValue: {
      default: 'id',
    },
    // Подписи для элементов списка
    optionSubtitle: {
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: Boolean,
      default: false,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: '',
    },
    filters: {
      type: Array,
    },
    unsetResKey: {
      type: Boolean,
    },
    query: {
      type: Array,
    },
    // Значение, которое можно установить из вне
    // По нему будет произведён поиск в списке options по полю id
    // Если в options нет значения с таким id, то ничего не произойдёт
    // Поэтому defineValue "безопаснее", чем прямая смена через value
    defineValue: {},
    // Зависимости, которые нужно подтянуть
    include: {
      type: Array,
      default: null,
    },
     // Запрос определенных полей
    columns: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      model: null,
      loading: false,
      options: [],
      filtered_options: [],
      innerInclude: null,
      cashedValue: null,
    };
  },
  watch: {
    model(value) {
      this.$emit('input', value);
    },
    defineValue() {
     this._defineValue();
    },
  },
  beforeMount() {
    this.model = this.value;
    let url = `${this.url}?perPage=1000`;

    url += this.addColumns();

    if (this.include && this.include.length > 0) {
      url += '&include=' + this.include.join();
    } else if (this.innerInclude && this.innerInclude.length > 0) {
      url += '&include=' + this.innerInclude.join();
    }

    if (this.query) {
      this.query.forEach((item) => {
        url += `&${item.key}=${item.value}`;
      });
    }

    if (this.filters) {
      const filters = this.filters.map((filter) => `${filter.key}:${filter.value}`);
      url += '&filters=' + filters.join(',');
    }

    this.loading = true;

    getData(url, METHODS.GET)
      .then((res) => this.options = this.unsetResKey ? res.result : res.result.items)
      .finally(() => {
        this.filtered_options = _copy(this.options);
        this.loading = false;
        this._defineValue();
      });
  },
  methods: {
    filterFn(val, update) {
      update(() => {
        if (this.autocomplete) {
          const needle = val.toLowerCase();

          this.filtered_options = this.options.filter((v) => {
            let opt;

            if (this.optionLabel.constructor.name === 'Function') {
              opt = this.optionLabel(v, 'v') || '';
            } else {
              opt = v[this.optionLabel] || '';
            }

            return opt.toLowerCase().indexOf(needle) > -1;
          });
        }
      });
    },

    getLabelValue(item) {
      if (this.optionLabel) {
        if (this.optionLabel.constructor.name === 'Function') {
          return this.optionLabel(item);
        } else {
          return item[this.optionLabel];
        }
      }
      return null;
    },

    // Получение значения subtitle, для отрисовки в списке выбора
    getSubtitleValue(item) {
      if (this.optionSubtitle) {
        if (this.optionSubtitle.constructor.name === 'Function') {
          return this.optionSubtitle(item);
        } else {
          return item[this.optionSubtitle];
        }
      }
      return null;
    },

    // Добавление 'фильтра' выбирающего только нужные поля
    addColumns() {
      let colsString = '&columns=';

      if (this.columns) {
        colsString += this.columns.join(',');
      } else if (typeof this.optionValue !== 'function' && typeof this.optionLabel !== 'function') {
        colsString += `${this.optionValue},${this.optionLabel}`;
        if (this.optionSubtitle) {
          if (typeof this.optionSubtitle !== 'function') {
            colsString += ',' + this.optionSubtitle;
          } else {
            colsString = '';
            this.innerInclude = ['*'];
          }
        }
      } else {
        colsString = '';
        this.innerInclude = ['*'];
      }
      return colsString;
    },
    _defineValue() {
      const id = this.defineValue?.id || null;
      for (const opt of this.options) {
        if (opt.id === id) {
          this.model = opt;
          break;
        }
      }
    },
  },
};
</script>
