<template>
  <div>
    <div class="row q-col-gutter-lg q-pa-sm">
      <div class="col-4">
        <span class="text-primary text-caption">Контрагент</span><br/>
        {{ value.contractor && value.contractor.title || 'Не указано' }}
      </div>

      <!-- Направление -->
      <div class="col-4 row flex-center">
        <div class="col col-11">
          <ref-select
            url="/refs/move_directions"
            label="Направление"
            :key="mdKey"
            v-model="value.move_direction"
            :disable="value.status === 'COMPLETED'"
            :hint="value.move_direction ? `Расстояние ${value.move_direction.distance} км` : ''"
            :autocomplete="true"
            :columns="['id', 'title', 'distance']"
          ></ref-select>
        </div>

        <div class="col">
          <q-btn
            flat round
            icon="add"
            @click="showMDDialog = true"
            :disable="value.status === 'COMPLETED'"
          >
          </q-btn>
        </div>
      </div>

      <div class="col-4">
        <select-enum
          v-if="value.status !== 'COMPLETED'"
          label="Статус"
          v-model="value.status"
          :options="statuses"
          :disable="value.status === 'COMPLETED'"
        ></select-enum>
      </div>
    </div>

    <!-- Груз -->
    <div class="q-pa-sm">
      <h6 class="q-ma-none q-mb-xs">Груз</h6>

      <div class="row q-mb-lg q-col-gutter-lg">
        <div class="col-4">
          <span class="text-primary text-caption">Наименование груза</span><br/>
          {{ value.cargo_name ? value.cargo_name.title : 'Не указано' }}
        </div>

        <div class="col-4">
          <span class="text-primary text-caption">Тип груза</span><br/>
          {{ value.cargo_type && value.cargo_type.title || 'Не указано' }}
        </div>

        <div class="col-4">
          <span class="text-primary text-caption">Единица измерения груза</span><br/>
          {{ value.cargo_unit && value.cargo_unit.title || 'Не указано' }}
        </div>
      </div>

      <div class="row q-col-gutter-lg">
        <!-- Норма естественной убыли -->
        <div class="col">
          <span class="text-primary text-caption">Норма естественной убыли</span><br/>
          {{ value.cargo_decrease || 'Не указано' }}
        </div>

        <!-- Количество единиц загрузки на ТС -->
        <div class="col">
          <span class="text-primary text-caption">Количество единиц загрузки на ТС</span><br/>
          {{ value.vehicle_cargo_units_count || 'Не указано' }}
        </div>

        <!-- Количество -->
        <div class="col row">
          <div class="col">
            <span class="text-primary text-caption">Количество</span><br/>
            {{ value.cargo_units_count || 'Не указано' }}
          </div>
          <div class="col">
            <span class="text-primary text-caption">Остаток</span><br/>
            {{ value.cargo_units_count_left || '0' }}
          </div>
        </div>
      </div>
    </div>

    <!-- Расчет -->
    <div class="q-pa-sm">
      <h6 class="q-ma-none q-mb-xs">Расчет</h6>

      <div class="row q-mb-lg q-col-gutter-lg">
        <!-- Блок стоимости -->
        <div class="col column">
          <q-input
            class="col"
            :label="`Цена, ${(value.price_type || 0) === 0 ? 'за единицу груза' : 'за транспорт'}`"
            v-model="value.price"
            type="number"
            :disable="value.status === 'COMPLETED' || value.is_request_price"
            :hint="value.is_request_price ? 'Указана ставка по запросу' : ''"
          ></q-input>

          <q-input
            class="col"
            :label="`Цена, ${(value.price_type || 0) === 0 ? 'за единицу груза' : 'за транспорт'} с НДС`"
            v-model="value.price_vat"
            type="number"
            :disable="value.status === 'COMPLETED' || value.is_request_price"
            :hint="value.is_request_price ? 'Указана ставка по запросу' : ''"
          ></q-input>
        </div>

        <div class="col">
          <ref-select
            url="/refs/orders_payments_type"
            label="Тип оплаты"
            v-model="value.orders_payments_type"
            :disable="value.status === 'COMPLETED'"
          ></ref-select>
        </div>

        <div class="col">
          <q-input
            class="col"
            label="Оплата простоя, руб/день"
            type="number"
            v-model.number="value.immobility_price"
            :disable="value.status === 'COMPLETED'"
          ></q-input>
        </div>
      </div>
    </div>

    <!-- Транспорт -->
    <div class="q-pa-sm">
      <h6 class="q-ma-none q-mb-xs">Транспорт</h6>

      <div class="row q-col-gutter-lg">
        <div class="col">
          <ref-select
            url="/refs/vehicle_type"
            label="Тип транспорта"
            option-label="title"
            multiple
            v-model="value.vehicle_types"
            :disable="value.status === 'COMPLETED'"
            :autocomplete="true"
          ></ref-select>
        </div>

        <div class="col">
          <ref-select
            label="Тип загрузки"
            option-label="title"
            multiple
            url="/refs/vehicle_loading_type"
            v-model="value.vehicles_loading_types"
            :disable="value.status === 'COMPLETED'"
            :autocomplete="true"
          ></ref-select>
        </div>

        <div class="col">
          <ref-select
            label="Тип выгрузки"
            option-label="title"
            multiple
            url="/refs/vehicle_unloading_type"
            v-model="value.vehicles_unloading_types"
            :disable="value.status === 'COMPLETED'"
            :autocomplete="true"
          ></ref-select>
        </div>

        <!-- Способ погрузки -->
        <div class="col">
          <span class="text-primary text-caption">Способ погрузки</span><br/>
          {{ value.method_of_loading ? value.method_of_loading.title : 'Не указано' }}
        </div>
      </div>

    </div>

    <q-separator class="q-my-md"/>

    <!-- Погрузка/выгрузка -->
    <div class="q-pa-sm">
      <div class="row q-col-gutter-lg">
        <div class="col">
          <span class="text-primary text-caption">Погрузка</span><br/>
          {{
            value.loading_start_date
              ? _splitDataAndFormat(value.loading_start_date).replaceAll('/', '.')
              : '[Не указано]'
          }}
          -
          {{
            value.loading_end_date
              ? _splitDataAndFormat(value.loading_end_date).replaceAll('/', '.')
              : '[Не указано]'
          }}, на погрузку {{ value.loading_days || 0 }} дней
        </div>

        <div class="col">
          <span class="text-primary text-caption">Выгрузка</span><br/>
          {{
            value.unloading_start_date != null
              ? _splitDataAndFormat(value.unloading_start_date).replaceAll('/', '.')
              : '[Не указано]'
          }}
          -
          {{
            value.unloading_end_date != null
              ? _splitDataAndFormat(value.unloading_end_date).replaceAll('/', '.')
              : '[Не указано]'
          }}, на погрузку {{ value.unloading_days || 0 }} дней
        </div>

        <div class="col">
          <q-input
            label="Комментарий"
            type="textarea"
            v-model="value.comment"
            :disable="value.status === 'COMPLETED'"
          ></q-input>
        </div>
      </div>
    </div>

    <move-direction-dialog
      v-if="showMDDialog"
      v-model="showMDDialog"
      @on-route-created="onRouteCreated"
    ></move-direction-dialog>
  </div>
</template>

<script>
import RefSelect from '@/components/fields/RefSelect';
import SelectEnum from '@/components/fields/SelectEnum';
import MoveDirectionDialog from '@/modules/orders/components/MoveDirectionDialog';

export default {
  name: 'OrderModerationForm',
  components: { MoveDirectionDialog, SelectEnum, RefSelect },
  props: {
    // Объект заказа
    value: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      showMDDialog: false,
      mdKey: (new Date()).getTime(),
      statuses: [
        {
          label: 'Открыто на прием заявок',
          value: 'OPENED_BIDS',
        },
        {
          label: 'Закрыт прием заявок',
          value: 'CLOSED_BIDS',
        },
      ],
    };
  },
  methods: {
    // Когда маршрут создан
    onRouteCreated(route) {
      this.mdKey = (new Date()).getTime();
      this.orderItem.move_direction = _copy(route);
    },
  },
};
</script>
