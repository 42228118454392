<template>
  <div>
    <entity-manager
        :name="ref.name"
        :title="ref.title"
        :entity-url="ref.url"
        :list-columns="ref.listColumns"
        :include="ref.include"
        :filters="ref.filters"
        :perms="ref.perms"
        :access="ref.access"
        :key="tableKey"
    >
      <template #top-right>
        <AttachOrder_dialog
            :order="order"
            @onRefresh="onRefresh"
            v-if="canAttach"
        >
        </AttachOrder_dialog>
      </template>

      <template #body="props">
        <q-tr :props="props">
          <q-td
              v-for="column in ref.listColumns"
              :key="column.name"
              :props="props"
          >
            <div
                v-if="column.map != null">
              {{ props.row[column.field] && props.row[column.field][column.map] || '[Не задано]' }}
            </div>
            <div
                v-else-if="column.type ==='checkbox'">
              <q-checkbox
                  :value="props.row[column.field]"
              ></q-checkbox>
            </div>

            <div v-else>
              <template v-if="column.field.constructor.name == 'Function'">
                {{ column.field(props.row) }}
              </template>
              <template v-else>
                {{ props.row[column.field] || '[Не задано]' }}
              </template>
            </div>
          </q-td>

          <q-td
          >
            <q-btn
                round flat
                text-color="primary"
                icon="done"
                @click="allow(props.row.id)"
            >
              <q-toolbar>Принять</q-toolbar>
            </q-btn>

            <q-btn
                round flat
                text-color="red"
                icon="close"
                @click="showDialog(props.row, false)"
            >
              <q-toolbar>Отклонить</q-toolbar>
            </q-btn>
          </q-td>
        </q-tr>
      </template>
    </entity-manager>

    <q-dialog v-model="showAllowDialog">
      <q-card>
        <!-- Заголовок -->
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">Отклонение исполнения заказа</div>

          <q-space/>

          <q-btn icon="close"
            flat round dense
            v-close-popup
            class="q-ml-md"
          ></q-btn>
        </q-card-section>

        <!-- Формируем поля для редактирования-->
        <q-card-section style="max-height: 500px">
          <div style="max-height: 500px">
            <div
              v-for="column in createColumns"
              :key="column.name">
              <field-generator
                v-model="editedItem[column.field]"
                :label="column.label"
                :type="column.type"
                :meta="column.meta"
              ></field-generator>
            </div>
          </div>
        </q-card-section>

        <!-- Действия -->
        <q-card-actions align="right">
          <q-btn
            :loading="allowLoading"
            flat
            label="OK"
            color="primary"
            v-close-popup
            @click="deny"
          ></q-btn>

          <q-btn
            :disable="allowLoading"
            flat
            label="Отмена"
            color="negative"
            v-close-popup
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>

  </div>
</template>

<script>
import { getData, METHODS } from '@/utils/http';
import FieldGenerator from '@/components/entity_manager/FieldGenerator';
import EntityManager from '@/components/entity_manager/EntityManager';
import AttachOrder_dialog from '@/modules/orders/OrdersModeration/AttachOrder_dialog';

export default {
  name: 'OrderCarriers',
  components: {AttachOrder_dialog, EntityManager, FieldGenerator },
  props: {
    order: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      allowLoading: false,
      showAllowDialog: false,
      editedItem: {},
      allowItem: null,
      tableKey: (new Date()).getTime(),
      ref: {
        name: 'organizations_order',
        title: 'Заказы перевозчиков',
        url: '/organizations_order',
        filters: [
          {
            by: 'orders_id',
            value: this.order.id,
          },
          {
            by: 'status',
            value: 'IN_EXECUTION',
          },
        ],
        access: 'AVAILABLE',
        include: ['*'],
        perms: {
          list: 'v1.organizations_order.list',
          read: 'v1.organizations_order.read',
          create: 'v1.organizations_order.create',
          update: 'v1.organizations_order.update',
          delete: 'v1.organizations_order.delete',
        },
        listColumns: [
          {
            name: 'organization',
            label: 'Организация',
            align: 'left',
            field: 'organization',
            sortable: true,
            map: 'title',
          },
          {
            name: 'cargo_units_count',
            label: 'Количество',
            align: 'left',
            field: 'cargo_units_count',
            sortable: true,
          },
          {
            name: 'cargo_unit_type',
            label: 'Единица измерения груза',
            align: 'left',
            field: 'cargo_unit_type',
            sortable: true,
            map: 'title',
          },
          {
            name: 'price',
            label: 'Цена',
            align: 'left',
            field: 'price',
            sortable: true,
          },
          {
            name: 'price_type',
            label: 'Оплата за',
            align: 'left',
            field: 'price_type',
            sortable: true,
          },
        ],
      },
      createColumns: [
        {
          name: 'cargo_value_left',
          required: true,
          label: 'Остаток груза',
          align: 'left',
          field: 'cargo_value_left',
          sortable: true,
        },
      ],
    };
  },
  methods: {
    showDialog(item) {
      this.allowItem = item;
      this.editedItem.cargo_value_left = this.allowItem.cargo_value_left;
      this.showAllowDialog = true;
    },

    confirmOrder(url, cargo_value_left) {
      this.loading = true;

      getData(url, METHODS.POST, {
        params: {
          cargo_value_left: cargo_value_left,
        },
      })
        .then(() => {
          this.loading = false;
          this.tableKey = (new Date()).getTime();
          this.$emit('onRefresh');

          this.$q.notify({
            color: 'primary',
            message: 'Заказ перевозчика успешно изменил статус!',
          });
        })
        .catch(() => {
          this.showAllowDialog = false;
          this.allowLoading = false;
          this.allowItem = null;

          this.$q.notify({
            color: 'negative',
            message: 'Не удалось перевести статус заказа перевозчика',
          });
        });
    },

    allow(id) {
      let query = '/organizations_order/allow/' + id;

      if (confirm('Уверены, что хотите отметить успешное завершение заказа перевозчика?')) {
        this.confirmOrder(query, 0);
      }
    },

    deny() {
      let query = '/organizations_order/deny/' + this.allowItem.id;

      this.confirmOrder(query, this.editedItem.cargo_value_left);
    },

    onRefresh() {
      this.tableKey = (new Date()).getTime();
    },

    canAttach() {
      // return GuardService.hasPerms([
      //   'v1.orders.own.list',
      // ]);
      return true;
    },
  },
};
</script>
