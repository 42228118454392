<template>
  <div>
    <q-dialog
        v-model="orderDialog"
        maximized
    >
      <q-layout view="Lhh lpR fff" container class="bg-white">
        <q-header class="bg-primary">
          <q-toolbar>
            <q-toolbar-title style="line-height: 110%">
              Заказ №{{ order_data.order.number }}
              <span v-if="order_data.order.move_direction && order_data.order.move_direction.title">
                | {{ order_data.order.move_direction.title }}
              </span>

              <div class="text-subtitle2 q-ma-none" style="line-height: 110%">
                {{ formatDateStr(order_data.order.loading_start_date) }} -
                {{ formatDateStr(order_data.order.unloading_start_date) }}
              </div>
            </q-toolbar-title>

            <!-- Закрыть -->
            <q-btn
                color="red"
                class="q-ml-md"
                @click="$emit('input', false)"
            >
              Закрыть
            </q-btn>
          </q-toolbar>
        </q-header>

        <!-- Основное содержимое -->
        <q-page-container>
          <q-page>
            <q-card-section>
              <template v-if="orderLoading">
                <q-spinner size="48"/>
              </template>

              <div v-else class="q-mb-md">
                <div class="row items-center">
                  <div class="col-auto text-body1">
                    {{ order_data.order.cargo_name.title }},
                    {{ order_data.cargo_units_count_left }} / {{ order_data.cargo_units_count }},
                    {{ order_data.order.cargo_unit.title }}
                  </div>

                  <div class="col q-ml-md">
                    <div>
                      <q-btn round
                             size="sm"
                             icon="edit"
                             @click="changeCount(order_data)"
                      >
                        <q-tooltip>Изменить</q-tooltip>
                      </q-btn>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <label>Контрагент: </label>
                <span class="text-bold">
                  {{ order_data.order.contractor.title }}
                </span>
              </div>

              <div class="row q-mb-md" style="max-width: 600px;">
                <div class="col-auto q-pr-md">
                  <div style="height: 24px;">
                    Распределено: {{ order_data.distributed_cargo_count }}
                  </div>

                  <div style="height: 24px;">
                    Исполнено: {{ order_data.executed_cargo_count }}
                  </div>
                </div>

                <div class="col">
                  <div style="height: 24px; padding-top: 8px;">
                    <q-linear-progress
                        size="10px"
                        :value="order_data.distributed_cargo_count / order_data.cargo_units_count"
                    />
                  </div>

                  <div style="height: 24px; padding-top: 8px;">
                    <q-linear-progress
                        size="10px"
                        :value="order_data.executed_cargo_count / order_data.cargo_units_count"
                    />
                  </div>
                </div>
              </div>

              <h6 class="q-ma-none q-mb-md">
                Рейсы
                <q-btn
                    v-if="order.status === 'IN_EXECUTION'"
                    @click="orderTripDialog = true"
                    color="primary"
                    class="q-ml-md"
                    :disable="loading"
                >
                  Назначить рейс
                </q-btn>

                <q-btn
                    @click="refreshData"
                    round
                    icon="refresh"
                    class="q-ml-md"
                    :disable="loading"
                >
                  <q-tooltip>Обновить</q-tooltip>
                </q-btn>
              </h6>

              <q-tabs
                  v-model="orders_type"
                  class="q-mb-md"
              >
                <q-tab name="IN_EXECUTION">Рейсы на исполнении</q-tab>
                <q-tab name="COMPLETED">Выполненные рейсы</q-tab>

                <template v-if="loadingFullData">
                  <div>
                    <q-spinner size="24"/>
                  </div>
                </template>
              </q-tabs>



              <q-tab-panels
                  keep-alive
                  v-model="orders_type"
              >

                <q-tab-panel name="IN_EXECUTION">
                  <ExecutionOrder
                      :orders_type="orders_type"
                      :order="order_data"
                      :columns="columns"
                      :newOrderLoading="newOrderLoading"
                      @onOpen="showDetail"
                      @onClose="denyOrder"
                  />
                </q-tab-panel>

                <q-tab-panel name="COMPLETED">
                  <CompletedOrder
                      :orders_type="orders_type"
                      :order="order_data"
                      :columns="columns"
                      :newOrderLoading="newOrderLoading"
                      @loadingFullData="loader"
                      @onOpen="showDetail"
                      @onClose="denyOrder"
                  />
                </q-tab-panel>
              </q-tab-panels>

            </q-card-section>
          </q-page>
        </q-page-container>
      </q-layout>
    </q-dialog>

    <q-dialog
        v-if="orderTripDialog"
        v-model="orderTripDialog"
    >

      <q-card style="width: 300px">
        <q-card-section>
          <q-input
              v-model="tripDateStart"
              label="Дата начала рейса"
              class="q-mb-md"
          >
            <template #append>
              <q-icon
                  name="event"
                  class="cursor-pointer"
              >
                <q-popup-proxy
                    transition-show="scale"
                    transition-hide="scale"
                >
                  <q-date
                      v-model="tripDateStart"
                      mask="DD/MM/YYYY"
                      @input="resetVehicleAndDriver"
                  >
                    <div class="row items-center justify-end">
                      <q-btn
                          v-close-popup
                          label="Close"
                          color="primary"
                          flat
                      />
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>

          <q-input
              v-model="tripDateEnd"
              label="Дата окончания рейса"
              class="q-mb-md"
          >
            <template #append>
              <q-icon
                  name="event"
                  class="cursor-pointer"
              >
                <q-popup-proxy
                    transition-show="scale"
                    transition-hide="scale"
                >
                  <q-date
                      v-model="tripDateEnd"
                      mask="DD/MM/YYYY"
                      @input="resetVehicleAndDriver"
                  >
                    <div class="row items-center justify-end">
                      <q-btn v-close-popup
                             label="Close"
                             color="primary"
                             flat
                      ></q-btn>
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>


          <div class="row items-center">
            <div class="col">
              <ref-select label="Транспорт"
                          v-model="vehicle"
                          option-label="reg_number"
                          url="/vehicles/schedule"
                          :query="[
                            {key: 'start_date', value: todayDateIso},
                            {key: 'end_date', value: tomorrowDateIso},
                            {key: 'is_active', value: 'true'},
                          ]"
                          unsetResKey
                          :define-value="mapVehicle"
                          @input="selectVehicle"
              ></ref-select>
            </div>
            <div class="col-auto">
              <q-btn icon="map"
                     color="primary"
                     class="q-ml-md"
                     @click="mapSelectVehicleShow = true"
              >
                <q-tooltip>Отобразить на карте</q-tooltip>
              </q-btn>
            </div>
          </div>

          <q-select
              label="Водитель"
              v-model="driver"
              :options="vehicle && vehicle.drivers || []"
              :disable="!vehicle || vehicle.drivers.length === 0"
              :hint="vehicle && vehicle.drivers.length === 0 ? 'Водитель не назначен на транспорт': null"
              :key="selectKey + '_driver'"
          >
            <template #option="scope">
              <q-item v-bind="scope.itemProps" @click="scope.toggleOption(scope.opt)">
                <q-item-section>
                  <q-item-label>
                    {{ scope.opt.user.full_name }}
                  </q-item-label>
                </q-item-section>
              </q-item>
            </template>

            <template #selected>
              {{ driver && driver.user && driver.user.full_name || '' }}
            </template>
          </q-select>

          <q-input label="Количество груза"
                   v-model="cargo_count"
                   type="number"
                   :hint="`Доступно ${order.cargo_units_count - order.distributed_cargo_count}`"
          ></q-input>

          <q-input label="Расстояние (км)"
                   v-model.number="distance"
                   type="number"
                   :hint="'По умолчанию: ' + order.order.move_direction.distance"
          ></q-input>

          <q-input label="Ставка"
                   v-model.number="price"
                   type="number"
                   :hint="'По умолчанию: ' + order.price"
          ></q-input>
        </q-card-section>

        <q-card-actions>
          <q-btn v-close-popup>Отмена</q-btn>

          <q-space/>

          <q-btn
              @click="save"
              :loading="sendLoading"
              :disable="vehicle == null || driver == null"
          >
            Сохранить
          </q-btn>

          <MapWithSelectVehicle
              v-if="mapSelectVehicleShow"
              @selectVehicle="selectVehicle"
              @close="mapSelectVehicleShow = false"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <VehicleOrderDetail
        v-if="vehicleOrderDialog"
        :orderDialog="vehicleOrderDialog"
        :order="vehicleOrder"
        @input="(state) => {vehicleOrderDialog = state}"
    ></VehicleOrderDetail>

    <q-dialog v-if="editDialog && editItem" v-model="editDialog">
      <q-card>
        <!-- Заголовок -->
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">
            Изменить количество груза
          </div>

          <q-space/>

          <q-btn flat round dense
                 v-close-popup
                 icon="close"
                 class="q-ml-md"
          ></q-btn>
        </q-card-section>

        <!-- Формируем поля для редактирования-->
        <q-card-section style="max-height: 500px; overflow: auto">
          <div style="max-height: 500px">
            <q-input
                label="Количество груза"
                v-model="editItem.cargo_units_count"
            />
          </div>
        </q-card-section>

        <!-- Действия -->
        <q-card-actions align="right">
          <q-btn
              flat
              label="OK"
              color="primary"
              :loading="editLoading"
              @click="saveCount(editItem)"
          ></q-btn>

          <q-btn
              flat
              label="Отмена"
              color="negative"
              :disable="editLoading"
              v-close-popup
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import {getData, METHODS} from '@/utils/http';
import RefSelect from '@/components/fields/RefSelect';
import {
  FORMAT_FOR_USER,
  formatDate,
  getDateToAPiFormat,
  getTodayDateShift,
  parseDateFromStr,
} from '@/utils/date';
import VehicleOrderDetail from '@/modules/organization/components/VehicleOrderDetail';
import MapWithSelectVehicle from '@/modules/organization/components/MapWithSelectVehicle';
import TripTableCompleted from './TripTableCompleted';
import ExecutionOrder from '@/modules/organization/components/ExecutionOrder';
import CompletedOrder from '@/modules/organization/components/CompletedOrder';
import DateField from '@/components/fields/date/DateField';

function formatStrDate(row) {
  const format = FORMAT_FOR_USER.ONLY_DATE;

  return `${formatDate(row.start_date, format)} - ${formatDate(row.end_date, format)}`;
}

export default {
  name: 'OrganizationOrderDetail',
  components: {
    MapWithSelectVehicle, VehicleOrderDetail, RefSelect, TripTableCompleted, DateField,
    ExecutionOrder, CompletedOrder,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    orderDialog: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      vehicleOrderDialog: false,
      vehicleOrder: null,
      denyLoading: false,
      denyDialog: false,
      editedItem: null,
      order_data: {},
      loading: false,
      loadingFullData: false,
      orderLoading: false,
      sendLoading: false,
      vehicle: null,
      driver: null,
      orderTripDialog: false,
      tripDateStart: '',
      tripDateEnd: '',
      selectKey: '',
      editDialog: false,
      editLoading: false,
      editItem: null,
      editItem_tmp: null,
      cargo_count: 0,
      cargo_count_execution: 0,
      price: null,
      distance: null,
      orders_type: 'IN_EXECUTION',
      num: 1,
      fullData: [],
      originalItems: [],
      originalItemsCompleted: [],
      vehicles_url: '',
      query: [],
      data: [],
      newOrderLoading: false,
      load: false,
      columns: [
        {
          name: 'vehicle',
          label: 'Сцепка',
          align: 'left',
          field: (row) => {
            return `${row.vehicle.reg_number} / ${row.vehicle.trailers.map((it) => it.reg_number).join(', ')}`;
          },
        },
        {
          name: 'driver',
          label: 'Водитель',
          align: 'left',
          field: (row) => {
            return row.driver?.user?.full_name || null;
          },
        },
        {
          name: 'dates',
          label: 'Даты',
          align: 'left',
          field: (row) => {
            return `${formatStrDate(row)}`;
          },
        },
        {
          name: 'count',
          label: 'Назначено/Загружено/Выгружено',
          align: 'left',
          field: (row) => {
            let str = `${row.cargo_count}`;
            str += `/${row.loaded || 'Нет данных'}`;
            str += `/${row.unloaded || 'Нет данных'}`;

            this.cargo_count_execution = row.cargo_count;

            return str;
          },
        },
        {
          name: 'load_capacity',
          label: 'Грузоподъемность',
          align: 'left',
          field: (row) => {
            if (row.vehicle.trailers.length === 0) {
              return 'Нет сцепки';
            }

            let capacity = parseInt(row.vehicle.trailers[0].pivot.vehicle_load_capacity?.weight || 0, 10);
            capacity -= row.vehicle.weight || 0;
            capacity -= row.vehicle.trailers.length ? (row.vehicle.trailers[0].weight || 0) : 0;

            return capacity / 1000;
          },
        },
        {
          name: 'price',
          label: 'Цена',
          align: 'left',
          field: (row) => {
            if (row.price === null) {
              if (this.order_data.order.price != null) {
                return this.order_data.order.price;
              } else {
                return 'Нет данных';
              }
            } else {
              return row.price;
            }
          },
        },
        {
          name: 'active_status',
          label: 'Статус',
          align: 'left',
          field: (row) => {
            const currDate = new Date();
            const diffFunc = (date) => {
              const dt = new Date(date.split('-'));
              const timeDiff = currDate.getTime() - dt;
              const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
              const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
              const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
              const diff = `${days} дн., ${hours} ч., ${minutes} мин.`;

              return diff;
            };

            if (row.last_status && row.last_status.pivot) {
              const statusDate = row.last_status.pivot.start_date;

              return {
                title: row.last_status.title,
                date: formatDate(new Date(statusDate), FORMAT_FOR_USER.DATE_TIME),
                time_diff: diffFunc(statusDate),
              };
            }

            return {
              title: '',
              date: '',
              time_diff: '',
            };
          },
        },
        {
          name: 'actions',
          label: 'Действия',
          align: 'left',
        },
      ],
      mapSelectVehicleShow: false,
      mapVehicle: null,
    };
  },
  computed: {
    todayDateIso() {
      return getTodayDateShift();
    },
    tomorrowDateIso() {
      return getTodayDateShift(1);
    },
  },
  watch: {
    orderTripDialog(val) {
      if (val === false) {
        this.clearOrderTripDialog();
      }
    },
  },
  created() {
    this.order_data = this.order;


    this.resetDate();
    this.setVehicleFilters();
  },
  methods: {
    loader() {
      this.loadingFullData = !this.loadingFullData;
    },
    getCurrentDate() {
      const date = new Date();

      return `${_setZero(date.getDate())}/${_setZero(date.getMonth() + 1)}/${date.getFullYear()}`;
    },
    ApplyChanges() {
      this.data_current = [];
      this.data_finished = [];
      this.originalItemsCompleted = [];
      this.originalItems = [];
    },
    refreshData() {
      this.newOrderLoading = !this.newOrderLoading;
    },
    save() {
      this.sendLoading = true;
      const otherParams = {};

      if (this.distance) {
        otherParams.distance = this.distance;
      }
      if (this.price) {
        otherParams.price = this.price;
      }

      getData('/vehicle_orders', METHODS.POST, {
        params: {
          'vehicle': {
            'id': this.vehicle.id,
          },
          'driver_id': this.driver.id,
          'start_date': getDateToAPiFormat(this.tripDateStart),
          'end_date': getDateToAPiFormat(this.tripDateEnd),
          'organization_order': {
            'id': this.order_data.id,
          },
          'cargo_count': this.cargo_count,
          ...otherParams,
        },
      })
          .then(() => {
            this.orderTripDialog = false;
            this.newOrderLoading = true;
            this.loadOrderData();

          })
          .catch((res) => {
            this.$q.notify({
              message: res.error,
              type: 'negative',
            });
          })
          .finally(() => {
            this.sendLoading = false;
            this.newOrderLoading = false;
          });
    },
    resetVehicleAndDriver() {
      this.vehicle = null;
      this.driver = null;
      this.selectKey = (new Date()).getTime();
      this.setVehicleFilters();
    },
    setVehicleFilters() {
      this.query = [
        {
          key: 'start_date',
          value: getDateToAPiFormat(this.tripDateStart),
        },
      ];
    },
    loadOrderData() {
      this.orderLoading = false;

      getData(`/organizations_order/${this.order_data.id}?include=*`, METHODS.GET)
          .then((res) => {
            this.order_data = res.result;
          })
          .finally(() => {
            this.loading = false;
          });
    },
    formatDateStr(date) {
      return formatDate(date, FORMAT_FOR_USER.ONLY_DATE);
    },
    parseDateFromStr(str) {
      return parseDateFromStr(str);
    },
    denyOrder(order) {
      if (confirm('Уверены, что хотите отменить заказ?')) {

        getData(`/vehicle_orders/${order.id}/close`, METHODS.POST, {
          params: {
            cargo_count: this.cargo_count_execution,
          },
        })
            .then(() => {
              this.newOrderLoading = true;
            })
            .catch((err) => {
              this.$q.notify({
                message: err.error,
                type: 'negative',
              });
            })
            .finally(() => {
              this.denyDialog = false;
              this.newOrderLoading = false;
            });
      }

    },
    formatStrDate(row) {
      return formatStrDate(row);
    },
    showDetail(order) {
      this.vehicleOrder = order;
      this.vehicleOrderDialog = true;
    },
    changeCount(order) {
      this.editItem = _copy(order);
      this.editItem_tmp = order;
      this.editDialog = true;
    },
    saveCount() {
      this.editLoading = true;

      getData(`/organizations_order/${this.editItem.id}`, METHODS.PUT, {
        params: {
          cargo_units_count: this.editItem.cargo_units_count,
        },
      })
          .then(() => {
            this.editItem_tmp.cargo_units_count = this.editItem.cargo_units_count;
            this.editItem = null;
            this.editItem_tmp = null;
            this.editDialog = false;
          })
          .catch((res) => {
            this.$q.notify({
              message: res.error,
              type: 'negative',
            });
          })
          .finally(() => {
            this.editLoading = false;
          });
    },
    resetDate() {
      let curDate = (new Date(getDateToAPiFormat(this.getCurrentDate()))).getTime();
      let ordDate = (new Date(this.order.order.loading_start_date)).getTime();

      if (curDate - ordDate > 0) {
        this.tripDateStart = this.getCurrentDate();
        this.tripDateEnd = this.getCurrentDate();
      } else {
        this.tripDateStart = formatDate(this.order.order.loading_start_date, FORMAT_FOR_USER.ONLY_DATE);
        this.tripDateEnd = formatDate(this.order.order.loading_start_date, FORMAT_FOR_USER.ONLY_DATE);
      }
    },
    clearOrderTripDialog() {
      this.resetDate();
      this.vehicle = null;
      this.driver = null;
      this.cargo_count = 0;
      this.price = null;
      this.distance = null;
    },
    selectVehicle(vehicle) {
      this.driver = null;
      this.mapSelectVehicleShow = false;
      if (!vehicle.drivers) {
        vehicle.drivers = [];
      }
      if (vehicle.drivers.length === 1) {
        this.driver = vehicle.drivers[0];
      }
      this.mapVehicle = vehicle;
    },
  },
};
</script>
