<template>
  <div class="q-pa-md">
    <div
        class="column"
    >
      <div class="row">
        <q-tabs v-model="tableType" class="q-mr-md q-pb-md">
          <q-tab name="all">Все</q-tab>
          <q-tab name="driver">По водителю</q-tab>
        </q-tabs>
      </div>
      <div>
        <div class="row q-gutter-sm">
          <q-radio v-model="periodType" val="month" label="Месяц"/>
          <q-radio v-model="periodType" val="range" label="Период"/>

          <div v-if="tableType === 'driver'" class="row">
            <ref-select
                label="Водители"
                url="/drivers"
                v-model="driver_data"
                :option-label="(row) => row.user ? row.user.full_name : ''"
                option-value="id"
                :autocomplete="true"
                class="q-ml-md"
            ></ref-select>

            <div>
              <q-btn
                  class="q-mb-md"
                  @click="loadData"
                  :loading="loading"
                  :disable="!driver_data"
              >
                Показать отчет
              </q-btn>
            </div>
          </div>
        </div>

        <div v-if="tableType === 'driver'">
          <div>
            <div
                v-if="periodType === 'range'"
                class="row justify-between q-col-gutter-md q-py-md"
            >
              <RangeDates class="col-8 "

                          :startDate.sync="tripDateStart"
                          :endDate.sync="tripDateEnd"
              ></RangeDates>

              <div>
                <q-btn
                    label="Добавить"
                    @click="showDialog = !showDialog"
                />
              </div>
            </div>

            <div
                v-else
                class="row justify-between"
            >
              <div class="flex items-center q-mb-sm">
                <div>
                  <q-btn
                      flat
                      icon="mdi-arrow-left"
                      @click="prevMonth"
                      :disable="!driver_data"
                  >
                    <q-tooltip>Предыдущий месяц</q-tooltip>
                  </q-btn>
                </div>

                <div class="q-px-sm text-h6">{{ monthName }}, {{ year }}</div>

                <div>
                  <q-btn
                      flat
                      icon="mdi-arrow-right"
                      @click="nextMonth"
                      :disable="!driver_data"
                  >
                    <q-tooltip>Следующий месяц</q-tooltip>
                  </q-btn>
                </div>
              </div>

              <div>
                <q-btn
                    label="Добавить"
                    @click="showDialog = !showDialog"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div>
            <div
                v-if="periodType === 'range'"
                class="row q-col-gutter-md q-py-md"
                style="max-width: 600px;"
            >
              <RangeDates class="col-8 "

                          :startDate.sync="tripDateStart"
                          :endDate.sync="tripDateEnd"
              ></RangeDates>
            </div>

            <div
                v-else
                class="row justify-between"
            >
              <div class="flex items-center q-mb-sm">
                <div>
                  <q-btn
                      flat
                      icon="mdi-arrow-left"
                      @click="prevMonth"
                      :disable="!allIncomes"
                  >
                    <q-tooltip>Предыдущий месяц</q-tooltip>
                  </q-btn>
                </div>

                <div class="q-px-sm text-h6">{{ monthName }}, {{ year }}</div>

                <div>
                  <q-btn
                      flat
                      icon="mdi-arrow-right"
                      @click="nextMonth"
                      :disable="!allIncomes"
                  >
                    <q-tooltip>Следующий месяц</q-tooltip>
                  </q-btn>
                </div>
              </div>

              <div>
                <q-btn
                    label="Добавить"
                    @click="showDialog = !showDialog"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <q-space/>
    </div>

    <div v-if="loading">
      <div class="text-center">
        <q-spinner size="48"/>
      </div>
    </div>
    <div v-else-if="tableType === 'driver'">
      <div v-if="driver_data">
        <div v-if="incomes && incomes.length > 0">
          <q-table
              :data="incomes"
              :columns="columnsList"
              row-key="name"
              :pagination.sync="pagination"
              hide-pagination
          >
            <template #body-cell-actions="props">
              <q-td :props="props">
                <q-btn
                    round flat
                    text-color="red"
                    icon="close"
                    size="sm"
                    @click.stop="remove(props.row)"
                >
                  <q-tooltip>
                    Удалить
                  </q-tooltip>
                </q-btn>
              </q-td>
            </template>

            <template v-if="incomes.length > 0" #bottom-row="{cols}">
              <q-tr class="bg-grey-4">
                <q-td v-for="(col, index) of cols" :key="col.name">
                  <template v-if="index === 0">Итого</template>
                  <template v-if="index > 1 && index < 5">
                    {{ _priceFormat(incomes.reduce((acc, val) => acc + val[col.name], 0)) }}
                  </template>
                </q-td>
              </q-tr>
            </template>
          </q-table>
        </div>

        <div v-else>
          За выбранный период не было начислений
        </div>
      </div>
      <div v-else>
        Выберите водителя, для которого необходимо получить отчет о начислениях
      </div>
    </div>

    <div v-else>
      <div v-if="allIncomes && allIncomes.length > 0">
        <q-table
            :data="allIncomes"
            :columns="columnsListForAll"
            row-key="name"
            :pagination.sync="pagination"
            hide-pagination
        >
          <template #body-cell-actions="props">
            <q-td :props="props">
              <q-btn
                  round flat
                  text-color="red"
                  icon="close"
                  size="sm"
                  @click.stop="remove(props.row)"
              >
                <q-tooltip>
                  Удалить
                </q-tooltip>
              </q-btn>
            </q-td>
          </template>

          <template v-if="allIncomes.length > 0" #bottom-row="{cols}">
            <q-tr class="bg-grey-4">
              <q-td v-for="(col, index) of cols" :key="col.name">
                <template v-if="index === 0">Итого</template>
                <template v-if="index >= 1 && index < 5">
                  {{ _priceFormat(allIncomes.reduce((acc, val) => +acc + +val[col.name], 0)) }}
                </template>
              </q-td>
            </q-tr>
          </template>


        </q-table>
      </div>
      <div v-else>
        За выбранный период не было начислений
      </div>
    </div>

    <div>
      <q-dialog v-model="showDialog">
        <q-card>
          <!-- Заголовок -->
          <q-form
              @submit="saveNewIncome"
          >
            <q-card-section class="row items-center q-pb-none">
              <div class="text-h6">
                Создание начисления
              </div>

              <q-space/>

              <q-btn flat round dense v-close-popup
                     icon="close"
                     class="q-ml-md"
                     @click="showDialog = !showDialog"
              ></q-btn>
            </q-card-section>

            <!-- Формируем поля для редактирования-->
            <q-card-section style="max-height: 500px; overflow: auto">
              <div style="max-height: 500px">
                <div
                    v-for="column in editFields"
                    :key="column.name"
                >
                  <field-generator
                      @input="setNewIncome($event, column.name)"
                      :label="column.label"
                      :type="column.type"
                      :meta="column.meta"
                      :value="column.type === 'reference' ? driver_data : null"
                      :rules="[val => checkValidate(val)]"
                  ></field-generator>
                </div>
              </div>
            </q-card-section>

            <!-- Действия -->
            <q-card-actions align="right">
              <q-btn
                  flat
                  label="OK"
                  color="primary"
                  :loading="editLoading"
                  type="submit"
              ></q-btn>

              <q-btn
                  flat
                  label="Отмена"
                  color="negative"
                  :disable="editLoading"
                  @click="showDialog = !showDialog"
              ></q-btn>
            </q-card-actions>
          </q-form>
        </q-card>
      </q-dialog>
    </div>
  </div>
</template>

<script>
import RangeDates from '@/components/fields/date/RangeDates';
import {getData, METHODS} from '@/utils/http';
import RefSelect from '@/components/fields/RefSelect';
import {getDateToAPiFormat} from '@/utils/date';
import FieldGenerator from '@/components/entity_manager/FieldGenerator';

export default {
  name: 'Expenses',
  components: {RefSelect, RangeDates, FieldGenerator},
  data() {
    return {
      editDialog: false,
      editLoading: false,
      showDialog: false,
      editItem: {},
      newIncome: {},
      tableType: 'all',
      periodType: 'month',
      incomes: [],
      allIncomes: [],
      driver_data: null,
      pagination: {
        rowsPerPage: 0,
      },
      tripDateStart: '',
      tripDateEnd: '',
      year: (new Date()).getFullYear(),
      month: (new Date()).getMonth(),
      monthDays: 0,
      monthName: '',
      monthNames: [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь',
      ],

      loading: false,
      columnsList: [
        {
          name: 'date',
          label: 'Дата',
          align: 'left',
          field: 'date',
        },
        {
          name: 'type',
          label: 'Тип',
          align: 'left',
          field: (row) => {
            return ({
              'LOAD_TYPE': 'Загрузка',
              'TONNE_KM_TYPE': 'Исполнение заказа',
              'IMMOBILITY_TYPE': 'Простой',
              'DAILY_TYPE': 'Ежедневное начисление',
            })[row.type];
          },
        },
        {
          name: 'sum',
          label: 'Сумма на руки',
          align: 'left',
          field: (row) => Math.floor(row.sum),
        },
        {
          name: 'vat',
          label: 'Налог',
          align: 'left',
          field: (row) => Math.floor(row.vat),
        },
        {
          name: 'income',
          label: 'Начисление',
          align: 'left',
          field: (row) => Math.floor(row.income),
        },
        {
          name: 'actions',
          label: 'Действия',
          align: 'left',
          field: 'actions',
        },
      ],
      columnsListForAll: [
        {
          name: 'full_name',
          label: 'Водитель',
          align: 'left',
          field: (row) => row.full_name,
        },
        {
          name: 'sum',
          label: 'Сумма на руки',
          align: 'left',
          field:  (row) => Math.floor(row.sum) ,
        },
        {
          name: 'vat',
          label: 'Налог',
          align: 'left',
          field: (row) => Math.floor(row.vat),
        },
        {
          name: 'income',
          label: 'Начисления',
          align: 'left',
          field: (row) => Math.floor(row.income),
        },
      ],
      editFields: [
        {
          name: 'date',
          label: 'Дата',
          align: 'left',
          type: 'date',
        },
        {
          name: 'type',
          label: 'Тип',
          align: 'left',
          type: 'enum',
          meta: {
            options: [
              {
                label: 'Ежедневная выплата',
                value: 'DAILY_TYPE',
              },
              {
                label: 'Простой',
                value: 'IMMOBILITY_TYPE',
              },
              {
                label: 'Аванс',
                value: 'PREPAYMENT_TYPE',
              },
              {
                label: 'Оплата ТМЦ',
                value: 'INVENTORY_ITEMS_TYPE',
              },
              {
                label: 'Штраф',
                value: 'PENALTY_TYPE',
              },
              {
                label: 'Премия',
                value: 'BONUS_TYPE',
              },
            ],
          },
        },
        {
          name: 'sum',
          label: 'Сумма на руки',
          align: 'left',
          type: 'number',
        },
        // {
        //   name: 'tax_sum',
        //   label: 'Сумма налога',
        //   align: 'left',
        //   type: 'number',
        // },
        {
          name: 'driver_id',
          label: 'Водитель',
          align: 'left',
          type: 'reference',
          meta: {
            url: '/drivers',
            columns: null,
            optionLabel: (label) => label.user.full_name,
          },
        },
      ],
      total: 0,
    };
  },
  watch: {
    periodType() {
      this.loadData();
    },
  },
  created() {
    this.monthDays = this.getMonthDays(this.year, this.month);
    this.initCalendar(this.year, this.month);
  },
  methods: {
    setNewIncome(value, name) {
      this.newIncome[name] = value;
    },
    checkValidate(val) {
      if (val !== '') {
        if ((this.newIncome['type'] === 'PENALTY_TYPE' || this.newIncome['type'] === 'PREPAYMENT_TYPE') && +val > 0) {
          return 'Поле только для отрицательных чисел';
        }
      } else {
        return 'Поле не должно быть пустым';
      }

    },
    getMonthDays(year, month) {
      return (new Date(year, month + 1, 0)).getDate();
    },
    loadData() {
      let startDt;
      let endDt;

      if (!this.driver_data || !this.driver_data.id) {
        return;
      }

      if (this.periodType === 'range') {
        if (this.tripDateStart === '' || this.tripDateEnd === '') {
          return;
        }

        startDt = getDateToAPiFormat(this.tripDateStart);
        endDt = getDateToAPiFormat(this.tripDateEnd);
      } else {
        startDt = `${this.year}-${_setZero(this.month + 1)}-01`;
        endDt = `${this.year}-${_setZero(this.month + 1)}-${this.monthDays}`;
      }

      let id = this.driver_data.id;
      let URL = `/incomes/by/driver/${id}?start_date=${startDt}&end_date=${endDt}&include=*`;

      this.loading = true;

      getData(URL, METHODS.GET)
          .then((res) => {
            const result = res.result.days;
            const incomes = [];
            Object.keys(result).forEach((key) => {
              result[key].forEach((value) => incomes.push(Object.assign(value, {date: key})));
            });

            incomes.forEach((el) => {
              el.income = el.sum + el.vat;
            });

            this.incomes = incomes.sort((a, b) => new Date(b.date) - new Date(a.date));
            this.total = _priceFormat(_roundHundredths(incomes.reduce((acc, el) => acc + el.sum, 0), true));
          })
          .catch((res) => {
            this.$q.notify({
              message: res.error,
              type: 'negative',
            });
          })
          .finally(() => {
            this.loading = false;
          });
    },
    saveNewIncome() {
      this.editLoading = true;
      if (this.newIncome.driver_id) {
        this.newIncome.driver_id = this.newIncome.driver_id.id;
      }

      getData('/incomes/add', METHODS.POST, {
        params: this.newIncome,
      })
          .then(() => {
            this.showDialog = false;
          })
          .catch((res) => {
            this.$q.notify({
              message: res.error,
              type: 'negative',
            });
          })
          .finally(() => {
            this.editLoading = false;
            this.loadAllData();
          });
    },
    loadAllData() {
      let startDt;
      let endDt;


      if (this.periodType === 'range') {
        if (this.tripDateStart === '' || this.tripDateEnd === '') {
          return;
        }

        startDt = getDateToAPiFormat(this.tripDateStart);
        endDt = getDateToAPiFormat(this.tripDateEnd);
      } else {
        startDt = `${this.year}-${_setZero(this.month + 1)}-01`;
        endDt = `${this.year}-${_setZero(this.month + 1)}-${this.monthDays}`;
      }

      let URL = `/reports/payments?start_date=${startDt}&end_date=${endDt}&include=*`;

      this.loading = true;

      getData(URL, METHODS.GET)
          .then((res) => {
            this.allIncomes = res.result;

          })
          .catch((res) => {
            this.$q.notify({
              message: res.error,
              type: 'negative',
            });
          })
          .finally(() => {
            this.loading = false;
          });
    },
    remove(item) {
      if (!item) {
        return;
      }

      if (confirm('Уверены, что хотите удалить эту запись?')) {
        getData('/incomes/remove', METHODS.DELETE, {
          params: {
            income_id: item.income_id,
          },
        })
            .then(() => {
              this.loadData();
            })
            .catch((res) => {
              this.$q.notify({
                message: res.error,
                type: 'negative',
              });
            })
            .finally(() => {
              this.editLoading = false;
            });
      }
    },
    initCalendar(year, month) {
      this.monthDays = this.getMonthDays(year, month);
      this.monthName = this.monthNames[this.month];

      if (this.tableType === 'all') {
        this.loadAllData();
      } else {
        this.loadData();
      }
    },
    prevMonth() {
      if (this.month > 0) {
        this.month--;
      } else {
        this.month = 11;
        this.year--;
      }

      this.initCalendar(this.year, this.month);
    },
    nextMonth() {
      if (this.month < 11) {
        this.month++;
      } else {
        this.month = 0;
        this.year++;
      }

      this.initCalendar(this.year, this.month);
    },
  },
}
;
</script>
