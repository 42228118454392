<template>
  <!-- Список -->
  <div>
    <span v-if="isField">
      {{ model }}
    </span>

    <q-select
      v-else
      v-model="model"
      :options="options"
      :disable="disable"
      :label="label"
      :rules="reactiveRules"
      clearable
    ></q-select>
  </div>
</template>

<script>
export default {
  name: 'SelectEnum',
  props: {
    value: null,
    options: {
      type: Array,
      default: () => ([]),
    },
    meta: {
      type: Object,
      default: () => ({}),
    },
    isField: {
      type: Boolean,
      default: false,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      model: null,

    };
  },
  computed: {
    reactiveRules() {
      return this.rules;
    },
  },
  watch: {
    value(v) {
      this.setValue(v);
    },
    model(v) {
      if (v && v.value) {
        this.$emit('input', v.value);
      }
    },
  },
  mounted() {
    this.setValue(this.value);
  },
  methods: {
    setValue(value) {
      if (typeof value === 'string') {
        for (let key in this.options) {
          if (this.options[key].value === value) {

            if (this.isField) {
              this.model = this.options[key].label;
            } else {
              this.model = this.options[key];
            }

            return;
          }
        }
      } else {
        this.model = value;
      }

    },
  },
};
</script>
