<template>
  <div>
    <entity-manager
        v-if="!loading && ref"
        :name="ref.name"
        :title="ref.title"
        :entity-url="ref.url"
        :list-columns="ref.listColumns"
        :list-edit="ref.editColumns"
        :include="ref.include"
        :perms="ref.perms"
        :filter-by="ref.filterBy"
        v-show="hasAccess[ref.perms.create]"
    ></entity-manager>
  </div>
</template>

<script>
import EntityManager from '@/components/entity_manager/EntityManager';
import HasPerms from '@/components/guard/mixins/HasPerms';

export default {
  name: 'RefsContainer',
  components: {
    EntityManager,
  },
  mixins: [HasPerms],
  props: {
    folder: {
      type: String,
      default: 'refs',
    },
  },
  data() {
    return {
      loading: true,
      ref: null,
    };
  },
  created() {
    const ref_name = this.$route.params.ref_name;


    import(`@/structures/${this.folder}/${ref_name}`)
      .then((res) => {
        this.ref = res[ref_name];
        this.loading = false;
      });
  },
  methods: {
    getPerms_() {
      if (!this.ref || !this.ref.perms || this.ref.perms.length === 0) {
        return [];
      }

      return [this.ref.perms.create];
    },
  },
};
</script>
