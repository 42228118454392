// Сторонние зависимости
import md5 from 'blueimp-md5';
// Vuex
import store from '@/store';

const METHODS = {
  //  Получение данных
  GET: 'GET',
  // Создание данных
  POST: 'POST',
  // Обновление данных
  PUT: 'PUT',
  // Удаление данных
  DELETE: 'DELETE',
};

function getData(
  // URL запроса
  url,
  // Метод запроса METHODS[GET, POST, PUT, DELETE]
  method,
  // Кастомные настройки
  config = {
    // Кэширование данных
    cache: false,
    // Параметры передаваемые в BODY
    params: {},
    // Добавление заголовков
    addHeaders: {},
    // Параметр строго для разработки
    // Использовать для удобства интеграции с API
    log: false,
    logShowUrl: false,
    logText: 'http.getData(): ',
    keyReplace: {},
  },
) {
  return new Promise((resolve, reject) => {
    if (!url || url === '/') {
      // Если поле url пустое, то бэк вернет ошибку CORS политики
      reject(new Error('Не указан URL!'));

      return;
    }

    // Проверка наличия кэша
    const cacheKey = md5(method + url);
    const cacheData = store.getters['cache/getItem'](cacheKey);

    if (cacheData !== null) {
      resolve(cacheData);

      return;
    }

    // Добавление дополнительных заголовков
    const headers = {
      'Content-Type': 'application/json',
    };

    // Добавление токена авторизации
    const token = store.getters['auth/getToken'];

    if (token) {
      headers['X-Auth-Token'] = token;
    }

    if (config.addHeaders) {
      for (const [key, value] of Object.entries(config.addHeaders)) {
        headers[key] = value;
      }
    }

    const params = config.params;

    // const apiUrl = localStorage.getItem('api_key');
    const apiUrl = process.env.VUE_APP_HOST;

    /*
      URL выглядит примерно так (ПРИМЕР):
        process.env.VUE_APP_HOST: "http://api.gas.local/api"
        url: "/settings/gas_cylinder_producer_limit?page=1&perPage=10&include=*"
     */
    fetch(`${apiUrl}/api${url}`, {
      method: method,
      headers: headers,
      body: (method === METHODS.GET
          ? null
          : typeof params === 'string'
            ? params
            : JSON.stringify(params)
      ),
    })
      .then((res) => {
        if (res.status === 401) {
          store.commit('auth/removeCredentials');

          return;
        }

        return res.json();
      })
      .then(async (res) => {
        if (config.log) {
          _log(config.logText, res?.result?.items || res, config.logShowUrl ? url : null);
        }

        if (!res.success) {
          _err('http: ', res.error, res.result);
          if (res.error === 'Невалидный токен. Доступ закрыт') {
            store.commit('auth/removeCredentials');
          }

          reject({
            error: res.error,
            details: res.result || {},
          });

          return;
        }

        if (config.cache) {
          await store.dispatch('cache/setItem', {
            key: cacheKey,
            value: res,
          });
        }

        resolve(res || {});
      });
  });
}

function downloadBlob(blob, name = 'file.txt') {
  // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
  const blobUrl = URL.createObjectURL(blob);

  // Create a link element
  const link = document.createElement('a');

  // Set link's href to point to the Blob URL
  link.href = blobUrl;
  link.download = name;

  // Append link to the body
  document.body.appendChild(link);

  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  );

  // Remove link from body
  document.body.removeChild(link);
}

/**
 * Формирование url
 *
 * @param {Object} options
 * @return {String}
 * @private
 */
function _performUrl({ pagination, filter }) {
  const { page = 1, rowsPerPage = 0, sortBy = '', descending = '', includes = [] } = pagination;

  let result = `?page=${page}&perPage=${rowsPerPage}`;

  // TODO: Временно закомментировал, что бы получать все поля (возможно не временно)
  // if (getFields.length > 0) {
  //   const columns = `&columns=${getFields.join(',')}`;
  //   result += columns;
  // }

  if (sortBy) {
    const sortDir = descending ? '-' : '';
    result += `&orders=${sortDir}${sortBy}`;
  }

  if (filter) {
    result += '&filters=' + filter;
  }

  if (typeof includes === 'string') {
    result += '&include=' + includes;
  } else if (includes.length > 0) {
    result += `&include=${includes.join(',')}`;
  }

  return result;
}

export {
  METHODS,
  getData,
  downloadBlob,
  _performUrl,
};
