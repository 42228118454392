<template>
  <q-layout view="hHh lpR fFf">

    <q-page-container>
      <q-card bordered class="ge-auth-card fixed-center">
        <q-card-section>
          <q-form @submit="clickLogin">
            <q-input v-model="login"
                     label="Логин"
                     class="auto-complete"
                     :rules="[val => new RegExp(/.+@.+\..+/).test(val) || 'Email введён некорректно']"
            >
              <template #prepend>
                <q-icon name="mdi-account"/>
              </template>
            </q-input>

            <q-input v-model="password"
                     label="Пароль"
                     :type="hidePassword ? 'password' : 'text'"
                     :rules="[val => (!!val && !!val.trim()) || 'Поле не должно быть пустым']"
            >
              <template #prepend>
                <q-icon name="mdi-key-variant"/>
              </template>

              <template #append>
                <q-icon class="cursor-pointer"
                        :name="hidePassword ? 'visibility_off' : 'visibility'"
                        @click="hidePassword = !hidePassword"
                />
              </template>
            </q-input>

            <div class="row justify-center">
              <q-btn label="Войти"
                     type="submit"
                     class="q-mt-md"
                     color="primary"
                     :loading="loading"
              />
            </div>

          </q-form>
        </q-card-section>
      </q-card>
    </q-page-container>

  </q-layout>
</template>

<script>
import router from '@/router/router';

export default {
  name: 'Login',
  data() {
    return {
      login: '',
      password: '',
      hidePassword: true,
      loading: false,
    };
  },
  methods: {
    clickLogin() {
      this.loading = true;
      this.$store.dispatch('auth/login', {
        email: this.login,
        password: this.password,
      }).then(() => {
        router.push(router?.currentRoute?.query?.redirect ?? '/');
      }).catch((err) => {
        this.$q.notify({
          message: err.error,
          type: 'negative',
        });
      }).finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.ge-auth-card {
  width: 100%;
  min-width: 250px;
  max-width: 600px;
  margin: 0 10px;
}
</style>
