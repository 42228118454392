<template>
  <div class="q-pt-md">

    <div v-if="loading" style="text-align: center">
      <q-spinner size="48"/>
    </div>

    <div v-else>
      <template v-if="data.length === 0">
        Нет ни одного заказа
      </template>

      <div class="row q-col-gutter-md">
        <OrganizationOrderCard v-for="order in dataArr" :key="order.id"
                               :order="order"
                               @onRefresh="onUpdate"
        ></OrganizationOrderCard>
      </div>
    </div>
  </div>
</template>

<script>
import { getData, METHODS } from '@/utils/http';
import OrganizationOrderCard from '@/modules/organization/components/OrganizationOrderCard';
import RefSelect from '@/components/fields/RefSelect';
import RangeDates from '@/components/fields/date/RangeDates';

export default {
  name: 'OrganizationOrders',
  components: {
    RangeDates,
    RefSelect,
    OrganizationOrderCard,
  },
  props: {
    dataArr: {
      type: Array,
    },
  },
  data() {
    return {
      refreshKey: (new Date()).getTime(),
      loading: false,
      // Фильтры
      contractor: null,
      legalEntity: null,
      moveDirection: null,
      startDate: null,
      endDate: null,
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true;
      const profile = this.$store.getters['auth/getProfile'];

      getData('/organizations_order/filtered?include=order&perPage=1000', METHODS.POST, {
        params: {
          filters: {
            type: 'and',
            values: [
              {
                type: 'eq',
                field: 'organizations_id',
                values: [profile.organization_id],
              },
              {
                type: 'like',
                field: 'status',
                values: [this.$route.params.status],
              },
              ...(this.generateFilter()),
            ],
          },
        },
      })
          .then((res) => {
            this.data = res.result.items;
          })
          .catch((res) => {
            this.$q.notify({
              message: res.error,
              type: 'negative',
            });
          })
          .finally(() => {
            this.loading = false;
          });
    },

    generateFilter() {
      const filters = [];

      if (this.contractor || this.moveDirection || this.startDate || this.endDate) {
        filters.push({
          type: 'inside',
          field: 'order',
          values: [],
        });

        const values = filters[0].values;

        if (this.contractor) {
          values.push({
            type: 'eq',
            field: 'contractors_id',
            values: [this.contractor.id],
          });
        }

        if (this.moveDirection) {
          values.push({
            type: 'eq',
            field: 'move_directions_id',
            values: [this.moveDirection.id],
          });
        }

        if (this.startDate) {
          values.push(
              {
                type: 'gte',
                field: 'loading_start_date',
                values: [this.startDate],
              },
              {
                type: 'gte',
                field: 'unloading_start_date',
                values: [this.startDate],
              },
          );
        }

        if (this.endDate) {
          values.push(
              {
                type: 'lte',
                field: 'unloading_start_date',
                values: [this.endDate],
              },
              {
                type: 'lte',
                field: 'loading_start_date',
                values: [this.endDate],
              },
          );
        }
      }

      if (this.legalEntity) {
        filters.push(
            {
              type: 'eq',
              field: 'legal_entity_id',
              values: [this.legalEntity.id],
            },
        );
      }

      return filters;
    },
    onUpdate() {
      this.$emit('onRefresh');
    },
  },
};
</script>
