const state = {
  components: {
    users: {
      isAllowed: false,
    },
    confirm: {
      show: false,
      height: 0,
      header: '',
      text: null,
      labelApply: null,
      labelCancel: null,
      callback: () => null,
    },
  },
};

const mutations = {
  setConfirm(state, payload) {
    const confirm = state.components.confirm;
    confirm.header = payload.header;
    confirm.height = payload.height;
    confirm.text = payload.text;
    confirm.labelApply = payload.labelApply;
    confirm.labelCancel = payload.labelCancel;
    confirm.callback = payload.callback || (() => null);
    confirm.show = true;
  },
  clearConfirm(state) {
    const confirm = state.components.confirm;
    confirm.show = false;
    confirm.height = 0;
    confirm.header = '';
    confirm.text = null;
    confirm.labelApply = null;
    confirm.labelCancel = null;
    confirm.callback = () => null;
  },
};

const getters = {
  isShow: (state) => state.components.confirm.show,
  confirm(state) {
    return state.components.confirm;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
};
