const manufacture = {
    name: 'ref_inventory_producers',
    title: 'Виды узлов',
    url: '/refs/manufacturers',
    include: ['*'],
    filterBy: ['title'],
    perms: {
        list: 'v1.manufacturers.list',
        read: 'v1.manufacturers.read',
        create: 'v1.manufacturers.create',
        update: 'v1.manufacturers.update',
        delete: 'v1.manufacturers.delete',
    },
    listColumns: [
        {
            name: 'title',
            required: true,
            label: 'Наименование',
            align: 'left',
            field: 'title',
            sortable: true,
        },
    ],
    editColumns: [
        {
            name: 'title',
            required: true,
            label: 'Наименование',
            align: 'left',
            field: 'title',
            sortable: true,
        },
        {
            name: 'types_of_nodes',
            required: true,
            label: 'Виды узлов',
            align: 'left',
            field: 'types_of_nodes',
            type: 'reference',
            meta: {
                url: '/refs/types_of_nodes',
                optionLabel: 'title',
                multiple: true,
            },
        },
    ],
};

export {
    manufacture,
};
