import RefsContainer from '@/modules/refs/components/RefsContainer';
import NestedMenu from '@/components/for_router/NestedMenu';

export default {
  name: 'settings',
  path: '/settings',
  component: NestedMenu,
  redirect: '/settings/cost_tonne_per_km',
  children: [
    {
      path: ':ref_name',
      component: RefsContainer,
      props: {
        folder: 'settings',
      },
    },
  ],
};
