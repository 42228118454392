<template>
  <div>
    <entity-manager
        :name="ref.name"
        :title="ref.title"
        :entity-url="ref.url"
        :filters="ref.filters"
        :filter-by="ref.filterBy"
        :list-columns="ref.listColumns"
        :list-edit="ref.editColumns"
        :include="ref.include"
        :perms="ref.perms"
        :use-native-add="false"
        :use-native-edit="false"
        :key="refreshKey"
        :row-class="(row) => row.is_priority ? 'bg-red-1' : ''"
        v-show="hasAccess[ref.perms.list]"
        @onRowClick="onRowClick"
        @onEditClick="onRowClick"
    ></entity-manager>

    <OrderLogic
        :order="currentOrder"
        v-model="showLogicDialog"
        @onRefresh="onRefresh"
    ></OrderLogic>
  </div>
</template>

<script>
import {getData, METHODS} from '@/utils/http';
import HasPerms from '@/components/guard/mixins/HasPerms';
import {orders_moderations} from '@/structures/orders_moderations';
import EntityManager from '@/components/entity_manager/EntityManager';
import OrderLogic from '@/modules/orders/OrderLogic';

export default {
  name: 'OrdersModeration',
  components: {OrderLogic, EntityManager},
  mixins: [HasPerms],
  data() {
    return {
      ref: orders_moderations,
      refreshKey: (new Date()).getTime(),
      // Показ диалога для Логиста
      showLogicDialog: false,
      // Заказ который отображаем
      currentOrder: {},
    };
  },
  created() {
    this.ref.filters = [{
      by: 'status',
      value: this.$route.params.status,
    }];

    if (this.$route.params.id) {
      this.loadDetailData(this.$route.params.id);
    }
  },
  methods: {
    loadDetailData(id) {
      this.loading = true;

      getData('/orders_moderate/' + id + '?include=*', METHODS.GET)
        .then((res) => {
          this.currentOrder = res.result;
          this.showLogicDialog = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getPerms_() {
      return [this.ref.perms.list];
    },
    // При клике по записи
    onRowClick(row) {
      this.showLogicDialog = true;

      this.currentOrder = row;
    },
    onRefresh() {
      this.refreshKey = (new Date()).getTime();
    },
  },
};
</script>
