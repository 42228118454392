const expenses_vehicle = {
    name: 'expenses_vehicle',
    title: 'Расходы',
    include: ['*'],
    perms: {
        list: 'v1.expenses.list',
        read: 'v1.expenses.read',
        create: 'v1.expenses.create',
        update: 'v1.expenses.update',
        delete: 'v1.expenses.delete',
    },
    listColumns: [
        {
            name: 'expenses_type',
            label: 'Статья расхода',
            align: 'left',
            field: (row) => {
                return row.expenses_type.title;
            },
        },
        {
            name: 'description',
            label: 'Описание',
            align: 'left',
            field: 'description',
        },
        {
            name: 'date',
            label: 'Дата',
            align: 'left',
            field: (row) => {
                const d = new Date(Date.parse(row.date));

                return `${_setZero(d.getDate())}/${_setZero(d.getMonth() + 1)}/${d.getFullYear()}`;
            },
        },
        {
            name: 'legal_entity',
            label: 'Юр.лицо',
            align: 'left',
            field: (row) => {
                if (row.legal_entity) {
                    return row.legal_entity.title;
                }

                return '';
            },
        },
        {
            name: 'cost',
            label: 'Сумма без НДС',
            align: 'left',
            field: (row) => {
                return _priceFormat(row.cost, true);
            },
        },
        {
            name: 'cost_vat',
            label: 'Сумма НДС',
            align: 'left',
            field: (row) => {
                return _priceFormat(row.cost_vat, true);
            },
        },
        {
            name: 'cost_original',
            label: 'Сумма',
            align: 'left',
            field: 'cost_original',
        },
    ],
};

export {
    expenses_vehicle,
};
