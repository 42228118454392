<template>
  <div class="row q-col-gutter-md q-py-md"
      :style="{'max-width': maxWidth + 'px'}"
  >
    <div class="flex items-center q-mb-sm">
      <div>
        <q-btn flat
               icon="mdi-arrow-left"
               @click="prevMonth"
        >
          <q-tooltip>Предыдущий месяц</q-tooltip>
        </q-btn>
      </div>

      <div class="q-px-sm text-h6">{{ monthName }}, {{ year }}</div>

      <div>
        <q-btn flat
               icon="mdi-arrow-right"
               @click="nextMonth"
        >
          <q-tooltip>Следующий месяц</q-tooltip>
        </q-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MonthPicker',
  props: {
    startDate: {
      type: String,
    },
    endDate: {
      type: String,
    },
    // Максимальная ширина компонента
    maxWidth: {
      type: Number,
      default: 600,
    },
    // Инициализация входной переменной текущей датой
    autofill: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      year: null,
      month: null,
      monthDays: 0,
      monthName: '',
      monthNames: [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь',
      ],
    };
  },
  computed: {
    innerStartDate: {
      get() {
        return this.startDate;
      },
      set(val) {
        this.$emit('update:startDate', val);
      },
    },
    innerEndDate: {
      get() {
        return this.endDate;
      },
      set(val) {
        this.$emit('update:endDate', val);
      },
    },
  },
  created() {
    const initDate = (this.innerStartDate ? new Date(this.innerStartDate) : new Date());
    this.month = initDate.getMonth();
    this.year = initDate.getFullYear();

    this.initCalendar();
  },
  methods: {
    // Инициализация календаря
    initCalendar() {
      this.monthDays = this.getMonthDays(this.year, this.month);
      this.monthName = this.monthNames[this.month];

      this.innerStartDate = `${this.year}-${_setZero(this.month + 1)}-01`;
      this.innerEndDate = `${this.year}-${_setZero(this.month + 1)}-${this.monthDays}`;
      this.$emit('change');
    },

    // Предыдущий месяц
    prevMonth() {
      if (this.month > 0) {
        this.month--;
      } else {
        this.month = 11;
        this.year--;
      }

      this.initCalendar();
    },

    // Следующий месяц
    nextMonth() {
      if (this.month < 11) {
        this.month++;
      } else {
        this.month = 0;
        this.year++;
      }

      this.initCalendar();
    },

    // Количество дней в месяце
    getMonthDays(year, month) {
      return (new Date(year, month + 1, 0)).getDate();
    },
  },
};
</script>
