<template>
  <div :class="{
    row: vertical,
    column: !vertical,
  }">
    <div :class="{
      'col-auto': vertical,
      'col-12': !vertical,
       widthFull: !vertical,
    }">
      <!-- Навигация -->
      <q-tabs class="text-primary bg-white"
              :class="{width300: vertical}"
              :vertical="vertical"
              inline-label align="left"
              active-bg-color="blue-1"
      >
        <template v-if="!isLoadingMenu">
          <q-route-tab v-for="menu of twoLvlMenuItems" :key="menu.to"
                       style="justify-content:initial"
                       :to="menu.path"
                       :label="menu.title"
          />
        </template>

        <!-- Лоадер -->
        <template v-else-if="nestedLvl === 1">
          <q-skeleton v-for="i in 5" :key="i"
                      type="rect"
                      animation="pulse-x"
                      class="q-ma-md"
                      style="width: 250px; margin-top: 24px"
          />
        </template>
      </q-tabs>
    </div>
    <div class="col">
      <router-view v-show="!isLoadingMenu" :key="$route.path"/>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'NestedMenu',
  props: {
    nestedLvl: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    ...mapGetters({
      isLoadingMenu: 'menu/isLoading',
    }),
    twoLvlMenuProps() {
      return this.$store.getters['menu/getNestedLvlMenu'](this.nestedLvl, false);
    },
    twoLvlMenuItems() {
      return this.$store.getters['menu/getNestedLvlMenu'](this.nestedLvl, true);
    },
    vertical() {
      return this.twoLvlMenuProps == null ? true : this.twoLvlMenuProps.type === 'vertical';
    },
  },
};
</script>

<style scoped>
.width300 {
  width: 300px;
}

.widthFull {
  max-width: 100%;
}
</style>

