import store from '@/store/.';

/**
 * Сервис проерки прав
 */
export default class GuardService {

  /**
   * Проверяет наличие нескольких прав у пользователя
   */
  static hasPermList(perms) {
    let result = {};

    for (let perm in perms) {
      result[perms[perm]] = this.hasPerm(perms[perm]);
    }

    return result;
  }

  /**
   * Проверяет наличие нескольких прав у пользователя
   */
  static hasPerms(perms) {
    for (let perm in perms) {
      if (this.hasPerm(perms[perm])) {
        return true;
      }
    }

    return false;
  }

  /**
   * Проверка наличия прав у пользователя
   *
   * @param permName
   * @returns {boolean}
   */
  static hasPerm(permName) {
    let profile = store.getters['auth/getProfile'];
    let isExist = false;

    if (!profile) {
      return false;
    }
    if (profile.full_name === 'admin') {
      return true;
    }


    for(let role of profile.roles) {

      role.perms.forEach(function(item) {

        if (item.name === permName) {
          isExist = true;
        }
      });
    }

    return isExist;
  }
}
