// Компоненты для отрисовки
import NestedMenu from '@/components/for_router/NestedMenu';
import OrganizationOrdersNestedMenu from './OrganizationOrdersNestedMenu';

// 2 Уровень вложенности
import Vehicles from '@/modules/organization/Vehicles';
import Drivers from '@/modules/organization/Drivers';
import Couplings from '@/modules/organization/Couplings';
import Trailers from '@/modules/organization/Trailers';
import OrganizationOrders from '@/modules/organization/OrganizationOrders';
import Finance from '@/modules/organization/Finance';

// 3 уровень вложенности
// finance
import Expenses from '@/modules/organization/Finance/Expenses';
import ExpensesTypes from '@/modules/organization/Finance/ExpensesTypes';
import Incomes from '@/modules/organization/Finance/Incomes';
import Fuel_expenses from '@/modules/organization/Finance/Fuel_expenses';
// inventory
import TypesOfNode from '@/modules/organization/Inventory/TypesOfNode';
import Manufacture from '@/modules/organization/Inventory/Manufacture';
import Providers from '@/modules/organization/Inventory/Providers';
import NodePositions from '@/modules/organization/Inventory/NodePositions';

// 4 уровень вложенности
// inventory_nodes
import NodeOnVehicles from '@/modules/organization/Inventory/InventoryNode/NodeOnVehicles';
import NodeOnTrailers from '@/modules/organization/Inventory/InventoryNode/NodeOnTrailers';
import NodeOnStore from '@/modules/organization/Inventory/InventoryNode/NodeOnStore';
import AllList from '@/modules/organization/Inventory/InventoryNode/AllList';
import ReportByOrders from '@/modules/organization/Reports/ReportByOrders';
import ReportByStatuses from '@/modules/organization/Reports/ReportByStatuses';
import ReportByVehicles from '@/modules/organization/Reports/ReportByVehicles';
import ReportByDrivers from '@/modules/organization/Reports/ReportByDrivers';
import ReportByContractor from '@/modules/organization/Reports/ReportByContractor';
import ReportTotal from '@/modules/organization/Reports/ReportTotal';
import ReportTotalDriver from '@/modules/organization/Reports/ReportTotalDriver';
import ReportByFuel from '@/modules/organization/Reports/ReportByFuel';
import ReportAccrualsDrivers from '@/modules/organization/Reports/ReportAccrualsDrivers';
import NodeReport from '@/modules/organization/Inventory/NodeReport';

export default {
  path: '/organization',
  component: NestedMenu,
  redirect: '/organization/vehicles',
  children: [
    {
      path: 'vehicles',
      component: Vehicles,
    },
    {
      path: 'drivers',
      component: Drivers,
    },
    {
      path: 'couplings',
      component: Couplings,
    },
    {
      path: 'trailers',
      component: Trailers,
    },
    {
      path: 'orders',
      component: OrganizationOrdersNestedMenu,
      redirect: '/organization/orders/in_execution',
      props: {
        nestedLvl: 2,
      },
      children: [
        {
          path: ':status',
          component: OrganizationOrders,
        },
      ],
    },
    {
      path: 'reports',
      component: NestedMenu,
      props: {
        nestedLvl: 2,
      },

      redirect: '/organization/reports/statuses',
      children: [
        {
          path: 'statuses',
          component: ReportByStatuses,
        },
        {
          path: 'vehicles',
          component: ReportByVehicles,
        },
        {
          path: 'drivers',
          component: ReportByDrivers,
        },
        {
          path: 'contractors',
          component: ReportByContractor,
        },
        {
          path: 'orders',
          component: ReportByOrders,
        },
        {
          path: 'total',
          component: ReportTotal,
        },
        {
          path: 'total_driver',
          component: ReportTotalDriver,
        },
        {
          path: 'accounting_fuel',
          component: ReportByFuel,
        },
        {
          path: 'drivers_accruals',
          component: ReportAccrualsDrivers,
        },
      ],
    },
    {
      path: 'finance',
      component: Finance,
      redirect: '/organization/finance/expenses',
      children: [
        {
          path: 'expenses',
          component: Expenses,
        },
        {
          path: 'expenses_types',
          component: ExpensesTypes,
        },
        {
          path: 'incomes',
          component: Incomes,
        },
        {
          path: 'fuel_expenses',
          component: Fuel_expenses,
        },
      ],
    },
    {
      path: 'inventory',
      component: NestedMenu,
      props: {
        nestedLvl: 2,
      },
      redirect: '/organization/inventory/inventory_nodes',
      children: [
        {
          path: 'types_of_nodes',
          component: TypesOfNode,
        },
        {
          path: 'manufacture',
          component: Manufacture,
        },
        {
          path: 'providers',
          component: Providers,
        },
        {
          path: 'node_positions',
          component: NodePositions,
        },
        {
          path: 'node_report',
          component: NodeReport,
        },
        {
          path: 'inventory_nodes',
          component: NestedMenu,
          props: {
            nestedLvl: 3,
          },
          redirect: '/organization/inventory/inventory_nodes/vehicles',
          children: [
            {
              path: 'all',
              component: AllList,
            },
            {
              path: 'vehicles',
              component: NodeOnVehicles,
            },
            {
              path: 'trailers',
              component: NodeOnTrailers,
            },
            {
              path: 'store',
              component: NodeOnStore,
            },
          ],
        },
      ],
    },
  ],
};
