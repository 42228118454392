import UsersList from '@/modules/users/components/UsersList';
import Roles from '@/modules/users/components/Roles';
import Perms from '@/modules/users/components/Perms';
import Actions from '@/modules/users/components/Actions';
import NestedMenu from '@/components/for_router/NestedMenu';

export default {
  name: 'users',
  path: '/users',
  component: NestedMenu,
  redirect: '/users/list',
  children: [
    {
      path: 'list',
      component: UsersList,
    },
    {
      path: 'roles',
      component: Roles,
    },
    {
      path: 'perms',
      component: Perms,
    },
    {
      path: 'actions',
      component: Actions,
    },
  ],
};
