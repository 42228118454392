const inventory_types = {
    'VEHICLE_TYPE': 'На транспорт',
    'TRAILER_TYPE': 'На прицепы',
};

const types_of_node = {
    name: 'ref_types_of_node',
    title: 'Виды узлов',
    url: '/refs/types_of_nodes',
    include: [],
    filterBy: ['title'],
    perms: {
        list: 'v1.types_of_node.list',
        read: 'v1.types_of_node.read',
        create: 'v1.types_of_node.create',
        update: 'v1.types_of_node.update',
        delete: 'v1.types_of_node.delete',
    },
    listColumns: [
        {
            name: 'title',
            required: true,
            label: 'Наименование',
            align: 'left',
            field: 'title',
            sortable: true,
        },
        // {
        //     name: 'install_object',
        //     required: true,
        //     label: 'Объект установки',
        //     align: 'left',
        //     field: (row) => {
        //         return inventory_types[row.install_object] || '';
        //     },
        //     sortable: true,
        // },
    ],
    editColumns: [
        {
            name: 'title',
            required: true,
            label: 'Наименование',
            align: 'left',
            field: 'title',
            sortable: true,
        },
        {
            name: 'rate_of_degradation',
            required: true,
            label: 'Норма износа',
            align: 'left',
            field: 'rate_of_degradation',
            sortable: true,
            type: 'number',
        },
        {
            name: 'degradation_notify_percent',
            required: true,
            label: 'Информировать при износе, %',
            align: 'left',
            field: 'degradation_notify_percent',
            sortable: true,
            type: 'number',
        },
        // {
        //     name: 'install_object',
        //     required: true,
        //     label: 'Объект установки',
        //     align: 'left',
        //     field: 'install_object',
        //     type: 'enum',
        //     meta: {
        //         options: Object.keys(inventory_types).map(key => {
        //             return {
        //                 value: key,
        //                 label: inventory_types[key],
        //             };
        //         }),
        //     },
        // },
    ],
};

export {
    types_of_node,
    inventory_types,
};
