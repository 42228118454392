var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
  row: _vm.vertical,
  column: !_vm.vertical,
}},[_c('div',{class:{
    'col-auto': _vm.vertical,
    'col-12': !_vm.vertical,
     widthFull: !_vm.vertical,
  }},[_c('q-tabs',{staticClass:"text-primary bg-white",class:{width300: _vm.vertical},attrs:{"vertical":_vm.vertical,"inline-label":"","align":"left","active-bg-color":"blue-1"}},[(!_vm.isLoadingMenu)?_vm._l((_vm.twoLvlMenuItems),function(menu){return _c('q-route-tab',{key:menu.to,staticStyle:{"justify-content":"initial"},attrs:{"to":menu.path,"label":menu.title}})}):(_vm.nestedLvl === 1)?_vm._l((5),function(i){return _c('q-skeleton',{key:i,staticClass:"q-ma-md",staticStyle:{"width":"250px","margin-top":"24px"},attrs:{"type":"rect","animation":"pulse-x"}})}):_vm._e()],2)],1),_c('div',{staticClass:"col"},[_c('router-view',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoadingMenu),expression:"!isLoadingMenu"}],key:_vm.$route.path})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }