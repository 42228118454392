const inventory_types = {
    'VEHICLE_TYPE': 'На транспорт',
    'TRAILER_TYPE': 'На прицепы',
};

const node_positions = {
    name: 'ref_node_positions',
    title: 'Поставщики',
    url: '/refs/node_positions',
    include: [],
    filterBy: ['title'],
    perms: {
        list: 'v1.node_positions.list',
        read: 'v1.node_positions.read',
        create: 'v1.node_positions.create',
        update: 'v1.node_positions.update',
        delete: 'v1.node_positions.delete',
    },
    listColumns: [
        {
            name: 'title',
            required: true,
            label: 'Позиция',
            align: 'left',
            field: 'title',
            sortable: true,
        },
        {
            name: 'type_of_node',
            required: true,
            label: 'Виды узлов',
            align: 'left',
            field: 'type_of_node',
            map: 'title',
        },
        {
            name: 'install_object',
            required: true,
            label: 'Объект установки',
            align: 'left',
            field: (row) => {
                return inventory_types[row.install_object] || '';
            },
            sortable: true,
        },
    ],
    editColumns: [
        {
            name: 'title',
            required: true,
            label: 'Позиция',
            align: 'left',
            field: 'title',
            sortable: true,
        },
        {
            name: 'type_of_node',
            required: true,
            label: 'Виды узлов',
            align: 'left',
            field: 'type_of_node',
            type: 'reference',
            meta: {
                url: '/refs/types_of_nodes',
                optionLabel: 'title',
            },
        },
        {
            name: 'install_object',
            required: true,
            label: 'Объект установки',
            align: 'left',
            field: 'install_object',
            type: 'enum',
            meta: {
                options: Object.keys(inventory_types).map((key) => {
                    return {
                        value: key,
                        label: inventory_types[key],
                    };
                }),
            },
        },
    ],
};

export {
    node_positions,
};
