<template>
  <q-dialog v-model="dialog" @input="$emit('input', false)">
    <q-card style="width: 400px">
      <q-toolbar>
        <q-toolbar-title>
          Назначить заказ
        </q-toolbar-title>
      </q-toolbar>

      <q-card-section>
        <ref-select
            label="Заказ"
            v-if="order.length === 0"
            v-model="order_data"
            :option-label="getOrderName"
            url="/organizations_order"
            @input="changeOrder"
            :filters="filters"
            :autocomplete="true"
        >
        </ref-select>

        <DateField label="Дата начала рейса"
                   v-model="tripDateStart"
        ></DateField>

        <DateField label="Дата окончания рейса"
                   v-model="tripDateEnd"
        ></DateField>


        <q-input
            label="Количество груза"
            v-model="cargo_count"
            type="number"
            :hint="hint"
        />
      </q-card-section>

      <q-card-actions>
        <q-btn
            :disable="loading"
            @click="$emit('input', false)"
        >
          Отмена
        </q-btn>

        <q-space />

        <q-btn
            @click="save"
            :loading="loading"
        >
          Сохранить
        </q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import {getData, METHODS} from '@/utils/http';
import RefSelect from '@/components/fields/RefSelect';
import {getDateToAPiFormat, parseDateFromStr} from '@/utils/date';
import DateField from '@/components/fields/date/DateField';

export default {
  name: 'AddOrder',
  components: {RefSelect, DateField},
  props: {
    ordersDialog: {
      type: Boolean,
      required: true,
    },
    drivers: {
      type: Object,
    },
    order: {
      type: Array,
      required: true,
    },
    vehicle: {
      type: Object,
      required: true,
    },
    date: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      order_data: null,
      tripDateStart: '',
      tripDateEnd: '',
      driver: [],
      selectKey: '',
      cargo_count: 0,
      price: 0,
      hint: '',
      filters: [
        {
          key: 'status',
          value: 'IN_EXECUTION',
        },
      ],
    };
  },
  created() {
    this.dialog = this.ordersDialog;

    if (this.drivers.drivers.length >= 2) {
        this.driver.push(this.drivers.drivers[0].id);
        this.driver.push(this.drivers.drivers[1].id);
    } else {
        this.driver = this.drivers.drivers[0].id;
    }

    if (this.order.length > 0) {
      this.order_data = this.order[0];
    }
  },
  methods: {
    save() {
      this.loading = true;

      if (!confirm('Назначить рейс?')) {
        return false;
      }

      getData('/vehicle_orders', METHODS.POST, {
          params: {
            'vehicle': {
              'id': this.vehicle.id,
            },
            'start_date': getDateToAPiFormat(this.tripDateStart),
            'end_date': getDateToAPiFormat(this.tripDateEnd),
            'organization_order': {
              'id': this.order_data.id,
            },
            'cargo_count': this.cargo_count,
            'price': this.price,
            'driver_id': this.driver,
          },
        })
        .then(() => {
          this.$emit('onRefresh');
        })
        .catch((res) => {
          this.$q.notify({
            message: res.error,
            type: 'negative',
          });
        })
        .finally(() => {
          this.loading = false;
          this.$emit('input', false);
        });
    },

    getOrderName(order) {
      const ord = order.order;
      return `${ord.move_direction.title},
      ${parseDateFromStr(ord.loading_start_date)} - ${parseDateFromStr(ord.unloading_start_date)}`;
    },
    getOrderTitle(order) {
      const ord = order.order;
      let title = '#' + ord.number + ' ' + ord.move_direction.title;
      title += `, ${parseDateFromStr(ord.loading_start_date)} - ${parseDateFromStr(ord.unloading_start_date)}`;

      return title;
    },
    cargoLeft(order) {
      if (!order) {
        return '';
      }

      return `Доступно ${order.cargo_units_count - order.distributed_cargo_count}`;
    },
    changeOrder(order) {
      this.hint = this.cargoLeft(order);
      this.tripDateStart = parseDateFromStr(order.order.loading_start_date);
      this.tripDateEnd = parseDateFromStr(order.order.unloading_start_date);
    },
  },
};
</script>
