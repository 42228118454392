<template>
  <div>
    <entity-manager
        :name="ref.name"
        :title="ref.title"
        :entity-url="ref.url"
        :list-columns="ref.listColumns"
        :list-edit="ref.editColumns"
        :include="ref.include"
        :filterBy="ref.filterBy"
        :perms="ref.perms"
        :access="ref.access"
        :key="tableKey"
    >
      <template #top-right>
        <q-card-actions align="right">
          <q-btn
              flat
              outline
              align="right"
              color="primary"
              label="Добавить"
              icon="add"
              @click="showAllowDialog = true; allowItem = {}"
          ></q-btn>
        </q-card-actions>
      </template>

      <template #body="props">
        <q-tr :props="props">
          <q-td
              v-for="column in ref.listColumns"
              :key="column.name"
              :props="props"
          >
            <!-- Выборка определенного поля из объекта -->
            <div
              v-if="column.map != null">
              <template v-if="column.type === 'multiple'">
                  <span
                    class="block"
                    v-for="it in props.row[column.field]"
                    :key="it[column.field]"
                  >
                    {{ it[column.map] || '[Не задано]' }}
                  </span>
              </template>

              <template v-else>
                {{ props.row[column.field] && props.row[column.field][column.map] || '[Не задано]' | truncate(50) }}
              </template>
            </div>

            <div
                v-else-if="column.type ==='checkbox'">
              <q-checkbox
                  :value="props.row[column.field]"
              ></q-checkbox>
            </div>

            <div
                v-else>
              {{ props.row[column.field] || '[Не задано]' }}
            </div>
          </q-td>

          <!-- Колонка с действиями -->
          <q-td key="actions">
            <q-btn
                round flat
                text-color="primary"
                icon="edit"
                @click="showAllowDialog = true; allowItem = _copy(props.row)"
            >
              <q-tooltip>Изменить</q-tooltip>
            </q-btn>

            <q-btn
                round flat
                text-color="red"
                icon="delete"
                @click="remove(props.row)"
            >
              <q-tooltip>Удалить</q-tooltip>
            </q-btn>
          </q-td>
        </q-tr>
      </template>
    </entity-manager>

    <q-dialog v-model="showAllowDialog">
      <q-card
          v-if="allowItem"
          style="max-width: 900px;"
          :style="`width: ${allowItem.id ? '900px' : '300px'}`"
      >
        <!-- Заголовок -->
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">{{allowItem.id ? 'Редактирование' : 'Создание'}} записи</div>

          <q-space/>

          <q-btn icon="close"
              flat round dense
              v-close-popup
          >
            <q-tooltip>Закрыть</q-tooltip>
          </q-btn>
        </q-card-section>

        <q-card-section>
          <div class="row">
            <div class="col" :class="{'col-3': allowItem.id}">
              <DadataSelect
                  v-model="allowItem.tin"
                  v-bind="ref.editColumns[0].meta"
                  :meta="ref.editColumns[0].meta"
                  label="ИНН"
                  @onSelect="onInput"
              >
              </DadataSelect>

              <q-input
                  label="Наименование"
                  v-model="allowItem.title"
              >
              </q-input>

              <ref-select
                  label="Тип ТС"
                  option-label="title"
                  multiple
                  url="/refs/vehicle_type"
                  v-model="allowItem.vehicle_types"
              ></ref-select>

              <q-checkbox
                  label="Является перевозчиком"
                  v-model="allowItem.isTransportation"
              >
              </q-checkbox>

              <div v-if="!allowItem.id">
                <q-input
                    label="Имя пользователя"
                    v-model="allowItem.user_name"
                >
                </q-input>

                <q-input
                    label="Email пользователя"
                    v-model="allowItem.user_email"
                    type="email"
                >
                </q-input>

                <q-input
                    label="Код авторизации"
                    v-model="allowItem.confirmation_code"
                    maxlength="4"
                >
                </q-input>

                <q-input
                    label="Телефон пользователя"
                    v-model="allowItem.user_phone"
                    type="tel"
                >
                </q-input>
              </div>
            </div>

            <div class="col-9 q-pl-lg" v-if="allowItem.id">
              <b>Пользователи</b>

              <carriers-users
                  :organization="allowItem"
              >
              </carriers-users>
            </div>
          </div>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn
              flat
              label="OK"
              color="primary"
              @click="sendData"
              :loading="loading"
          ></q-btn>

          <q-btn
              flat
              label="Отмена"
              color="negative"
              v-close-popup
              :disable="loading"
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import EntityManager from '@/components/entity_manager/EntityManager';
import DadataSelect from '@/components/fields/DadataSelect';
import {getData, METHODS} from '@/utils/http';
import RefSelect from '@/components/fields/RefSelect';
import CarriersUsers from '@/modules/carriers/CarriersUsers';

export default {
  name: 'CarriersData',
  components: {CarriersUsers, RefSelect, EntityManager, DadataSelect},
  props: {
    title: String,
    entity: String,
  },
  data() {
    return {
      showAllowDialog: false,
      fields: [],
      edit: [],
      data: [],
      allowItem: {},
      loading: false,
      tableKey: (new Date()).getTime(),
      pagination: {
        sortBy: 'desc',
        descending: false,
        page: 1,
        rowsPerPage: 10,
        rowsNumber: 0,
      },
      ref: {
        name: 'carriers',
        title: 'Перевозчики',
        url: '/carriers',
        include: ['vehicle_types'],
        filterBy: ['title'],
        perms: {
          list: 'v1.carriers.list',
          read: 'v1.carriers.read',
          create: 'v1.carriers.create',
          update: 'v1.carriers.update',
          delete: 'v1.carriers.delete',
        },
        listColumns: [
          {
            name: 'title',
            required: true,
            label: 'Наименование',
            align: 'left',
            field: 'title',
            sortable: true,
          },
          {
            name: 'tin',
            required: true,
            label: 'ИНН',
            align: 'left',
            field: 'tin',
            sortable: true,
          },
          {
            name: 'description',
            required: true,
            label: 'Описание',
            align: 'left',
            field: 'description',
            sortable: false,
          },
          {
            name: 'isTransportation',
            required: true,
            label: 'Является перевозчиком',
            align: 'left',
            field: 'isTransportation',
            sortable: true,
            type: 'checkbox',
          },
          {
            name: 'vehicle_types',
            required: true,
            label: 'Тип тс',
            align: 'left',
            field: 'vehicle_types',
            sortable: true,
            map: 'title',
            type: 'multiple',
          },
        ],
        editColumns: [
          {
            name: 'title',
            required: true,
            label: 'Наименование',
            align: 'left',
            field: 'title',
            sortable: true,
          },
          {
            name: 'tin',
            required: true,
            label: 'ИНН',
            align: 'left',
            field: 'tin',
            sortable: true,
            type: 'dadata',
            meta: {
              map: 'inn',
            },
          },
          {
            name: 'vehicle_types',
            required: true,
            label: 'Тип ТС',
            align: 'left',
            field: 'vehicle_types',
            sortable: true,
            type: 'reference',
            meta: {
              url: '/refs/vehicle_type',
              optionLabel: 'title',
              multiple: true,
            },
          },
          {
            name: 'isTransportation',
            required: true,
            label: 'Является перевозчиком',
            align: 'left',
            field: 'isTransportation',
            sortable: true,
            type: 'checkbox',
          },
        ],
        createColumns:  [
          {
            name: 'title',
            required: true,
            label: 'Наименование',
            align: 'left',
            field: 'title',
            sortable: true,
          },
          {
            name: 'tin',
            required: true,
            label: 'ИНН',
            align: 'left',
            field: 'tin',
            sortable: true,
            type: 'dadata',
            meta: {
              map: 'inn',
            },
          },
          {
            name: 'vehicle_types',
            required: true,
            label: 'Тип ТС',
            align: 'left',
            field: 'vehicle_types',
            sortable: true,
            type: 'reference',
            meta: {
              url: '/refs/vehicle_type',
              optionLabel: 'title',
              multiple: true,
            },
          },
          {
            name: 'isTransportation',
            required: true,
            label: 'Является перевозчиком',
            align: 'left',
            field: 'isTransportation',
            sortable: true,
            type: 'checkbox',
          },
          {
            name: 'user_name',
            required: true,
            label: 'Имя нового пользователя',
            align: 'left',
            field: 'user_name',
            sortable: true,
          },
          {
            name: 'user_email',
            required: true,
            label: 'Email нового пользователя',
            align: 'left',
            field: 'user_email',
            sortable: true,
            type: 'email',
          },
          {
            name: 'user_phone',
            required: true,
            label: 'Телефон нового пользователя',
            align: 'left',
            field: 'user_phone',
            sortable: true,
            type: 'tel',
          },
        ],
      },
    };
  },
  methods: {
    onInput(value) {
      this.allowItem.title = value.data.value;
    },

    sendData() {
      const method = this.allowItem.id ? METHODS.PUT : METHODS.POST;
      this.loading = true;
      const url = '/carriers' + (this.allowItem.id ? '/' + this.allowItem.id : '');

      getData(url, method, {
        params: this.allowItem,
      })
        .then(() => {
          this.tableKey = (new Date()).getTime();
          this.showAllowDialog = false;
          this.allowItem = {};
        })
        .catch(() => {
          this.$q.notify({
            message: 'Не удалось создать запись',
            type: 'negative',
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    remove(item) {
      if (confirm('Уверены, что хотите удалить перевозчика?')) {
        this.loading = true;

        getData('/carriers/' + item.id, METHODS.DELETE)
          .then(() => {
            this.tableKey = (new Date()).getTime();
          })
          .catch(() => {
            this.$q.notify({
              message: 'Не удалось удалить запись',
              type: 'negative',
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
