<template>
  <div>
    <entity-manager
        :name="ref.name"
        :title="ref.title"
        :entity-url="ref.url"
        :filters="ref.filters"
        :filter-by="ref.filterBy"
        :list-columns="ref.listColumns"
        :list-edit="ref.editColumns"
        :include="ref.include"
        :perms="ref.perms"
        :use-native-add="false"
        :use-native-edit="false"
        :key="refreshKey"
        :row-class="(row) => row.is_priority ? 'bg-red-1' : ''"
        v-show="hasAccess[ref.perms.list]"
        @onRowClick="onRowClick"
        @onAddClick="onAddClick"
        @onEditClick="onEditClick"
    >
      <template v-if="hasAccess[ref.perms.create]" #actions="props">
        <q-btn
            round flat
            icon="content_copy"
            size="sm"
            text-color="primary"
            @click.stop.prevent="copyItem(props.row)"
        >
          <q-tooltip>
            Дублировать заказ
          </q-tooltip>
        </q-btn>
      </template>
    </entity-manager>

    <OrderCreate
        :order="currentOrder"
        v-model="showCommDialog"
        v-if="showCommDialog"
        @onRefresh="onRefresh"
    ></OrderCreate>
  </div>
</template>

<script>
import HasPerms from '@/components/guard/mixins/HasPerms';
import {orders_list} from '@/structures/orders_list';
import EntityManager from '@/components/entity_manager/EntityManager';
import OrderCreate from '@/modules/orders/OrderCreate';

export default {
  name: 'OrdersList',
  components: {OrderCreate, EntityManager},
  mixins: [HasPerms],
  data() {
    return {
      ref: orders_list,
      refreshKey: (new Date()).getTime(),
      // Показ диалога для Коммерческого менеджера
      showCommDialog: false,
      // Заказ который отображаем
      currentOrder: {},
    };
  },
  created() {
    this.ref.filters = [{
      by: 'status',
      value: this.$route.params.status,
    }];

    if (this.$store.getters['auth/getProfile'] && !this.hasAccess[this.ref.perms.list]) {
      this.$router.replace('/orders/moderation');
    }
  },
  methods: {
    getPerms_() {
      return [this.ref.perms.list];
    },
    // При клике по записи
    onRowClick(row) {
      this.showCommDialog = true;

      this.currentOrder = row;
    },
    // При нажатии на кнопку "Добавить"
    onAddClick() {
      this.currentOrder = {};
      this.showCommDialog = true;
    },
    onEditClick(e) {
      this.onRowClick(e);
    },
    onRefresh() {
      this.refreshKey = (new Date()).getTime();
    },
    copyItem(item) {
      this.currentOrder = _copy(item);

      delete this.currentOrder.id;

      this.showCommDialog = true;
    },
  },
};
</script>
