const drivers_ref = {
    name: 'drivers',
    title: 'Водители',
    url: '/drivers',
    include: ['user'],
    perms: {
        list: 'v1.drivers.list',
        read: 'v1.drivers.read',
        create: 'v1.drivers.create',
        update: 'v1.drivers.update',
        delete: 'v1.drivers.delete',
    },
    listColumns: [
        {
            name: 'user_name',
            required: true,
            label: 'Имя',
            align: 'left',
            field: 'user',
            map: 'full_name',
            sortable: true,
        },
        {
            name: 'user_phone',
            required: true,
            label: 'Телефон',
            align: 'left',
            field: 'user',
            map: 'phone',
            sortable: true,
            type: 'tel',
        },
    ],
    editColumns: [
        {
            name: 'user_name',
            required: true,
            label: 'Имя',
            align: 'left',
            field: 'user_name',
            sortable: true,
        },
        {
            name: 'user_phone',
            required: true,
            label: 'Телефон',
            align: 'left',
            field: 'user_phone',
            sortable: true,
            type: 'tel',
        },
        {
            name: 'user_confirm',
            required: true,
            label: 'Код подтверждения',
            align: 'left',
            field: 'confirmation_code',
            sortable: true,
        },
    ],
    createColumns:  [
        {
            name: 'user_name',
            required: true,
            label: 'Имя',
            align: 'left',
            field: 'user_name',
            sortable: true,
        },
        {
            name: 'user_phone',
            required: true,
            label: 'Телефон',
            align: 'left',
            field: 'user_phone',
            sortable: true,
            type: 'tel',
        },
        {
            name: 'user_confirm',
            required: true,
            label: 'Код подтверждения',
            align: 'left',
            field: 'confirmation_code',
            sortable: true,
        },
    ],
};

export {
    drivers_ref,
};
