import { render, staticRenderFns } from "./ReportByVehicles.vue?vue&type=template&id=f116152a&"
import script from "./ReportByVehicles.vue?vue&type=script&lang=js&"
export * from "./ReportByVehicles.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QRadio from 'quasar/src/components/radio/QRadio.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QRadio,QBtn,QTable,QTr,QTd});
