import { render, staticRenderFns } from "./OrganizationOrdersNestedMenu.vue?vue&type=template&id=7a0c0c9d&scoped=true&"
import script from "./OrganizationOrdersNestedMenu.vue?vue&type=script&lang=js&"
export * from "./OrganizationOrdersNestedMenu.vue?vue&type=script&lang=js&"
import style0 from "./OrganizationOrdersNestedMenu.vue?vue&type=style&index=0&id=7a0c0c9d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a0c0c9d",
  null
  
)

export default component.exports
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QRouteTab from 'quasar/src/components/tabs/QRouteTab.js';
import QSkeleton from 'quasar/src/components/skeleton/QSkeleton.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QTabs,QRouteTab,QSkeleton,QBtn});
