<template>
  <Dialog
    v-model="show"
    :header="mainHeader"
    :label-apply="mainLabelApply"
    :height="mainHeight"
    @clickApply="apply"
    @clickCancel="cancel"
    :loading-apply="loading"
  >
    {{ mainText }}
  </Dialog>
</template>

<script>
import Dialog from '@/components/Dialog';

export default {
  /**
   * Компонент GeConfirm имеет 2 варианта использования:
   * 1) Мы добавляем компонент и прописываем ему все props (как обычно)
   * 2) Комитим необходимые props (тогда используется глобальный confirm)
   * example: this.$store.commit('components/setConfirm', { your confirm props });
   */
  name: 'ConfirmDialog',
  components: {Dialog},
  props: {
    // Использовать конфиг из стора
    useStoreProps: {
      type: Boolean,
      default: false,
    },
    // Заголовок
    header: String,
    //Высота
    height: Number,
    // Основной текст
    text: String,
    // Текст кнопки Отмена
    labelCancel: String,
    // Текст кнопки Ок
    labelApply: String,
  },
  data: () => ({
    show: false,
    loading: false,
    storeProps: {},
    callback: () => null,
  }),
  computed: {
    mainText() {
      return (this.useStoreProps ? this.storeProps.text : this.text) || 'Вы уверены?';
    },
    mainLabelApply() {
      return (this.useStoreProps ? this.storeProps.labelApply : this.labelApply) || 'Ок';
    },
    mainLabelCancel() {
      return (this.useStoreProps ? this.storeProps.labelCancel : this.labelCancel) || 'Отмена';
    },
    mainHeader() {
      return (this.useStoreProps ? this.storeProps.header : this.header) || '';
    },
    mainHeight() {
      return (this.useStoreProps ? this.storeProps.height : this.height) || '';
    },
  },
  created() {
    if (this.useStoreProps) {
      this.storeProps = this.$store.getters['components/confirm'];
      this.callback = this.storeProps.callback;
    }
    this.show = true;
  },
  methods: {
    apply() {
      this.loading = true;
      if (this.useStoreProps) {
        this.callback();
      }
      this.$emit('click-apply');
      setTimeout(() => {
          this.$store.commit('components/clearConfirm');
        },
        1000,
      );
    },
    cancel() {
      this.$emit('click-cancel');
      this.$store.commit('components/clearConfirm');
    },
  },
};
</script>
