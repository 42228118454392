<template>
  <div>
    <entity-manager
        :name="ref.name"
        :title="ref.title"
        :entity-url="ref.url"
        :list-columns="ref.listColumns"
        :list-edit="ref.editColumns"
        :include="ref.include"
        :perms="ref.perms"
        :filter-by="ref.filterBy"
        v-show="hasAccess[ref.perms.list]"
    ></entity-manager>
  </div>
</template>

<script>
import HasPerms from '@/components/guard/mixins/HasPerms';
import EntityManager from '@/components/entity_manager/EntityManager';
import {vehicles_ref} from '@/structures/vehicles';

export default {
  name: 'Vehicles',
  components: {EntityManager },
  mixins: [HasPerms],
  data() {
    return {
      ref: vehicles_ref,
    };
  },
  methods: {
    // @override
    getPerms_() {
      return [
        'v1.vehicles.list',
      ];
    },
  },
};
</script>
