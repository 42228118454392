<template>
  <q-dialog v-model="dialog" @input="$emit('input', false)">
    <q-card style="width: 400px">
      <q-toolbar>
        <q-toolbar-title>
          Привязать прицеп {{date ? `на ${date}` : ''}}
        </q-toolbar-title>
      </q-toolbar>

      <q-card-section>
        <ref-select
            label="Прицеп"
            v-model="trailer_data"
            option-label="reg_number"
            url="/trailers"
            :autocomplete="true"
        >
        </ref-select>
      </q-card-section>

      <q-card-actions>
        <q-btn
            :disable="loading"
            @click="$emit('input', false)"
        >
          Отмена
        </q-btn>

        <q-space />

        <q-btn
            @click="save"
            :loading="loading"
        >
          Сохранить
        </q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import {getData, METHODS} from '@/utils/http';
import RefSelect from '@/components/fields/RefSelect';
import {getDateToAPiFormat} from '@/utils/date';

export default {
  name: 'AddTrailer',
  components: {RefSelect},
  props: {
    trailersDialog: {
      type: Boolean,
      required: true,
    },
    trailer: {
      type: Array,
      required: true,
    },
    vehicle: {
      type: Object,
      required: true,
    },
    date: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      trailersLoading: true,
      trailer_data: null,
    };
  },
  created() {
    this.dialog = this.trailersDialog;

    if (this.trailer.length > 0) {
      this.trailer_data = this.trailer[0];
    }
  },
  methods: {
    save() {
      this.loading = true;
      let currentDate;

      if (this.date) {
        currentDate = this.date;
      } else {
        const curDate = new Date();
        const day = _setZero(curDate.getDate());
        const month = _setZero(curDate.getMonth() + 1);
        const year = curDate.getFullYear();
        currentDate = `${day}/${month}/${year}`;
      }

      if (!confirm('Уверены, что хотите поставить этот прицеп?')) {
        return false;
      }

      getData('/vehicles/' + this.vehicle.id + '/attach/trailer', METHODS.POST, {
          params: {
            trailer_id: this.trailer_data.id,
            start_date: getDateToAPiFormat(currentDate),
            force: true,
          },
        })
        .then(() => {
          this.$emit('onRefresh');
        })
        .catch((res) => {
          this.$q.notify({
            message: res.error,
            type: 'negative',
          });
        })
        .finally(() => {
          this.loading = false;
          this.$emit('input', false);
        });
    },
  },
};
</script>
