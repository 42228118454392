// Vue зависимости
import Vue from 'vue';
import Vuex from 'vuex';

// Модули
import auth from '@/store/modules/auth';
import cache from '@/store/modules/cache';
import components from '@/store/modules/components';
import menu from '@/store/modules/menu';


Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    cache,
    components,
    menu,
  },
});
