import CarriersData from '@/modules/carriers/CarriersData';
import RegistrationOrders from '@/modules/carriers/RegistrationOrders';
import NestedMenu from '@/components/for_router/NestedMenu';

export default {
  name: 'carriers',
  path: '/carriers',
  component: NestedMenu,
  redirect: '/carriers/list',
  children: [
    {
      path: 'list',
      component: CarriersData,
    },
    {
      path: 'applications',
      component: RegistrationOrders,
    },
    {
      path: 'applications/:id',
      component: RegistrationOrders,
    },
  ],
};
