<template>
  <q-layout view="hHh lpR fFf">

    <q-page-container>
      <q-card class="fixed-center ge-api-card">

        <q-card-section class="row justify-center q-pa-none">
          <div class="text-h6">
            Настройки сервера
          </div>
        </q-card-section>

        <q-card-section>
          <q-form @submit="clickSubmit">
            <q-input v-model="apiKey"
                     label="Код настройки"
                     class="auto-complete"
                     :rules="[val => (!!val && !!val.trim()) || 'Поле не должно быть пустым']"
            >
              <template #prepend>
                <q-icon name="mdi-key"/>
              </template>
            </q-input>

            <div class="row justify-center">
              <q-btn label="Подключить"
                     type="submit"
                     class="q-mt-md"
                     color="primary"
                     :loading="loading"
              />
            </div>

          </q-form>
        </q-card-section>
      </q-card>
    </q-page-container>

  </q-layout>
</template>

<script>
import router from '@/router/router';

export default {
  name: 'apiScreen',
  data() {
    return {
      apiKey: '',
      loading: false,
    };
  },
  methods: {
    async clickSubmit() {
      this.loading = true;
      try {
        let result = await fetch(process.env.VUE_APP_GW_URL + `/checkApiKey?api_key=${this.apiKey}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        result = await result.json();

        if (result.success) {
          localStorage.setItem('api_key', result.url);
          await router.push('/login');
          this.loading = false;
        } else {
          this.$q.notify({
            message: result.error,
            type: 'negative',
          });
          this.loading = false;
        }

      } catch (e) {
        console.log(e);
        this.$q.notify({
          message: e,
          type: 'negative',
        });
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>

.ge-api-card {
  width: 100%;
  min-width: 250px;
  max-width: 600px;
  margin: 0 10px;
}

</style>
