import Vue from 'vue';
import store from '@/store';
import VueRouter from 'vue-router';

// Страницы
import Login from '@/modules/login/Login';

// Стандартные компоненты для роутера
import BaseLayout from '@/components/for_router/BaseLayout';

// Модули
import refsRouteProps from '@/modules/refs/refsRouteProps';
import settingsRouteProps from '@/modules/settings/settingsRouteProps';
import carriersRouteProps from '@/modules/carriers/carriersRouteProps';
import ordersRouteProps from '@/modules/orders/ordersRouteProps';
import usersRouteProps from '@/modules/users/usersRouteProps';
import organizationRouteProps from '@/modules/organization/organizationRouteProps';
import ApiScreen from '@/modules/api/ApiScreen';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: BaseLayout,
      redirect: '/orders',
      meta: {
        requiresAuth: true,
      },
      children: [
        refsRouteProps,
        settingsRouteProps,
        carriersRouteProps,
        ordersRouteProps,
        usersRouteProps,
        organizationRouteProps,
      ],
    },
    {
      path: '/login',
      component: Login,
      meta: {
        requiresAuth: false,
      },
    },
    // {
    //   path: '/api',
    //   component: ApiScreen,
    //   meta: {
    //     requiresAuth: false,
    //   },
    // },
  ],
});

/**
 * Проверка авторизации при переходе между страницами
 */
// TODO: в идеале проверять права на переход
router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters['auth/isAuthenticated'];
  const apiKey = localStorage.getItem('api_key');
  const requireAuth = to.matched.some((record) => record.meta.requiresAuth);


  // TODO: Удалить, когда роутер будет полностью настроен
  // Для отладки, пока не удалять. Если мешает закомментировать
  // console.log(
  //   `Router::beforeEach
  //   \nisAuth: ${isAuthenticated}
  //   \nrequireAuth: ${requireAuth}`,
  //   '\n\nto: ', to,
  //   '\n\nfrom: ', from,
  // );


  if (to.path === '/login' && isAuthenticated) {
    next({path: from.fullPath});
  }

  // if (to.path === '/api' && apiKey) {
  //   next({path: from.fullPath});
  // }
  //
  // if (to.path === '/login' && !apiKey) {
  //   next({path: '/api'});
  // }

  if (requireAuth) {
    // если в сторадже нет ссылки на бэк, то редиректим на  страницу её получения
    // if (!apiKey) {
    //   next({path: '/api'});
    // }
    // этот путь требует авторизации, проверяем залогинен ли
    // пользователь, и если нет, перенаправляем на страницу логина
    if (!isAuthenticated) { // проверка авторизации
      next({
        path: '/login',
        query: {redirect: to.fullPath},
      });
    } else {
      next();
    }
  } else {
    next(); // всегда так или иначе нужно вызвать next()!
  }
});


export default router;
