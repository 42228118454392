<template>
  <div>
    <div class="q-gutter-sm">
      <q-radio v-model="periodType" val="month" label="Месяц"/>
      <q-radio v-model="periodType" val="range" label="Период"/>
      <q-radio v-model="periodType" val="week" label="Неделя"/>
    </div>

    <div v-if="periodType === 'range'"
         class="row items-center q-col-gutter-md q-py-md"
         style="max-width: 600px;"
    >
      <RangeDates class="col-8"
                  :startDate.sync="tripDateStart"
                  :endDate.sync="tripDateEnd"
      ></RangeDates>

      <div class="col-4">
        <q-btn class="q-mb-md"
               label="Показать отчет"
               @click="loadData"
               :loading="loading"
        ></q-btn>
      </div>
    </div>

    <MonthPicker v-else-if="periodType === 'month'"
                 :startDate.sync="tripDateStart"
                 :endDate.sync="tripDateEnd"
                 @change="loadData"
    ></MonthPicker>

    <WeekPicker v-else-if="periodType === 'week'"
                :startDate.sync="tripDateStart"
                :endDate.sync="tripDateEnd"
                @change="loadData"
    ></WeekPicker>

    <q-table row-key="id"
             :data="data"
             :columns="columns"
             :loading="loading"
             :pagination="{rowsPerPage: 0}"
             hide-pagination
    >
      <template #body="props">
        <q-tr :props="props">
          <q-td
              v-for="col in props.cols"
              :key="col.name"
              @click="props.expand = !props.expand"
              :props="props"
          >
            {{ col.value }}
          </q-td>
        </q-tr>
        <q-tr v-if="props.expand" :key="props.row.id" :props="props">
          <q-td colspan="100%" class="bg-grey-4">
            <fuel-table
                :reportProps="props"
                :data="data[props.rowIndex].list"
                :tripDateStart="tripDateStart"
                :tripDateEnd="tripDateEnd"
                @changeMonth="props.expand = !props.expand"
            ></fuel-table>
          </q-td>
        </q-tr>
      </template>
    </q-table>
  </div>
</template>

<script>
import {getData, METHODS} from '@/utils/http';
import RangeDates from '@/components/fields/date/RangeDates';
import MonthPicker from '@/components/fields/date/MonthPicker';
import WeekPicker from '@/components/fields/date/WeekPicker';
import FuelTable from '@/components/fields/FuelTable';

export default {
  name: 'ReportByFuel',
  components: {FuelTable, MonthPicker, RangeDates, WeekPicker},
  data() {
    return {
      tripDateStart: null,
      tripDateEnd: null,
      loading: false,
      loadFuel: false,
      periodType: 'month',
      data: [],
      columns: [
        {
          name: 'index',
          label: 'Транспорт',
          align: 'left',
          field: 'reg_number',
        },
        {
          name: 'driver',
          label: 'Ёмкость бака',
          align: 'left',
          field: 'fuel_capacity',
        },
        {
          name: 'gross',
          label: 'Остаток в баке на начало недели',
          align: 'left',
          field: 'fuel_remains_period_end',
        },
        {
          name: 'gross_tonne',
          label: 'Заправлено за неделю, литров',
          align: 'left',
          field: (row) => {
            return row.refueled_for_period == null ? 0 : row.refueled_for_period;
          },
        },
        {
          name: 'expenses',
          label: 'Пробег за период',
          align: 'left',
          field: (row) => {
            return +row.odometer_period_end - +row.odometer_period_start;
          },
        },
        {
          name: 'profit',
          label: 'Остаток в баке на конец недели',
          align: 'left',
          field: 'fuel_remains_period_start',
        },
      ],
    };
  },
  watch: {
    periodType() {
      this.loadData();
    },
  },
  methods: {
    loadData() {
      if (this.tripDateStart == null || this.tripDateEnd == null) {
        this.$q.notify({
          color: 'warning',
          message: 'Заполните поле с датой',
        });
        return;
      }
      this.loading = true;

      getData(
          `/reports/fuel_consumption?start_date=${this.tripDateStart}&end_date=${this.tripDateEnd}`, METHODS.GET)
          .then((res) => {
            this.data = res.result;
          })
          .catch((res) => {
            this.$q.notify({
              message: res.error,
              type: 'negative',
            });
          })
          .finally(() => {
            this.loading = false;
          });
    },
  },
};
</script>

