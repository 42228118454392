<template>
  <q-layout view="hHh lpR fFf">

    <!-- Апп Бар -->
    <q-header reveal elevated class="bg-primary text-white" height-hint="98">
      <q-toolbar>

        <!-- Лого Арнод -->
        <q-img src="/assets/logo.png" style="width: 48px; height: 48px"/>

        <!-- Главное меню -->
        <q-tabs v-if="!isLoadingMenu"
                align="left"
                class="header-menu"
                active-bg-color="blue-6"
        >
          <q-route-tab v-for="mn in toolbarMenu" :key="mn.url"
                       :label="mn.title"
                       :to="mn.path"/>
        </q-tabs>

        <!-- Лоадер для главного меню -->
        <div v-else class="row no-wrap overflow-hidden q-pl-md">
          <q-skeleton v-for="i in 5" :key="i"
                      type="QBadge"
                      animation="pulse-x"
                      class="q-mx-md"/>
        </div>

        <q-space/>

        <!-- Имя пользователя -->
        <span v-if="profileName">
          {{ profileName.full_name }}
        </span>

        <!-- Выход -->
        <q-btn @click="exit"
               icon="mdi-exit-to-app"
               unelevated round
        >
          <q-tooltip>Выход</q-tooltip>
        </q-btn>

      </q-toolbar>
    </q-header>



    <q-page-container class="q-pa-sm" style="padding-top: 50px !important;">
      <router-view v-if="!isLoadingAuth"
                   :key="$route.path"/>

      <!-- Лоадер для страницы -->
      <div v-else
           class="row">
        <div class="col-auto"
             style="width: 300px">
          <q-skeleton v-for="i in 5" :key="i"
                      type="QBadge"
                      style="width: 230px; height: 25px; margin: 35px"
          />
        </div>

        <div class="col" style="margin: 35px">
          <div v-for="i in 3" :key="i">
            <q-skeleton type="text" class="text-subtitle1"/>
            <q-skeleton type="text" width="50%" class="text-subtitle1"/>
          </div>
        </div>
      </div>
    </q-page-container>


    <!-- Диалог подтверждения, используется глобально -->
    <ConfirmDialog v-if="isConfirm" useStoreProps/>
  </q-layout>
</template>

<script>
import ConfirmDialog from '@/components/ConfirmDialog';
import HasNotify from '@/components/notify/mixins/HasNotify';
import {mapGetters} from 'vuex';

export default {
  name: 'BaseLayout',
  components: {
    ConfirmDialog,
  },
  mixins: [HasNotify],
  computed: {
    ...mapGetters({
      isLoadingAuth: 'auth/isLoading',
      isLoadingMenu: 'menu/isLoading',
      isConfirm: 'components/isShow',
      profileName: 'auth/getProfile',
    }),
    toolbarMenu() {
      return this.$store.getters['menu/getNestedLvlMenu'](0);
    },
  },
  methods: {
    exit() {
      this.$store.commit('components/setConfirm', {
        header: 'Выход',
        text: 'Вы уверены, что хотите выйти?',
        labelApply: 'Да',
        labelCancel: 'Нет',
        height: 160,
        callback: () => {
          this.$store.dispatch('auth/logout');
        },
      });
    },
  },
};
</script>

<style scoped>
.header-menu {
  overflow: auto;
}
</style>
