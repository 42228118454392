import {EXPENSES_TYPES} from '@/structures/refs/expenses_types';

const expenses = {
    name: 'expenses',
    title: 'Расходы',
    url: '/expenses',
    include: ['*'],
    perms: {
        list: 'v1.expenses.list',
        read: 'v1.expenses.read',
        create: 'v1.expenses.create',
        update: 'v1.expenses.update',
        delete: 'v1.expenses.delete',
    },
    listColumns: [
        {
            name: 'expenses_type',
            label: 'Статья расхода',
            align: 'left',
            field: (row) => {
                return `${row.expenses_type.title} (${EXPENSES_TYPES[row.expenses_type.expenses_type] || ''})`;
            },
        },
        {
            name: 'description',
            label: 'Описание',
            align: 'left',
            field: 'description',
        },
        {
            name: 'date',
            label: 'Дата',
            align: 'left',
            field: (row) => {
                const d = new Date(Date.parse(row.date));

                return `${_setZero(d.getDate())}/${_setZero(d.getMonth() + 1)}/${d.getFullYear()}`;
            },
        },
        {
            name: 'legal_entity',
            label: 'Юр.лицо',
            align: 'left',
            field: (row) => {
                if (row.legal_entity) {
                    return row.legal_entity.title;
                }

                return '';
            },
        },
        {
            name: 'expenses_subject',
            label: 'Объект расхода',
            align: 'left',
            field: (row) => {
                if (row.expenses_type.expenses_type === 'DRIVER_TYPE') {
                    return `Водитель: ${row.drivers.map((d) => d.user.full_name).join(', ')}`;
                }

                if (row.expenses_type.expenses_type === 'VEHICLE_TYPE') {
                    return `Транспорт: ${row.vehicles.map((d) => d.reg_number).join(', ')}`;
                }

                return '';
            },
        },
        {
            name: 'cost',
            label: 'Сумма без НДС',
            align: 'left',
            field: (row) => {
                return _priceFormat(row.cost, true);
            },
        },
        {
            name: 'cost_vat',
            label: 'Сумма НДС',
            align: 'left',
            field: (row) => {
                return _priceFormat(row.cost_vat, true);
            },
        },
        {
            name: 'cost_original',
            label: 'Сумма',
            align: 'left',
            field: (row) => {
                return _priceFormat(row.cost_original, true);
            },
        },
        {
            name: 'actions',
            label: 'Действия',
            align: 'left',
            field: 'actions',
        },
    ],
};

const expenses_by_type = {
    name: 'expenses_by_type',
    title: 'Расходы',
    url: '/expenses',
    include: ['*'],
    perms: {
        list: 'v1.expenses.list',
        read: 'v1.expenses.read',
        create: 'v1.expenses.create',
        update: 'v1.expenses.update',
        delete: 'v1.expenses.delete',
    },
    listColumns: [
        {
            name: 'expenses_type',
            label: 'Статья расхода',
            align: 'left',
            field: (row) => {
                return row.expenses_type.title;
            },
        },
        {
            name: 'cost',
            label: 'Сумма без НДС',
            align: 'left',
            field: (row) => {
                return _priceFormat(row.cost, true);
            },
        },
        {
            name: 'cost_vat',
            label: 'Сумма НДС',
            align: 'left',
            field: (row) => {
                return _priceFormat(row.cost_vat, true);
            },
        },
        {
            name: 'cost_original',
            label: 'Сумма',
            align: 'left',
            field: (row) => {
                return _priceFormat(row.cost_original, true);
            },
        },
    ],
};

export {
    expenses,
    expenses_by_type,
};
