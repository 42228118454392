<script>
import GuardService from '@/components/guard/mixins/GuardService';
import store from '@/store';

export default {
  data() {
    return {
      permAccess: {},
    };
  },
  computed: {
    hasAccess() {
      const r = GuardService.hasPermList([
        ...this.getPerms_(),
      ]);

      // При изменении профиля
      store.getters['auth/getProfile'];

      return r;
    },
  },
  created() {
    this.permAccess = GuardService.hasPerms([
      // ...this.perms,
      ...this.getPerms_(),
    ]);
  },
  methods: {
    getPerms_() {
      return [];
    },
  },
};
</script>
