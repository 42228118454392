<template>
  <div class="q-pa-sm q-gutter-sm">
    <q-table
        title="Маршруты"
        :loading="isLoading"
        :data="data"
        :columns="fields"
        row-key="name"
        :pagination.sync="pagination"
        :filter="filterStr"
        @request="loadData"
        binary-state-sort
    >
      <!-- Верхняя часть таблицы-->
      <template #top="props">
        <slot name="top-left" v-bind="props">
          <div class="flex">
            <slot name="top-filter" v-bind="props">
              <q-input
                  dense
                  debounce="300"
                  v-model="filterStr"
                  placeholder="Поиск"
              >
                <template #append>
                  <q-icon name="search"/>
                </template>
              </q-input>

              <q-space/>
            </slot>

            <q-btn @click="loadData"
                   round
                   class="q-ml-lg"
                   icon="refresh"
            >
              <q-tooltip>Обновить</q-tooltip>
            </q-btn>
          </div>
        </slot>

        <q-space />

        <!-- Кнопка добавить -->
        <q-card-actions align="right">
          <q-btn
              flat outline dense
              align="right"
              color="primary"
              label="Добавить"
              icon="add"
              @click="onShowDialog"
          ></q-btn>
        </q-card-actions>

        <!--Диалог создания/редактирования-->
        <div class="q-pa-md q-gutter-md">
          <q-dialog maximized v-model="showDialog">
            <q-card class="full-width full-height" v-if="showDialog">
              <!-- Toolbar -->
              <q-toolbar class="bg-primary bg-dark text-white">
                <q-toolbar-title>
                  Маршруты: {{ edited.id != null ? 'Редактировать' : 'Новая' }} запись
                </q-toolbar-title>

                <q-space/>

                <q-btn
                    color="red"
                    text-color="white"
                    @click="onCloseDialog"
                >
                  Отмена
                </q-btn>

                <q-btn
                    class="q-ml-md"
                    color="primary"
                    text-color="white"
                    :loading="isLoading"
                    @click="onSaveRoute"
                >
                  Сохранить
                </q-btn>
              </q-toolbar>

              <!-- Основное содержимое -->
              <q-card-section class="row" style="height: 94%;">
                <div class="col-4 column q-pa-md" style="width: 330px;">
                  <!-- Наименование -->
                  <q-input
                      label="Наименование"
                      class="q-mt-sm"
                      v-model="edited.title"
                      :disable="!ready"
                  ></q-input>

                  <!-- Пункт загрузки -->
                  <div class="q-my-md">
                    <ref-select
                        label="Пункт загрузки"
                        url="/loading_points"
                        :disable="!ready"
                        :value="edited.loading_point"
                        :autocomplete="true"
                        :columns="['id', 'title', 'lon', 'lat']"
                        @input="onLoadingPointChange"
                    ></ref-select>
                  </div>

                  <!-- Пункт выгрузки -->
                  <div class="q-my-md">
                    <ref-select
                        label="Пункт выгрузки"
                        url="/unloading_points"
                        option-value="id"
                        option-label="title"
                        :disable="!ready"
                        :value="edited.unloading_point"
                        :autocomplete="true"
                        :columns="['id', 'title', 'lon', 'lat']"
                        @input="onUnloadingPointChange"
                    ></ref-select>
                  </div>

                  <!-- Расстояние -->
                  <div class="q-my-md">
                    <q-input
                        label="Расстояние, км"
                        v-model="edited.distance"
                        :disable="!ready"
                    ></q-input>
                  </div>
                </div>

                <div class="col">
                  <div
                      id="move_direction_map"
                      class="full-width full-height"
                  ></div>
                </div>
              </q-card-section>
            </q-card>
          </q-dialog>
        </div>
      </template>

      <!-- Основная часть -->
      <template #body="props">
        <q-tr :props="props">
          <!-- Формирование колонок вручную и попапы для редактируемых полей -->
          <q-td v-for="column in fields" :key="column.name" :props="props">
            <div v-if="column.map != null">
              {{ props.row[column.field] && props.row[column.field][column.map] || '[Не задано]' }}
            </div>

            <div v-else>
              {{ props.row[column.field] }}
            </div>

            <!-- Колонка с действиями -->
            <template v-if="column.name === 'actions'">
              <q-btn
                  round flat
                  text-color="primary"
                  @click="onEditItem(props.row)"
                  icon="edit"
              >
                <q-tooltip>Изменить</q-tooltip>
              </q-btn>

              <q-btn
                  round flat
                  text-color="red"
                  @click="onDelete(props.row)"
                  icon="delete"
              >
                <q-tooltip>Удалить</q-tooltip>
              </q-btn>
            </template>
          </q-td>
        </q-tr>
      </template>
    </q-table>
  </div>
</template>

<script>

// Modules
import CrudService from './CrudService';
import MapDirectionsService from './MapDirectionsService';

// Utils
import Map from '@/utils/map';
import RefSelect from '@/components/fields/RefSelect';

const _source = 'move_directions';
const _layer = 'move_directions';
const _layerText = 'move_directions_text';

const _sourceRoute = 'move_directions_route';
const _layerRoute = 'move_directions_route';

let _map = null;
let _ne = [];

export default {
  name: 'RefMoveDirection',
  components: {RefSelect},
  data() {
    return {
      entity: 'move_directions',
      fields: [],
      edit: [],
      data: [],
      filterStr: '',
      pagination: {
        sortBy: 'desc',
        descending: false,
        page: 1,
        rowsPerPage: 10,
        rowsNumber: 0,
      },

      // -------

      // Флага показа/скрытие диалога
      showDialog: false,
      // Редактируемый объект
      edited: {
        title: '',
        loading_points_id: null,
        unloading_points_id: null,
        distance: 0,
      },
      // Флаг что карта загружена и готова к работе
      ready: false,
      // Флаг что происходит загрузка
      isLoading: false,
    };
  },
  watch: {
    // При изменении показа диалога
    showDialog(val) {
      if (val) {
        setTimeout(() => {
          Map.init({
            container: document.getElementById('move_direction_map'),
          }).then((map) => {
            _map = map;

            this.ready = true;

            // Источник точек погрузки/разгрузки
            Map.createOrDataSource(_source, {
              type: 'FeatureCollection',
              features: [],
            });

            // Слой для отображения точек погрузки/разгрузки
            Map.createOrDataLayer(_layer, {
              id: _layer,
              type: 'circle',
              source: _source,
              paint: {
                'circle-radius': 8,
                'circle-stroke-width': 2,
                'circle-color': '#1760dc',
                'circle-stroke-color': '#3b5d9c',
              },
            });

            // Слой для отображения текста погрузки/разгрузки
            Map.createOrDataLayer(_layerText, {
              id: _layerText,
              type: 'symbol',
              source: _source,
              layout: {
                'text-field': ['get', 'title'],
                'text-variable-anchor': ['bottom'],
                'text-radial-offset': 0.5,
                'text-justify': 'auto',
                'text-size': 14,
              },
            });

            // --------

            // Источник для маршрута движения
            Map.createOrDataSource(_sourceRoute, {
              type: 'FeatureCollection',
              features: [],
            });

            // Слой для отображения линии маршрута движения
            Map.createOrDataLayer(_layerRoute, {
              id: _layerRoute,
              type: 'line',
              source: _sourceRoute,
              paint: {
                'line-color': '#0f778e',
                'line-width': 2,
              },
              layout: {
                'line-cap': 'round',
                'line-join': 'round',
              },
            });
          });
        }, 300);
      } else {
        this.ready = false;

        Map.clear();

        _map = null;
      }
    },
    // Когда карта загружена
    ready() {
      _ne.forEach((it) => {
        it();
      });

      _ne = [];
    },
  },
  beforeMount() {
    this.loadStruct();
    this.loadData();
  },
  methods: {
    // Событие перезагрузки данных таблицы
    onRefresh() {
      this.loadData();
    },
    // Стркутура
    loadStruct() {
      this.fields = [
        {
          name: 'title',
          required: true,
          label: 'Наименование',
          align: 'left',
          field: 'title',
          sortable: true,
        },
        {
          name: 'distance',
          required: true,
          label: 'Расстояние, км',
          align: 'left',
          field: 'distance',
          sortable: true,
        },
        {
          name: 'actions',
          label: 'Действия',
          field: 'actions',
        },
      ];

      this.edit = [
        {
          name: 'title',
          required: true,
          label: 'Наименование',
          align: 'left',
          field: 'title',
          sortable: true,
        },
        {
          name: 'distance',
          required: true,
          label: 'Расстояния',
          align: 'left',
          field: 'distance',
          sortable: true,
        },
      ];
    },

    // Загрузка данных
    loadData(props) {
      this.isLoading = true;

      let query = [];

      if (props && props.filter) {
        query.push(`title:${props.filter}`);
      }

      CrudService.list(
        this.entity,
        this.pagination.page,
        this.pagination.rowsPerPage,
          'loading_point,unloading_point',
        query,
      )
        .then((response) => {
          this.data = response.result.items;
          this.pagination.rowsNumber = response.result.total;
        })
        .catch(() => {
          this.$q.notify({
            message: 'Не удалось загрузить список маршрутов',
            color: 'red',
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    // Показ диалога
    onShowDialog() {
      this.showDialog = true;
    },

    // Скрытие диалога
    onCloseDialog() {
      this.showDialog = false;

      this.edited = {
        title: '',
        loading_points_id: null,
        unloading_points_id: null,
        distance: 0,
        route: {},
      };
    },

    // При изменении пункта загрузки
    onLoadingPointChange(item) {
      const callback = () => {
        if (item != null) {
          this.edited.loading_point = _copy(item);

          this._findAndReplaceFeature('loading-point', this._feature('loading-point', item));
        }
      };

      if (this.ready) {
        callback();
      } else {
        _ne.push(callback);
      }
    },

    // При изменении пункта выгрузки
    onUnloadingPointChange(item) {
      const callback = () => {
        if (item != null) {
          this.edited.unloading_point = _copy(item);

          this._findAndReplaceFeature('unloading-point', this._feature('unloading-point', item));
        }
      };

      if (this.ready) {
        callback();
      } else {
        _ne.push(callback);
      }
    },

    // Редактирование записи
    onEditItem(item) {
      ////////////////////
      this.edited = _copy(item);

      this.showDialog = true;
    },

    // При сохранении маршрута
    onSaveRoute() {
      this.isLoading = true;

      let errorText = '';

      if (!this.edited) {
        errorText = 'Ошибка при сохранении записи';
      }

      if (!this.edited.loading_point || !this.edited.loading_point.id) {
        errorText = 'Не указан пункт загрузки';
      }

      if (!this.edited.unloading_point || !this.edited.unloading_point.id) {
        errorText = 'Не указан пункт выгрузки';
      }

      if (errorText) {
        this.$q.notify({
          message: errorText,
          color: 'red',
        });

        this.isLoading = false;

        return false;
      }

      const data = {
        title: this.edited.title,
        loading_points_id: this.edited.loading_point.id,
        unloading_points_id: this.edited.unloading_point.id,
        distance: this.edited.distance,
        route: this.edited.route,
      };

      if (this.edited.id == null) {
        CrudService.add(this.entity, data)
          .then((response) => {
            if (response.success) {
              this.loadData();
            }

            this.$q.notify({
              message: 'Запись успешно создана',
              color: 'primary',
            });

            this.showDialog = false;
          })
          .catch((err) => {
            this.$q.notify({
              message: err.error,
              color: 'red',
            });
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        CrudService.edit(this.entity, this.edited.id, data)
          .then((response) => {
            if (response.success) {
              this.loadData();
            }

            this.$q.notify({
              message: 'Запись успешно сохранена',
              color: 'primary',
            });

            this.showDialog = false;
          })
          .catch(() => {
            this.$q.notify({
              message: 'Не удалось обновить маршрут',
              color: 'red',
            });
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },

    // При удалении маршрута
    onDelete(item) {
      if (confirm('Уверены, что хотите удалить маршрут?')) {
        CrudService.delete(this.entity, item.id)
            .then((response) => {
              if (response.success) {
                this.loadData();
              }
            })
            .catch(() => {
              this.$q.notify({
                message: 'Не удалось удалить маршрут',
                color: 'red',
              });
            });
      }
    },

    // Поиск и замена точки на карте, если сменили пункт поугрзки/выгрузки
    _findAndReplaceFeature(id, feature) {
      const sourceData = _map.getSource(_source)._data;
      const index = sourceData.features.findIndex((it) => it.id === id);

      if (index > -1) {
        sourceData.features.splice(index, 1, feature);
      } else {
        sourceData.features.push(feature);
      }

      Map.createOrDataSource(_source, sourceData);

      const coords = sourceData.features.map((it) => it.geometry.coordinates);

      if (coords.length === 2) {
        _map.fitBounds(coords, {
          padding: 40,
        });

        this._route(coords);
      } else {
        _map.panTo(coords[0]);
      }
    },

    // Создание фичи для точки на карте
    _feature(id, item) {
      return {
        type: 'Feature',
        id: id,
        properties: {
          title: item.title,
        },
        geometry: {
          type: 'Point',
          coordinates: [
            item.lon, item.lat,
          ],
        },
      };
    },

    // Автоматический путь
    async _route(coords) {
      const data = await MapDirectionsService.getDirection([
        { coordinates: coords[0], id: 1 },
        { coordinates: coords[1], id: 2 },
      ]);

      if (data.route.coords == null) {
        this.$q.notify({
          message: 'Не удалось построить маршрут между пунктами',
          color: 'red',
        });

        return;
      }

      if (this.edited.loading_point && this.edited.unloading_point) {
        const firstPart = this.edited.loading_point.title.split(',');
        const secondPart = this.edited.unloading_point.title.split(',');
        const nameArr = [firstPart[0], secondPart[0]];

        this.edited.title = nameArr.join(' - ');
      }

      // Дистанция приходит в метрах
      this.edited.distance = Math.ceil(data.raw?.distance / 1000);
      this.edited.route = data.raw;

      Map.createOrDataSource(_sourceRoute, {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'LineString',
              properties: {},
              coordinates: data.route.coords,
            },
          },
        ],
      });
    },
  },
};
</script>
