<template>
  <entity-manager
    :name="ref.name"
    :title="ref.title"
    :entity-url="ref.url"
    :list-columns="ref.listColumns"
    :list-edit="ref.editColumns"
    :include="ref.include"
    :perms="ref.perms"
    :filter-by="ref.filterBy"
    :pagination_rules="{rowsPerPage: -1}"
    v-show="hasAccess[ref.perms.list]"
  ></entity-manager>
</template>

<script>
import HasPerms from '@/components/guard/mixins/HasPerms';
import EntityManager from '@/components/entity_manager/EntityManager';
import {trailers_ref} from '@/structures/trailers';

export default {
  name: 'Trailers',
  components: { EntityManager },
  mixins: [HasPerms],
  data() {
    return {
      ref: trailers_ref,
    };
  },
  methods: {
    // @override
    getPerms_() {
      return [
        'v1.trailers.list',
      ];
    },
  },
};
</script>
