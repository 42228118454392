const vehicles_ref = {
    name: 'vehicles',
    title: 'Транспорт',
    url: '/vehicles',
    include: ['legal_entity'],
    filterBy: ['reg_number'],
    perms: {
        list: 'v1.vehicles.list',
        read: 'v1.vehicles.read',
        create: 'v1.vehicles.create',
        update: 'v1.vehicles.update',
        delete: 'v1.vehicles.delete',
    },
    listColumns: [
        {
            name: 'reg_number',
            required: true,
            label: 'Рег.номер',
            align: 'left',
            field: 'reg_number',
            sortable: true,
        },
        {
            name: 'legal_entity',
            required: true,
            label: 'Юр.лицо',
            align: 'left',
            field: 'legal_entity',
            map: 'title',
            sortable: true,
        },
        {
            name: 'name',
            required: true,
            label: 'Имя/Марка',
            align: 'left',
            field: 'name',
            sortable: true,
        },
        {
            name: 'weight',
            required: true,
            label: 'Вес',
            align: 'left',
            field: 'weight',
            sortable: true,
            type: 'number',
        },
        {
            name: 'release_year',
            required: true,
            label: 'Год выпуска',
            align: 'left',
            field: 'release_year',
            sortable: true,
            type: 'number',
        },
        {
            name: 'fuel_capacity',
            required: true,
            label: 'Объем бака',
            align: 'left',
            field: 'fuel_capacity',
            sortable: true,
            type: 'number',
        },
    ],
    editColumns: [
        {
            name: 'reg_number',
            required: true,
            label: 'Рег.номер',
            align: 'left',
            field: 'reg_number',
            sortable: true,
        },
        {
            name: 'legal_entity',
            required: true,
            label: 'Юр.лицо',
            align: 'left',
            field: 'legal_entity',
            type: 'reference',
            meta: {
                url: '/refs/legal_entity',
                optionLabel: 'title',
            },
        },
        {
            name: 'description',
            required: true,
            label: 'Описание',
            align: 'left',
            field: 'description',
            sortable: true,
        },
        {
            name: 'name',
            required: true,
            label: 'Имя/Марка',
            align: 'left',
            field: 'name',
            sortable: true,
        },
        {
            name: 'axes',
            required: true,
            label: 'Количество осей',
            align: 'left',
            field: 'axes',
            type: 'number',
        },
        {
            name: 'weight',
            required: true,
            label: 'Вес',
            align: 'left',
            field: 'weight',
            sortable: true,
            type: 'number',
        },
        {
            name: 'release_year',
            required: true,
            label: 'Год выпуска',
            align: 'left',
            field: 'release_year',
            sortable: true,
            type: 'number',
        },
        {
            name: 'fuel_capacity',
            required: true,
            label: 'Объем бака',
            align: 'left',
            field: 'fuel_capacity',
            sortable: true,
            type: 'number',
        },
    ],
};

export {
    vehicles_ref,
};
