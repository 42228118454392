<template>
  <q-dialog v-model="dialog" @input="$emit('input', false)">
    <q-card style="width: 400px">
      <q-toolbar>
        <q-toolbar-title>
          Статус транспорта {{date ? `на ${parseDateFromStr(date)}` : ''}}
        </q-toolbar-title>
      </q-toolbar>

      <q-card-section>
        <q-input
            v-model="tripDateStart"
            label="Начало действия статуса"
            filled
            class="q-mb-md"
        >
          <template #append>
            <q-icon
                name="event"
                class="cursor-pointer"
            >
              <q-popup-proxy
                  transition-show="scale"
                  transition-hide="scale"
              >
                <q-date
                    v-model="tripDateStart"
                    mask="DD/MM/YYYY"
                >
                  <div class="row items-center justify-end">
                    <q-btn
                        v-close-popup
                        label="Close"
                        color="primary"
                        flat
                    />
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>

        <q-input
            v-model="tripDateEnd"
            label="Окончание действия статуса"
            filled
            class="q-mb-md"
        >
          <template #append>
            <q-icon
                name="event"
                class="cursor-pointer"
            >
              <q-popup-proxy
                  transition-show="scale"
                  transition-hide="scale"
              >
                <q-date
                    v-model="tripDateEnd"
                    mask="DD/MM/YYYY"
                >
                  <div class="row items-center justify-end">
                    <q-btn
                        v-close-popup
                        label="Close"
                        color="primary"
                        flat
                    />
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>

        <ref-select
            label="Статус"
            v-model="status_data"
            option-label="title"
            :unsetResKey="true"
            url="/vehicle/statuses/by/mechanic"
        >
        </ref-select>
      </q-card-section>

      <q-card-actions>
        <q-btn
            :disable="loading"
            @click="$emit('input', false)"
        >
          Отмена
        </q-btn>

        <q-space />

        <q-btn
            @click="save"
            :loading="loading"
        >
          Сохранить
        </q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import {getData, METHODS} from '@/utils/http';
import RefSelect from '@/components/fields/RefSelect';
import {FORMAT_FOR_USER, formatDate, parseDateFromStr, getDateToAPiFormat} from '@/utils/date';

export default {
  name: 'AddStatus',
  components: {RefSelect},
  props: {
    statusesDialog: {
      type: Boolean,
      required: true,
    },
    status: {
      type: Array,
      required: true,
    },
    vehicle: {
      type: Object,
      required: true,
    },
    date: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      statuses: [],
      status_data: null,
      tripDateStart: '',
      tripDateEnd: '',
    };
  },
  created() {
    this.dialog = this.statusesDialog;

    if (this.status.length > 0) {
      this.status_data = this.status[0];
    }

    if (this.date) {
      this.tripDateStart = parseDateFromStr(this.date);
      this.tripDateEnd = parseDateFromStr(this.date);
    } else {
      this.tripDateStart = this.formatDateStr(this.getCurrentDate());
      this.tripDateEnd = this.formatDateStr(this.getCurrentDate());
    }
  },
  methods: {
    getCurrentDate() {
      const date = new Date();

      return `${_setZero(date.getDate())}/${_setZero(date.getMonth() + 1)}/${date.getFullYear()}`;
    },
    save() {
      this.loading = true;

      if (!confirm('Уверены, что хотите поставить этот статус?')) {
        return false;
      }

      let start_dt;
      let end_dt;

      if (this.tripDateStart > this.tripDateEnd) {
        start_dt = this.tripDateStart;
        end_dt = this.tripDateEnd;
      } else {
        start_dt = this.tripDateEnd;
        end_dt = this.tripDateStart;
      }

      getData('/vehicles/' + this.vehicle.id + '/attach/status', METHODS.POST, {
        params: {
          status_id: this.status_data.id,
          start_date: getDateToAPiFormat(start_dt) + ' 00:00:00',
          end_date: getDateToAPiFormat(end_dt) + ' 23:59:00',
        },
      })
        .then(() => {
          this.$emit('onRefresh');
        })
        .catch((res) => {
          this.$q.notify({
            message: res.error,
            type: 'negative',
          });
        })
        .finally(() => {
          this.loading = false;
          this.$emit('input', false);
        });
    },
    formatDateStr(date) {
      return formatDate(date, FORMAT_FOR_USER.ONLY_DATE);
    },
    parseDateFromStr(str) {
      return parseDateFromStr(str);
    },
  },
};
</script>
