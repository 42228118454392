const inventory_nodes_ref = {
    name: 'inventory_nodes',
    title: 'Узлы',
    url: '/inventory_nodes',
    include: ['*'],
    perms: {
        list: 'v1.inventory_nodes.list',
        read: 'v1.inventory_nodes.read',
        create: 'v1.inventory_nodes.create',
        update: 'v1.inventory_nodes.update',
        delete: 'v1.inventory_nodes.delete',
    },
    listColumns: [
        {
            name: 'title',
            required: true,
            label: 'Имя',
            align: 'left',
            field: 'title',
        },
        {
            name: 'inventory_number',
            required: true,
            label: 'Инвентарный номер',
            align: 'left',
            field: 'inventory_number',
        },
        {
            name: 'type_of_node',
            required: true,
            label: 'Вид узла',
            align: 'left',
            field: 'type_of_node',
            map: 'title',
        },
        {
            name: 'distance',
            label: 'Пробег',
            align: 'left',
            field: 'distance',
        },
        {
            name: 'manufacture',
            required: true,
            label: 'Производитель',
            align: 'left',
            field: 'manufacture',
            map: 'title',
        },
        {
            name: 'provider',
            required: true,
            label: 'Поставщик',
            align: 'left',
            field: 'provider',
            map: 'title',
        },
        {
            name: 'price',
            label: 'Стоимость',
            align: 'left',
            field: 'price',
        },
    ],
};

export {
    inventory_nodes_ref,
};
