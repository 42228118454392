const actions_ref = {
    name: 'non_actions',
    title: 'Без активности',
    url: '/auth/users/non_active',
    include: ['*&days=2'],
    filterBy: ['full_name'],
    perms: {
        list: 'v1.users.list',
    },
    listColumns: [
        {
            name: 'full_name',
            required: true,
            label: 'Имя',
            align: 'left',
            field: 'full_name',
            sortable: true,
        },
        {
            name: 'organization',
            required: true,
            label: 'Организация',
            align: 'left',
            field: 'organization',
            map: 'title',
        },
        {
            name: 'email',
            required: true,
            label: 'Email',
            align: 'left',
            field: 'email',
            sortable: true,
        },
        {
            name: 'phone',
            required: true,
            label: 'Номер телефона',
            align: 'left',
            field: 'phone',
            sortable: true,
        },
        {
            name: 'roles',
            required: true,
            label: 'Роли',
            align: 'left',
            field: 'roles',
            map: 'title',
            type: 'multiple',
        },
        {
            name: 'last_activity',
            required: true,
            label: 'Последняя активность',
            align: 'left',
            field: 'last_activity',
            map: 'date',
            meta: {
                subtype: 'date',
            },
        },
    ],
};

export {
    actions_ref,
};
