<template>
  <div class="col-4">
    <q-card
        @click="orderDialog = true"
    >
      <q-card-section>
        <div class="row">
          <div class="col-8">
            <div class="text-bold">
              #{{ order.order.number }}
              {{ order.order.move_direction.title }}
            </div>
          </div>

          <div
              class="col-4"
              align="right"
              v-if="order.status === 'IN_EXECUTION'"
              style="margin-bottom: -8px;"
          >
            <q-btn
                round flat
                text-color="primary"
                icon="done"
                size="sm"
                @click.stop="allow(order.id)"
            >
              <q-tooltip>
                Принять
              </q-tooltip>
            </q-btn>

            <q-btn
                round flat
                text-color="red"
                icon="close"
                size="sm"
                @click.stop="showDialog(order.id, false)"
            >
              <q-tooltip>
                Отклонить
              </q-tooltip>
            </q-btn>
          </div>
        </div>

        <div class="text-caption q-mb-sm" style="line-height: 10px;">
          {{ formatDateStr(order.order.loading_start_date) }} -
          {{ formatDateStr(order.order.unloading_start_date) }}
        </div>

        <div class="q-mb-sm">
          <label>Контрагент: </label>
          <span class="text-bold">
            {{ order.order.contractor.title }}
          </span>
        </div>

        <div class="q-mb-sm">
          <label>Груз: </label>
          <span class="text-bold">
            {{ order.order.cargo_name.title }},
            {{ order.cargo_units_count }} ({{ order.order.cargo_unit.title }})
          </span>
        </div>

        <div class="row">
          <div class="col">
            <div
                style="height: 24px;"
                class="text-no-wrap"
            >
              Распределено: {{ order.distributed_cargo_count }}
            </div>

            <div
                style="height: 24px;"
                class="text-no-wrap"
            >
              Исполнено: {{ order.executed_cargo_count }}
            </div>
          </div>

          <div class="col">
            <div style="height: 24px; padding-top: 8px;">
              <q-linear-progress
                  size="10px"
                  :value="order.distributed_cargo_count / order.cargo_units_count"
              />
            </div>

            <div style="height: 24px; padding-top: 8px;">
              <q-linear-progress
                  size="10px"
                  :value="order.executed_cargo_count / order.cargo_units_count"
              />
            </div>
          </div>
        </div>
      </q-card-section>

      <OrganizationOrderDetail
          v-if="orderDialog"
          :orderDialog="orderDialog"
          :order="order"
          @input="(state) => {orderDialog = state}"
      >
      </OrganizationOrderDetail>
    </q-card>

    <q-dialog v-model="showAllowDialog" v-if="showAllowDialog">
      <q-card>
        <!-- Заголовок -->
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">Отклонение исполнения заказа</div>

          <q-space/>

          <q-btn icon="close"
                 flat round dense
                 v-close-popup
                 class="q-ml-md"
          ></q-btn>
        </q-card-section>

        <!-- Формируем поля для редактирования-->
        <q-card-section style="max-height: 500px">
          <div style="max-height: 500px">
            <q-input
                v-model="editedItem.cargo_value_left"
                label="Остаток груза"
                type="number"
            >
            </q-input>
          </div>
        </q-card-section>

        <!-- Действия -->
        <q-card-actions align="right">
          <q-btn
              flat
              label="OK"
              color="primary"
              @click.stop="deny"
              :loading="allowLoading"
          >
          </q-btn>

          <q-btn
              flat
              label="Отмена"
              color="negative"
              v-close-popup
              :disable="allowLoading"
          >
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import OrganizationOrderDetail from '@/modules/organization/components/OrganizationOrderDetail';
import { FORMAT_FOR_USER, formatDate } from '@/utils/date';
import { getData, METHODS } from '@/utils/http';

export default {
  name: 'OrganizationOrderCard',
  components: { OrganizationOrderDetail },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      vehicle: null,
      orderDialog: false,
      showAllowDialog: false,
      allowLoading: false,
      editedItem: {},
      statuses: {
        'IN_EXECUTION': 'На исполнении',
      },
    };
  },
  methods: {
    getProgress(order) {
      return (order.cargo_units_count - order.cargo_units_count_left) / order.cargo_units_count;
    },
    formatDateStr(date) {
      return formatDate(date, FORMAT_FOR_USER.ONLY_DATE);
    },
    showDialog() {
      this.editedItem = _copy(this.order);
      const leftCount = this.editedItem.cargo_units_count - this.editedItem.distributed_cargo_count;
      this.editedItem.cargo_value_left = leftCount;
      this.showAllowDialog = true;
    },
    confirmOrder(url, cargo_value_left) {
      this.allowLoading = true;

      getData(url, METHODS.POST, {
        params: {
          cargo_value_left: parseInt(cargo_value_left, 10),
        },
      })
          .then(() => {
            this.$emit('onRefresh');

            this.$q.notify({
              color: 'primary',
              message: 'Заказ перевозчика успешно изменил статус!',
            });
          })
          .catch((error) => {
            this.showAllowDialog = false;
            this.editedItem = null;

            this.$q.notify({
              color: 'negative',
              message: error.error || 'Не удалось закрыть заказ перевозчика',
            });
          })
          .finally(() => {
            this.allowLoading = false;
          });
    },

    allow(id) {
      let query = '/organizations_order/allow/' + id;

      if (confirm('Уверены, что хотите отметить успешное завершение заказа перевозчика?')) {
        this.confirmOrder(query, 0);
      }
    },

    deny() {
      let query = '/organizations_order/deny/' + this.order.id;
      this.confirmOrder(query, +this.editedItem.cargo_value_left);
    },
  },
};
</script>
