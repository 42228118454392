import { getData, METHODS } from '@/utils/http';

/**
 * Сервис для работы с точками разгрузки
 */
export default class LoadingPointsService {
  /**
   * Получение списка данных
   *
   * @param {Number} page
   * @param {Number} perPage
   * @returns {Promise | Promise<unknown>}
   */
  static list(page = 1, perPage = 10, filters = []) {
    let query = `/loading_points?page=${page}&perPage=${perPage}`;

    if (filters.length) {
      query += `&filters=${filters.join(',')}`;
    }

    return getData(query, METHODS.GET);
  }

  /**
   * Добавление точки
   *
   * @param {{
   *   title: String,
   *   unloading_point_places: Array<{
   *     title: String,
   *     lon: double,
   *     lat: double,
   *   }>
   * }} data Создаваемая модель
   * @returns {Promise | Promise<unknown>}
   */
  static add(data) {
    return getData('/loading_points', METHODS.POST, {
      params: data,
    });
  }

  /**
   * Обновление записи
   *
   * @param {Number} id Идентификатор записи
   * @param {{
   *   title: String,
   *   unloading_point_places: Array<{
   *     title: String,
   *     lon: double,
   *     lat: double,
   *   }>
   * }} data Изменяемая модель
   * @returns {Promise | Promise<unknown>}
   */
  static update(id, data) {
    return getData(`/loading_points/${id}`, METHODS.PUT, {
      params: data,
    });
  }

  /**
   * Удаление записи
   *
   * @param {Number} id Идентификатор записи
   * @returns {Promise | Promise<unknown>}
   */
  static delete(id) {
    return getData(`/loading_points/${id}`, METHODS.DELETE);
  }
}
