const contractors = {
    name: 'contractor',
    title: 'Контрагенты',
    url: '/contractors',
    include: [],
    filterBy: ['title'],
    perms: {
        list: 'v1.contractors.list',
        read: 'v1.contractors.read',
        create: 'v1.contractors.create',
        update: 'v1.contractors.update',
        delete: 'v1.contractors.delete',
    },
    listColumns: [
        {
            name: 'title',
            required: true,
            label: 'Наименование',
            align: 'left',
            field: 'title',
            sortable: true,
        },
        {
            name: 'description',
            required: true,
            label: 'Описание',
            align: 'left',
            field: 'description',
            sortable: true,
        },
    ],
    editColumns: [
        {
            name: 'tin',
            required: true,
            label: 'ИНН',
            align: 'left',
            field: 'tin',
            sortable: true,
            type: 'dadata',
            meta: {
                map: 'inn',
                depended: {
                    field: 'title',
                    function: (a, b, c) => {
                        a[b] = c.data.value;
                    },
                },
            },
        },
        {
            name: 'title',
            required: true,
            label: 'Наименование',
            align: 'left',
            field: 'title',
            sortable: true,
            type: 'text',
        },
        {
            name: 'description',
            required: true,
            label: 'Описание',
            align: 'left',
            field: 'description',
            sortable: true,
            type: 'textarea',
        },
    ],
};

export {
    contractors,
};
