import NestedMenu from '@/components/for_router/NestedMenu';
import RefsContainer from '@/modules/refs/components/RefsContainer';
import RefMoveDirection from '@/modules/refs/ref_modules/move_direction/RefMoveDirection';
import RefLoadingPointPlaces from '@/modules/refs/ref_modules/loading_point_places/RefLoadingPointPlaces';
import RefUnloadingPointPlaces from '@/modules/refs/ref_modules/unloading_point_places/RefUnloadingPointPlaces';
import Contractors from '@/modules/refs/ref_modules/contractors/Contractors';
import ExpensesTypes from '@/modules/organization/Finance/ExpensesTypes';
import TypesOfNode from '@/modules/organization/Inventory/TypesOfNode';
import Manufacture from '@/modules/organization/Inventory/Manufacture';
import Providers from '@/modules/organization/Inventory/Providers';
import NodePositions from '@/modules/organization/Inventory/NodePositions';
import NodeReport from '@/modules/organization/Inventory/NodeReport';

export default {
  path: '/refs',
  name: 'refs',
  redirect: '/refs/legal_entity',
  component: NestedMenu,
  props: true,
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: 'move_directions',
      component: RefMoveDirection,
    },
    {
      path: 'loading_points',
      component: RefLoadingPointPlaces,
    },
    {
      path: 'unloading_points',
      component: RefUnloadingPointPlaces,
    },
    {
      path: 'contractors',
      component: Contractors,
    },
    // Справочники из других разделов
    {
      path: 'expenses_types',
      component: ExpensesTypes,
    },
    {
      path: 'types_of_nodes',
      component: TypesOfNode,
    },
    {
      path: 'manufacture',
      component: Manufacture,
    },
    {
      path: 'providers',
      component: Providers,
    },
    {
      path: 'node_positions',
      component: NodePositions,
    },
    {
      path: 'node_report',
      component: NodeReport,
    },
    // Стандартные (самые просты справочники)
    {
      path: ':ref_name',
      component: RefsContainer,
      props: {
        folder: 'refs',
      },
    },
  ],
};
