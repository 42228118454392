/**
 * Вспомогательный класс для работы с картой на странице
 */

import MapBoxGL from 'mapbox-gl';

// Экземпляр карты
let _map = null;

// TODO: Необходимо заменить
const _mapToken = 'pk.eyJ1IjoiYW50b25rIiwiYSI6ImNqbmRpaDJtazA3b3Azd280cDFwdWs1eDQifQ.ufbdwsRZeb0zFIya-B1PJQ';

/**
 * Инициализация карты
 *
 * @param {{
 *   container: String|HTMLElement
 * }} options
 * @returns {Promise<MapBoxGL>}
 */
function init(options) {
  return new Promise((resolve) => {
    if (_map != null) {
      resolve(_map);
    }

    MapBoxGL.accessToken = _mapToken;

    // Создаем экземпляр карты
    _map = new MapBoxGL.Map({
      container: options.container,
      style: 'mapbox://styles/antonk/ckq043i5b0p5o17p83r9ubhbl',
      center: [
        38.97603,
        45.04484,
      ],
      zoom: 8,
      maxZoom: 24,
      pitchWithRotate: false,
      preserveDrawingBuffer: true,
    });

    window._map = _map; // TODO: DELETE IT

    _map.on('load', () => {
      resolve(_map);
    });
  });
}

/**
 * Получение экземпляра карты
 *
 * @return {MapBoxGL}
 */
function getMap() {
  return _map;
}

/**
 * Очистка карты
 */
function clear() {
  if (_map != null && _map instanceof MapBoxGL.Map) {
    _map.stop();
    _map.remove();
    _map = null;
  }
}

/**
 * Создание либо установка новых данных в источник
 *
 * @param {String} sourceId Имя истоничка
 * @param {{
 *   type: String,
 *   features: Array<{
 *      type: String,
 *      geometry: {
 *        type: String,
 *        coordinates: Array<number>,
 *      },
 *      properties: Object|*,
 *   }>,
 * }|*} sourceData Данные источника
 * @param {Object} options Дополнительные параметры слоя
 */
function createOrDataSource(sourceId, sourceData, options = {}) {
  if (!_map.getSource(sourceId)) {
    _map.addSource(
      sourceId,
      Object.assign(options, {
        type: 'geojson',
        data: sourceData,
      }),
    );
  } else {
    _map.getSource(sourceId).setData(sourceData);
  }
}

/**
 * Создает слой, если такового не было
 *
 * @param {String} layerId Идентификатор слоя
 * @param {{
 *   id: String,
 *   type: String,
 *   source: String,
 *   paint: *|Object,
 *   layout: *|Object,
 * }} layerData Данные слоя
 */
function createOrDataLayer(layerId, layerData) {
  if (!_map.getLayer(layerId)) {
    // Если нет источника - создадим по-умолчанию
    if (!_map.getSource(layerData.source)) {
      _map.addSource(layerData.source, {
        type: 'FeatureCollection',
        features: [],
      });
    }

    _map.addLayer(layerData);
  }
}

export default {
  init,
  getMap,
  clear,
  createOrDataLayer,
  createOrDataSource,
};
