<template>
  <div>
    <template v-if="loadingFullData">
      <div class="text-center">
        <q-spinner size="48"/>
      </div>
    </template>

    <template v-if="data_finished && data_finished.length === 0 && !loading">
      Ни одного рейса
    </template>

    <div
        v-for="date in data_finished"
        :key="`in_execution_${date.date}`"
        class="q-mb-md"
    >
      <div class="q-mb-sm text-bold">
        {{ date.date | formatDate }}
      </div>

      <TripTableCompleted
          :data_finished="date.data"
          :columns="columns"
          :order="order"
          @onReload="applyChanges"
          @onOpen="showDetail"
          @onClose="denyOrder"
      ></TripTableCompleted>
    </div>

  </div>
</template>

<script>
import TripTableCompleted from '@/modules/organization/components/TripTableCompleted';
import {getData, METHODS} from '@/utils/http';
import {parseDateFromStr} from '@/utils/date';


export default {
  name: 'CompletedOrder',
  components: {TripTableCompleted},
  filters: {
    formatDate: (date) => parseDateFromStr(date),
  },
  props: {
    orders_type: String,
    order: {},
    columns: Array,
    newOrderLoading: Boolean,
  },
  data() {
    return {
      data_finished: [],
      originalItemsCompleted: [],
      num: 1,
      loading: false,
      loadingFullData: false,
      fetchingData: true,
    };
  },
  watch: {
    newOrderLoading() {
      this.data_finished = [];
      this.originalItemsCompleted = [];
      this.num = 1;
      this.loadData();
    },
  },
  created() {
    this.loadData();
  },
  destroyed() {
    this.fetchingData = false;
  },
  methods: {
    loadData() {
        this.loading = true;
        this.fetchingData = true;
        this.$emit('loadingFullData', this.loading);

        this.sendData(this.orders_type, this.num)
            .then((res) => {
              if (this.orders_type === 'COMPLETED') {
                this.data_finished = res;
              } else if (this.orders_type === 'IN_EXECUTION') {
                this.data_current = res;
              }
            })
            .finally(() => {
              this.loading = false;
              this.$emit('loadingFullData', this.loading);
            });
    },
    sendData(state, num) {
      let URL = `/vehicle_orders?orders=-start_date&include=vehicle&page=${num}&perPage=15`;
      URL += `&filters=organization_orders_id:${this.order.id},state:${state}`;

      return getData(URL, METHODS.GET)
          .then((res) => {

            let data_finished = res.result.items;

            this.originalItemsCompleted = this.originalItemsCompleted.concat(data_finished);

            let in_execution = [];

            this.originalItemsCompleted.forEach((item) => {
              const start_dt = item.start_date.split(' ')[0].replace(/-/g, '');

              if (!in_execution[start_dt]) {
                in_execution[start_dt] = [];
              }

              in_execution[start_dt].push(item);
            });

            let sorted_data = [];

            in_execution.forEach((it, index) => {
              sorted_data.push({
                date: index,
                data: it,
              });
            });

            if (this.num < res.result.pages && this.fetchingData) {
              this.num++;
              this.loadData(state, this.num);
            } else {
              this.loading = false;
              this.num = 1;
            }
            return sorted_data.sort((a, b) => b.date - a.date);

          });
    },
    applyChanges() {
      this.data_finished = [];
      this.originalItemsCompleted = [];
      this.num = 1;
      this.loadData();
    },
    showDetail(order) {
      this.$emit('onOpen', order);
    },
    denyOrder(order) {
      this.$emit('onClose', order);
    },
  },
};
</script>

<style scoped>

</style>
