<template>
  <q-dialog
      v-model="orderDialog"
      maximized
  >
    <div class="bg-white column">
      <q-header class="bg-primary col-auto">
        <q-toolbar>
          <q-toolbar-title style="line-height: 110%">
            Заказ №{{order_data.organization_order.order.number}}
            {{order_data.organization_order.order.move_direction.title}}

            <div class="text-subtitle2 q-ma-none" style="line-height: 110%">
              {{formatDateStr(order_data.organization_order.order.loading_start_date)}} -
              {{formatDateStr(order_data.organization_order.order.unloading_start_date)}}
            </div>
          </q-toolbar-title>

          <!-- Закрыть -->
          <q-btn
              color="red"
              class="q-ml-md"
              @click="$emit('input', false)"
          >
            Закрыть
          </q-btn>
        </q-toolbar>
      </q-header>

      <!-- Основное содержимое -->
      <div class="col">
        <div class="row full-height">
          <div
              class="full-height q-pa-md"
              style="width: 300px;"
          >
            <h6 class="q-mb-md">Транспорт</h6>
            {{order.vehicle.reg_number}}

            <div class="q-py-sm">
              Загружено:
              <span
                  v-if="order.loaded"
                  class="text-bold"
              >{{order.loaded}} / {{order.organization_order.order.cargo_unit.title}}</span>
              <span
                  v-else
                  class="text-bold"
              >Ещё не загружен</span>
            </div>

            <div class="q-py-sm">
              Выгружено:
              <span
                  class="text-bold"
                  v-if="order.unloaded"
              >{{order.unloaded}} / {{order.organization_order.order.cargo_unit.title}}</span>
              <span
                  v-else
                  class="text-bold"
              >Ещё не выгружен</span>
            </div>

            <div v-if="order.status.length">
              <template v-for="(status, index) in order.status">
                <q-separator
                    v-if="index !== 0"
                    :key="`separator_${status.id}`"
                />

                <div
                    :key="status.id"
                    class="q-py-sm"
                >
                  {{status.title}}
                  <div class="text-caption">{{formatDateStr(status.pivot.start_date)}}</div>
                </div>
              </template>
            </div>

            <div v-else>
              Водитель еще не принял заказ
            </div>
          </div>

          <div class="col full-height">
            <div
                :id="`vehicle_map_${order.id}`"
                class="full-width full-height"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </q-dialog>
</template>

<script>
// Utils
import Map from '@/utils/map';

const _sourceRoute = 'move_directions_route';
const _layerRoute = 'move_directions_route';

import {FORMAT_FOR_USER, formatDate} from '@/utils/date';
const mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');

export default {
  name: 'VehicleOrderDetail',
  props: {
    order: {
      type: Object,
      required: true,
    },
    orderDialog: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      order_data: {},
      statuses: {
        'IN_EXECUTION': 'На исполнении',
      },
    };
  },
  created() {
    this.order_data = this.order;
  },
  mounted() {
    setTimeout(() => {
      Map.init({
        container: document.getElementById(`vehicle_map_${this.order.id}`),
      }).then((map) => {
        // Источник для маршрута движения
        Map.createOrDataSource(_sourceRoute, {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: this.order_data.organization_order.order.move_direction.route.geometry,
            },
          ],
        });

        // Слой для отображения линии маршрута движения
        Map.createOrDataLayer(_layerRoute, {
          id: _layerRoute,
          type: 'line',
          source: _sourceRoute,
          paint: {
            'line-color': '#0f778e',
            'line-width': 2,
          },
          layout: {
            'line-cap': 'round',
            'line-join': 'round',
          },
        });

        const coords = this.order_data.organization_order.order.move_direction.route.geometry.coordinates;

        const bounds = coords.reduce((bounds, coord) => {
          return bounds.extend(coord);
        }, new mapboxgl.LngLatBounds(coords[0], coords[0]));

        map.fitBounds(bounds, {
          padding: 20,
        });
      });
    }, 300);
  },
  methods: {
    formatDateStr(date) {
      return formatDate(date, FORMAT_FOR_USER.DATE_TIME);
    },
  },
};
</script>
