const EXPENSES_TYPES = {
    'VEHICLE_TYPE': 'На транспорт',
    'DRIVER_TYPE': 'На водителя',
    'GENERAL_TYPE': 'Другие',
};
const expenses_types = {
    name: 'ref_expenses_types',
    title: 'Виды расходов',
    url: '/expenses_types',
    include: [],
    filterBy: ['title'],
    perms: {
        list: 'v1.expenses_types.list',
        read: 'v1.expenses_types.read',
        create: 'v1.expenses_types.create',
        update: 'v1.expenses_types.update',
        delete: 'v1.expenses_types.delete',
    },
    listColumns: [
        {
            name: 'title',
            label: 'Наименование',
            align: 'left',
            field: 'title',
            sortable: true,
        },
        {
            name: 'expenses_type',
            label: 'Тип расхода',
            align: 'left',
            field: (row) => {
                return expenses_types[row.expenses_type] || '';
            },
            sortable: true,
        },
        {
            name: 'separate_expense',
            required: true,
            label: 'Отдельный расход',
            align: 'left',
            field: (row) => {
                return row.separate_expense ? 'Да' : 'Нет';
            },
            sortable: true,
        },
    ],
    editColumns: [
        {
            name: 'title',
            required: true,
            label: 'Наименование',
            align: 'left',
            field: 'title',
            sortable: true,
        },
        {
            name: 'expenses_type',
            required: true,
            label: 'Тип расхода',
            align: 'left',
            type: 'enum',
            field: 'expenses_type',
            meta: {
                options: Object.keys(EXPENSES_TYPES).map((key) => {
                    return {
                        value: key,
                        label: EXPENSES_TYPES[key],
                    };
                }),
            },
        },
        {
            name: 'separate_expense',
            label: 'Отдельный расход',
            align: 'left',
            type: 'checkbox',
            field: 'separate_expense',
        },
    ],
};

export {
    EXPENSES_TYPES,
    expenses_types,
};
