<template>
  <q-dialog v-model="show" @input="$emit('input', false)">
    <q-card style="width: 500px">
      <q-toolbar>
        <q-toolbar-title>
          Снятие водителя
        </q-toolbar-title>
      </q-toolbar>

      <q-card-section>
        <q-select v-model="action"
                  label="Действие"
                  :options="actionOptions"
        ></q-select>

        <div class="row">
          <div v-if="action.fields.startDate" class="col">
            <DateField v-model="insideStartDate"
                       label="Дата снятия"
            ></DateField>
          </div>
          <div v-if="action.fields.startDate && action.fields.endDate" class="col-1"/>
          <div v-if="action.fields.endDate" class="col">
            <DateField v-model="insideEndDate"
                       label="Дата повторного назначения"
                       ref="date-field-end"
                       :rules="[val => checkValidDates(val)]"
            ></DateField>
          </div>
        </div>

        <RefSelect v-model="driverForReplace"
                   label="Водитель"
                   url="/drivers"
                   option-value="id"
                   :disable="!action.fields.driver"
                   :option-label="(row) => row.user ? row.user.full_name : ''"
                   :autocomplete="true"
                   :define-value="showDriver"
        ></RefSelect>
      </q-card-section>

      <q-card-actions>
        <q-btn label="Отмена"
               :disable="loading"
               @click="$emit('input', false)"
        ></q-btn>

        <q-space/>

        <q-btn label="Сохранить"
               color="primary"
               :loading="loading"
               :disable="disableSave"
               @click="action.callback"
        ></q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import DateField from '@/components/fields/date/DateField';
import RefSelect from '@/components/fields/RefSelect';
import { getData, METHODS } from '@/utils/http';
import { FORMAT_FOR_SERVER, formatDate } from '@/utils/date';

export default {
  name: 'DetachDriver',
  components: { DateField, RefSelect },
  props: {
    endOfFullDate: Object,
    // show dialog
    value: {
      type: Boolean,
      required: true,
    },
    driver: {
      type: Object,
      required: true,
    },
    date: {
      type: String,
      default: null,
    },
  },
  data() {
    const actionOptions = [
      {
        label: 'Замена',
        value: 'full_change',
        url: '',
        fields: {
          startDate: true,
          driver: true,
        },
        callback: async () => {
          await this.detachDriver();
          this.insideEndDate = null;
          await this.attachDriver();
          this.$emit('onChange');
          this.$emit('input', false);
        },
      },
      {
        label: 'Замена на период',
        value: 'period_change',
        url: '',
        fields: {
          startDate: true,
          endDate: true,
          driver: true,
          endofAllTime: '',
        },
        callback: async () => {
          await this.detachDriver();
          await this.attachDriver();

          const startDate = new Date(this.insideEndDate);
          startDate.setDate(startDate.getDate() + 1);
          this.insideStartDate = startDate.toISOString().substr(0, 10);


          this.insideEndDate = null;
          this.driverForReplace = this.driver;

          await this.attachDriver();
          this.$emit('onChange');
          this.$emit('input', false);
        },
      },
      {
        label: 'Снятие',
        value: 'detach',
        url: '',
        fields: {
          startDate: true,
        },
        callback: async () => {
          await this.detachDriver();
          this.$emit('onChange');
          this.$emit('input', false);
        },
      },
    ];

    return {
      actionOptions,
      action: actionOptions[0],

      endDateOfAttachDriver: '',
      showDriver: null,

      loading: false,
      insideStartDate: null,
      insideEndDate: null,
      driverForReplace: null,
    };
  },
  computed: {
    show: {
      set(val) {
        this.$emit('input', val);
      },
      get() {
        return this.value;
      },
    },
    disableSave() {
      const fields = this.action.fields;
      return fields.startDate && this.insideStartDate == null
          || fields.endDate && this.insideEndDate == null
          || fields.driver && this.driverForReplace == null;
    },
  },
  watch: {
    insideStartDate() {
      if (this.action.fields.endDate) {
        this.$refs['date-field-end'].validate();
      }
    },
    action(val) {
      if (val.value === 'detach') {
        this.showDriver = this.driver;
      } else {
        this.showDriver = null;
        this.driverForReplace = null;
      }
    },
  },
  created() {
    const date = new Date(this.getDetachDate(this.driver.pivot));
    date.setDate(date.getDate() + 1);
    this.insideStartDate = date.toISOString();

    if (this.action.value === 'detach') {
      this.showDriver = this.driver;

    }
  },
  methods: {
    checkValidDates(val) {
      if (val) {
        return new Date(val) >= new Date(this.insideStartDate)
            || 'Дата повторного назначения не может быть меньше даты снятия';
      }
      return true;
    },

    async detachDriver() {
      const date = new Date(this.insideStartDate);
      date.setDate(date.getDate() - 1);

      const params = {
        driver_id: this.driver.id,
        end_date: date.toISOString(),
        ignore_payment: true,
      };

      this.loading = true;

      try {
        await getData('/vehicles/' + this.driver.vehicleId + '/detach/driver', METHODS.POST, {
          params: params,
        });
      } catch (e) {
        this.$q.notify({
          message: e.error,
          type: 'negative',
        });
      } finally {
        this.loading = false;
      }

    },

    async attachDriver() {
      this.loading = true;
      const params = {
        driver_id: this.driverForReplace.id,
        start_date: this.insideStartDate,
        end_date: this.endOfFullDate.end_date,
      };

      if (this.insideEndDate) {
        params.end_date = this.insideEndDate;
      }

      try {
        await getData('/vehicles/' + this.driver.vehicleId + '/attach/driver', METHODS.POST, {
          params: params,
        });
      } catch (e) {
        this.$q.notify({
          message: e.error,
          type: 'negative',
        });
      } finally {
        this.loading = false;
      }
    },

    getDetachDate(pivot) {
      const dt = this.date.split('-');
      const new_dt = new Date(dt[0], dt[1] - 1, dt[2]);
      new_dt.setMinutes(new_dt.getMinutes() - 1);
      let param_date = formatDate(new_dt.getTime(), FORMAT_FOR_SERVER.DATE_TIME);

      return pivot.start_date > param_date ? pivot.start_date : param_date;
    },
  },
};
</script>
