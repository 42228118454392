<script>
import store from '@/store';

export default {
  name: 'HasNotify',
  mounted() {
    this.$store.dispatch('auth/getAccount')
        .then(() => this.connect());
  },
  methods: {
    async connect() {
      const profile = store.getters['auth/getProfile'];

      const ws = new WebSocket(process.env.VUE_APP_HOST_WS + `?user_id=${profile.id}`);



      ws.onmessage = (m) => {
        const message = JSON.parse(m.data);
        let actions = [];

        if (message?.notification) {
          if (message.notification.data) {
            const entity = message.notification.data.entity;
            const id = message.notification.data.id;

            if (entity && id) {
              let handler;

              if (entity === 'UserRegistration') {
                handler = () => {
                  this.$router.push('/carriers/applications/' + id);
                };
              }

              if (entity === 'OrderBid') {
                handler = () => {
                  this.$router.push('/orders/bids/' + id);
                };
              }

              if (entity === 'Order') {
                handler = () => {
                  this.$router.push('/orders/moderation/' + id);
                };
              }

              if (handler) {
                actions.push({
                  label: 'Перейти',
                  color: 'white',
                  handler: handler,
                });
              }
            }
          }

          let notify = {
            message: `${message?.notification?.body || 'Вам пришло пустое уведомление'}`,
            caption: `${message?.notification?.title || 'Уведомление'}`,
            type: 'dark',
            icon: 'announcement',
            actions: actions,
          };

          if (actions.length) {
            notify.actions = actions;
          }

          this.$q.notify(notify);
        }
      };
    },
  },
};
</script>
