<template>
  <div>
    <div class="flex items-center q-mb-sm">
      <q-btn
          @click="getMonthData"
          round
          icon="refresh"
          class="q-mr-lg"
          :disable="loading"
      >
        <q-tooltip>Обновить</q-tooltip>
      </q-btn>

      <q-space />

      <div>
        <q-btn
            flat
            icon="mdi-arrow-left"
            @click="prevMonth"
        >
          <q-tooltip>Предыдущий месяц</q-tooltip>
        </q-btn>
      </div>

      <div class="q-px-sm text-h6">{{monthName}}, {{ year }}</div>

      <div>
        <q-btn
            flat
            icon="mdi-arrow-right"
            @click="nextMonth"
        >
          <q-tooltip>Следующий месяц</q-tooltip>
        </q-btn>
      </div>
    </div>

    <div
        class="flex wrap"
        style="border-bottom: 1px solid #cecece; border-left: 1px solid #cecece;"
        v-if="!loading"
    >
      <div
          v-for="day in calendarDays"
          :key="`day_${day}`"
          style="width: calc(100% / 7); height: 150px;
                border-top: 1px solid #cecece; border-right: 1px solid #cecece;"
      >
        <CalendarCell
            v-if="getCalendarDate(day)"
            :month="month"
            :year="year"
            :firstWeekDay="firstWeekDay"
            :monthDays="monthDays"
            :day="day"
            :vehicle="value"
            :dayData="data[day - firstWeekDay]"
            @onChange="getMonthData"
        ></CalendarCell>
      </div>
    </div>

    <template v-else>
      <div class="text-center">
        <q-spinner size="48" />
      </div>
    </template>
  </div>
</template>

<script>
import CalendarCell from '@/modules/organization/components/CalendarCell';
import {getData, METHODS} from '@/utils/http';
export default {
  name: 'Calendar',
  components: {CalendarCell},
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    return {
      trailersDialog: false,
      driversDialog: false,
      orderDialog: false,
      statusDialog: false,
      year: (new Date()).getFullYear(),
      month: (new Date()).getMonth(),
      monthName: '',
      data: {},
      loading: true,
      monthDays: 0,
      firstWeekDay: 1,
      calendarDays: 0,
      monthNames: [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь',
      ],
    };
  },
  created() {
    this.initCalendar(this.year, this.month);
    this.getMonthData();
  },
  methods: {
    getMonthData() {
      const start_dt = `${this.year}-${_setZero(this.month + 1)}-01 00:00:00`;
      const end_dt = `${this.year}-${_setZero(this.month + 1)}-${this.monthDays} 23:59:00`;
      const query = `/vehicles/${this.value.id}/schedule?include=*&start_date=${start_dt}&end_date=${end_dt}`;

      this.loading = true;

      getData(query, METHODS.GET, {})
        .then((res) => {
          let data = {};
          data = this.mapInner(data, res.result, this.year, this.month + 1, this.monthDays, 'trailers');
          data = this.mapInner(data, res.result, this.year, this.month + 1, this.monthDays,'drivers');
          data = this.mapInner(data, res.result, this.year, this.month + 1, this.monthDays,'orders');
          data = this.mapInner(data, res.result, this.year, this.month + 1, this.monthDays,'status', true);

          this.$set(this, 'data', data);
        })
        .catch((res) => {
          this.$q.notify({
            message: res.error,
            type: 'negative',
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    mapInner(data, list, year, month, maxDay, key, isOneDay) {
      if (!list[key]) {
        return data;
      }

      list[key].forEach((it) => {
        let it_data = it.pivot ? it.pivot : it;
        const dt_start_split = it_data.start_date.split(' ');
        const dt_start = dt_start_split[0];
        let dt_start_day;

        if (!dt_start.match(`${year}-${_setZero(month)}`)) {
          if (isOneDay) {
            return false;
          }

          dt_start_day = 1;
        } else {
          dt_start_day = parseInt((dt_start.split('-'))[2], 10);
        }

        let dt_end_day;

        if (it_data.end_date) {
          if (it_data.end_date === it_data.start_date) {
            return false;
          }

          const dt_end_split = it_data.end_date.split(' ');
          const dt_end = dt_end_split[0];

          if (dt_end.match(`${year}-${_setZero(month)}`)) {
            dt_end_day = parseInt((dt_end.split('-'))[2], 10);
          } else {
            dt_end_day = maxDay;
          }
        } else {
          dt_end_day = maxDay;
        }

        if (isOneDay && it_data.end_date === null) {
          dt_end_day = dt_start_day;
        }

        for (let i = dt_start_day, l = dt_end_day; i <= l; i++) {
          if (!data[i]) {
            data[i] = {};
          }

          if (!data[i][key]) {
            data[i][key] = [];
          }

          data[i][key].push(it);
        }
      });

      return data;
    },
    initCalendar(year, month) {
      this.firstWeekDay = (this.getMonthWeekDay(year, month) - 1 + 7) % 7;
      this.monthDays = this.getMonthDays(year, month);
      let calendarDays = this.monthDays + this.firstWeekDay;
      calendarDays = Math.ceil(calendarDays/7) * 7;
      this.calendarDays = calendarDays;
      this.monthName = this.monthNames[this.month];
    },
    getMonthDays(year, month) {
      return (new Date(year, month + 1, 0)).getDate();
    },
    getMonthWeekDay(year, month) {
      return (new Date(year, month)).getDay();
    },
    getCalendarDate(date) {
      const dateStr = date - this.firstWeekDay;

      return dateStr > 0 ? (dateStr <= this.monthDays ? dateStr : '') : '';
    },
    prevMonth() {
      if (this.month > 0) {
        this.month --;
      } else {
        this.month = 11;
        this.year --;
      }

      this.initCalendar(this.year, this.month);
      this.getMonthData();
    },
    nextMonth() {
      if (this.month < 11) {
        this.month ++;
      } else {
        this.month = 0;
        this.year ++;
      }

      this.initCalendar(this.year, this.month);
      this.getMonthData();
    },
  },
};
</script>
