<!--Datepicker отображающий дату в TextField-->
<template>
  <q-input
    v-model="formatDate"
    class="date-field date-field-2 date-field-3"
    :mask="mask"
    :label="label"
    :readonly="true"
    :error="error.exist"
    :error-message="error.message"
  >
    <q-menu
      touch-position
      transition-show="scale"
      transition-hide="scale"
      anchor="center middle"
      self="center middle"
      :value="showMenu"
    >
      <q-date v-model="dateInCalendar" mask="YYYY-MM-DD">
        <div class="row items-center justify-between">
          <q-btn
            label="Закрыть"
            @click="dateInCalendar = date"
            color="primary"
            v-close-popup
          ></q-btn>

          <q-btn
            label="Выбрать"
            @click="date = dateInCalendar"
            color="primary"
            v-close-popup
          ></q-btn>
        </div>
      </q-date>
    </q-menu>

    <template #append>
      <q-btn
        v-if="clearable"
        v-show="date"
        @click.stop="clearDate"
        icon="close"
        flat rounded
      >
        <q-tooltip>Очистить поле</q-tooltip>
      </q-btn>

      <q-icon
        class="cursor-pointer"
        name="event"
      ></q-icon>
    </template>
  </q-input>
</template>

<script>
import { FORMAT_FOR_USER, formatDate } from '@/utils/date';

export default {
  name: 'DateField',
  props: {
    value: String,
    label: {
      type: String,
      default: '',
    },
    mask: {
      type: String,
      default: '##.##.####',
    },
    // Инициализация входной переменной текущей датой
    autofill: {
      type: Boolean,
      default: false,
    },
    // Возможность очистки поля
    clearable: {
      type: Boolean,
      default: false,
    },
    // Валидация
    rules: {
      type: Array,
      default: () => ([]),
    },

  },
  data() {
    return {
      dateInCalendar: null,
      showMenu: false,
      error: {
        exist: false,
        message: null,
      },

    };
  },
  computed: {
    formatDate: {
      get() {
        return formatDate(this.date, FORMAT_FOR_USER.ONLY_DATE, true);
      },
      set() {
        // TODO: реализовать возможность наборы даты при помощи обычного text-input
      },
    },
    date: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  watch: {
    date(val) {
      this.checkRules(val);
    },
  },
  created() {
    if (this.autofill && !this.date) {
      this.date = this.getToday();
    }

    this.dateInCalendar = this.date;
  },
  methods: {
    clearDate() {
      this.date = null;
      this.dateInCalendar = null;
    },
    getToday() {
      return new Date().toISOString().substr(0, 10);
    },

    // Валидация
    async validate() {
      await this.checkRules(this.value);
      return !this.error.exist;
    },
    resetValidation() {
      this.error = {
        exist: false,
        message: null,
      };
      return true;
    },
    isValidError() {
      return this.error.exist;
    },
    async checkRules(val) {
      let error = null;
      for (const rule of this.rules) {
        const res = rule(val);
        if (typeof res === 'string') {
          error = res;
          break;
        } else if (res === false) {
          error = ' ';
          break;
        }
      }

      this.error = {
        exist: !!error,
        message: error,
      };
    },

  },
};
</script>

<style lang="sass" scoped>
// ".date-field.date-field-2.date-field-3" необходимо для увеличения веса селектора
.date-field.date-field-2.date-field-3::v-deep .q-field__control::before
  border-bottom-style: solid
</style>
