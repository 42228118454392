import store from '@/store';

/**
 * Проверка наличия прав у пользователя
 * @param permName - право наличие которого будет проверенно у роли
 * @returns {boolean}
 */
function hasPerm(permName) {
  let profile = store.getters['auth/getProfile'];

  if (!profile) {
    return false;
  }
  if (profile.full_name === 'admin') {
    return true;
  }

  let isExist = false;
  for (let role of profile.roles) {
    role.perms.forEach((item) => {
      if (item.name === permName) {
        isExist = true;
      }
    });
  }

  return isExist;
}

/**
 * Проверка стека прав
 * @param perms
 * @returns {boolean}
 */
function hasPerms(perms) {
  if (Array.isArray(perms) && perms.length === 0) {
    return true;
  }
  if (typeof perms === 'object' && Object.keys(perms).length === 0) {
    return true;
  }

  for (let perm in perms) {
    if (hasPerm(perms[perm])) {
      return true;
    }
  }

  return false;
}

/**
 * Рекурсивное удаление пунктов меню к которым нет доступа
 * @param menu {Object}
 * @returns {boolean} - true права найдены. false прав нет
 */
function delUnnecessary(menu) {
  if (menu.perms) {
    if (!hasPerms(menu.perms)) {
      return false;
    }
  }

  if (menu.items) {
    for (const mn in menu.items) {
      const valid = delUnnecessary(menu.items[mn]);
      if (!valid || (_hasOwnProperty(mn, 'items') && Object.values(mn.items).length === 0)) {
        delete menu.items[mn];
      }
    }

  }

  return true;
}

/**
 * Проверяет структуру меню, удаляя все пункты меню для которых недостаточно прав
 * @param menu - полная структура меню
 * @returns {Object} - возвращает меню, которое будет отрисовано
 */
function editStructureMenuOnPerms(menu) {
  delUnnecessary({items: menu});
  return menu;
}

export {
  hasPerms,
  editStructureMenuOnPerms,
};
