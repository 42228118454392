<template>
  <q-dialog v-model="dialog" @input="$emit('input', false)">
    <q-card style="width: 400px">
      <q-toolbar>
        <q-toolbar-title>
          Указать пробег
        </q-toolbar-title>
      </q-toolbar>

      <q-card-section>
        <q-input
            v-model="odometerDate"
            label="Дата занесения"
            filled
        >
          <template #append>
            <q-icon
                name="event"
                class="cursor-pointer"
            >
              <q-popup-proxy
                  transition-show="scale"
                  transition-hide="scale"
              >
                <q-date
                    v-model="odometerDate"
                    mask="DD/MM/YYYY"
                >
                  <div class="row items-center justify-end">
                    <q-btn
                        v-close-popup
                        label="Ok"
                        color="primary"
                        flat
                    />
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>

        <q-input
            label="Показания"
            v-model="distance"
            type="number"
        />
        <q-select label="Водители"
                  v-model="driver"
                  :options="vehicle.drivers"
                  option-value="id"
                  :option-label="(value) => value.user && value.user.full_name || ''"
        ></q-select>

        
      </q-card-section>

      <q-card-actions>
        <q-btn @click="$emit('input', false)">Отмена</q-btn>

        <q-space />

        <q-btn @click="save">Сохранить</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import {getData, METHODS} from '@/utils/http';
import {getDateToAPiFormat} from '@/utils/date';

export default {
  name: 'AddOdometer',
  props: {
    vehicle: {
      type: Object,
      required: true,
    },
    odometerDialog: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      distance: '',
      odometerDate: '',
      driver: '',
      odometerLoading: false,
      dialog: false,
    };
  },
  created() {
    this.dialog = this.odometerDialog;
    this.odometerDate = this.getCurrentDate();
  },
  methods: {
    getCurrentDate() {
      const date = new Date();

      return `${_setZero(date.getDate())}/${_setZero(date.getMonth() + 1)}/${date.getFullYear()}`;
    },
    save() {
      if (!confirm('Уверены, что указали верный пробег?')) {
        return false;
      }

      getData('/odometers', METHODS.POST, {
        params: {
          distance: parseInt(this.distance),
          type: 'km',
          date: getDateToAPiFormat(this.odometerDate),
          vehicle: {
            id: this.vehicle.id,
          },
          driver_id: this.driver.id,
        },
      })
        .then(() => {
          this.$emit('onRefresh');
        })
        .catch((res) => {
          this.$q.notify({
            message: res.error,
            type: 'negative',
          });
        })
        .finally(() => {
          this.loading = false;
          this.$emit('input', false);
        });
    },
  },
};
</script>
