<template>
  <div class="q-pa-md">
    <div
        class="row items-start"
    >
      <div>
        <div class="q-gutter-sm">
          <q-radio v-model="periodType" val="month" label="Месяц"/>
          <q-radio v-model="periodType" val="range" label="Период"/>
        </div>

        <div>
          <div
              v-if="periodType === 'range'"
              class="row q-col-gutter-md q-py-md items-center"
              style="max-width: 600px;"
          >
            <RangeDates class="col-8 "

                        :startDate.sync="tripDateStart"
                        :endDate.sync="tripDateEnd"
            ></RangeDates>


            <div class="col-4">
              <q-btn
                  class="q-mb-md"
                  @click="loadData"
                  :loading="loading"
              >
                Показать отчет
              </q-btn>
            </div>
          </div>

          <div
              v-else
              class="row"
              style="max-width: 600px;"
          >
            <div class="flex items-center q-mb-sm">
              <div>
                <q-btn
                    flat
                    icon="mdi-arrow-left"
                    @click="prevMonth"
                >
                  <q-tooltip>Предыдущий месяц</q-tooltip>
                </q-btn>
              </div>

              <div class="q-px-sm text-h6">{{ monthName }}, {{ year }}</div>

              <div>
                <q-btn
                    flat
                    icon="mdi-arrow-right"
                    @click="nextMonth"
                >
                  <q-tooltip>Следующий месяц</q-tooltip>
                </q-btn>
              </div>
            </div>
          </div>
        </div>
      </div>

      <q-space/>

        <div class="row justify-end q-pb-sm">
          <q-btn @click="print" icon="print">
            <q-tooltip>Печать</q-tooltip>
          </q-btn>
        </div>  
    </div>

    <q-table
        v-if="tableType === 'all'"
        key="expenses_table_all"
        row-key="id"
        class="sticky-table"
        binary-state-sort hide-pagination hide-no-data
        :data="data"
        :columns="ref.listColumns"
        :pagination.sync="pagination"
        :loading="loading"
        @request="loadData"
        id="expenses_table_all"
    >
      <template #body-cell-actions="props">
        <q-td :props="props">
          <q-btn
              round flat
              icon="edit"
              size="sm"
              @click.stop="showEdit(props.row)"
          >
            <q-tooltip>
              Редактировать
            </q-tooltip>
          </q-btn>

          <q-btn
              round flat
              text-color="red"
              icon="close"
              size="sm"
              @click.stop="remove(props.row)"
          >
            <q-tooltip>
              Удалить
            </q-tooltip>
          </q-btn>
        </q-td>
      </template>

      <template v-if="data.length > 0" #top-row="{cols}">
        <q-tr class="bg-grey-4">
          <q-td v-for="(col, index) of cols" :key="col.name">
            <template v-if="index === 0">Итого</template>
            <template v-if="index === 6">
              {{ data.reduce((acc, val) => acc + val[col.name], 0) }}
            </template>
          </q-td>
        </q-tr>
      </template>

      <template v-if="data.length > 0" #bottom-row="{cols}">
        <q-tr class="bg-grey-4">
          <q-td v-for="(col, index) of cols" :key="col.name">
            <template v-if="index === 0">Итого</template>
            <template v-if="index === 6">
              {{ data.reduce((acc, val) => acc + val[col.name], 0) }}
            </template>
          </q-td>
        </q-tr>
      </template>
    </q-table>
    
    <q-dialog
        v-if="editDialog"
        v-model="editDialog"
        @close="closeEdit"
    >
      <q-card style="width: 300px">
        <q-card-section>

          <q-input label="Сумма"
                   v-model.number="editItem.total_cost"
                   type="number"
          ></q-input>

        </q-card-section>

        <q-card-actions>
          <q-btn v-close-popup>Отмена</q-btn>

          <q-space/>

          <q-btn
              @click="save"
              :loading="editLoading"
          >
            Сохранить
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { getData, METHODS } from '@/utils/http';
import RefSelect from '@/components/fields/RefSelect';
import { getDateToAPiFormat } from '@/utils/date';
import { fuel_expenses } from '@/structures/fuel_expenses';
import { EXPENSES_TYPES } from '@/structures/refs/expenses_types';
import ExpensesByDriver from './ExpensesByDriver';
import ExpensesByVehicle from './ExpensesByVehicle';
import RangeDates from '@/components/fields/date/RangeDates';
import MonthPicker from '@/components/fields/date/MonthPicker';
import DateField from '@/components/fields/date/DateField';

export default {
  name: 'Fuel_expenses',
  components: {DateField, ExpensesByVehicle, ExpensesByDriver, RefSelect, MonthPicker, RangeDates },
  data() {
    return {
      editDialog: false,
      editLoading: false,
      editItem: {},
      periodType: 'month',
      tableType: 'all',
      data: [],
      dataTypes: [],
      pagination: {
        rowsPerPage: 0,
      },
      paginationTypes: {
        rowsPerPage: 0,
      },
      dataKey: 1,
      tripDateStart: '',
      tripDateEnd: '',
      year: (new Date()).getFullYear(),
      month: (new Date()).getMonth(),
      monthDays: 0,
      monthName: '',
      monthNames: [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь',
      ],
      loading: false,
      ref: fuel_expenses,
      ref_by_type: fuel_expenses,
      shareWithAll: false,
      isTax: false,
    };
  },
  computed: {
    startDate() {
      if (this.periodType === 'range') {
        if (this.tripDateStart === '') {
          return '';
        }

        return getDateToAPiFormat(this.tripDateStart);
      } else {
        return `${this.year}-${_setZero(this.month + 1)}-01`;
      }
    },
    endDate() {
      if (this.periodType === 'range') {
        if (this.tripDateEnd === '') {
          return '';
        }

        return getDateToAPiFormat(this.tripDateEnd);
      } else {
        return `${this.year}-${_setZero(this.month + 1)}-${this.monthDays}`;
      }
    },
    columnsForIncludeTable() {
      const columns = [];
      // Копируем столбцы для построения таблицы. Нельзя использовать _copy(), т.к. он избавляется от функций
      // Удаляем первый и последние столбцы (Статья расходов и Действия)
      for (let i = 1; i < this.ref.listColumns.length - 1; i++) {
        columns.push(this.ref.listColumns[i]);
      }
      return columns;
    },
    
  },
  watch: {
    periodType() {
      this.loadData();
    },
  },
  created() {
    this.monthDays = this.getMonthDays(this.year, this.month);
    this.initCalendar(this.year, this.month);
  },
  methods: {
    print() {
      const prtContent = document.createElement('div');
      let id = '';
      switch (this.tableType) {
        case 'types':
          id = 'expenses_table_types';
          break;
        case 'driver':
          id = 'expenses_table_driver';
          break;
        case 'vehicle':
          id = 'expenses_table_vehicle';
          break;
        case 'all':
        default:
          id = 'expenses_table_all';
      }
      prtContent.innerHTML = document.getElementById(id).innerHTML;

      // Удаляем столбец "Действия"
      if (this.tableType === 'all') {
        prtContent.querySelectorAll('td:last-child').forEach((td) => td.remove());
        prtContent.querySelector('th:last-child').remove();
      }

      // Открываем окно для печати
      const WinPrint = window.open('', '', 'left=50,top=50,width=800,height=640,toolbar=0,scrollbars=1,status=0');
      WinPrint.document.write(prtContent.innerHTML);
      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
    },
    getCurrentDate() {
      const date = new Date();

      return `${_setZero(date.getDate())}/${_setZero(date.getMonth() + 1)}/${date.getFullYear()}`;
    },
    getMonthDays(year, month) {
      return (new Date(year, month + 1, 0)).getDate();
    },
    loadData() {
      let startDt;
      let endDt;

      if (this.periodType === 'range') {
        if (this.tripDateStart === '' || this.tripDateEnd === '') {
          return;
        }

        startDt = getDateToAPiFormat(this.tripDateStart);
        endDt = getDateToAPiFormat(this.tripDateEnd);
      } else {
        startDt = `${this.year}-${_setZero(this.month + 1)}-01`;
        endDt = `${this.year}-${_setZero(this.month + 1)}-${this.monthDays}`;
      }

      let URL = `/fuel_expenses?start_date=${startDt}&end_date=${endDt}&include=*`;
      URL += '&perPage=100000';

      this.loading = true;

      getData(URL, METHODS.GET)
          .then((res) => {
            const items = res.result.items;
            this.data = items;

          })
          .catch((res) => {
            this.$q.notify({
              message: res.error,
              type: 'negative',
            });
          })
          .finally(() => {
            this.loading = false;
          });
    },
    save() {
      this.editLoading = true;

      let editItem = _copy(this.editItem);
      const method = !editItem.is_copy && editItem.id ? METHODS.PUT : METHODS.POST;
      const URL = '/fuel_expenses' + (editItem.id ? `/${editItem.id}` : '');

      editItem.date = getDateToAPiFormat(editItem.date);

      getData(URL, method, {
        params: editItem,
      })
          .then(() => {
            this.closeEdit();
            this.loadData();
          })
          .catch((res) => {
            this.$q.notify({
              message: res.error,
              type: 'negative',
            });
          })
          .finally(() => {
            this.editLoading = false;
          });
    },
    remove(item) {
      if (!item) {
        return;
      }

      if (confirm('Уверены, что хотите удалить эту запись?')) {
        getData(`/expenses/${item.id}`, METHODS.DELETE)
            .then(() => {
              this.loadData();
            })
            .catch((res) => {
              this.$q.notify({
                message: res.error,
                type: 'negative',
              });
            })
            .finally(() => {
              this.editLoading = false;
            });
      }
    },
    closeEdit() {
      this.editItem = null;
      this.editDialog = false;
    },
    showEdit(item, is_copy) {
      const item_copy = _copy(item) || {};

      this.editItem = item_copy;
      this.editDialog = true;
    },
    getOptionLabel(item) {
      return `${item.title} <span class="text-caption">(${this.getExpensesTitle(item.expenses_type)})</span>`;
    },
    getExpensesTitle(type) {
      return EXPENSES_TYPES[type] || '';
    },
    initCalendar(year, month) {
      this.monthDays = this.getMonthDays(year, month);
      this.monthName = this.monthNames[this.month];

      this.loadData();
    },
    prevMonth() {
      if (this.month > 0) {
        this.month--;
      } else {
        this.month = 11;
        this.year--;
      }

      this.initCalendar(this.year, this.month);
    },
    nextMonth() {
      if (this.month < 11) {
        this.month++;
      } else {
        this.month = 0;
        this.year++;
      }

      this.initCalendar(this.year, this.month);
    },
    getLegalEntity(item) {
      this.dataKey = (new Date()).getTime();

      if (item && item.legal_entity) {
        this.$set(this.editItem, 'legal_entity', item.legal_entity);
      }
    },
  },
};
</script>

<style lang="scss">
.sticky-table {
  max-height: calc(100vh - 200px);

  th:last-child, td:last-child {
    padding-left: 30px;
    position: sticky;
    right: 0;
    background-image: linear-gradient(90deg, rgba(2, 0, 36, 0) 0%, rgb(239, 239, 239) 20%);
    z-index: 1;
  }

  td:nth-child(2) {
    min-width: 200px;
    max-width: 400px;
    white-space: normal;
  }

  thead tr {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: white;
  }
}

#includedTable {
  th, td, td:last-child {
    background-image: none;
    background-color: #efefef;
  }
}
</style>
