<template>
  <q-dialog v-model="dialog" @input="$emit('input', false)">
    <q-card style="width: 400px">
      <q-toolbar>
        <q-toolbar-title>
          Привязать водителя
        </q-toolbar-title>
      </q-toolbar>

      <q-card-section>
        <ref-select
            label="Водители"
            url="/drivers"
            v-model="driver_data"
            :option-label="(row) => row.user ? row.user.full_name : ''"
            option-value="id"
            :autocomplete="true"
        ></ref-select>

        <date-field
          v-model="insideStartDate"
          label="Дата начала"
          autofill
        ></date-field>

        <date-field
          v-model="insideEndDate"
          label="Дата окончания"
          ref="date-field-end"
          clearable
          :rules="[val => checkValidDates(val)]"
        ></date-field>
      </q-card-section>

      <q-card-actions>
        <q-btn
            :disable="loading"
            @click="$emit('input', false)"
        >
          Отмена
        </q-btn>

        <q-space />

        <q-btn
            @click="save"
            :loading="loading"
        >
          Сохранить
        </q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import {getData, METHODS} from '@/utils/http';
import RefSelect from '@/components/fields/RefSelect';
import DateField from '@/components/fields/date/DateField';

export default {
  name: 'AddDriver',
  components: {DateField, RefSelect},
  props: {
    driversDialog: {
      type: Boolean,
      required: true,
    },
    driver: {
      type: Array,
      required: true,
    },
    vehicle: {
      type: Object,
      required: true,
    },
    date: {
      type: String,
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      driver_data: [],
      insideStartDate: this.date,
      insideEndDate: null,
    };
  },
  watch: {
    insideStartDate() {
      this.$refs['date-field-end'].validate();
    },
  },
  created() {
    this.dialog = this.driversDialog;

    if (this.driver.length > 0) {
      this.driver_data = this.driver[0];
    }
  },
  methods: {
    save() {
      this.loading = true;

      if (!confirm('Уверены, что хотите поставить этого водителя?')) {
        return false;
      }
      const params = {
        driver_id: this.driver_data.id,
        start_date: this.insideStartDate,
      };

      if (this.insideEndDate) {
        params.end_date = this.insideEndDate;
      }

      getData('/vehicles/' + this.vehicle.id + '/attach/driver', METHODS.POST, {
        params: params,
      })
        .then(() => {
          this.$emit('onRefresh');
        })
        .catch((res) => {
          this.$q.notify({
            message: res.error,
            type: 'negative',
          });
        })
        .finally(() => {
          this.loading = false;
          this.$emit('input', false);
        });
    },
    checkValidDates(val) {
      if (val) {
        return new Date(val) >= new Date(this.insideStartDate) || 'Дата окончания не может быть меньше даты начала';
      }
      return true;
    },
  },
};
</script>
