<template>
  <div class="q-pt-sm">
    <div class="q-mb-md">
      <q-btn @click="loadData"
             round
             class="q-mr-lg"
             icon="refresh"
             :disable="loading"
      >
        <q-tooltip>Обновить</q-tooltip>
      </q-btn>
    </div>

    <q-table
        :data="vehicles_data"
        :columns="columns"
        row-key="vehicle"
        :pagination.sync="pagination"
        :loading="loading"
    >
      <template #body="props">
        <tr
            :props="props"
            :class="getWeightIndicator(props.row)"
            @click="showVehicleDetail(props.row)"
        >
          <q-td
              v-for="column in props.cols"
              :key="`${column.name}_${props.rowIndex}`"
          >
            <template v-if="column.name === 'vehicle'">
              {{ column.value.vehicle }} /
              <template v-if="column.value.trailers">
                {{ column.value.trailers }}
              </template>
              <q-icon v-else color="red" name="warning"></q-icon>
            </template>

            <template v-else-if="column.name === 'driver'">
              <template v-if="column.value">
                {{ column.value }}
              </template>
              <q-icon v-else color="red" name="warning"></q-icon>
            </template>

            <template v-else-if="column.name === 'vehicle_status'">
              <template v-if="column.value">
                <template v-if="column.value.title">
                  {{ column.value.title }}
                  <div class="text-caption">
                    {{ column.value.date }} / <span class="text-bold">{{ column.value.time_diff }}</span>

                    <q-icon
                        v-if="column.value.has_break"
                        color="red"
                        name="warning"
                    ></q-icon>
                  </div>
                </template>
              </template>
            </template>

            <template v-else>
              {{ column.value }}
            </template>
          </q-td>
        </tr>
      </template>
    </q-table>

    <q-dialog
        v-model="vehicleDetailDialog"
        v-if="vehicleDetailDialog"
        maximized
        @close="updateList"
    >
      <q-layout
          view="Lhh lpR fff"
          container
          class="bg-white"
      >
        <q-header class="bg-primary">
          <q-toolbar>
            <q-toolbar-title style="line-height: 110%">
              Транспорт {{ currentVehicle.reg_number }}
            </q-toolbar-title>

            <!-- Закрыть -->
            <q-btn color="red"
                   class="q-ml-md"
                   v-close-popup
            >
              Закрыть
            </q-btn>
          </q-toolbar>
        </q-header>

        <!-- Основное содержимое -->
        <q-page-container>
          <q-page>
            <Staffing
                :vehicle_origin="currentVehicle"
                @onUpdate="setUpdate"
            />
          </q-page>
        </q-page-container>
      </q-layout>
    </q-dialog>
  </div>
</template>

<script>
import { getData, METHODS } from '@/utils/http';
import Staffing from '@/modules/organization/Staffing';
import { FORMAT_FOR_USER, formatDate } from '@/utils/date';

export default {
  name: 'Couplings',
  components: { Staffing },
  data() {
    return {
      loading: false,
      vehicleDetailDialog: false,
      vehicles_data: [],
      currentVehicle: null,
      pagination: {
        rowsPerPage: 0,
      },
      needUpdate: false,
      columns: [
        {
          name: 'vehicle',
          label: 'Транспорт/прицеп',
          align: 'left',
          field: (row) => {
            return {
              vehicle: row.reg_number,
              trailers: row.trailers.map((it) => it.reg_number).join(', '),
            };
          },
        },
        {
          name: 'driver',
          label: 'Водитель',
          align: 'left',
          field: (row) => {
            return row.drivers.map((it) => it.user.full_name).join(', ');
          },
        },
        {
          name: 'vehicle_status',
          label: 'Статус ТС',
          align: 'left',
          field: (row) => {
            const currDate = new Date();
            const diffFunc = (date) => {
              const dt = new Date(date.split('-'));
              const timeDiff = currDate.getTime() - dt;
              const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
              const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
              const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
              const diff = `${days} дн., ${hours} ч., ${minutes} мин.`;

              return diff;
            };

            if (row.active_status) {
              const statusDate = row.active_status.pivot.start_date;

              return {
                title: row.active_status.title,
                date: formatDate(new Date(statusDate), FORMAT_FOR_USER.DATE_TIME),
                time_diff: diffFunc(statusDate),
              };
            }

            return {
              title: '', //'Свободен',
              date: '', //statusDate,
              time_diff: '', //diffFunc(statusDate),
              has_break: '', //hasBreak(statusDate),
            };
          },
        },
      ],
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    updateList() {
      if (this.needUpdate) {
        this.loadData();
      }
    },
    loadData() {
      this.loading = true;
      const date = new Date();
      const date_str = `${date.getFullYear()}-${_setZero(date.getMonth() + 1)}-${_setZero(date.getDate())}`;

      getData(`/vehicles/schedule?start_date=${date_str}&end_date=${date_str}&is_active=true`, METHODS.GET, {})
          .then((res) => {
            this.vehicles_data = res.result;
            this.loading = false;
          })
          .catch((res) => {
            this.$q.notify({
              message: res.error,
              type: 'negative',
            });

            this.loading = false;
          })
          .finally(() => {
          });
    },
    showVehicleDetail(row) {
      this.vehicleDetailDialog = true;
      this.currentVehicle = row;
    },
    getWeightIndicator(row) {
      if (row.active_status) {
        if (!row.active_status.is_vehicle_active) {
          return 'bg-grey-2';
        }
      }

      if (row.orders.length) {
        if (row.orders.length > 1) {
          return 'bg-red-2';
        }
      }

      if (!row.drivers.length) {
        return 'bg-orange-2';
      }

      return '';
    },
    setUpdate() {
      this.needUpdate = true;
    },
  },
};
</script>
