<template>
  <entity-manager
      :name="ref.name"
      :title="ref.title"
      :entity-url="ref.url"
      :list-columns="ref.listColumns"
      :list-edit="ref.listEdit"
      :include="ref.include"
      :filter-by="ref.filterBy"
      :perms="ref.perms"
      v-show="hasAccess[ref.perms.create]"
  />
</template>

<script>
import EntityManager from '@/components/entity_manager/EntityManager';
import HasPerms from '@/components/guard/mixins/HasPerms';
import {roles_ref} from '@/structures/roles';

export default {
  name: 'Roles',
  components: {
    EntityManager,
  },
  mixins: [HasPerms],
  data() {
    return {
      loading: true,
      ref: roles_ref,
    };
  },
  methods: {
    getPerms_() {
      if (!this.ref || !this.ref.perms || this.ref.perms.length === 0) {
        return [];
      }

      return [this.ref.perms.create];
    },
  },
};
</script>
