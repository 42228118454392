<template>
  <q-dialog maximized v-model="show">
    <q-layout view="Lhh lpR fff" container class="bg-white" v-if="show">
      <q-header class="bg-primary">
        <q-toolbar>
          <q-toolbar-title class="q-py-xs">
            <div class="flex">
              <div>
                <div>
                  Заказ №{{ orderItem.number }}
                  <span v-if="orderItem.move_direction && orderItem.move_direction.title">
                  | {{ orderItem.move_direction.title }}
                  </span>
                </div>

                <div class="text-subtitle1">
                  создан {{ new Date(orderItem.created_at).toLocaleDateString() }}
                </div>
              </div>

              <div class="col q-ml-lg" v-if="orderItem.is_priority">
                <div class="col-4 text-subtitle1">
                  <q-icon name="warning" color="red"></q-icon>
                  Приоритетный заказ
                </div>
              </div>
            </div>
          </q-toolbar-title>

          <q-btn
            color="white"
            text-color="black"
            @click="onPublish"
            :loading="loading"
          >
            Опубликовать
          </q-btn>

          <!-- Закрыть -->
          <q-btn
            v-close-popup
            color="negative"
            text-color="white"
            class="q-ml-md"
          >
            Закрыть
          </q-btn>
        </q-toolbar>
      </q-header>

      <!-- Основное содержимое -->
      <q-page-container>
        <q-page class="row">
          <div class="col-2 column">
            <q-tabs
              vertical
              inline-label
              class="text-primary bg-white"
              v-model="tab"
            >
              <!-- Инфо -->
              <q-tab
                label="Инфо"
                name="info"
                style="justify-content:initial"
              ></q-tab>

              <!-- Перевозчики -->
              <q-tab
                label="Перевозчики"
                name="carriers"
                style="justify-content:initial"
              ></q-tab>

              <!-- Отклики (Заявки) -->
              <q-tab
                label="Отклики"
                name="bids"
                style="justify-content:initial"
              ></q-tab>
            </q-tabs>
          </div>

          <!-- Основное содержимое -->
          <div class="col">
            <OrderModerationForm
              v-if="tab === 'info'"
              v-model="orderItem"
              :order="order"
            ></OrderModerationForm>

            <OrderCarriers
              v-if="tab === 'carriers'"
              :order="order"
              @onRefresh="onRefresh"
            ></OrderCarriers>

            <OrderBids
              v-if="tab === 'bids'"
              :order="order"
              @onRefresh="onRefresh"
            ></OrderBids>
          </div>
        </q-page>
      </q-page-container>
    </q-layout>

  </q-dialog>
</template>

<script>
import { getData, METHODS } from '@/utils/http';
import OrderBids from '@/modules/orders/OrdersBids/OrdersBids';
import OrderModerationForm from '@/modules/orders/OrdersModeration/OrderModeraionForm';
import OrderCarriers from '@/modules/orders/OrdersModeration/OrderCarriers';

/**
 * Компонент для просмотра логистом
 */
export default {
  name: 'OrderLogic',
  components: { OrderCarriers, OrderModerationForm, OrderBids },
  props: {
    // Показ диалога
    value: {
      type: Boolean,
      required: true,
      default: false,
    },
    // Объект заказа
    order: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      tab: 'info',
      orderItem: null,
      loading: false,
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  watch: {
    order(val) {
      this.orderItem = _copy(val);

      if (this.orderItem.status === 'ON_MODERATE') {
        this.orderItem.status = 'OPENED_BIDS';
      }
    },
  },
  methods: {
    // При нажатии на "Опубликовать"
    onPublish() {
      this.loading = true;
      let order = this.orderItem;

      getData(`/orders_moderate/${this.orderItem.id}`, 'PUT', {
        params: {
          move_directions_id: order.move_direction?.id || null,
          status: order.status,
          price: order.price || 1,
          price_vat: order.price_vat || null,
          orders_payments_types_id: order.orders_payments_type?.id || 1,
          immobility_price: order.immobility_price,
          vehicle_types: order.vehicle_types || null,
          vehicles_loading_types: order.vehicles_loading_types || null,
          vehicles_unloading_types: order.vehicles_unloading_types || null,
          comment: order.comment,
        },
      })
        .then(() => {
          this.$q.notify({
            color: 'primary',
            message: 'Заказ успешно сохранен!',
          });

          // Скрываем диалог
          this.show = false;

          this.$emit('onRefresh');
        })
        .catch((error) => {
          this.$q.notify({
            message: error?.error || 'Не удалось опубликовать заказ',
            color: 'red',
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onRefresh() {
      getData('/orders_moderate/' + this.orderItem.id, METHODS.GET)
        .then((res) => {
          this.orderItem = res.result;
        });
    },
  },
};
</script>
