<template>
  <div :class="{'row': horizontal, 'no-wrap': horizontal}">
    <div :class="{'q-mr-sm': horizontal}">
      <DateField :label="startDateLabel"
                 v-model="innerStartDate"
                 :clearable="startDateClearable"
      ></DateField>
    </div>

    <div :class="{'q-ml-sm': horizontal}">
      <DateField :label="endDateLabel"
                 v-model="innerEndDate"
                 ref="range-date-end-date"
                 :rules="[val => checkValidDates(val)]"
                 :clearable="endDateClearable"
      ></DateField>
    </div>
  </div>
</template>

<script>
import DateField from '@/components/fields/date/DateField';

export default {
  name: 'RangeDates',
  components: { DateField },
  props: {
    startDate: {
      type: String,
    },
    endDate: {
      type: String,
    },

    // Расположение элементов
    horizontal: {
      type: Boolean,
      default: true,
    },

    startDateLabel: {
      type: String,
      default: 'Начало периода',
    },
    endDateLabel: {
      type: String,
      default: 'Окончание периода',
    },

    startDateClearable: Boolean,
    endDateClearable: Boolean,
  },
  computed: {
    innerStartDate: {
      get() {
        return this.startDate;
      },
      set(val) {
        this.$emit('update:startDate', val);
      },
    },
    innerEndDate: {
      get() {
        return this.endDate;
      },
      set(val) {
        this.$emit('update:endDate', val);
      },
    },
  },
  watch: {
    innerStartDate() {
      this.$refs['range-date-end-date'].validate();
    },
  },
  methods: {
    checkValidDates(val) {
      if (val && this.innerStartDate) {
        return new Date(val) >= new Date(this.innerStartDate)
            || `${this.startDateLabel} не может быть меньше ${this.endDateLabel}]`;
      }
      return true;
    },
  },
};
</script>
