

const fuel_expenses = {
    name: 'fuel_expenses',
    title: 'Расходы',
    url: '/fuel_expenses',
    include: ['*'],
    perms: {
        list: 'v1.expenses.list',
        read: 'v1.expenses.read',
        create: 'v1.expenses.create',
        update: 'v1.expenses.update',
        delete: 'v1.expenses.delete',
    },
    listColumns: [
        {
            name: 'fuel_consumptions',
            label: 'Средний расход',
            align: 'left',
            field: (row) => {
                return `${row.fuel_consumption_value}`;
            },
        },
        {
            name: 'fuel_cost',
            label: 'Средняя стоимость',
            align: 'left',
            field: (row) => {
                return `${row.fuel_cost_value}`;
            },
        },
        {
            name: 'date',
            label: 'Дата',
            align: 'left',
            field: (row) => {
                const d = new Date(Date.parse(row.date));

                return `${_setZero(d.getDate())}/${_setZero(d.getMonth() + 1)}/${d.getFullYear()}`;
            },
        },
        {
            name: 'driver',
            label: 'Водитель',
            align: 'left',
            field: (row) => {
                return `${row.drivers.map((d) => d.user.full_name)}`;
            },
        },
        {
            name: 'vehicle',
            label: 'Транспорт',
            align: 'left',
            field: (row) => {
                return `${row.vehicles.map((d) => d.reg_number)}`;
            },
        },
        {
            name: 'distance',
            label: 'КМ',
            align: 'left',
            field: (row) => {
                return `${row.distance}`;
            },
        },
        {
            name: 'total_cost',
            label: 'Сумма',
            align: 'left',
            field: (row) => {
                return _priceFormat(row.total_cost, true);
            },
        },
        {
            name: 'actions',
            label: 'Действия',
            align: 'left',
            field: 'actions',
        },
    ],
};

export {
    fuel_expenses,
};
