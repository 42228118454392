<template>
  <div>
    <entity-manager
        :name="ref.name"
        :title="ref.title"
        :entity-url="ref.url"
        :list-columns="ref.listColumns"
        :list-edit="ref.editColumns"
        :include="ref.include"
        :filters="ref.filters"
        :perms="ref.perms"
        :access="ref.access"
        :key="tableKey"
    >
      <template #body="props">
        <q-tr :props="props">
          <q-td
              v-for="column in ref.listColumns"
              :key="column.name"
              :props="props"
          >
            <!-- Выборка определенного поля из объекта -->
            <div v-if="column.map != null">
              <!-- multiple -->
              <template v-if="column.type === 'multiple'">
                  <span
                      class="block"
                      v-for="it in props.row[column.field]"
                      :key="it[column.field]"
                  >
                    {{ it[column.map] || '[Не задано]' }}
                  </span>
              </template>

              <!-- Function -->
              <template v-else>
                <template v-if="column.field.constructor.name === 'Function'">
                  {{ column.field(props.row) }}
                </template>

                <template v-else>
                  <template v-if="props.row[column.field]">
                    <template v-if="column.map">
                      {{ props.row[column.field][column.map] || '[Не задано]' | truncate(50) }}
                    </template>

                    <template v-else>
                      {{ props.row[column.field] | truncate(50) }}
                    </template>
                  </template>

                  <template v-else>
                    [Не задано]
                  </template>
                </template>
              </template>
            </div>

            <!-- date -->
            <div v-else-if="column.type ==='date'">
              <template v-if="props.row[column.field]">
                {{formatStrDate(props.row[column.field])}}
              </template>
            </div>

            <!-- checkbox -->
            <div v-else-if="column.type ==='checkbox'">
              <q-checkbox
                  :value="props.row[column.field]"
              ></q-checkbox>
            </div>

            <!-- enum -->
            <div v-else-if="column.type ==='enum'">
              <select-enum
                  :is-field="true"
                  :options="column.meta.options"
                  :value="props.row[column.field]"
              ></select-enum>
            </div>

            <div v-else>
              <!-- Function -->
              <template v-if="column.field.constructor.name === 'Function'">
                {{ column.field(props.row) }}
              </template>

              <template v-else>
                <template v-if="props.row[column.field]">
                  <template v-if="column.map">
                    {{ props.row[column.field][column.map] || '[Не задано]' | truncate(50) }}
                  </template>

                  <template v-else>
                    {{ props.row[column.field] | truncate(50) }}
                  </template>
                </template>

                <template v-else>
                  [Не задано]
                </template>
              </template>
            </div>

            <!-- Если поле помечено как редактируемое, то его можно редактировать в  самой табличке -->
            <div v-if="column['editable'] === true">
              <q-popup-edit
                  v-if="editColumns.findIndex(it => it.field === column.field) > -1"
                  v-model="props.row[column.field]"
                  @save="savePopup(props.row)"
              >
                <q-input
                    v-model="props.row[column.field]"
                    dense autofocus counter
                ></q-input>
              </q-popup-edit>
            </div>

          </q-td>

          <!-- Колонка с действиями -->
          <q-td key="actions">
            <q-btn
                round flat
                text-color="primary"
                icon="done"
                @click="showDialog(props.row)"
            >
              <q-tooltip>Принять</q-tooltip>
            </q-btn>

            <q-btn
                round flat
                text-color="red"
                icon="close"
                @click="deny(props.row.id)"
            >
              <q-tooltip>Закрыть</q-tooltip></q-btn>
          </q-td>
        </q-tr>
      </template>
    </entity-manager>

    <q-dialog v-model="showAllowDialog">
      <q-card v-if="allowItem">

        <!-- Заголовок -->
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">Подтверждение заявки</div>

          <q-space/>

          <q-btn icon="close"
                 flat round dense
                 v-close-popup
          ></q-btn>
        </q-card-section>

        <div v-if="allowItem.organizations_id">
          <q-card-section>
            Подтвердите, что передаете перевозчику {{allowItem.organization.title}} заказ<br />

            <div class="flex items-center">
              <div class="col-grow">
                <q-input
                    label="Количество"
                    type="number"
                    v-model="cargo_units_count"
                    :hint="`Доступно ${allowItem.order.cargo_units_count_left}`"
                >
                </q-input>
              </div>
              <div>
                <q-btn
                    flat
                    size="sm"
                    color="primary"
                    @click.prevent="cargo_units_count = allowItem.order.cargo_units_count_left"
                >
                  Выдать максимум
                </q-btn>
              </div>
            </div>

            <q-input
                label="Цена"
                type="number"
                v-model="price"
            >
            </q-input>
          </q-card-section>

          <q-card-actions align="right">
            <q-btn
                flat
                label="OK"
                color="primary"
                @click="apply"
                :loading="allowLoading"
            ></q-btn>

            <q-btn
                flat
                label="Отмена"
                color="negative"
                v-close-popup
                @click="clearData"
            ></q-btn>
          </q-card-actions>
        </div>

        <q-card-section v-else>
          Извините, произошла ошибка. Невозможно подтвердить данную заявку.
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import EntityManager from '@/components/entity_manager/EntityManager';
import {getData, METHODS} from '@/utils/http';
import {formatDate, FORMAT_FOR_USER} from '@/utils/date';
import {orders_bids} from '@/structures/orders_bids';

export default {
  name: 'OrdersBids',
  components: {EntityManager},
  props: {
    order: {
      type: Object,
    },
  },
  data() {
    if (this.order && this.order.id) {
      orders_bids.filters.push({
        by: 'orders_id',
        value: this.order.id,
      });
    }

    return {
      tableKey: (new Date()).getTime(),
      cargo_units_count: 0,
      price: 0,
      ref: orders_bids,
      showAllowDialog: false,
      allowLoading: false,
      copyData: [],
      allowItem: null,
    };
  },
  created() {
    if (this.$route.params.id) {
      this.loadDetailData(this.$route.params.id);
    }
  },
  methods: {
    loadDetailData(id) {
      this.loading = true;

      getData('/order_bids/' + id + '?include=*', METHODS.GET)
        .then((res) => {
          this.showDialog(res.result);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    clearData() {
      this.cargo_units_count = 0;
      this.price = 0;
    },

    formatDateStr(date) {
      return formatDate(date, FORMAT_FOR_USER.ONLY_DATE);
    },

    showDialog(item) {
      this.showAllowDialog = true;
      this.allowItem = item;
      this.price = item.order.price;
    },

    apply() {
      if (this.cargo_units_count === 0 || this.price === 0) {
        this.$q.notify({
          message: 'Цена и количество должны быть больше 0',
          type: 'negative',
        });

        return;
      }

      const allowItem = _copy(this.allowItem);
      this.allowLoading = true;

      getData('/order_bids/allow/' + allowItem.id, METHODS.POST, {
        params: {
          cargo_value: this.cargo_units_count,
          cargo_price: this.price,
        },
      })
        .then(() => {
          this.tableKey = (new Date()).getTime();

          this.$q.notify({
            color: 'primary',
            message: 'Заявка на заказ подтверждена',
          });

          this.showAllowDialog = false;
          this.allowItem = null;
        })
        .catch((res) => {
          let errMsg = 'Не удалось подтвердить заявку';

          if (res && res.error) {
            errMsg = res.error;
          }

          this.$q.notify({
            message: errMsg,
            type: 'negative',
          });
        })
        .finally(() => {
          this.allowLoading = false;
        });
    },

    deny(id) {
      if (confirm('Уверены, что хотите отменить заявку?')) {
        getData('/order_bids/deny/' + id, METHODS.POST, {})
          .then(() => {
            this.tableKey = (new Date()).getTime();

            this.$emit('onRefresh');

            this.$q.notify({
              color: 'primary',
              message: 'Заявка на заказ отменена',
            });
          })
          .finally(() => {
            this.showAllowDialog = false;
            this.allowLoading = false;
            this.allowItem = null;
          });
      }
    },
  },
};
</script>
