<template>
  <entity-manager
      :name="ref.name"
      :title="ref.title"
      :entity-url="ref.url"
      :list-columns="ref.listColumns"
      :list-edit="ref.listEdit"
      :include="ref.include"
      :filters="ref.filters"
      :perms="ref.perms"
      @onAddClick="onAddClick"
  />
</template>

<script>
import EntityManager from '@/components/entity_manager/EntityManager';
import HasPerms from '@/components/guard/mixins/HasPerms';

export default {
  name: 'CarriersUsers',
  components: {EntityManager},
  mixins: [HasPerms],
  props: {
    organization: {
      type: Object,
    },
  },
  data() {
    return {
      refreshKey: (new Date()).getTime(),
      ref: {
        name: 'users',
        title: 'Заказы (все)',
        url: '/rbac/users',
        include: [],
        filterBy: ['full_name'],
        perms: {
          own: 'v1.users.list.own',
          list: 'v1.users.list.own',
          read: 'v1.users.read',
          create: 'v1.users.create',
          update: 'v1.users.update',
          delete: 'v1.users.delete',
        },
        listColumns: [
          {
            name: 'full_name',
            required: true,
            label: 'Имя',
            align: 'left',
            field: 'full_name',
            sortable: true,
          },
          {
            name: 'email',
            required: true,
            label: 'Email',
            align: 'left',
            field: 'email',
            sortable: true,
          },
          {
            name: 'phone',
            required: true,
            label: 'Номер телефона',
            align: 'left',
            field: 'phone',
            sortable: true,
          },
        ],
        listEdit: [
          {
            name: 'full_name',
            required: true,
            label: 'Имя',
            align: 'left',
            field: 'full_name',
            sortable: true,
          },
          {
            name: 'email',
            required: true,
            label: 'Email',
            align: 'left',
            field: 'email',
            sortable: true,
          },
          {
            name: 'phone',
            required: true,
            label: 'Номер телефона',
            align: 'left',
            field: 'phone',
            sortable: true,
          },
          {
            name: 'password',
            required: true,
            label: 'Новый пароль',
            align: 'left',
            field: 'password',
            sortable: true,
            type: 'password',
          },
        ],
      },
    };
  },

  created() {
    this.ref.filters = [{
      by: 'organization_id',
      value: this.organization.id,
    }];
  },

  methods: {
    getPerms_() {
      return this.ref.perms.own;
    },

    onAddClick(editedItem) {
      editedItem.organization_id = this.organization.id;
    },
  },
};
</script>
