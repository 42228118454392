const orders_bids = {
    name: 'bids',
    title: 'Заявки в ожидании',
    url: '/order_bids',
    filters: [{
        by: 'status',
        value: 'AVAILABLE',
    }],
    access: 'AVAILABLE',
    include: ['organization', 'order'],
    perms: {
        list: 'v1.order_bids.list',
        read: 'v1.order_bids.read',
        create: 'v1.order_bids.create',
        update: 'v1.order_bids.update',
        delete: 'v1.order_bids.delete',
    },
    listColumns: [
        {
            name: 'order',
            required: true,
            label: 'Заказ',
            align: 'left',
            field: (row) => {
                return `${row.order.number} ${row.order.move_direction.title}`;
            },
        },
        {
            name: 'organization',
            required: true,
            label: 'Перевозчик',
            align: 'left',
            field: 'organization',
            sortable: true,
            map: 'title',
        },
        {
            name: 'user',
            required: true,
            label: 'Контакты',
            align: 'left',
            field: (row) => {
                return `${row.user.full_name} ${row.user.phone}`;
            },
        },
        {
            name: 'comment',
            required: true,
            label: 'Сообщение',
            align: 'left',
            field: 'comment',
            sortable: true,
        },
        {
            name: 'vehicle_count',
            required: true,
            label: 'Количество ТС',
            align: 'left',
            field: 'vehicle_count',
            sortable: true,
        },
        {
            name: 'start_date',
            required: true,
            label: 'Дата выезда',
            align: 'left',
            field: 'start_date',
            sortable: true,
            meta: {
                subtype: 'date',
            },
        },
    ],
    editColumns: [],
};

export {
    orders_bids,
};
