import router from '@/router/router';
import {editStructureMenuOnPerms} from '@/utils/perms';

const state = {
    loading: true,
    menu: {},
};

const mutations = {
    setLoading: (state, payload) => state.loading = payload,
    setMenu(state, payload) {
        state.menu = payload ? payload : {};
    },
    clearMenu: (state) => {
        state.menu = {};
        state.loading = true;
    },
};

const getters = {
    isLoading: (state) => state.loading,
    getNestedLvlMenu(state) {
        /**
         * Возвращает нужный уровень меню относительно маршрута
         * @example находясь на маршруте /refs/cargo_type мы получим из menu.js указывая уровень:
         * 0 - Полностью главное меню
         * 1 - refs.items
         * 2 - refs.items.cargo_type.items
         * @param {Number} [lvl=0] - глубина (уровень) маршрута
         * @param {Boolean} [isReturnItems=true] -  при true вернёт items
         * при false вернёт объект содержащий свойства (в том числе items)
         */
        return (lvl, isReturnItems) => {
            if (lvl === undefined) lvl = 0;
            if (isReturnItems === undefined) isReturnItems = true;
            if (lvl === 0) return state.menu;

            if (!state.loading) {
                const matched = router.currentRoute.matched;
                const partPath = matched[matched.length - 1].path.split('/');
                let link = state.menu;
                for (let i = 1; i <= lvl; i++) {
                    if (i !== 1) {
                        link = link.items;
                    }
                    link = link[partPath[i]];
                }
                return isReturnItems ? Object.values(link.items) : link;
            } else {
                return isReturnItems ? [] : null;
            }
        };
    },
};

const actions = {
    async loadMenu({commit, rootGetters}) {
        commit('setLoading', true);
        const response = await import('@/structures/menu/menu');
        let res = _copy(response.default);

        // TODO: Удалить, когда список меню будет прилетать с бэкенда
        // В зависимости от роли переименовывает пункты меню
        const profile = rootGetters['auth/getProfile'];
        profile.roles.forEach((it) => {
            if (it.title === 'Администратор') {
                const items = res?.orders?.items || {};
                if (_hasOwnProperty(items, 'orders_list')) {
                    items.orders_list.title = 'Заказы комм.';
                }
                if (_hasOwnProperty(items, 'moderation')) {
                    items.moderation.title = 'Заказы логист';
                }
            }
        });

        const menu = editStructureMenuOnPerms(res);

        // Добавление path, потому что доступ по name доступен не для всех роутов
        // К динамичным роутам можно обращаться только через path
        Object.values(menu).forEach((mn) => setPathMenu(mn));

        commit('setLoading', false);
        commit('setMenu', menu);
    },
};

function setPathMenu(menu, beforePath) {
    menu.path = (beforePath ? beforePath : '') + '/' + menu.url;
    if (menu.items) {
        Object.values(menu.items).forEach((it) => setPathMenu(it, menu.path));
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
};
