import { render, staticRenderFns } from "./Finance.vue?vue&type=template&id=f0c5c52a&"
import script from "./Finance.vue?vue&type=script&lang=js&"
export * from "./Finance.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QSplitter from 'quasar/src/components/splitter/QSplitter.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QRouteTab from 'quasar/src/components/tabs/QRouteTab.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QSplitter,QTabs,QRouteTab});
