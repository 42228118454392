
const node_report = {
    name: 'ref_node_positions',
    title: 'Поставщики',
    url: '/reports/inventory_nodes',
    include: [],
    filterBy: ['title'],
    perms: {
        list: 'v1.node_positions.list',
        read: 'v1.node_positions.read',
        // create: 'v1.node_positions.create',
        // update: 'v1.node_positions.update',
        // delete: 'v1.node_positions.delete',
    },
    listColumns: [
        {
            name: 'inventory_number',
            required: true,
            label: 'Инвентарный номер узла',
            align: 'left',
            field: 'inventory_number',
            sortable: true,
        },
        {
            name: 'type_of_node',
            required: true,
            label: 'ТС или прицеп',
            align: 'left',
            field: (row) => {
                if (row.install_object === 'VEHICLE_TYPE') {
                    return `ТС ${row.reg_number}`;
                } else {
                    return `Прицеп ${row.reg_number}`;
                }
            },
        },
        {
            name: 'distance',
            required: true,
            label: 'Пробег',
            align: 'left',
            field: 'distance',
            sortable: true,
        },
        {
            name: 'rate_of_degradation',
            required: true,
            label: 'Норма износа',
            align: 'left',
            field: 'rate_of_degradation',
        },
        {
            name: 'degradation_percent',
            required: true,
            label: 'Процент износа',
            align: 'left',
            field: 'degradation_percent',
        },
    ],
};

export {
    node_report,
};
