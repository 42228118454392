<template>
  <crud-table
    :title="title"
    :list-columns="listColumns"
    :edit-columns="listEdit"
    :create-columns="listCreate"
    :data="data"
    :loading="isLoading"
    :pagination.sync="pagination"
    :can-add="hasAccess[perms.create]"
    :can-edit="hasAccess[perms.update]"
    :can-delete="hasAccess[perms.delete]"
    :use-native-add="useNativeAdd"
    :use-native-edit="useNativeEdit"
    :row-class="rowClass"
    :canFilter="canFilter"
    @onAdd="onAdd"
    @onEdit="onEdit"
    @onDelete="onDelete"
    @onRequest="onRequest"
    @onRowClick="onRowClick"
    @onAddClick="onAddClick"
    @onEditClick="onEditClick"
    @onRefresh="onRefresh"
  >
    <template #body="props">
      <slot name="body" v-bind="props"></slot>
    </template>

    <template #top-left="props">
      <slot name="top-left" v-bind="props"></slot>
    </template>

    <template #top-right="props">
      <slot name="top-right" v-bind="props"></slot>
    </template>

    <template #actions="props">
      <slot name="actions" v-bind="props"></slot>
    </template>

    <template #cu-dialog="props">
      <slot name="cu-dialog" v-bind="props"></slot>
    </template>
  </crud-table>
</template>

<script>
import CrudService from '@/services/CrudService';
import CrudTable from '@/components/entity_manager/CrudTable';
import GuardService from '@/services/GuardService';
import HasPerms from '@/components/guard/mixins/HasPerms';

export default {
  name: 'EntityManager',
  components: { CrudTable },
  mixins: [HasPerms],
  props: {
    name: String,
    title: String,
    entityUrl: String,
    listColumns: Array,
    listEdit: Array,
    listCreate: Array,
    include: Array,
    filters: Array,
    filterBy: {
      type: Array,
    },
    perms: {
      list: 'list',
      read: 'read',
      create: 'create',
      update: 'update',
      delete: 'delete',
    },
    // Использовать встроенный генератор формы создания
    useNativeAdd: {
      type: Boolean,
      required: false,
      default: true,
    },
    // Использовать встроенный генератор формы редактирования
    useNativeEdit: {
      type: Boolean,
      required: false,
      default: true,
    },
    pagination_rules: {
      type: Object,
    },
    rowClass: {
      type: Function,
      default: () => {
      },
    },
  },
  data() {
    return {
      isLoading: true,
      data: [],
      canList: false,
      canRead: false,
      canAdd: false,
      canEdit: false,
      canDelete: false,
      isAvailable: false,
      tableKey: (new Date()).getTime(),
      pagination: {
        descending: false,
        page: 1,
        rowsPerPage: 10,
        rowsNumber: 0,
      },
      canFilter: this.filterBy && !!this.filterBy.length,
    };
  },
  mounted() {
    this.loadData(this.pagination, this.filters);
  },
  created() {
    this.checkPerms();

    if (this.pagination_rules) {
      Object.keys(this.pagination_rules).forEach((rule) => {
        if (Object.hasOwnProperty.call(this.pagination, rule)) {
          this.pagination[rule] = this.pagination_rules[rule];
        }
      });
    }
  },
  methods: {
    getPerms_() {
      return Object.values(this.perms);
    },

    onRowClick(row, index) {
      this.$emit('onRowClick', row, index);
    },

    checkPerms() {
      this.canList = GuardService.hasPerm(this.perms.list ?? 'no_perm');
      this.canRead = GuardService.hasPerm(this.perms.read ?? 'no_perm');
      this.canAdd = GuardService.hasPerm(this.perms.create ?? 'no_perm');
      this.canEdit = GuardService.hasPerm(this.perms.update ?? 'no_perm');
      this.canDelete = GuardService.hasPerm(this.perms.delete ?? 'no_perm');

      this.isAvailable = this.canList || this.canRead || this.canAdd || this.canEdit || this.canDelete;
    },

    onRequest(props) {
      let pagination = props?.pagination ?? this.pagination;
      let filters = this.filters || [];

      if (this.filterBy) {
        this.filterBy.forEach((it) => {
          if (props.filter) {
            filters.push({
              by: it,
              value: props.filter,
            });
          }
        });
      }

      this.loadData(pagination, filters);
    },

    // Событие перезагрузки данных таблицы
    onRefresh() {
      this.loadData(this.pagination, this.filters);
    },

    loadData(pagination, filters) {
      this.isLoading = true;

      if (this.perms.own) {
        CrudService.entity(this.entityUrl)
          .own(
            pagination.page,
            pagination.rowsPerPage,
            this.include,
            pagination.sortBy,
            pagination.descending,
            filters,
          )
          .then((response) => {
            this.data = response.result.items;
            this.pagination.rowsNumber = response.result.total;
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        CrudService.entity(this.entityUrl)
          .list(
            pagination.page,
            pagination.rowsPerPage,
            this.include,
            pagination.sortBy,
            pagination.descending,
            filters,
          )
          .then((response) => {
            this.data = response.result.items ? response.result.items : response.result;
            this.pagination.rowsNumber = response.result.total;
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },

    onAdd(item, success, error) {
      this.listEdit.forEach((it) => {
        if (it.meta && it.meta.prepareTo) {
          if (Object.prototype.hasOwnProperty.call(item, it.field)) {
            item[it.field] = it.meta.prepareTo(item[it.field]);
          }
        }
      });

      CrudService.entity(this.entityUrl)
        .add(item)
        .then((response) => {
          if (response.success) {
            this.loadData(this.pagination);

            if (success) {
              success();
            }
          }
        })
        .catch((response) => {
          if (error) {
            error(response);
          }
        });
    },

    onEdit(item, success, error) {
      this.listEdit.forEach((it) => {
        if (it.meta && it.meta.prepareTo) {
          if (Object.prototype.hasOwnProperty.call(item, it.field)) {
            item[it.field] = it.meta.prepareTo(item[it.field]);
          }
        }
      });

      CrudService.entity(this.entityUrl)
        .edit(item.id, item)
        .then((response) => {
          if (response.success) {
            this.loadData(this.pagination);

            if (success) {
              success();
            }
          }
        })
        .catch((response) => {
          if (error) {
            error(response);
          }
        });
    },

    onDelete(item) {
      CrudService.entity(this.entityUrl)
        .delete(item.id)
        .then((response) => {
          if (response.success) {
            this.loadData(this.pagination);
          }
        });
    },

    onAddClick(editedItem) {
      this.$emit('onAddClick', editedItem);
    },

    onEditClick(editedItem) {
      this.$emit('onEditClick', editedItem);
    },
  },
};
</script>
