import { render, staticRenderFns } from "./Expenses.vue?vue&type=template&id=4c91e3b6&"
import script from "./Expenses.vue?vue&type=script&lang=js&"
export * from "./Expenses.vue?vue&type=script&lang=js&"
import style0 from "./Expenses.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QRadio from 'quasar/src/components/radio/QRadio.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QMarkupTable from 'quasar/src/components/markup-table/QMarkupTable.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QRadio,QBtn,QTooltip,QSpace,QTabs,QTab,QTable,QTd,QTr,QDialog,QCard,QCardSection,QInput,QCheckbox,QMarkupTable,QCardActions});qInstall(component, 'directives', {ClosePopup});
