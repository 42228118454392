<template>
  <div>
    <entity-manager
        :name="ref.name"
        :title="ref.title"
        :entity-url="ref.url"
        :list-columns="ref.listColumns"
        :list-edit="ref.editColumns"
        :include="ref.include"
        :filters="ref.filters"
        :filter-by="ref.filterBy"
        :perms="ref.perms"
        :access="ref.access"
        :key="tableKey"
    >
      <template #top-right>
        <q-card-actions align="right">
          <q-btn
              flat
              outline
              align="right"
              color="primary"
              label="Добавить"
              icon="add"
              @click="showAllowDialog = true; allowItem = {}"
          ></q-btn>
        </q-card-actions>
      </template>

      <template #body="props">
        <q-tr :props="props">
          <q-td
              v-for="column in ref.listColumns"
              :key="column.name"
              :props="props"
          >
            <div
                v-if="column.map != null">
              {{ props.row[column.field] && props.row[column.field][column.map] || '[Не задано]' }}
            </div>

            <div
                v-else-if="column.type ==='checkbox'">
              <q-checkbox
                  :value="props.row[column.field]"
              ></q-checkbox>
            </div>

            <div
                v-else>
              {{ props.row[column.field] || '[Не задано]' }}
            </div>
          </q-td>

          <!-- Колонка с действиями -->
          <q-td key="actions">
            <q-btn
                round flat
                text-color="primary"
                icon="edit"
                size="sm"
                @click="showAllowDialog = true; allowItem = _copy(props.row)"
            >
              <q-tooltip>Изменить</q-tooltip>
            </q-btn>

            <q-btn
                round flat
                text-color="red"
                icon="delete"
                size="sm"
                @click="removeItem(props.row)"
            >
              <q-tooltip>Удалить</q-tooltip>
            </q-btn>
          </q-td>
        </q-tr>
      </template>
    </entity-manager>

    <q-dialog v-model="showAllowDialog">
      <q-card v-if="allowItem" style="width: 300px">
        <!-- Заголовок -->
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">{{ allowItem.id ? 'Редактирование' : 'Создание' }} записи</div>

          <q-space/>

          <q-btn icon="close"
                 flat round dense
                 v-close-popup
          ></q-btn>
        </q-card-section>

        <q-card-section>
          <DadataSelect
              v-model="allowItem.tin"
              v-bind="ref.editColumns[0].meta"
              :meta="ref.editColumns[0].meta"
              label="ИНН"
              @onSelect="onInput"
          >
          </DadataSelect>

          <q-input
              label="Наименование"
              v-model="allowItem.title"
          >
          </q-input>

          <q-input
              label="Комментарий"
              v-model="allowItem.description"
              type="textarea"
          >
          </q-input>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn
              flat
              label="OK"
              color="primary"
              @click="sendData"
              :loading="loading"
          ></q-btn>

          <q-btn
              flat
              label="Отмена"
              color="negative"
              v-close-popup
              :disable="loading"
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import DadataSelect from '@/components/fields/DadataSelect';
import EntityManager from '@/components/entity_manager/EntityManager';
import { getData, METHODS } from '@/utils/http';
import { contractors } from '@/structures/refs/contractors';

export default {
  name: 'Contractors',
  components: { EntityManager, DadataSelect },
  props: {
    title: String,
    entity: String,
  },
  data() {
    return {
      showAllowDialog: false,
      fields: [],
      edit: [],
      data: [],
      allowItem: {},
      loading: false,
      tableKey: (new Date()).getTime(),
      pagination: {
        sortBy: 'desc',
        descending: false,
        page: 1,
        rowsPerPage: 10,
        rowsNumber: 0,
      },
      ref: contractors,
    };
  },
  methods: {
    onInput(value) {
      this.allowItem.title = value.data.value;
    },

    sendData() {
      const method = this.allowItem.id ? METHODS.PUT : METHODS.POST;
      this.loading = true;
      const url = '/contractors' + (this.allowItem.id ? '/' + this.allowItem.id : '');

      getData(url, method, {
        params: this.allowItem,
      })
          .then(() => {
            this.tableKey = (new Date()).getTime();
            this.showAllowDialog = false;
            this.allowItem = {};
          })
          .catch(() => {
            this.$q.notify({
              message: 'Не удалось создать запись',
              type: 'negative',
            });
          })
          .finally(() => {
            this.loading = false;
          });
    },

    removeItem(item) {
      if (!item || !item.id) {
        return;
      }

      if (confirm('Уверены, что хотите удалить запись?')) {
        this.loading = true;

        getData('/contractors/' + item.id, METHODS.DELETE)
            .then(() => {
              this.tableKey = (new Date()).getTime();
            })
            .finally(() => {
              this.loading = false;
            });
      }
    },
  },
};
</script>
