<template>
  <q-dialog maximized v-model="show">
    <q-layout view="Lhh lpR fff" container class="bg-white" v-if="show">
      <q-header class="bg-primary">
        <q-toolbar>
          <q-toolbar-title>{{ this.dialogTitle }}</q-toolbar-title>

          <!-- Сохранить -->
          <q-btn
              color="white"
              text-color="black"
              :disable="isLoading"
              :loading="isLoading"
              @click="onSave"
          >
            Сохранить
          </q-btn>

          <!-- Закрыть -->
          <q-btn
              v-close-popup
              color="red"
              class="q-ml-md"
          >
            Закрыть
          </q-btn>
        </q-toolbar>
      </q-header>

      <!-- Основное содержимое -->
      <q-page-container>
        <q-page>
          <q-form ref="orderCreateForm">
            <div class="q-pa-lg">
              <div class="row q-col-gutter-lg">
                <!-- Контрагент -->
                <div class="col-4">
                  <ref-select
                      label="Контрагент"
                      url="/contractors"
                      v-model="orderItem.contractor"
                      :disable="orderItem.status === 'COMPLETED'"
                      :autocomplete="true"
                  ></ref-select>
                </div>

                <!-- Направление -->
                <div class="col-4 row flex-center">
                  <div class="col col-11">
                    <ref-select
                        url="/refs/move_directions"
                        label="Направление"
                        :key="mdKey"
                        v-model="orderItem.move_direction"
                        :disable="orderItem.status === 'COMPLETED'"
                        :autocomplete="true"
                    ></ref-select>
                  </div>

                  <div class="col">
                    <q-btn
                        flat round
                        icon="add"
                        @click="showMDDialog = true"
                        :disable="orderItem.status === 'COMPLETED'"
                    ></q-btn>
                  </div>
                </div>

                <!-- Статус -->
                <div
                    class="col-4"
                    v-if="canEditStatus"
                >
                  <select-enum
                      label="Статус"
                      v-model="orderItem.status"
                      :options="statuses"

                  ></select-enum>
                </div>

                <div class="col-4">
                  <q-checkbox
                      label="Приоритетный заказ"
                      v-model="orderItem.is_priority"
                      :disable="orderItem.status === 'COMPLETED'"
                  >
                  </q-checkbox>
                </div>
              </div>
            </div>

            <!-- Груз -->
            <div class="bg-blue-1 q-pa-lg">
              <h6 class="q-ma-none q-mb-xs">Груз</h6>

              <div class="row q-col-gutter-lg">
                <!-- Наименование груза -->
                <div class="col">
                  <ref-select
                      url="/refs/cargo_names"
                      label="Наименование груза"
                      v-model="orderItem.cargo_name"
                      :disable="orderItem.status === 'COMPLETED'"
                      :autocomplete="true"
                  ></ref-select>
                </div>

                <!-- Тип груза -->
                <div class="col">
                  <ref-select
                      url="/refs/cargo_type"
                      label="Тип груза"
                      v-model="orderItem.cargo_type"
                      :disable="orderItem.status === 'COMPLETED'"
                      :autocomplete="true"
                  ></ref-select>
                </div>

                <!-- Единица измерения груза -->
                <div class="col">
                  <ref-select
                      url="/refs/cargo_unit"
                      label="Единица измерения груза"
                      v-model="orderItem.cargo_unit"
                      :disable="orderItem.status === 'COMPLETED'"
                      :autocomplete="true"
                  ></ref-select>
                </div>
              </div>

              <div class="row q-col-gutter-lg">
                <!--- Норма естественной убыли -->
                <div class="col">
                  <q-input
                      label="Норма естественной убыли"
                      v-model="orderItem.cargo_decrease"
                      :disable="orderItem.status === 'COMPLETED'"
                  ></q-input>
                </div>

                <!-- Количество единиц загрузки на ТС -->
                <div class="col">
                  <q-input
                      label="Количество единиц загрузки на ТС"
                      v-model="orderItem.vehicle_cargo_units_count"
                      :disable="orderItem.status === 'COMPLETED'"
                      type="number"
                  ></q-input>
                </div>

                <!-- Количество -->
                <div class="col">
                  <q-input
                      label="Количество"
                      v-model="orderItem.cargo_units_count"
                      type="number"
                      :disable="orderItem.status === 'COMPLETED'"
                  ></q-input>
                </div>
              </div>
            </div>

            <!-- Расчет -->
            <div class="q-pa-lg ">
              <h6 class="q-ma-none q-mb-xs">Расчет</h6>

              <div class="row q-col-gutter-lg">
                <div class="col" style="padding-top: 35px">
                  <span class="text-primary">Оплата</span><br/>
                  <q-btn-toggle
                      toggle-color="primary"
                      toggle-text-color="white"
                      v-model="typeButton"
                      size="md"
                      :options="[
                      { label: 'За единицу груза', value: 0 },
                      { label: 'За Машину', value: 1 }
                    ]"
                      :disable="orderItem.status === 'COMPLETED'"
                  ></q-btn-toggle>
                </div>

                <div class="col" style="padding-top: 40px">
                  <ref-select
                      disable
                      url="/refs/orders_payments_type"
                      label="Тип оплаты"
                      v-model="orderItem.orders_payments_type"
                  ></ref-select>
                </div>

                <div class="col" style="padding-top: 0px" >
                  <div>
                    <q-checkbox
                        label="Запрос ставки"
                        v-model="orderItem.is_request_price"
                    >
                    </q-checkbox>
                  </div>

                  <div class="row q-col-gutter-lg" v-if="orderItem.is_request_price">
                    <div class="col">
                      <q-input
                          disable
                          class="col"
                          :label="`Цена ${typeButton === 0 ? 'за единицу груза' : 'за машину'}`"
                          v-model="orderItem.price"
                      ></q-input>
                    </div>

                    <div class="col">
                      <q-input
                          disable
                          class="col"
                          label="Оплата простоя, руб/день"
                          v-model.number="orderItem.immobility_price"
                      ></q-input>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Погрузка/выгрузка -->
            <div class="q-pa-lg bg-blue-1">
              <div class="row q-col-gutter-lg">
                <div class="col">
                  <h6 class="q-ma-none q-mb-xs">Погрузка</h6>
                  <!-- Начало -->
                  <div class="col">
                    <q-input
                        hide-hint
                        mask="##.##.####"
                        label="Начало"
                        v-model="dateStart"
                        :disable="orderItem.status === 'COMPLETED'"
                    >
                      <template #append>
                        <q-icon name="event" class="cursor-pointer">
                          <q-popup-proxy
                              ref="qDateProxy"
                              transition-show="scale"
                              transition-hide="scale"
                          >
                            <q-date v-model="dateStart" mask="DD.MM.YYYY" @input="reLoadingDays">
                              <div class="row items-center justify-end">
                                <q-btn
                                    v-close-popup flat
                                    label="Close"
                                    color="primary"
                                ></q-btn>
                              </div>
                            </q-date>
                          </q-popup-proxy>
                        </q-icon>
                      </template>
                    </q-input>
                  </div>

                  <!-- Окончание -->
                  <div class="col">
                    <q-input
                        mask="##.##.####"
                        label="Окончание"
                        v-model="dateEnd"
                        @input="reLoadingDays"
                        :disable="orderItem.status === 'COMPLETED'"
                    >
                      <template #append>
                        <q-icon name="event" class="cursor-pointer">
                          <q-popup-proxy
                              ref="qDateProxy"
                              transition-show="scale"
                              transition-hide="scale"
                          >
                            <q-date v-model="dateEnd" mask="DD.MM.YYYY" @input="reLoadingDays">
                              <div class="row items-center justify-end">
                                <q-btn
                                    v-close-popup
                                    label="Close"
                                    color="primary"
                                    flat
                                ></q-btn>
                              </div>
                            </q-date>
                          </q-popup-proxy>
                        </q-icon>
                      </template>
                    </q-input>
                  </div>

                  <!-- Дней -->
                  <div class="col">
                    <q-input
                        label="Дней"
                        type="number"
                        v-model="orderItem.loading_days"
                        :disable="orderItem.status === 'COMPLETED'"
                    ></q-input>
                  </div>
                </div>

                <div class="col">
                  <h6 class="q-ma-none q-mb-xs">Выгрузка</h6>
                  <!-- Начало -->
                  <div class="col">
                    <q-input
                        mask="##.##.####"
                        label="Начало"
                        v-model="dateEndStart"
                        :disable="orderItem.status === 'COMPLETED'"
                    >
                      <template #append>
                        <q-icon name="event" class="cursor-pointer">
                          <q-popup-proxy
                              ref="qDateProxy"
                              transition-show="scale"
                              transition-hide="scale"
                          >
                            <q-date v-model="dateEndStart" mask="DD.MM.YYYY" @input="reUnLoadingDays">
                              <div class="row items-center justify-end">
                                <q-btn
                                    v-close-popup
                                    label="Close"
                                    color="primary"
                                    flat
                                ></q-btn>
                              </div>
                            </q-date>
                          </q-popup-proxy>
                        </q-icon>
                      </template>
                    </q-input>
                  </div>

                  <!-- Окончание -->
                  <div class="col">
                    <q-input
                        mask="##.##.####"
                        label="Окончание"
                        v-model="dateEndEnd"
                        :disable="orderItem.status === 'COMPLETED'"
                    >
                      <template #append>
                        <q-icon name="event" class="cursor-pointer">
                          <q-popup-proxy
                              ref="qDateProxy"
                              transition-show="scale"
                              transition-hide="scale"
                          >
                            <q-date v-model="dateEndEnd" mask="DD.MM.YYYY" @input="reUnLoadingDays">
                              <div class="row items-center justify-end">
                                <q-btn
                                    v-close-popup flat
                                    label="Close"
                                    color="primary"
                                ></q-btn>
                              </div>
                            </q-date>
                          </q-popup-proxy>
                        </q-icon>
                      </template>
                    </q-input>
                  </div>

                  <!-- Дней -->
                  <div class="col">
                    <q-input
                        label="Дней"
                        type="number"
                        v-model="orderItem.unloading_days"
                        :disable="orderItem.status === 'COMPLETED'"
                    ></q-input>
                  </div>
                </div>

                <div class="col">
                  <q-input
                      label="Комментарий"
                      type="text"
                      v-model="orderItem.comment"
                      :disable="orderItem.status === 'COMPLETED'"
                  ></q-input>
                </div>
              </div>
            </div>

            <!-- Транспорт -->
            <div class="q-pa-lg">
              <h6 class="q-ma-none q-mb-xs">
                Транспорт
              </h6>

              <div class="row q-col-gutter-lg">
                <div class="col">
                  <ref-select
                      url="/refs/vehicle_type"
                      label="Тип транспорта"
                      option-label="title"
                      multiple
                      v-model="orderItem.vehicle_types"
                      :disable="orderItem.status === 'COMPLETED'"
                      :autocomplete="true"
                  ></ref-select>
                </div>

                <div class="col">
                  <ref-select
                      label="Тип загрузки"
                      option-label="title"
                      multiple
                      url="/refs/vehicle_loading_type"
                      v-model="orderItem.vehicles_loading_types"
                      :disable="orderItem.status === 'COMPLETED'"
                      :autocomplete="true"
                  ></ref-select>
                </div>

                <div class="col">
                  <ref-select
                      label="Тип выгрузки"
                      multiple
                      url="/refs/vehicle_unloading_type"
                      v-model="orderItem.vehicles_unloading_types"
                      :disable="orderItem.status === 'COMPLETED'"
                  ></ref-select>
                </div>

                <div class="col">
                  <ref-select
                      label="Способ погрузки"
                      url="/refs/method_of_loading"
                      v-model="orderItem.method_of_loading"
                      :disable="orderItem.status === 'COMPLETED'"
                      :autocomplete="true"
                  ></ref-select>
                </div>

              </div>
            </div>
          </q-form>
        </q-page>
      </q-page-container>
    </q-layout>

    <move-direction-dialog
        v-if="showMDDialog"
        v-model="showMDDialog"
        @on-route-created="onRouteCreated"
    ></move-direction-dialog>
  </q-dialog>
</template>

<script>
import RefSelect from '@/components/fields/RefSelect';
import SelectEnum from '@/components/fields/SelectEnum';

import {getData, METHODS} from '@/utils/http';
import MoveDirectionDialog from '@/modules/orders/components/MoveDirectionDialog';

export default {
  name: 'OrderCreate',
  components: {MoveDirectionDialog, SelectEnum, RefSelect},
  props: {
    // Показ диалога
    value: {
      type: Boolean,
      required: true,
      default: false,
    },
    // Объект заказа
    order: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    const order = _copy(this.order);
    const orderNull = order == null || (typeof order === 'object' && Object.keys(order).length === 0);

    const curDate = new Date();
    const day = _setZero(curDate.getDate());
    const month = _setZero(curDate.getMonth() + 1);
    const year = curDate.getFullYear();
    const currentDate = `${day}/${month}/${year}`;
    order.is_request_price = this.order.is_request_price || false;

    let loading_end_date = currentDate;
    let unloading_end_date = currentDate;

    if (!orderNull) {
      if (!order.loading_end_date) {
        loading_end_date = order.loading_start_date;
      } else {
        loading_end_date = order.loading_end_date;
      }

      if (!order.unloading_end_date) {
        unloading_end_date = order.unloading_start_date;
      } else {
        unloading_end_date = order.unloading_end_date;
      }

      loading_end_date = _splitDataAndFormat(loading_end_date);
      unloading_end_date = _splitDataAndFormat(unloading_end_date);
    }

    return {
      tab: 'info',
      dialogTitle: orderNull ? 'Новый заказ' : 'Редактирование заказа',
      orderItem: order,
      dateStart: orderNull ? currentDate : _splitDataAndFormat(order.loading_start_date),
      dateEnd: loading_end_date,
      dateEndStart: orderNull ? currentDate : _splitDataAndFormat(order.unloading_start_date),
      dateEndEnd: unloading_end_date,
      typeButton: 0,
      isLoading: false,
      canEditStatus: true,
      statuses: [
        {
          label: 'Открыто на прием заявок',
          value: 'OPENED_BIDS',
        },
        {
          label: 'Закрыт прием заявок',
          value: 'CLOSED_BIDS',
        },
        {
          label: 'Заказ завершен',
          value: 'COMPLETED',
        },
      ],
      moveDirectionOptions: [],
      showMDDialog: false,
      mdKey: (new Date()).getTime(),
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  watch: {
    order(val) {
      this.orderItem = _copy(val);
      this.typeButton = this.orderItem.price_type || 0;

      if (this.order && this.order.status) {
        // this.canEditStatus = this.order?.status !== 'ON_MODERATE' && this.order?.status !== 'COMPLETED';
      }
    },
  },
  methods: {
    reLoadingDays() {
      this.orderItem.loading_days = this.getDateDiff(this.dateStart, this.dateEnd);
    },

    reUnLoadingDays() {
      this.orderItem.unloading_days = this.getDateDiff(this.dateEndStart, this.dateEndEnd);
    },

    // Когда маршрут создан
    onRouteCreated(route) {
      this.mdKey = (new Date()).getTime();
      this.orderItem.move_direction = _copy(route);
    },

    // Вычисление разницы между двумя датами
    getDateDiff(dateStart, dateEnd) {
      const date1 = this.getDateFromStr(dateStart);
      const date2 = this.getDateFromStr(dateEnd);

      return Math.ceil((date2.getTime() - date1.getTime()) / (1000 * 3600 * 24)) + 1;
    },

    // Вычисление даты из строки
    getDateFromStr(dateStr) {
      const dateArr = dateStr.replaceAll('/', '.').split('.');

      return new Date(dateArr[2], dateArr[1], dateArr[0]);
    },

    // Возвращает дату в формате для отправки на бэк
    getDateToAPiFormat(dateStr) {
      const dateArr = dateStr.replaceAll('/', '.').split('.');
      return dateArr.reverse().join('-');
    },

    // Метод отправки данных о заказе
    sendData(url, method, data, text) {
      getData(url, method, {params: data})
          .then(() => {
            this.$q.notify({
              color: 'primary',
              message: text,
            });

            // Скрываем диалог
            this.show = false;

            this.$emit('onRefresh');
          })
          .catch((error) => {
            this.$q.notify({
              color: 'red',
              message: error.error || 'Невозможно сохранить заказ',
            });
          })
          .finally(() => {
            this.isLoading = false;
          });
    },

    // При нажатии на "Сохранить"
    onSave() {
      this.$refs.orderCreateForm.validate().then((ok) => {
            if (ok) {
              this.isLoading = true;
              let order = this.orderItem;
              let cargo_units_count_left;

              if (order.id) {
                if (order.cargo_units_count !== this.order.cargo_units_count) {
                  let cargo_units_count_diff = Math.max(this.order.cargo_units_count
                      - this.order.cargo_units_count_left, 0);
                  cargo_units_count_left = Math.max(order.cargo_units_count - cargo_units_count_diff, 0);
                } else {
                  cargo_units_count_left = Math.min(order.cargo_units_count_left, order.cargo_units_count);
                }
              } else {
                cargo_units_count_left = order.cargo_units_count;
              }

              const data = {
                contractors_id: order.contractor?.id || null,
                move_directions_id: order.move_direction?.id || null,
                is_priority: order.is_priority,
                cargo_title: order.cargo_title,
                cargo_name: order.cargo_name,
                cargo_units_count: order.cargo_units_count,
                cargo_units_count_left: cargo_units_count_left,
                cargo_types_id: order.cargo_type?.id || null,
                cargo_units_id: order.cargo_unit?.id || null,
                cargo_decrease: order.cargo_decrease,
                vehicle_cargo_units_count: order.vehicle_cargo_units_count,
                loading_start_date: this.getDateToAPiFormat(this.dateStart),
                loading_end_date: this.getDateToAPiFormat(this.dateEnd),
                loading_days: order.loading_days,
                unloading_start_date: this.getDateToAPiFormat(this.dateEndStart),
                unloading_end_date: this.getDateToAPiFormat(this.dateEndEnd),
                unloading_days: order.unloading_days,
                vehicle_types: order.vehicle_types || null,
                vehicles_loading_types: order.vehicles_loading_types || null,
                vehicles_unloading_types: order.vehicles_unloading_types || null,
                method_of_loading: order.method_of_loading || null,
                comment: order.comment,
                users_id: this.$store.getters['auth/getProfile'].id,
                is_request_price: order.is_request_price,
                price_type: this.typeButton,
                status: order.status,
              };

              if (order.id != null) {
                this.sendData(`/orders/${order.id}`, METHODS.PUT, data, 'Заказ успешно обновлен!');
              } else {
                this.sendData('/orders', METHODS.POST, data, 'Заказ успешно создан!');
              }
            } else {
              this.$q.notify({
                message: 'Некоторые обязательные поля не были заполнены!',
                color: 'red',
              });
            }
          },
      );
    },
  },
};
</script>

<style scoped>
</style>
