// Vue
import Vue from 'vue';

// Клонирование объекта
window._copy = (data) => {
  return JSON.parse(JSON.stringify(data));
};

// Проверка что объект пустой
window._emptyObject = (object) => {
  return Object.keys(object).length === 0;
};

// Проверка что свойство есть в объекте
window._hasOwnProperty = (object, propertyName) => {
  return Object.prototype.hasOwnProperty.call(object, propertyName);
};

window._log = (...args) => {
  // eslint-disable-next-line no-console
  console.log.apply(window, args);
};

window._warn = (...args) => {
  // eslint-disable-next-line no-console
  console.warn.apply(window, args);
};

window._err = (...args) => {
  // eslint-disable-next-line no-console
  console.error.apply(window, args);
};

window._setZero = (args) => {
  return args > 9 ? args : '0' + args;
};

window._splitDataAndFormat = (dateString) => {
  const date = (new Date(dateString.split('T')[0]));

  const day = _setZero(date.getDate());
  const month = _setZero(date.getMonth() + 1);
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

window._priceFormat = (number, dec) => {
  const num = number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace(/,/g, ' ');

  if (!dec) {
    return num.split('.')[0];
  }

  return num;
};

window._roundHundredths = (number) => {
  return parseInt(number * 100, 10) / 100;
};

// Добавляем глобальный миксин
Vue.mixin({
  methods: {
    // Проверка что объект не пустой
    _emptyObject: (object) => window._emptyObject(object),
    // Формат даты
    _splitDataAndFormat: (dateString) => window._splitDataAndFormat(dateString),
    // eslint-disable-next-line no-console
    _log: (...args) => console.log.apply(window, args),
    _priceFormat: window._priceFormat,
    _copy: (data) => window._copy(data),
  },
});

// Фильтр обрезает длинные строки текста
Vue.filter('truncate', (text, length, suffix) => {
  suffix = suffix || '...';

  return text.length > length
    ? text.substring(0, length) + suffix
    : text;
});
