const orders_moderations = {
    name: 'orders_moderate',
    title: 'Заказы',
    url: '/orders_moderate',
    include: [],
    filters: [{
        by: 'status',
        value: 'ON_MODERATE',
    }],
    perms: {
        list: 'v1.orders_moderate.list',
        read: 'v1.orders_moderate.read',
        update: 'v1.orders_moderate.update',
    },
    listColumns: [
        {
            name: 'number',
            required: true,
            label: 'Номер заказа',
            align: 'left',
            field: 'number',
            sortable: true,
        },
        {
            name: 'move_direction',
            required: true,
            label: 'Маршрут',
            align: 'left',
            field: 'move_direction',
            map: 'title',
            sortable: true,
        },
        {
            name: 'cargo_name',
            required: true,
            label: 'Наименование груза',
            align: 'left',
            field: 'cargo_name',
            sortable: true,
            map: 'title',
        },
        {
            name: 'distance',
            required: true,
            label: 'Расстояние',
            align: 'left',
            field: (row) => {
                if (row.move_direction) {
                    return row.move_direction.distance + 'км';
                }

                return '';
            },
            sortable: true,
        },
        {
            name: 'cargo_value',
            required: true,
            label: 'Кол-во изначальное',
            align: 'left',
            field: 'cargo_units_count',
            sortable: true,
        },
        {
            name: 'cargo_units_count_left',
            required: true,
            label: 'Остаток ед. груза',
            align: 'left',
            field: 'cargo_units_count_left',
            sortable: true,
        },
        {
            name: 'cargo_unit',
            required: true,
            label: 'Единица измерения груза',
            align: 'left',
            field: 'cargo_unit',
            sortable: true,
            map: 'title',
        },
        {
            name: 'price',
            required: true,
            label: 'Стоимость',
            align: 'left',
            field: (row) => row.is_request_price ? 'Запрос ставки' : row.price,
            sortable: true,
        },
        {
            name: 'price_vat',
            required: true,
            label: 'Стоимость с НДС',
            align: 'price_vat',
            field: 'price_vat',
            sortable: true,
        },
        // {
        //     name: 'price_type',
        //     required: true,
        //     label: 'Оплата за',
        //     align: 'left',
        //     field: 'price_type',
        //     sortable: true,
        //     type: 'enum',
        //     meta: {
        //         options: [
        //             {
        //                 label: 'Единица груза',
        //                 value: '0',
        //             },
        //             {
        //                 label: 'Транспорт',
        //                 value: '1',
        //             },
        //         ],
        //     },
        // },
        {
            name: 'comment',
            required: true,
            label: 'Комментарий',
            align: 'left',
            field: 'comment',
            sortable: true,
        },
        // {
        //     name: 'vehicle_types',
        //     required: true,
        //     label: 'Тип тс',
        //     align: 'left',
        //     field: 'vehicle_types',
        //     sortable: true,
        //     map: 'title',
        //     type: 'multiple',
        // },
    ],
    editColumns: [
        {
            name: 'cargo_value',
            required: true,
            label: 'Объем груза',
            align: 'left',
            field: 'cargo_value',
            sortable: true,
        },
        {
            name: 'cargo_units_count',
            required: true,
            label: 'Кол-во ед. груза',
            align: 'left',
            field: 'cargo_units_count',
            sortable: true,
        },
        {
            name: 'cargo_units_count_left',
            required: true,
            label: 'Остаток ед. груза',
            align: 'left',
            field: 'cargo_units_count_left',
            sortable: true,
        },
        {
            name: 'price',
            required: true,
            label: 'Стоимость',
            align: 'left',
            field: 'price',
            sortable: true,
        },
        {
            name: 'immobility_price',
            required: true,
            label: 'Стоимость простоя',
            align: 'left',
            field: 'immobility_price',
            sortable: true,
        },
        {
            name: 'price_type',
            required: true,
            label: 'Стоимость',
            align: 'left',
            field: 'price_type',
            sortable: true,
        },
        {
            name: 'status',
            required: true,
            label: 'Статус',
            align: 'left',
            field: 'status',
            sortable: true,
            type: 'enum',
            options: [
                {
                    label: 'На модерации',
                    value: 'ON_MODERATE',
                },
                {
                    label: 'Закрыт прием заявок',
                    value: 'CLOSED_BIDS',
                },
                {
                    label: 'Открыто на прием заявок',
                    value: 'OPENED_BIDS',
                },
                {
                    label: 'Заказ завершен',
                    value: 'COMPLETED',
                },
            ],
        },
        {
            name: 'contractor',
            required: true,
            label: 'Контрагент',
            align: 'left',
            field: 'contractor',
            sortable: true,
            type: 'reference',
            meta: {
                url: '/contractors',
                optionLabel: 'title',
            },
        },
        {
            name: 'orders_payments_type',
            required: true,
            label: 'Тип оплаты заказа',
            align: 'left',
            field: 'orders_payments_type',
            sortable: true,
            type: 'reference',
            meta: {
                url: '/refs/orders_payments_type',
                optionLabel: 'title',
            },
        },
    ],
};

export {
    orders_moderations,
};
