const users_ref = {
    name: 'users',
    title: 'Пользователи',
    url: '/rbac/users',
    include: ['roles', 'organization'],
    filterBy: ['full_name'],
    perms: {
        list: 'v1.users.list',
        read: 'v1.users.read',
        create: 'v1.users.create',
        update: 'v1.users.update',
        delete: 'v1.users.delete',
    },
    listColumns: [
        {
            name: 'full_name',
            required: true,
            label: 'Имя',
            align: 'left',
            field: 'full_name',
            sortable: true,
        },
        {
            name: 'organization',
            required: true,
            label: 'Организация',
            align: 'left',
            field: 'organization',
            map: 'title',
        },
        {
            name: 'email',
            required: true,
            label: 'Email',
            align: 'left',
            field: 'email',
            sortable: true,
        },
        {
            name: 'phone',
            required: true,
            label: 'Номер телефона',
            align: 'left',
            field: 'phone',
            sortable: true,
        },
        {
            name: 'roles',
            required: true,
            label: 'Роли',
            align: 'left',
            field: 'roles',
            map: 'title',
            type: 'multiple',
        },
    ],
    listEdit: [
        {
            name: 'full_name',
            required: true,
            label: 'Имя',
            align: 'left',
            field: 'full_name',
            sortable: true,
        },
        {
            name: 'email',
            required: true,
            label: 'Email',
            align: 'left',
            field: 'email',
            sortable: true,
        },
        {
            name: 'phone',
            required: true,
            label: 'Номер телефона',
            align: 'left',
            field: 'phone',
            sortable: true,
        },
        {
            name: 'password',
            required: true,
            label: 'Новый пароль',
            align: 'left',
            field: 'password',
            sortable: true,
            type: 'password',
        },
        {
            name: 'roles',
            required: true,
            label: 'Роли',
            align: 'left',
            field: 'roles',
            sortable: true,
            type: 'reference',
            meta: {
                url: '/rbac/roles',
                optionLabel: 'title',
                multiple: true,
            },
        },
        {
            name: 'organization',
            required: false,
            label: 'Организация',
            align: 'left',
            field: 'organization',
            type: 'reference',
            meta: {
                url: '/carriers',
                optionLabel: 'title',
                autocomplete: true,
            },
        },
    ],
};

export {
    users_ref,
};
