<template>
  <div :class="{
    row: vertical,
    column: !vertical,
  }">
    <div :class="{
      'col-auto': vertical,
      'col-12': !vertical,
       widthFull: !vertical,
    }">
      <!-- Навигация -->
      <q-tabs class="text-primary bg-white"
              :class="{width300: vertical}"
              :vertical="vertical"
              inline-label align="left"
              active-bg-color="green-1"
      >
        <template v-if="!isLoadingMenu">
          <q-route-tab v-for="menu of twoLvlMenuItems" :key="menu.to"
                       style="justify-content:initial"
                       :to="menu.path"
                       :label="menu.title"/>
        </template>

        <!-- Лоадер -->
        <template v-else-if="nestedLvl === 1">
          <q-skeleton v-for="i in 5" :key="i"
                      type="rect"
                      animation="pulse-x"
                      class="q-ma-md"
                      style="width: 250px; margin-top: 24px"
          />
        </template>
      </q-tabs>
    </div>
    <div class="col">
      <div class="row no-wrap items-center q-mb-md" style="max-width: 100%; align-items: center">
        <RefSelect label="Контрагент"
                   v-model="contractor"
                   url="/contractors"
                   class="q-mx-sm"
                   autocomplete clearable
        ></RefSelect>

        <RefSelect label="Юр. лицо"
                   v-model="legalEntity"
                   url="/refs/legal_entity"
                   class="q-mx-sm"
                   autocomplete clearable
        ></RefSelect>

        <RangeDates :startDate.sync="startDate"
                    :endDate.sync="endDate"
                    startDateClearable
                    endDateClearable
        ></RangeDates>

        <RefSelect label="Направление движения"
                   v-model="moveDirection"
                   url="/refs/move_directions"
                   class="q-mx-sm"
                   autocomplete clearable
        ></RefSelect>

        <div class="col-auto q-ml-sm">
          <q-btn @click="loadData"
                 label="Показать"
                 color="primary"
                 :disable="loading"
                 class=""
          ></q-btn>
        </div>
      </div>
      <router-view v-show="!isLoadingMenu" @onRefresh="loadData" :key="$route.path" :dataArr="dataArr"/>
    </div>
  </div>
</template>

<script>
import { getData, METHODS } from '@/utils/http';
import {mapGetters} from 'vuex';
import RangeDates from '../../components/fields/date/RangeDates';
import RefSelect from '../../components/fields/RefSelect';

export default {
  name: 'OrganisationOrdersNestedMenu',
  components: {
    RangeDates,
    RefSelect,
  },
  props: {
    nestedLvl: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      refreshKey: (new Date()).getTime(),
      loading: false,
      data: [],
      dataArr: this.data,
      contractor: null,
      cashedFilters: null,
      legalEntity: null,
      moveDirection: null,
      startDate: null,
      endDate: null,
    };
  },
  computed: {
    ...mapGetters({
      isLoadingMenu: 'menu/isLoading',
    }),
    twoLvlMenuProps() {
      return this.$store.getters['menu/getNestedLvlMenu'](this.nestedLvl, false);
    },
    twoLvlMenuItems() {
      return this.$store.getters['menu/getNestedLvlMenu'](this.nestedLvl, true);
    },
    vertical() {
      return this.twoLvlMenuProps == null ? true : this.twoLvlMenuProps.type === 'vertical';
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true;
      const profile = this.$store.getters['auth/getProfile'];

      getData('/organizations_order/filtered?include=order&perPage=1000', METHODS.POST, {
        params: {
          filters: {
            type: 'and',
            values: [
              {
                type: 'eq',
                field: 'organizations_id',
                values: [profile.organization_id],
              },
              {
                type: 'like',
                field: 'status',
                values: [this.$route.params.status],
              },
              ...(this.generateFilter()),
            ],
          },
        },
      })
          .then((res) => {
            this.data = res.result.items;
            this.dataArr = _copy(this.data);
          })
          .catch((res) => {
            this.$q.notify({
              message: res.error,
              type: 'negative',
            });
          })
          .finally(() => {
            this.loading = false;
          });
    },

    generateFilter() {
      const filters = [];

      if (this.contractor || this.moveDirection || this.startDate || this.endDate) {
        filters.push({
          type: 'inside',
          field: 'order',
          values: [],
        });

        const values = filters[0].values;

        if (this.contractor) {
          values.push({
            type: 'eq',
            field: 'contractors_id',
            values: [this.contractor.id],
          });
        }

        if (this.moveDirection) {
          values.push({
            type: 'eq',
            field: 'move_directions_id',
            values: [this.moveDirection.id],
          });
        }

        if (this.startDate) {
          values.push(
              {
                type: 'gte',
                field: 'loading_start_date',
                values: [this.startDate],
              },
              {
                type: 'gte',
                field: 'unloading_start_date',
                values: [this.startDate],
              },
          );
        }

        if (this.endDate) {
          values.push(
              {
                type: 'lte',
                field: 'unloading_start_date',
                values: [this.endDate],
              },
              {
                type: 'lte',
                field: 'loading_start_date',
                values: [this.endDate],
              },
          );
        }
      }

      if (this.legalEntity) {
        filters.push(
            {
              type: 'eq',
              field: 'legal_entity_id',
              values: [this.legalEntity.id],
            },
        );
      }
      this.cashedFilters = filters;
      return filters;
    },
  },
};
</script>

<style scoped>
.width300 {
  width: 300px;
}

.widthFull {
  max-width: 100%;
}
</style>

