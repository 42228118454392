<template>
  <div>
    <div class="q-gutter-sm">
      <q-radio v-model="periodType" val="month" label="Месяц"/>
      <q-radio v-model="periodType" val="range" label="Период"/>
    </div>

    <div v-if="periodType === 'range'"
         class="row items-center q-col-gutter-md q-py-md"
         style="max-width: 600px;"
    >
      <RangeDates class="col-8"
                  :startDate.sync="tripDateStart"
                  :endDate.sync="tripDateEnd"
      ></RangeDates>

      <div class="col-4">
        <q-btn class="q-mb-md"
               label="Показать отчет"
               @click="loadData"
               :loading="loading"
        ></q-btn>
      </div>
    </div>

    <MonthPicker v-else
                 :startDate.sync="tripDateStart"
                 :endDate.sync="tripDateEnd"
                 @change="loadData"
    ></MonthPicker>

    <q-table row-key="name"
             :data="data"
             :columns="columns"
             :loading="loading"
             :pagination.sync="paginationData"
             @request="onRequest"
             binary-state-sort
    > 
    </q-table>
  </div>
</template>

<script>
import { getData, METHODS } from '@/utils/http';
import RangeDates from '@/components/fields/date/RangeDates';
import MonthPicker from '@/components/fields/date/MonthPicker';
import { FORMAT_FOR_USER, formatDate } from '@/utils/date';

export default {
  name: 'ReportByOrders',
  components: { MonthPicker, RangeDates },
  data() {
    return {
      tripDateStart: null,
      tripDateEnd: null,
      loading: false,
      periodType: 'month',
      paginationData: {
        descending: false,
        page: 1,
        rowsPerPage: 3,
        rowsNumber: 10,
        sortBy: '',
      },
      filters: '',
      data: [],
      columns: [
        {
          name: 'index',
          label: 'П/п',
          align: 'left',
          field: 'index',
        },
        {
          name: 'date',
          label: 'Дата рейса',
          align: 'left',
          field: (row) => formatDate(row.date, FORMAT_FOR_USER.ONLY_DATE),
          sortable: true,
        },
        {
          name: 'driver',
          label: 'Водитель',
          align: 'left',
          field: 'driver',
          sortable: true,
        },
        {
          name: 'vehicle',
          label: 'Транспорт',
          align: 'left',
          field: 'reg_number',
        },
        {
          name: 'route_name',
          label: 'Маршрут рейса',
          align: 'left',
          field: 'route_name',
          sortable: true,
        },
        {
          name: 'orderDistance',
          label: 'Расстояние рейса',
          align: 'left',
          field: 'distance',
        },
        {
          name: 'valCost',
          label: 'Вал рублей за выполнение рейса',
          align: 'left',
          field: (row) => Number(row.sum).toFixed(2),
        },
        {
          name: 'tonPerKm',
          label: 'Тоннокилометры',
          align: 'left',
          field: (row) => Number(row.tonn_km).toFixed(2),
        },
        {
          name: 'loaded',
          label: 'Загружено',
          align: 'left',
          field: 'loaded',
        },
        {
          name: 'unloaded',
          label: 'Выгружено',
          align: 'left',
          field: 'unloaded',
        },
      ],
    };
  },
  watch: {
    periodType() {
      this.loadData();
    },
  },
  methods: {
    onRequest(props, paginationData) {
      this.paginationData = props.pagination;
      let filters = this.filters || [];

      if (this.filterBy) {
        this.filterBy.forEach((it) => {
          if (props.filter) {
            filters.push({
              by: it,
              value: props.filter,
            });
          }
        });
      }

      this.loadData(paginationData);
    },

    loadData(pagination) {
      let filterRules = pagination ?? this.paginationData;
      const symbol = filterRules.descending ? '' : '-';
      const sorted = filterRules.sortBy  ? symbol + filterRules.sortBy : '';

      if (this.tripDateStart == null || this.tripDateEnd == null) {
        this.$q.notify({
          color: 'warning',
          message: 'Заполните поле с датой',
        });
        return;
      }
      this.loading = true;

      getData(`/reports/by/orders?start_date=${this.tripDateStart}&end_date=${this.tripDateEnd}&ordered=${sorted}`,
       METHODS.GET)  
          .then((res) => {
            this.data = res.result.map((it, i) => Object.assign(it, { index: i + 1 }));
          })
          .catch((res) => {
            this.$q.notify({
              message: res.error,
              type: 'negative',
            });
          })
          .finally(() => {
            this.loading = false;
          });
    },
  },
};
</script>
