<template>
  <!--Справочник-->
  <div v-if="type === 'reference'">
    <ref-select
      v-model="model"
      v-bind="meta"
      :label="label"
    ></ref-select>
  </div>

  <!-- Таблица для управления сущностью -->
  <div v-else-if="type === 'entity'">
    <entity-manager
      :title="label"
      v-bind="meta"
    ></entity-manager>
  </div>

  <!-- Поиск по ИНН в dadata -->
  <div v-else-if="type === 'dadata'">
    <dadata-select
      v-model="model"
      v-bind="meta"
      :meta="meta"
      :label="label"
    ></dadata-select>
  </div>

  <!-- Список -->
  <div v-else-if="type === 'enum'">
    <select-enum
      v-model="model"
      v-bind="meta"
      :label="label"
      :rules="reactiveRules"
    ></select-enum>
  </div>

  <!-- CheckBox -->
  <div v-else-if="type === 'checkbox'">
    <q-checkbox
      v-model="model"
      v-bind="meta"
      :label="label"
    ></q-checkbox>
  </div>

  <!-- Дата -->
  <div v-else-if="type === 'date'">
    <date-field
        v-model="model"
        :label="label"
    ></date-field>
  </div>

  <!-- Стандартные поля ввода -->
  <div v-else-if="[
    'text', 'password', 'textarea', 'email',
    'search', 'tel', 'file', 'number', 'url',
    'time'
    ].indexOf(type) !== -1"
  >
    <q-input
      v-model="model"
      v-bind="meta"
      :type="type"
      :label="label"
      :rules="reactiveRules"
    ></q-input>
  </div>

  <!-- Любой другой тип-->
  <div v-else>
    {ERROR: Неизвестный тип поля}
  </div>

</template>

<script>
import DateField from '@/components/fields/date/DateField';
import RefSelect from '@/components/fields/RefSelect';
import EntityManager from '@/components/entity_manager/EntityManager';
import DadataSelect from '@/components/fields/DadataSelect';
import SelectEnum from '@/components/fields/SelectEnum';
import {getDateToAPiFormat} from '@/utils/date';

export default {
  name: 'FieldGenerator',
  components: { SelectEnum, DadataSelect, EntityManager, RefSelect, DateField },
  props: {
    label: {
      type: String,
      default: 'Поле',
    },
    type: {
      type: String,
      default: 'text',
    },
    meta: {
      type: Object,
      default: () => ({}),
    },
    rules: {
      type: Array,
      default: null,
    },
    value: null,
  },
  data: () => ({
    model: null,
  }),
  computed: {
    reactiveRules() {
      return this.rules;
    },
  },
  watch: {
    'model': function(value) {
      if (this.type === 'date') {
        this.$emit('input', getDateToAPiFormat(value || '') || null);
      } else {
        this.$emit('input', value);
      }
    },
  },
  beforeMount() {
    this.model = this.value;

    if (this.meta && this.meta.prepareFrom) {
      this.model = this.meta.prepareFrom(this.model);
    }
  },
};
</script>
