<template>
  <div class="q-pa-sm full-height text-caption">
    <div class="flex text-bold">
      <span>
      {{ getCalendarDate() }}
      </span>

      <q-space/>

      <span
          v-if="dayData && dayData.orders && dayData.orders.length"
          style="padding: 0 !important; min-height: 24px;"
      >
        На рейсе
      </span>

      <q-icon
          :color="getCellColor()"
          name="warning"
      ></q-icon>
    </div>

    <q-list dense>
      <q-item style="padding: 0 !important; min-height: 24px;">
        <q-item-label>
          <template v-if="dayData && dayData.trailers && dayData.trailers.length">
            <div class="flex items-center no-wrap">
              {{ dayData.trailers[0].reg_number }}

              <q-chip v-if="dayData.trailers.length > 1">
                +{{ dayData.trailers.length - 1 }}
              </q-chip>

              <q-btn size="xs"
                     round flat
                     class="q-ml-xs q-pa-none"
                     style="margin: 0 -2px"
                     @click="detachTrailer(dayData.trailers[0])"
                     color="red"
                     icon="close"
              >
                <q-tooltip>Удалить</q-tooltip>
              </q-btn>
            </div>
          </template>

          <a
              v-else
              href="#"
              @click.prevent.stop="trailersDialog = true"
              class="text-caption"
          >
            <q-icon name="mdi-plus"></q-icon>
            Прицеп
          </a>
        </q-item-label>
      </q-item>

      <q-item style="padding: 0 !important; min-height: 24px;">
        <q-item-label>
          <template v-if="dayData && dayData.drivers && dayData.drivers.length">
            <div>
              <div
                  v-for="(driver, index) in dayData.drivers"
                  :key="`driver_${driver.id}`"
                  class="flex items-center no-wrap"
              >
                <div v-if="index === 0">
                  {{ driver.user.full_name }}

                  <q-btn size="xs"
                         round flat
                         class="q-ml-xs q-pa-none"
                         style="margin: 0 -2px"
                         @click="detachDriver(driver)"
                         color="red"
                         icon="close"
                  >
                    <q-tooltip>Изменить</q-tooltip>
                  </q-btn>

                  <span @click="driversOpen = !driversOpen">
                    <q-chip
                        dense
                        v-if="dayData.drivers.length > 1"
                    >
                      +{{ dayData.drivers.length - 1 }}
                    </q-chip>
                  </span>
                </div>

                <div v-else-if="driversOpen">
                  {{ driver.user.full_name }}

                  <q-btn size="xs"
                         round flat
                         class="q-ml-xs q-pa-none"
                         style="margin: 0 -2px"
                         @click="detachDriver(driver)"
                         color="red"
                         icon="close"
                  >
                    <q-tooltip>Изменить</q-tooltip>
                  </q-btn>
                </div>
              </div>
            </div>

            <a
                v-if="dayData.drivers.length < 2"
                href="#"
                @click.prevent.stop="driversDialog = true"
                class="text-caption"
            >
              <q-icon name="mdi-plus"></q-icon>
              Водитель
            </a>
          </template>

          <a
              v-else
              href="#"
              @click.prevent.stop="driversDialog = true"
              class="text-caption"
          >
            <q-icon name="mdi-plus"></q-icon>
            Водитель
          </a>
        </q-item-label>
      </q-item>

      <q-item style="padding: 0 !important; min-height: 24px;">
        <q-item-label>
          <template v-if="dayData && dayData.status && dayData.status.length">
            <div class="flex items-center no-wrap">
              {{ dayData.status[0].title }}

              <q-btn size="xs"
                     round flat
                     class="q-ml-xs q-pa-none"
                     style="margin: 0 -2px"
                     @click.prevent.stop="detachStatus(dayData.status[0])"
                     color="red"
                     icon="close"
              >
                <q-tooltip>Изменить</q-tooltip>
              </q-btn>
            </div>
          </template>

          <a
              v-else
              href="#"
              @click.prevent.stop="statusesDialog = true"
              class="text-caption"
          >
            <q-icon name="mdi-plus"></q-icon>
            Статус
          </a>
        </q-item-label>
      </q-item>
    </q-list>

    <AddTrailer
        v-if="trailersDialog"
        :trailer="trailer"
        :vehicle="vehicle"
        :date="date"
        :trailersDialog="trailersDialog"
        @input="(state) => {trailersDialog = state}"
        @onRefresh="getVehicleData"
    ></AddTrailer>

    <AddDriver
        v-if="driversDialog"
        :driver="driver"
        :vehicle="vehicle"
        :date="date"
        :driversDialog="driversDialog"
        @input="(state) => {driversDialog = state}"
        @onRefresh="getVehicleData"
    ></AddDriver>

    <AddStatus
        v-if="statusesDialog"
        :status="status"
        :vehicle="vehicle"
        :date="date"
        :statusesDialog="statusesDialog"
        @input="(state) => {statusesDialog = state}"
        @onRefresh="getVehicleData"
    ></AddStatus>

    <AddOrder
        v-if="ordersDialog"
        :order="orders"
        :vehicle="vehicle"
        :date="date"
        :ordersDialog="ordersDialog"
        @input="(state) => {ordersDialog = state}"
        @onRefresh="getVehicleData"
    ></AddOrder>

    <DetachDriver
        v-if="detachDriverDialog"
        v-model="detachDriverDialog"
        :driver="selectDriver"
        :date="date"
        :endOfFullDate="endOfFullDate"
        @onChange="$emit('onChange')"
    ></DetachDriver>
  </div>
</template>

<script>
import { formatDate, FORMAT_FOR_SERVER } from '@/utils/date';
import { getData, METHODS } from '@/utils/http';
import AddTrailer from '@/modules/organization/components/AddTrailer';
import AddDriver from '@/modules/organization/components/AddDriver';
import AddStatus from '@/modules/organization/components/AddStatus';
import AddOrder from '@/modules/organization/components/AddOrder';
import DetachDriver from '@/modules/organization/components/DetachDriver';

export default {
  name: 'CalendarCell',
  components: {
    AddOrder,
    AddStatus,
    AddDriver,
    AddTrailer,
    DetachDriver,
  },
  props: {
    year: {
      type: Number,
      required: true,
    },
    month: {
      type: Number,
      required: true,
    },
    day: {
      type: Number,
      required: true,
    },
    dayData: {
      type: Object,
    },
    monthDays: {
      type: Number,
      required: true,
    },
    firstWeekDay: {
      type: Number,
      required: true,
    },
    vehicle: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      trailer: [],
      trailersDialog: false,
      driver: [],
      driversDialog: false,
      status: [],
      statusesDialog: false,
      date: '',
      orders: [],
      ordersDialog: false,
      driversOpen: false,
      endOfFullDate: null,
      detachDriverDialog: false,
      selectDriver: null,
    };
  },
  created() {
    this.date = `${this.year}-${_setZero(this.month + 1)}-${_setZero(this.getCalendarDate())}`;

    if (this.dayData) {
      this.trailer = this.dayData.trailers || [];
      this.driver = this.dayData.drivers || [];
      this.status = this.dayData.status || [];
    }
  },
  methods: {
    getCalendarDate() {
      const dateStr = this.day - this.firstWeekDay;

      return dateStr > 0 ? (dateStr <= this.monthDays ? dateStr : '') : '';
    },
    detachTrailer(item) {
      const message = `Уверены, что хотите снять прицеп с данного ТС на ${this.date}`;
      const url = '/vehicles/' + this.vehicle.id + '/detach/trailer';
      const param_date = this.getDetachDate(item.pivot);

      const params = {
        trailer_id: item.id,
        end_date: param_date,
      };

      this.detach(message, url, params);
    },
    detachDriver(item) {
      this.endOfFullDate = Object.assign(
          _copy(item.pivot),

      );
      this.selectDriver = Object.assign(
          _copy(item),
          { vehicleId: this.vehicle.id },
      );
      this.detachDriverDialog = true;
    },

    detachStatus(item) {
      const message = `Уверены, что хотите отменить статус данного ТС на ${this.date}`;
      const url = '/vehicles/' + this.vehicle.id + '/detach/status';
      const param_date = this.getDetachDate(item.pivot);

      const params = {
        status_journal_id: item.pivot.id,
        end_date: param_date,
      };

      this.detach(message, url, params);
    },
    getDetachDate(pivot) {
      const dt = this.date.split('-');
      const new_dt = new Date(dt[0], dt[1] - 1, dt[2]);
      new_dt.setMinutes(new_dt.getMinutes() - 1);
      let param_date = formatDate(new_dt.getTime(), FORMAT_FOR_SERVER.DATE_TIME);

      return pivot.start_date > param_date ? pivot.start_date : param_date;
    },
    detach(message, url, params, method) {
      if (confirm(message)) {
        getData(url, method || METHODS.POST, {
          params: params,
        })
            .then(() => {
              this.$emit('onChange');
            })
            .catch((res) => {
              this.$q.notify({
                message: res.error,
                type: 'negative',
              });
            })
            .finally(() => {
              this.loading = false;
              this.$emit('input', false);
            });
      }
    },

    // Возвращает дату в формате для отправки на бэк
    getDateToAPiFormat(dateStr) {
      const dateArr = dateStr.replaceAll('/', '.').split('.');
      return dateArr.reverse().join('-');
    },

    getCellColor() {
      let color = 'red';

      if (this.dayData) {
        if (this.dayData.trailers && this.dayData.trailers.length) {
          color = 'yellow';
        }

        if (this.dayData.drivers && this.dayData.drivers.length) {
          if (color === 'yellow') {
            color = 'white';
          } else {
            color = 'yellow';
          }
        }
      }

      return color;
    },
    getVehicleData() {
      this.$emit('onChange');
    },
  },
};
</script>
