<template>
  <div>
    <template v-if="loading">
      <div class="text-center">
        <q-spinner size="48"/>
      </div>
    </template>


    <template v-if="data_current && data_current.length === 0 && !loading">
      Ни одного рейса
    </template>

    <div
        v-for="date in data_current"
        :key="`in_execution_${date.date}`"
        class="q-mb-md"
    >
      <div class="q-mb-sm text-bold">
        {{ date.date | formatDate }}
      </div>

      <TripTable
          :data="date.data"
          :columns="columns"
          :order="order"
          @onReload="ApplyChanges"
          @onOpen="showDetail"
          @onClose="denyOrder"
      ></TripTable>
    </div>
  </div>
</template>

<script>
import {getData, METHODS} from '@/utils/http';
import TripTable from '@/modules/organization/components/TripTable';
import {formatDate, parseDateFromStr} from '@/utils/date';

export default {
  name: 'ExecutionOrder',
  components: { TripTable, parseDateFromStr },
  filters: {
    formatDate: (date) => parseDateFromStr(date),
  },
  props: {
    orders_type: String,
    order: Object,
    columns: Array,
    newOrderLoading: Boolean,
  },
  data() {
    return {
      data_current: [],
      loading: false,
    };
  },
  watch: {
    newOrderLoading() {
      this.data_finished = [];
      this.originalItemsCompleted = [];
      this.num = 1;
      this.loadData();
    },
  },
  created() {
      this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true;
      if(this.orders_type === 'IN_EXECUTION') {
        this.num = 1;
      }

      this.sendData(this.orders_type, this.num)
          .then((res) => {
            this.data_current = res;
          })
          .finally(() => {
            this.loading = false;
          });
    },
    sendData(state, num) {
      let URL = `/vehicle_orders?orders=-start_date&include=vehicle&page=${num}&perPage=15`;
      URL += `&filters=organization_orders_id:${this.order.id},state:${state}`;

      return getData(URL, METHODS.GET)
          .then((res) => {
              let data_current = res.result.items;

              this.data_finished = [];

              this.originalItemsCompleted = [];
              this.originalItems = [];

              this.originalItems = this.originalItems.concat(data_current);

              let in_execution = [];

              this.originalItems.forEach((item) => {
                const start_dt = item.start_date.split(' ')[0].replace(/-/g, '');

                if (!in_execution[start_dt]) {
                  in_execution[start_dt] = [];
                }

                in_execution[start_dt].push(item);
              });

              let sorted_data = [];

              in_execution.forEach((it, index) => {
                sorted_data.push({
                  date: index,
                  data: it,
                });
              });

              if (this.num < res.result.pages) {
                this.num++;
                this.loadData(state, this.num);
              } else {
                this.loadingFullData = false;
              }
              return sorted_data.sort((a, b) => b.date - a.date);
          });
    },
    ApplyChanges() {
      this.data_finished = [];
      this.originalItemsCompleted = [];
      this.loadData();
    },
    showDetail(order) {
      this.$emit('onOpen', order);
    },
    denyOrder(order) {
      this.$emit('onClose', order);
    },
  },
};
</script>

<style scoped>

</style>
