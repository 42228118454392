const orders_list = {
    name: 'orders',
    title: 'Заказы',
    url: '/orders',
    include: [],
    filters: [{
        by: 'status',
        value: 'ON_MODERATE',
    }],
    perms: {
        list: 'v1.orders.list',
        read: 'v1.orders.read',
        create: 'v1.orders.create',
        update: 'v1.orders.update',
        delete: 'v1.orders.delete',
    },
    listColumns: [
        {
            name: 'number',
            required: true,
            label: 'Номер заказа',
            align: 'left',
            field: 'number',
            sortable: true,
        },
        {
            name: 'move_direction',
            required: true,
            label: 'Маршрут',
            align: 'left',
            field: 'move_direction',
            map: 'title',
            sortable: true,
        },
        {
            name: 'cargo_name',
            required: true,
            label: 'Наименование груза',
            align: 'left',
            field: 'cargo_name',
            sortable: true,
            map: 'title',
        },
        {
            name: 'cargo_value',
            required: true,
            label: 'Количество',
            align: 'left',
            field: 'cargo_units_count',
            sortable: true,
        },
        {
            name: 'cargo_unit',
            required: true,
            label: 'Единица измерения груза',
            align: 'left',
            field: 'cargo_unit',
            sortable: true,
            map: 'title',
        },
        {
            name: 'price',
            required: true,
            label: 'Стоимость',
            align: 'left',
            field: (row) => row.is_request_price ? 'Запрос ставки' : row.price,
            sortable: true,
        },
        {
            name: 'price_vat',
            required: true,
            label: 'Стоимость с НДС',
            align: 'left',
            field: 'price_vat',
            sortable: true,
        },
        // {
        //     name: 'price_type',
        //     required: true,
        //     label: 'Оплата за',
        //     align: 'left',
        //     field: 'price_type',
        //     sortable: true,
        //     type: 'enum',
        //     meta: {
        //         options: [
        //             {
        //                 label: 'Единица груза',
        //                 value: 0,
        //             },
        //             {
        //                 label: 'Транспорт',
        //                 value: 1,
        //             },
        //         ],
        //     },
        // },
        {
            name: 'comment',
            required: true,
            label: 'Комментарий',
            align: 'left',
            field: 'comment',
            sortable: true,
        },
        // {
        //     name: 'vehicle_types',
        //     required: true,
        //     label: 'Тип тс',
        //     align: 'left',
        //     field: 'vehicle_types',
        //     sortable: true,
        //     map: 'title',
        //     type: 'multiple',
        // },
    ],
    editColumns: [
        {
            name: 'cargo_name',
            required: true,
            label: 'Наименование груза',
            align: 'left',
            field: 'cargo_name',
            sortable: true,
            type: 'reference',
            meta: {
                url: '/refs/cargo_names',
                optionLabel: 'title',
            },
        },
        {
            name: 'is_priority',
            required: true,
            label: 'Приоритет',
            align: 'left',
            field: 'is_priority',
            type: 'checkbox',
        },
        {
            name: 'status',
            required: true,
            label: 'Статус',
            align: 'left',
            field: 'status',
            sortable: true,
            type: 'enum',
            meta: {
                options: [
                    {
                        label: 'На модерации',
                        value: 'ON_MODERATE',
                    },
                    {
                        label: 'Завершен',
                        value: 'COMPLETED',
                    },
                ],
            },
        },
        {
            name: 'comment',
            required: true,
            label: 'Комментарий',
            align: 'left',
            field: 'comment',
            sortable: true,
            type: 'textarea',
        },
        {
            name: 'vehicle_types',
            required: true,
            label: 'Тип тс',
            align: 'left',
            field: 'vehicle_types',
            sortable: true,
            type: 'reference',
            meta: {
                url: '/refs/vehicle_type',
                optionLabel: 'title',
            },
        },
        {
            name: 'move_direction',
            required: true,
            label: 'Направление',
            align: 'left',
            field: 'move_direction',
            sortable: true,
            type: 'reference',
            meta: {
                url: '/refs/move_directions',
                optionLabel: 'title',
            },
        },
        {
            name: 'contractor',
            required: true,
            label: 'Контрагент',
            align: 'left',
            field: 'contractor',
            sortable: true,
            type: 'reference',
            meta: {
                url: '/contractors',
                optionLabel: 'title',
            },
        },
        {
            name: 'cargo_type',
            required: true,
            label: 'Тип груза',
            align: 'left',
            field: 'cargo_type',
            sortable: true,
            type: 'reference',
            meta: {
                url: '/refs/cargo_type',
                optionLabel: 'title',

            },
        },
        {
            name: 'cargo_unit',
            required: true,
            label: 'Ед. измерения груза',
            align: 'left',
            field: 'cargo_unit',
            sortable: true,
            type: 'reference',
            meta: {
                url: '/refs/cargo_unit',
                optionLabel: 'title',
            },
        },
        {
            name: 'vehicles_loading_types',
            required: true,
            label: 'Тип загрузки ТС',
            align: 'left',
            field: 'vehicles_loading_types',
            sortable: true,
            type: 'reference',
            meta: {
                url: '/refs/vehicle_loading_type',
                optionLabel: 'title',

            },
        },
        {
            name: 'vehicles_unloading_types',
            required: true,
            label: 'Тип выгрузки ТС',
            align: 'left',
            field: 'vehicles_unloading_types',
            sortable: true,
            type: 'reference',
            meta: {
                url: '/refs/vehicle_unloading_type',
                optionLabel: 'title',

            },
        },

        {
            name: 'loading_start_date',
            required: true,
            label: 'Начало загрузки',
            align: 'left',
            field: 'loading_start_date',
            sortable: true,
            type: 'date',
        },
        {
            name: 'loading_end_date',
            required: true,
            label: 'Конец загрузки',
            align: 'left',
            field: 'loading_end_date',
            sortable: true,
            type: 'date',
        },
        {
            name: 'unloading_start_date',
            required: true,
            label: 'Начало выгрузки',
            align: 'left',
            field: 'unloading_start_date',
            sortable: true,
            type: 'date',
        },
        {
            name: 'unloading_end_date',
            required: true,
            label: 'Конец выгрузки',
            align: 'left',
            field: 'unloading_end_date',
            sortable: true,
            type: 'date',
        },

    ],
};

export {
    orders_list,
};
