<template>
  <div>
    <div class="q-mb-md">
      <q-btn @click="loadData"
             round
             class="q-mr-lg"
             icon="refresh"
             :disable="loading"
      >
        <q-tooltip>Обновить</q-tooltip>
      </q-btn>

      <q-btn
          @click="orderTripDialog = true"
          color="primary"
          :disable="loading"
      >
        Назначить рейс
      </q-btn>
    </div>

    <template v-if="loading">
      <div class="text-center">
        <q-spinner size="48"/>
      </div>
    </template>

    <div v-else>
      <template v-if="orders.length === 0">
        Ни одного рейса не назначено на ТС
      </template>

      <div class="row q-col-gutter-md">
        <div
            v-for="order in orders"
            :key="`order_${order.id}`"
            class="col-6"
        >
          <q-card
              @click="showDetail(order)"
              :class="getWeightIndicator(order)"
          >
            <q-card-section>
              <div class="text-bold">
                #{{ order.organization_order.order.number }}
                {{ order.organization_order.order.move_direction.title }},
                {{ order.organization_order.order.move_direction.distance }} км
              </div>

              <div class="q-pr-md">
                <div class="text-caption">
                  {{ formatDateStr(order.start_date) }} - {{ formatDateStr(order.end_date) }}
                </div>
              </div>

              <div class="q-pr-md">
                <label>Груз: </label>
                <span class="text-bold">
                  {{ order.organization_order.order.cargo_name.title }},
                  {{ order.cargo_count }} ({{ order.organization_order.order.cargo_unit.title }})
                  </span>
              </div>

              <div class="q-pr-md">
                <label>Прицеп: </label>
                <span
                    v-if="order.schedule.trailers && order.schedule.trailers.length"
                    class="text-bold"
                >
                    {{ order.schedule.trailers.map(it => it.reg_number).join(', ') }}
                  </span>

                <span v-else class="text-bold">
                  Не установлен
                  <q-icon color="red" name="warning"></q-icon>
                </span>
              </div>

              <div class="q-pr-md">
                <label>Водитель(-и): </label>
                <span
                    v-if="order.schedule.drivers && order.schedule.drivers.length"
                    class="text-bold"
                >
                    {{ order.schedule.drivers.map(it => it.user.full_name).join(', ') }}
                  </span>

                <span class="text-bold" v-else>
                  Не установлен
                  <q-icon color="red" name="warning"></q-icon>
                </span>
              </div>

              <div class="q-pr-md">
                <label>Статус: </label>
                <span
                    v-if="order.active_status"
                >
                  <b>{{ order.active_status.title }}</b>

                  <span class="text-caption q-ml-xs">
                    ({{ order.active_status.updated_at }})
                  </span>

                  <div v-if="order.loaded">
                    Загружено:
                    <b>{{ order.loaded }} {{ order.organization_order.order.cargo_unit.title }}</b><br/>
                    Грузоподъемность:
                    <template v-if="order.schedule.trailers.length">
                      <b v-if="order.schedule.trailers[0].pivot.vehicle_load_capacity">
                        {{ order.schedule.trailers[0].pivot.vehicle_load_capacity.weight || 0 }}
                      </b>
                    </template>
                  </div>
                </span>

                <span class="text-bold" v-else>Заказ еще не принят</span>
              </div>

              <div class="q-pt-sm flex">
                <div class="text-caption q-pb-md" v-if="order.loaded > 0">
                  Отменить заказ невозможно, загрузка уже произведена.
                </div>

                <q-btn v-else
                       text-color="red"
                       icon="close"
                       size="sm"
                       @click.stop="denyOrder(order)"
                       color="white"
                       :disable="order.loaded > 0"
                >
                  Отменить заказ
                </q-btn>
              </div>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </div>

    <VehicleOrderDetail
        v-if="vehicleOrderDialog"
        :orderDialog="vehicleOrderDialog"
        :order="vehicleOrder"
        @input="(state) => {vehicleOrderDialog = state}"
    ></VehicleOrderDetail>

    <AddOrder
        v-if="orderTripDialog"
        :ordersDialog="orderTripDialog"
        :order="[]"
        :vehicle="vehicle"
        :drivers="drivers"
        @onRefresh="loadData"
        @input="(state) => {orderTripDialog = state}"
    ></AddOrder>
  </div>
</template>

<script>
import { getData, METHODS } from '@/utils/http';
import VehicleOrderDetail from '@/modules/organization/components/VehicleOrderDetail';
import { FORMAT_FOR_USER, formatDate } from '@/utils/date';
import AddOrder from '@/modules/organization/components/AddOrder';

export default {
  name: 'Orders',
  components: { AddOrder, VehicleOrderDetail },
  props: {
    vehicle: {
      type: Object,
      required: true,
    },
    drivers: {
      type: Object,
    },
  },
  data() {
    return {
      denyLoading: false,
      denyDialog: false,
      editedItem: null,
      loading: false,
      orders: [],
      orderTripDialog: false,
      vehicleOrderDialog: false,
      vehicleOrder: null,
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true;

      let URL = '/vehicle_orders?include=*';
      URL += '&filters=vehicle_id:' + this.vehicle.id;
      URL += ',state:IN_EXECUTION';

      getData(URL, METHODS.GET, {})
          .then((res) => {
            let data = res.result.items;
            let promises = [];

            data.forEach((item) => {
              const start_dt = item.start_date;
              const end_dt = item.end_date;

              let query = `/vehicles/${item.vehicle.id}`;
              query += `/schedule?include=*&start_date=${start_dt}&end_date=${end_dt}`;

              promises.push(getData(query, METHODS.GET, {}));
            });

            Promise
                .all(promises)
                .then((res) => {
                  data.forEach((item, index) => {
                    item.schedule = res[index].result;
                  });

                  data.sort((a, b) => {
                    return this.dateNumFromStr(a.start_date) - this.dateNumFromStr(b.start_date);
                  });

                  this.orders = data;
                  this.loading = false;
                });
          })
          .catch((res) => {
            this.$q.notify({
              message: res.error,
              type: 'negative',
            });
          })
          .finally(() => {
          });
    },
    showDetail(order) {
      this.vehicleOrderDialog = true;
      this.vehicleOrder = order;
    },
    formatDateStr(date) {
      return formatDate(date, FORMAT_FOR_USER.ONLY_DATE);
    },
    dateNumFromStr(date) {
      return date.split(' ')[0].replace(/-/g, '');
    },
    getWeightIndicator(order) {
      if (order.schedule.trailers.length === 0 || !order.loaded) {
        return '';
      }

      let delta = order.loaded * 10 / (order.schedule.trailers[0].pivot.vehicle_load_capacity?.weight || 1);
      delta = parseInt(delta, 10);

      if (delta >= 11) {
        return 'bg-red-2';
      }

      if (delta <= 9) {
        return 'bg-orange-2';
      }

      return '';
    },
    denyOrder(order) {
      if (confirm('Уверены, что хотите отменить заказ?')) {
        this.loading = true;

        getData(`/vehicle_orders/${order.id}/close`, METHODS.POST, {
          params: {
            cargo_count: order.loaded,
          },
        })
            .then(() => {
              this.loadData();
            })
            .catch((err) => {
              this.$q.notify({
                message: err.error,
                type: 'negative',
              });
            })
            .finally(() => {
              this.loading = false;
              this.denyDialog = false;
            });
      }
    },
  },
};
</script>
