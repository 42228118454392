const trailers_ref = {
    name: 'trailers',
    title: 'Прицепы',
    url: '/trailers',
    include: ['*'],
    filterBy: ['reg_number'],
    perms: {
        list: 'v1.trailers.list',
        read: 'v1.trailers.read',
        create: 'v1.trailers.create',
        update: 'v1.trailers.update',
        delete: 'v1.trailers.delete',
    },
    listColumns: [
        {
            name: 'reg_number',
            required: true,
            label: 'Рег.номер',
            align: 'left',
            field: 'reg_number',
            sortable: true,
        },
        {
            name: 'vehicle_type',
            required: true,
            label: 'Тип транспорта',
            align: 'left',
            field: 'vehicle_type',
            map: 'title',
            sortable: true,
        },
        {
            name: 'name',
            required: true,
            label: 'Марка/Имя',
            align: 'left',
            field: 'name',
            sortable: true,
        },
        {
            name: 'weight',
            required: true,
            label: 'Вес, кг',
            align: 'left',
            field: 'weight',
            sortable: true,
        },
        {
            name: 'release_year',
            required: true,
            label: 'Год выпуска',
            align: 'left',
            field: 'release_year',
            sortable: true,
        },
        {
            name: 'volume',
            required: true,
            label: 'Объём',
            align: 'left',
            field: 'volume',
            sortable: true,
        },
        {
            name: 'vehicles_loading_types',
            required: true,
            label: 'Тип загрузки',
            align: 'left',
            field: 'vehicles_loading_types',
            map: 'title',
            sortable: true,
            type: 'multiple',
        },
        {
            name: 'vehicles_unloading_types',
            required: true,
            label: 'Тип выгрузки',
            align: 'left',
            field: 'vehicles_unloading_types',
            map: 'title',
            sortable: true,
            type: 'multiple',
        },
    ],
    editColumns: [
        {
            name: 'reg_number',
            required: true,
            label: 'Рег.номер',
            align: 'left',
            field: 'reg_number',
        },
        {
            name: 'vehicle_type',
            required: true,
            label: 'Тип ТС',
            align: 'left',
            field: 'vehicle_type',
            type: 'reference',
            meta: {
                url: '/refs/vehicle_type',
                optionLabel: 'title',
            },
        },
        {
            name: 'axes',
            required: true,
            label: 'Количество осей',
            align: 'left',
            field: 'axes',
            type: 'number',
        },
        {
            name: 'name',
            required: true,
            label: 'Марка/Имя',
            align: 'left',
            field: 'name',
            sortable: true,
        },
        {
            name: 'release_year',
            required: true,
            label: 'Год выпуска',
            align: 'left',
            field: 'release_year',
            type: 'number',
            sortable: true,
        },
        {
            name: 'volume',
            required: true,
            label: 'Объём',
            align: 'left',
            field: 'volume',
            type: 'number',
            sortable: true,
        },

        {
            name: 'vehicles_loading_types',
            required: true,
            label: 'Тип загрузки',
            align: 'left',
            field: 'vehicles_loading_types',
            type: 'reference',
            meta: {
                url: '/refs/vehicle_loading_type',
                optionLabel: 'title',
                multiple: true,
            },
        },
        {
            name: 'vehicles_unloading_types',
            required: true,
            label: 'Тип выгрузки',
            align: 'left',
            field: 'vehicles_unloading_types',
            type: 'reference',
            meta: {
                url: '/refs/vehicle_unloading_type',
                optionLabel: 'title',
                multiple: true,
            },
        },
        {
            name: 'description',
            required: true,
            label: 'Дополнение',
            align: 'left',
            field: 'description',
            sortable: true,
            type: 'textarea',
        },
        {
            name: 'weight',
            required: true,
            label: 'Собственный вес',
            align: 'left',
            field: 'weight',
            type: 'number',
            sortable: true,
        },
    ],
};

export {
    trailers_ref,
};
