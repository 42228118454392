import { getData, METHODS } from '@/utils/http';

/**
 * Сервис для CRUD запросов на сервер
 */
export default class CrudService {

  /**
   * Кеш экземпляров
   *
   * @type {{}}
   */
  static cache = {};

  /**
   *
   * @type {string}
   */
  baseUrl = '{url}';

  /**
   * Получает url относительно crud операций
   *
   * @param entityUrl
   */
  constructor(entityUrl) {
    this.baseUrl = entityUrl;
  }

  /**
   * Возвращает экземпляр класса по url сущности
   *
   * @param entityUrl
   * @returns {*}
   */
  static entity(entityUrl) {

    if (!this.cache[entityUrl]) {
      this.cache[entityUrl] = new CrudService(entityUrl);
    }

    return this.cache[entityUrl];
  }


  /**
   * Выводит список элементов с пагинацией
   *
   * @param page
   * @param perPage
   * @param include
   * @param sortBy
   * @param desc
   * @param filter
   * @returns {Promise<unknown>}
   */
  list(page = 1,
       perPage = 1,
       include = [],
       sortBy = null,
       desc = 1,
       filters = [],
       ) {

    let includeQuery = include.join(',');

    let query = this.baseUrl + '?page=' + page + '&perPage=' + perPage + '&include=' + includeQuery;

    const filterStr = filters.map((filter) => {
      if (filter.value != null) {
        return `${filter.by}:${filter.value}`;
      }
    });

    if (filterStr.length) {
      query += `&filters=${filterStr.join(',')}`;
    }

    if (sortBy) {
      query += '&orders=';
      query += desc > 0 ? sortBy : '-' + sortBy;
    }

    return getData(
      query,
      METHODS.GET,
      {});
  }

  /**
   * Выводит список элементов с пагинацией
   *
   * @param page
   * @param perPage
   * @param include
   * @param sortBy
   * @param desc
   * @param filter
   * @returns {Promise<unknown>}
   */
  own(page = 1,
       perPage = 1,
       include = [],
       sortBy = null,
       desc = 1,
       filters = [],
  ) {

    let includeQuery = include.join(',');

    let query = this.baseUrl + '/own?page=' + page + '&perPage=' + perPage + '&include=' + includeQuery;

    const filterStr = filters.map((filter) => {
      if (filter.value != null) {
        return `${filter.by}:${filter.value}`;
      }
    });

    if (filterStr.length) {
      query += `&filters=${filterStr.join(',')}`;
    }

    if (sortBy) {
      query += '&orders=';
      query += desc > 0 ? sortBy : '-' + sortBy;
    }

    return getData(
      query,
      METHODS.GET,
      {});
  }

  /**
   * Считывает одну запись по идентификатору
   *
   * @param entityName
   * @param id
   * @returns {Promise | Promise<unknown>}
   */
  read(id) {
    return getData(this.baseUrl + '/' + id, METHODS.GET, {});
  }

  /**
   * Добавляет новую запись
   *
   * @param entityName
   * @param data
   * @returns {Promise | Promise<unknown>}
   */
  add(data) {
    return getData(this.baseUrl, METHODS.POST, {
      params: data,
    });
  }

  /**
   * Изменяет запись по идентификатору
   *
   * @param entityName
   * @param id
   * @param data
   * @returns {Promise | Promise<unknown>}
   */
  edit(id, data) {
    return getData(this.baseUrl + '/' + id, METHODS.PUT, {
      params: data,
    });
  }

  /**
   * Удаляет запись по идентификатору
   *
   * @param entityName
   * @param id
   * @returns {Promise | Promise<unknown>}
   */
  delete(id) {
    return getData(this.baseUrl + '/' + id, METHODS.DELETE, {});
  }
}
