<template>
  <div>
    <q-btn
        flat outline
        align="right"
        color="primary"
        label="Назначить"
        icon="add"
        :loading="orgLoading"
        :disable="!canAttachBtn"
        @click="attachDialog = true"
    ></q-btn>

    <q-dialog v-model="attachDialog">
      <q-card>
        <!-- Заголовок -->
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">
            Назначить заказ на собственный транспорт
          </div>

          <q-space/>

          <q-btn flat round dense
                 v-close-popup
                 icon="close"
                 class="q-ml-md"
          ></q-btn>
        </q-card-section>

        <!-- Формируем поля для редактирования-->
        <q-card-section style="max-height: 500px">
          <div v-if="organization">
            Подтвердите, что передаёте перевозчику {{ organization.title }} заказ <br/>
            №{{ order.number }} "{{ order.move_direction.title }}"
          </div>

          <div class="flex items-center">
            <!-- Количество -->
            <div class="col-grow">
              <q-input
                  label="Количество"
                  type="number"
                  v-model="cargo_units_count"
                  :hint="`Доступно ${order.cargo_units_count_left}`"
              ></q-input>
            </div>

            <!-- Выдать максимум -->
            <div>
              <q-btn
                  flat
                  size="sm"
                  color="primary"
                  @click.prevent="cargo_units_count = order.cargo_units_count_left"
              >
                Выдать максимум
              </q-btn>
            </div>
          </div>

          <!-- Цена -->
          <div class="q-mt-md">
            <q-input
                label="Цена"
                v-model="order.price"
            ></q-input>
          </div>

          <!-- Юридическое лицо -->
          <div class="q-mt-md">
            <ref-select
                url="/refs/legal_entity"
                label="Юридическое лицо"
                v-model="legal_entity"
            ></ref-select>
          </div>
        </q-card-section>

        <!-- Действия -->
        <q-card-actions align="right">
          <q-btn
              :loading="loading"
              flat
              label="OK"
              color="primary"
              @click="attachOrder"
          ></q-btn>

          <q-btn
              :disable="loading"
              flat
              label="Отмена"
              color="negative"
              v-close-popup
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { getData, METHODS } from '@/utils/http';
import RefSelect from '@/components/fields/RefSelect';

export default {
  name: 'AttachOrder_dialog',
  components: { RefSelect },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      orgLoading: true,
      canAttachBtn: true,
      attachDialog: false,
      cargo_units_count: 0,
      legal_entity: null,
      price: 0,
      organization: null,
    };
  },
  created() {
    const profile = this.$store.getters['auth/getProfile'];

    getData('/organizations/' + profile.organization_id, METHODS.GET)
        .then((res) => {
          this.orgLoading = false;
          this.organization = res.result;
        })
        .catch(() => {
          let errMsg = 'Не удалось получить информацию о вашей организации';

          this.$q.notify({
            message: errMsg,
            type: 'negative',
          });

          this.canAttachBtn = false;
        })
        .finally(() => {
          this.orgLoading = false;
        });
  },
  methods: {
    attachOrder() {
      this.loading = true;

      getData('/organizations_order/own', METHODS.POST, {
        params: {
          orders_id: this.order.id,
          cargo_units_count: this.cargo_units_count,
          price: this.order.price || 1,
          legal_entity_id: this.legal_entity && this.legal_entity.id || null,
        },
      })
          .then(() => {
            this.attachDialog = false;

            this.$q.notify({
              message: 'Заказ успешно назначен',
              type: 'positive',
            });

            this.$emit('onRefresh');
          })
          .catch((error) => {
            let errMsg = error.error || 'Не удалось назначить заказ';

            this.$q.notify({
              message: errMsg,
              type: 'negative',
            });
          })
          .finally(() => {
            this.loading = false;
          });
    },
  },
};
</script>
