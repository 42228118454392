<template>
  <div class="q-pa-md">
    <div
        class="row items-start"
    >
      <div>
        <div class="q-gutter-sm">
          <q-radio v-model="periodType" val="month" label="Месяц"/>
          <q-radio v-model="periodType" val="range" label="Период"/>
        </div>

        <div>
          <div
              v-if="periodType === 'range'"
              class="row q-col-gutter-md q-py-md items-center"
              style="max-width: 600px;"
          >
            <RangeDates class="col-8 "

                        :startDate.sync="tripDateStart"
                        :endDate.sync="tripDateEnd"
            ></RangeDates>


            <div class="col-4">
              <q-btn
                  class="q-mb-md"
                  @click="loadData"
                  :loading="loading"
              >
                Показать отчет
              </q-btn>
            </div>
          </div>

          <div
              v-else
              class="row"
              style="max-width: 600px;"
          >
            <div class="flex items-center q-mb-sm">
              <div>
                <q-btn
                    flat
                    icon="mdi-arrow-left"
                    @click="prevMonth"
                >
                  <q-tooltip>Предыдущий месяц</q-tooltip>
                </q-btn>
              </div>

              <div class="q-px-sm text-h6">{{ monthName }}, {{ year }}</div>

              <div>
                <q-btn
                    flat
                    icon="mdi-arrow-right"
                    @click="nextMonth"
                >
                  <q-tooltip>Следующий месяц</q-tooltip>
                </q-btn>
              </div>
            </div>
          </div>
        </div>
      </div>

      <q-space/>

      <div class="col-auto">
        <div class="row">
          <div class="flex items-center">
            <q-tabs v-model="tableType" class="q-mr-md">
              <q-tab name="all">Все</q-tab>
              <q-tab name="types">По статьям</q-tab>
              <q-tab name="driver">По водителю</q-tab>
              <q-tab name="vehicle">По транспорту</q-tab>
            </q-tabs>

            <div>
              <q-btn
                  @click="showEdit(null)"
              >
                Добавить
              </q-btn>
            </div>
          </div>
        </div>
        <div class="row justify-end q-pb-sm">
          <q-btn @click="print" icon="print">
            <q-tooltip>Печать</q-tooltip>
          </q-btn>
        </div>
      </div>
    </div>

    <!-- Перенести в отдельный компонент -->
    <q-table
        v-if="tableType === 'all'"
        key="expenses_table_all"
        row-key="id"
        class="sticky-table"
        binary-state-sort hide-pagination hide-no-data
        :data="data"
        :columns="ref.listColumns"
        :pagination.sync="pagination"
        :loading="loading"
        @request="loadData"
        id="expenses_table_all"
    >
      <template #body-cell-actions="props">
        <q-td :props="props">
          <q-btn
              round flat
              icon="content_copy"
              size="sm"
              @click.stop="showEdit(props.row, true)"
          >
            <q-tooltip>
              Копировать
            </q-tooltip>
          </q-btn>

          <q-btn
              round flat
              icon="edit"
              size="sm"
              @click.stop="showEdit(props.row)"
          >
            <q-tooltip>
              Редактировать
            </q-tooltip>
          </q-btn>

          <q-btn
              round flat
              text-color="red"
              icon="close"
              size="sm"
              @click.stop="remove(props.row)"
          >
            <q-tooltip>
              Удалить
            </q-tooltip>
          </q-btn>
        </q-td>
      </template>

      <template v-if="data.length > 0" #top-row="{cols}">
        <q-tr class="bg-grey-4">
          <q-td v-for="(col, index) of cols" :key="col.name">
            <template v-if="index === 0">Итого</template>
            <template v-if="index > 4 & index < 8">
              {{ _priceFormat(data.reduce((acc, val) => acc + val[col.name], 0)) }}
            </template>
          </q-td>
        </q-tr>
      </template>

      <template v-if="data.length > 0" #bottom-row="{cols}">
        <q-tr class="bg-grey-4">
          <q-td v-for="(col, index) of cols" :key="col.name">
            <template v-if="index === 0">Итого</template>
            <template v-if="index > 4 & index < 8">
              {{ _priceFormat(data.reduce((acc, val) => acc + val[col.name], 0)) }}
            </template>
          </q-td>
        </q-tr>
      </template>
    </q-table>

    <!-- Перенести в отдельный компонент -->
    <q-table
        v-if="tableType === 'types'"
        key="expenses_table_types"
        row-key="id"
        binary-state-sort hide-pagination hide-no-data
        :data="dataTypes"
        :columns="ref_by_type.listColumns"
        :pagination.sync="paginationTypes"
        :loading="loading"
        @request="loadData"
        id="expenses_table_types"
    >
      <template v-if="data.length > 0" #top-row="{cols}">
        <q-tr class="bg-grey-4">
          <q-td v-for="(col, index) of cols" :key="col.name">
            <template v-if="index === 0">Итого</template>
            <template v-if="index > 0">
              {{ _priceFormat(data.reduce((acc, val) => acc + val[col.name], 0)) }}
            </template>
          </q-td>
        </q-tr>
      </template>

      <template v-if="data.length > 0" #bottom-row="{cols}">
        <q-tr class="bg-grey-4">
          <q-td v-for="(col, index) of cols" :key="col.name">
            <template v-if="index === 0">Итого</template>
            <template v-if="index > 0">
              {{ _priceFormat(data.reduce((acc, val) => acc + val[col.name], 0)) }}
            </template>
          </q-td>
        </q-tr>
      </template>

      <template #body="props">
        <q-tr :props="props">
          <q-td
              v-for="col in props.cols"
              @click="props.row.expand = !props.row.expand"
              :key="col.name"
              :props="props"
          >
            {{ col.value }}
          </q-td>
        </q-tr>
        <q-tr v-if="props.row.expand" :props="props">
          <q-td colspan="100%">
            <q-table
                id="includedTable"
                hide-pagination hide-no-data
                :data="props.row.list"
                :columns="columnsForIncludeTable"
                :pagination="{rowsPerPage: 0}"
            ></q-table>
          </q-td>
        </q-tr>
      </template>
    </q-table>

    <ExpensesByDriver
        v-if="tableType === 'driver'"
        :start-date="startDate"
        :end-date="endDate"
    ></ExpensesByDriver>

    <ExpensesByVehicle
        v-if="tableType === 'vehicle'"
        :start-date="startDate"
        :end-date="endDate"
    ></ExpensesByVehicle>

    <!-- Перенести в отдельный компонент -->
    <q-dialog
        v-if="editDialog"
        v-model="editDialog"
        @close="closeEdit"
    >
      <q-card style="width: 300px">
        <q-card-section>

          <date-field
              label="Дата расхода"
              v-model="editItem.date"
          ></date-field>

          <ref-select
              label="Статья расходов"
              v-model="editItem.expenses_type"
              :option-label="getOptionLabel"
              url="/expenses_types"
              :autocomplete="true"
          >
          </ref-select>

          <div v-if="editItem.expenses_type && editItem.expenses_type.expenses_type === 'VEHICLE_TYPE'">
            <div v-if="editItem.id">
              <ref-select
                  label="Транспорт"
                  v-model="editItem.vehicles[0]"
                  option-label="reg_number"
                  url="/vehicles"
                  :autocomplete="true"
                  @input="(item) => getLegalEntity(item)"
              >
              </ref-select>
            </div>
            <div v-else>
              <ref-select label="Транспорт"
                          v-model="editItem.entity_id"
                          option-label="reg_number"
                          url="/vehicles"
                          :autocomplete="true"
                          :columns="['id', 'reg_number', 'legal_entity_id']"
                          :include="['legal_entity']"
                          @input="getLegalEntity"
              >
              </ref-select>
            </div>
          </div>

          <div v-if="editItem.expenses_type && editItem.expenses_type.expenses_type === 'DRIVER_TYPE'">
            <div v-if="editItem.id">
              <ref-select
                  label="Водитель"
                  url="/drivers"
                  v-model="editItem.drivers[0]"
                  :option-label="(row) => row.user ? row.user.full_name : ''"
                  :autocomplete="true"
                  @input="getLegalEntity"
              >
              </ref-select>
            </div>
            <div v-else>
              <ref-select
                  label="Водитель"
                  url="/drivers"
                  v-model="editItem.entity_id"
                  :option-label="(row) => row.user ? row.user.full_name : ''"
                  :autocomplete="true"
                  @input="getLegalEntity"
              >
              </ref-select>
            </div>
          </div>

          <ref-select
              label="Юр.лицо"
              v-model="editItem.legal_entity"
              url="/refs/legal_entity"
              :key="dataKey"
          >
          </ref-select>

          <q-input label="Описание"
                   v-model="editItem.description"
          ></q-input>

          <q-input label="Сумма"
                   v-model.number="editItem.cost_original"
                   type="number"
          ></q-input>

          <q-checkbox label="Добавить % налога к сумме"
                      v-model="isTax"
          ></q-checkbox>

          <ref-select v-if="!isTax"
                      label="Ставка НДС"
                      v-model="editItem.vat_rate"
                      url="/refs/vat_rates"
                      clearable
                      :columns="['id', 'title', 'size']"
                      :optionSubtitle="row => row.size + '%'"
          ></ref-select>

          <q-input v-if="isTax"
                   label="Налог"
                   v-model.number="editItem.tax"
                   type="number"
                   class="q-mb-md"
          ></q-input>

          <q-checkbox v-if="showShareWithAll"
                      v-model="shareWithAll"
                      label="Разделить на всех"
          ></q-checkbox>

          <q-markup-table>
            <thead>
            <tr>
              <th class="text-left">Сумма</th>
              <th class="text-right">
                {{ isTax ? 'Налог' : 'НДС' }}
              </th>
              <th class="text-right">Итог</th>
            </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-left">{{ calculateExpenses.cost | taxFixed }}</td>
                <td class="text-right">{{ calculateExpenses.vatTax | taxFixed }}</td>
                <td class="text-right">{{ calculateExpenses.total | taxFixed }}</td>
              </tr>
            </tbody>
          </q-markup-table>
        </q-card-section>

        <q-card-actions>
          <q-btn v-close-popup>Отмена</q-btn>

          <q-space/>

          <q-btn
              @click="save"
              :loading="editLoading"
          >
            Сохранить
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { getData, METHODS } from '@/utils/http';
import RefSelect from '@/components/fields/RefSelect';
import { getDateToAPiFormat } from '@/utils/date';
import { expenses, expenses_by_type } from '@/structures/expenses';
import { EXPENSES_TYPES } from '@/structures/refs/expenses_types';
import ExpensesByDriver from './ExpensesByDriver';
import ExpensesByVehicle from './ExpensesByVehicle';
import RangeDates from '@/components/fields/date/RangeDates';
import MonthPicker from '@/components/fields/date/MonthPicker';
import DateField from '@/components/fields/date/DateField';

export default {
  name: 'Expenses',
  components: {DateField, ExpensesByVehicle, ExpensesByDriver, RefSelect, MonthPicker, RangeDates },
  filters: {
    taxFixed: function(val) {
      if (val) {
        return Math.floor (val * 100) / 100;
      }
    },
  },
  data() {
    return {
      editDialog: false,
      editLoading: false,
      editItem: {},
      periodType: 'month',
      tableType: 'all',
      data: [],
      dataTypes: [],
      pagination: {
        rowsPerPage: 0,
      },
      paginationTypes: {
        rowsPerPage: 0,
      },
      dataKey: 1,
      tripDateStart: '',
      tripDateEnd: '',
      year: (new Date()).getFullYear(),
      month: (new Date()).getMonth(),
      monthDays: 0,
      monthName: '',
      monthNames: [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь',
      ],
      loading: false,
      ref: expenses,
      ref_by_type: expenses_by_type,
      shareWithAll: false,
      isTax: false,
    };
  },
  computed: {
    startDate() {
      if (this.periodType === 'range') {
        if (this.tripDateStart === '') {
          return '';
        }

        return getDateToAPiFormat(this.tripDateStart);
      } else {
        return `${this.year}-${_setZero(this.month + 1)}-01`;
      }
    },
    endDate() {
      if (this.periodType === 'range') {
        if (this.tripDateEnd === '') {
          return '';
        }

        return getDateToAPiFormat(this.tripDateEnd);
      } else {
        return `${this.year}-${_setZero(this.month + 1)}-${this.monthDays}`;
      }
    },
    columnsForIncludeTable() {
      const columns = [];
      // Копируем столбцы для построения таблицы. Нельзя использовать _copy(), т.к. он избавляется от функций
      // Удаляем первый и последние столбцы (Статья расходов и Действия)
      for (let i = 1; i < this.ref.listColumns.length - 1; i++) {
        columns.push(this.ref.listColumns[i]);
      }
      return columns;
    },
    showShareWithAll() {
      const expenses_type = this.editItem?.expenses_type?.expenses_type || null;
      return expenses_type === 'VEHICLE_TYPE' || expenses_type === 'DRIVER_TYPE';
    },
    calculateExpenses() {
      const costOriginal = this.editItem.cost_original || 0;
      const vatRate = this.editItem?.vat_rate?.size || 0;
      const tax = this.editItem?.tax || 0;

      const vatTax = this.isTax ? costOriginal / 100 * tax : costOriginal / (100 + vatRate) * vatRate;
      const cost = this.isTax ? costOriginal : costOriginal - vatTax;
      const total = this.isTax ? costOriginal + vatTax : costOriginal;

      return {
        cost,
        vatTax,
        total,
        tax,
      };
    },
  },
  watch: {
    periodType() {
      this.loadData();
    },
    showShareWithAll(val) {
      if (val === false) {
        this.shareWithAll = false;
      }
    },
    isTax(val) {
      if (val === true) {
        this.editItem.vat_rate = null;
      }
    },
  },
  created() {
    this.monthDays = this.getMonthDays(this.year, this.month);
    this.initCalendar(this.year, this.month);
  },
  methods: {
    print() {
      const prtContent = document.createElement('div');
      let id = '';
      switch (this.tableType) {
        case 'types':
          id = 'expenses_table_types';
          break;
        case 'driver':
          id = 'expenses_table_driver';
          break;
        case 'vehicle':
          id = 'expenses_table_vehicle';
          break;
        case 'all':
        default:
          id = 'expenses_table_all';
      }
      prtContent.innerHTML = document.getElementById(id).innerHTML;

      // Удаляем столбец "Действия"
      if (this.tableType === 'all') {
        prtContent.querySelectorAll('td:last-child').forEach((td) => td.remove());
        prtContent.querySelector('th:last-child').remove();
      }

      // Открываем окно для печати
      const WinPrint = window.open('', '', 'left=50,top=50,width=800,height=640,toolbar=0,scrollbars=1,status=0');
      WinPrint.document.write(prtContent.innerHTML);
      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
    },
    getCurrentDate() {
      const date = new Date();

      return `${_setZero(date.getDate())}/${_setZero(date.getMonth() + 1)}/${date.getFullYear()}`;
    },
    getMonthDays(year, month) {
      return (new Date(year, month + 1, 0)).getDate();
    },
    loadData() {
      let startDt;
      let endDt;

      if (this.periodType === 'range') {
        if (this.tripDateStart === '' || this.tripDateEnd === '') {
          return;
        }

        startDt = getDateToAPiFormat(this.tripDateStart);
        endDt = getDateToAPiFormat(this.tripDateEnd);
      } else {
        startDt = `${this.year}-${_setZero(this.month + 1)}-01`;
        endDt = `${this.year}-${_setZero(this.month + 1)}-${this.monthDays}`;
      }

      let URL = `/expenses?start_date=${startDt}&end_date=${endDt}&include=*`;
      URL += '&perPage=100000';

      this.loading = true;

      getData(URL, METHODS.GET)
          .then((res) => {
            const items = res.result.items;
            this.data = items;
            let dataTypes = [];

            items.forEach((exp) => {
              let curExpense;
              const curExpBlock = dataTypes.filter((dExp) => dExp.expenses_type_id === exp.expenses_type_id);

              if (curExpBlock.length === 0) {
                curExpense = {
                  expenses_type_id: exp.expenses_type_id,
                  expenses_type: exp.expenses_type,
                  cost: 0,
                  cost_vat: 0,
                  cost_original: 0,
                  expand: false,
                  list: [],
                };

                dataTypes.push(curExpense);
              } else {
                curExpense = curExpBlock[0];
              }

              curExpense.cost += exp.cost;
              curExpense.cost_vat += (exp.cost_vat || 0);
              curExpense.cost_original += (exp.cost_original || 0);
              curExpense.list.push(exp);
            });

            this.dataTypes = dataTypes;
          })
          .catch((res) => {
            this.$q.notify({
              message: res.error,
              type: 'negative',
            });
          })
          .finally(() => {
            this.loading = false;
          });
    },
    save() {
      this.editLoading = true;
      
      let editItem = _copy(this.editItem);
      const method = !editItem.is_copy && editItem.id ? METHODS.PUT : METHODS.POST;
      const URL = '/expenses' + (editItem.id ? `/${editItem.id}` : '');

      if (!editItem.id || editItem.is_copy) {
        editItem.expenses_type_id = editItem.expenses_type.id;

        if (editItem.entity_id) {
          editItem.entity_id = editItem.entity_id.id;
        }
      }

      editItem.date = getDateToAPiFormat(editItem.date);

      if (this.shareWithAll) {
        editItem.for_all = true;
      }

      if (this.isTax) {
        delete editItem.vat_rate;
        editItem.cost_tax = this.calculateExpenses.vatTax;
      } else {
        delete editItem.cost_tax;
      }

      if (this.isTax) {
        editItem.cost = this.calculateExpenses.total;
        editItem.cost_original = this.calculateExpenses.cost;
      } else {
        editItem.cost_original = this.calculateExpenses.total;
        editItem.cost = this.calculateExpenses.cost;
      }

      

      getData(URL, method, {
        params: editItem,
      })
          .then(() => {
            this.closeEdit();
            this.loadData();
          })
          .catch((res) => {
            this.$q.notify({
              message: res.error,
              type: 'negative',
            });
          })
          .finally(() => {
            this.editLoading = false;
          });
    },
    remove(item) {
      if (!item) {
        return;
      }

      if (confirm('Уверены, что хотите удалить эту запись?')) {
        getData(`/expenses/${item.id}`, METHODS.DELETE)
            .then(() => {
              this.loadData();
            })
            .catch((res) => {
              this.$q.notify({
                message: res.error,
                type: 'negative',
              });
            })
            .finally(() => {
              this.editLoading = false;
            });
      }
    },
    closeEdit() {
      this.editItem = null;
      this.editDialog = false;
    },
    showEdit(item, is_copy) {
      const item_copy = _copy(item) || {};

      if (is_copy) {
        item_copy.is_copy = true;

        if (item_copy.expenses_type) {
          switch (item_copy.expenses_type.expenses_type) {
            case 'DRIVER_TYPE':
              if (item_copy.drivers.length) {
                item_copy.entity_id = item_copy.drivers[0];
              }

              break;
            case 'VEHICLE_TYPE':
              if (item_copy.vehicles.length) {
                item_copy.entity_id = item_copy.vehicles[0];
              }

              break;
          }
        }

        delete item_copy.id;
        delete item_copy.drivers;
        delete item_copy.vehicles;
      }

      this.editItem = item_copy;
      this.editDialog = true;
    },
    getOptionLabel(item) {
      return `${item.title} <span class="text-caption">(${this.getExpensesTitle(item.expenses_type)})</span>`;
    },
    getExpensesTitle(type) {
      return EXPENSES_TYPES[type] || '';
    },
    initCalendar(year, month) {
      this.monthDays = this.getMonthDays(year, month);
      this.monthName = this.monthNames[this.month];

      this.loadData();
    },
    prevMonth() {
      if (this.month > 0) {
        this.month--;
      } else {
        this.month = 11;
        this.year--;
      }

      this.initCalendar(this.year, this.month);
    },
    nextMonth() {
      if (this.month < 11) {
        this.month++;
      } else {
        this.month = 0;
        this.year++;
      }

      this.initCalendar(this.year, this.month);
    },
    getLegalEntity(item) {
      this.dataKey = (new Date()).getTime();

      if (item && item.legal_entity) {
        this.$set(this.editItem, 'legal_entity', item.legal_entity);
      }
    },
  },
};
</script>

<style lang="scss">
.sticky-table {
  max-height: calc(100vh - 200px);

  th:last-child, td:last-child {
    padding-left: 30px;
    position: sticky;
    right: 0;
    background-image: linear-gradient(90deg, rgba(2, 0, 36, 0) 0%, rgb(239, 239, 239) 20%);
    z-index: 1;
  }

  td:nth-child(2) {
    min-width: 200px;
    max-width: 400px;
    white-space: normal;
  }

  thead tr {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: white;
  }
}

#includedTable {
  th, td, td:last-child {
    background-image: none;
    background-color: #efefef;
  }
}
</style>
