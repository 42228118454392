<template>
  <div class="q-pa-sm q-gutter-sm">
    <q-table
        title="Пункты загрузки"
        :data="data"
        :pagination.sync="pagination"
        :loading="loading"
        :columns="fields"
        row-key="name"
        :filter="filterStr"
        @request="loadData"
        binary-state-sort
    >
      <!-- Верхняя часть таблицы-->
      <template #top="props">
        <slot name="top-left" v-bind="props">
          <div class="flex">
            <slot name="top-filter" v-bind="props">
              <q-input
                  dense
                  debounce="300"
                  v-model="filterStr"
                  placeholder="Поиск"
              >
                <template #append>
                  <q-icon name="search"/>
                </template>
              </q-input>

              <q-space/>
            </slot>

            <q-btn @click="loadData"
                   round
                   class="q-ml-lg"
                   icon="refresh"
            >
              <q-tooltip>Обновить</q-tooltip>
            </q-btn>
          </div>
        </slot>

        <q-space/>

        <!-- Кнопка добавить -->
        <q-card-actions align="right">
          <q-btn
              flat outline dense
              align="right"
              color="primary"
              label="Добавить"
              icon="add"
              @click="onShowDialog"
          ></q-btn>
        </q-card-actions>

        <!--Диалог создания/редактирования-->
        <div class="q-pa-md q-gutter-md">
          <q-dialog v-model="showDialog">
            <q-card style="width: 800px; max-width: 800px;">
              <q-card-section class="row items-center q-pb-none">
                <div class="text-h6">
                  {{ edited.id != null ? 'Редактирование' : 'Создание' }} записи
                </div>

                <q-space/>

                <q-btn flat round dense
                       v-close-popup
                       icon="close"
                ></q-btn>
              </q-card-section>

              <q-card-section style="max-height: 500px; overflow: auto;">
                <!-- Наименование -->
                <q-input
                    label="Наименование"
                    class="col"
                    v-model="edited.title"
                ></q-input>

                <!-- Координаты точки -->
                <div class="col row">
                  <div class="col-9">
                    <q-input
                        label="Координаты точки"
                        v-model="edited.coords"
                    ></q-input>
                  </div>

                  <div class="col">
                    <q-btn
                        flat
                        color="primary"
                        class="full-width full-height"
                        @click="onPointMapPlace"
                    >
                      На карте
                    </q-btn>
                  </div>
                </div>

                <!-- Комментарий -->
                <q-input
                    label="Комментарий"
                    class="col"
                    v-model="edited.comment"
                ></q-input>

                <hr/>

                <div class="row">
                  <div class="col column">
                    <h6 class="flex items-center q-mb-sm">
                      <span>Точки загрузки</span>

                      <q-space/>

                      <q-btn outline class="q-mt-sm" @click="showPointsDialog">
                        Добавить
                      </q-btn>
                    </h6>

                    <q-table
                        :columns="adTable.headers"
                        :data="edited.loading_point_places"
                        hide-pagination
                    >
                      <template #body-cell-actions="props">
                        <q-td :props="props">
                          <q-btn flat round
                                 color="primary"
                                 icon="mode_edit"
                                 @click="onPointEdit(props)"
                          >
                            <q-tooltip>Изменить</q-tooltip>
                          </q-btn>

                          <q-btn
                              flat round
                              color="red"
                              icon="delete"
                              @click="onPointDelete(props)"
                          >
                            <q-tooltip>Удалить</q-tooltip>
                          </q-btn>
                        </q-td>
                      </template>
                    </q-table>
                  </div>
                </div>
              </q-card-section>

              <q-card-actions class="row q-pa-md">
                <q-btn v-close-popup color="red">
                  Отмена
                </q-btn>

                <q-space/>

                <q-btn color="primary" @click="onSave">
                  Сохранить
                </q-btn>
              </q-card-actions>
            </q-card>
          </q-dialog>
        </div>
      </template>

      <!-- Основная часть -->
      <template #body="props">
        <q-tr :props="props">
          <!-- Формирование колонок вручную и попапы для редактируемых полей -->
          <q-td v-for="column in fields" :key="column.name" :props="props">
            <div v-if="column.map != null">
              {{ props.row[column.field] && props.row[column.field][column.map] || '[Не задано]' }}
            </div>

            <div v-else>
              {{ props.row[column.field] }}
            </div>

            <!-- Колонка с действиями -->
            <template v-if="column.name === 'actions'">
              <q-btn
                  round flat
                  text-color="primary"
                  @click="onEditItem(props.row)"
                  icon="edit"
              >
                <q-tooltip>Изменить</q-tooltip>
              </q-btn>

              <q-btn
                  round flat
                  text-color="red"
                  @click="onDelete(props.row)"
                  icon="delete"
              >
                <q-tooltip>Удалить</q-tooltip>
              </q-btn>
            </template>
          </q-td>
        </q-tr>
      </template>
    </q-table>

    <!-- Диалог добавления/редактирования точки -->
    <q-dialog v-model="dialogPoint">
      <q-card style="width: 600px;">
        <q-card-section>
          <div class="text-h6">
            Точка пункта: {{ !!editedPoint.id ? 'редактирование' : 'создание' }} записи
          </div>
        </q-card-section>

        <q-card-section class="column">
          <!-- Наименование -->
          <q-input
              label="Наименование"
              v-model="editedPoint.title"
          ></q-input>

          <div class="row q-mt-md">
            <!-- Координаты точки -->
            <q-input
                label="Координаты точки"
                class="col-9"
                v-model="editedPoint.coords"
                @input="onPointCoordsChange"
            ></q-input>

            <!-- Выбор точки на карте -->
            <q-btn
                flat
                color="primary"
                class="col q-ml-md"
                @click="dialogMap = true"
            >
              На карте
            </q-btn>
          </div>
        </q-card-section>

        <q-card-actions class="row q-pa-md">
          <q-btn color="red" @click="cancelPointsDialog">
            Отмена
          </q-btn>

          <q-space/>

          <q-btn color="primary" @click="onSavePointDialog">
            Сохранить
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <!-- Диалог с картой -->
    <q-dialog maximized v-model="dialogMap">
      <q-card>
        <q-toolbar class="bg-primary text-white">
          <q-toolbar-title>
            Точка на карте
          </q-toolbar-title>

          <q-space/>

          <q-space/>

          <q-btn color="red" @click="cancelDialogMap">
            Отмена
          </q-btn>

          <q-btn class="q-ml-md" @click="onSaveDialogMap">
            Сохранить
          </q-btn>
        </q-toolbar>

        <q-card-section class="full-width" style="height: 94%;">
          <div
              id="point_map"
              class="full-height full-width"
          ></div>
        </q-card-section>
      </q-card>
    </q-dialog>

    <!-- Диалог с картой v2 -->
    <q-dialog maximized v-model="dialogMap2">
      <q-card>
        <q-toolbar class="bg-primary text-white">
          <q-toolbar-title>
            Точка на карте
          </q-toolbar-title>

          <q-space/>

          <q-btn color="red" @click="cancelDialogMap">
            Отмена
          </q-btn>

          <q-btn class="q-ml-md" @click="onSaveDialogMap2">
            Сохранить
          </q-btn>
        </q-toolbar>

        <q-card-section class="full-width" style="height: 94%;">
          <div
              id="point_map_v2"
              class="full-height full-width"
          ></div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import LoadingPointsService from '@/modules/refs/ref_modules/loading_point_places/LoadingPointsService';

import Map from '@/utils/map';

// @deprecated
const mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');
mapboxgl.accessToken = 'pk.eyJ1IjoiYW50b25rIiwiYSI6ImNqbmRpaDJtazA3b3Azd280cDFwdWs1eDQifQ.ufbdwsRZeb0zFIya-B1PJQ';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

// @deprecated
let _map = null;
let _marker = new mapboxgl.Marker();

let _currentLngLat = null;

export default {
  name: 'RefLoadingPointPlaces',
  props: {
    title: String,
    entity: String,
  },
  data() {
    return {
      loading: false,
      fields: [],
      edit: [],
      data: [],
      pagination: {
        sortBy: 'desc',
        descending: false,
        page: 1,
        rowsPerPage: 10,
        rowsNumber: 0,
      },
      edited: {},
      filterStr: '',
      editedPoint: {
        title: '',
        coords: '',
      },
      dialogPoint: false,
      dialogMap: false,
      dialogMap2: false,
      adTable: {
        headers: [
          { name: 'title', field: 'title', label: 'Наименование' },
          { name: 'coords', field: (row) => `${row.lon}, ${row.lat}`, label: 'Координаты' },
          { name: 'actions', field: 'actions', label: 'Действия' },
        ],
      },
      showDialog: false,
      ready: false,
    };
  },
  watch: {
    dialogPoint(val) {
      if (val) {
        this.$on('point_map', (latLng) => {
          this.editedPoint.coords = `${latLng.lng}, ${latLng.lat}`;
        });
      } else {
        this.$off('point_map');
      }
    },
    dialogMap(val) {
      if (val) {
        setTimeout(() => {
          _map = new mapboxgl.Map({
            container: document.querySelector('#point_map'),
            // eslint-disable-next-line max-len
            style: 'mapbox://styles/antonk/ckq043i5b0p5o17p83r9ubhbl',
            center: [38.976288, 45.028261],
            zoom: 12,
          });

          const geocoder = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl: mapboxgl,
            marker: false,
          });

          _map.addControl(geocoder);

          geocoder.on('result', (res) => {
            _marker.setLngLat(res.result.geometry.coordinates).addTo(_map);
            _currentLngLat = res.result.geometry.coordinates;
          });

          _map.on('load', () => {
            // Клик по карте
            _map.on('click', function(e) {
              // Ставим маркер
              _marker.setLngLat(e.lngLat).addTo(_map);
              _currentLngLat = e.lngLat;
            });

            this.placeMarkerIfEdit();
          });
        }, 300);
      } else {
        if (_marker != null) {
          _marker.remove();
        }

        _map = null;
        _currentLngLat = null;
      }
    },
    dialogMap2(val) {
      if (val) {
        setTimeout(() => {
          Map.init({
            container: document.getElementById('point_map_v2'),
          }).then((map) => {
            if (
                this.edited.lon != null
                && this.edited.lat != null
                && this.edited.id != null
            ) {
              const lngLat = [this.edited.lon, this.edited.lat];

              _marker.remove();
              _marker.setLngLat(lngLat).addTo(map);

              map.panTo(lngLat);

              this.edited._coords = `${lngLat[0]}, ${lngLat[1]}}`;
            }

            const geocoder = new MapboxGeocoder({
              accessToken: mapboxgl.accessToken,
              mapboxgl: mapboxgl,
              marker: false,
            });

            map.addControl(geocoder);

            geocoder.on('result', (res) => {
              _marker.setLngLat(res.result.geometry.coordinates).addTo(map);
              this.edited._coords = res.result.geometry.coordinates;
            });

            // Обработка клика по карте
            map.on('click', (e) => {
              this.edited._coords = `${e.lngLat.lng}, ${e.lngLat.lat}`;

              _marker.remove();
              _marker.setLngLat(e.lngLat).addTo(map);

              map.panTo(e.lngLat);
            });
          });
        }, 300);
      } else {
        this.ready = false;

        Map.clear();

        _map = null;
      }
    },
  },
  beforeMount() {
    this.loadStruct();
    this.loadData();
  },
  methods: {
    // Загрузка структуры
    loadStruct() {
      const fFields = [
        {
          name: 'title',
          required: true,
          label: 'Наименование',
          align: 'left',
          field: 'title',
          sortable: true,
        },
        {
          name: 'comment',
          required: true,
          label: 'Комментарий',
          align: 'left',
          field: 'comment',
          sortable: true,
        },
        {
          name: 'actions',
          label: 'Действия',
          field: 'actions',
        },
      ];

      this.fields = _copy(fFields);
      this.edit = _copy(fFields);

      this.edit.push(...[
        {
          name: 'loading_points',
          required: true,
          label: 'Точки загрузки',
          align: 'left',
          field: 'title',
          sortable: true,
          type: 'points_table',
        },
      ]);
    },

    // Загрузка данных
    loadData(props) {
      this.loading = true;

      let query = [];

      if (props && props.filter) {
        query.push(`title:${props.filter}`);
      }

      LoadingPointsService.list(this.pagination.page, this.pagination.rowsPerPage, query)
          .then((response) => {
            this.data = _copy(response.result.items).map((it) => {
              it.coords = `${it.lon}, ${it.lat}`;
              it.loading_point_places = (it.loading_point_places || []).map((lit) => {
                lit._id = lit.id;

                return lit;
              });

              return it;
            });

            this.pagination.rowsNumber = response.result.total;
          })
          .catch((res) => {
            this.$q.notify({
              message: res.error,
              type: 'negative',
            });
          })
          .finally(() => {
            this.loading = false;
          });
    },

    // Метод срабатывает когда нажимем "Добавить"
    onShowDialog() {
      this.edited = {
        title: '',
        loading_point_places: [],
        coords: '',
        comment: '',
      };

      this.showDialog = true;
    },

    // Отображение диалога на создание/редактирование точки на карте
    showPointsDialog() {
      this.dialogPoint = true;

      this.editedPoint = _copy({
        _id: new Date().getMilliseconds(),
        title: '',
        coords: '',
        comment: '',
      });
    },

    // При изменении точки координат
    onPointCoordsChange(item) {
      const point = _copy(this.editedPoint);

      point.coords = item.split(',')
          .map((it) => it.trim().replace(/[^\d.]/g, ''))
          .join(',');

      this.$set(this, 'editedPoint', point);
    },

    // При сохранении точки координат
    onSavePointDialog() {
      if (this.editedPoint.coords === '' || this.editedPoint.coords.split(',').length !== 2) {
        this.$q.notify({
          message: 'Не указана точка на карте',
          color: 'warning',
        });

        return;
      }

      const coords = this.editedPoint.coords.split(',');

      if (this.editedPoint.id !== undefined && this.editedPoint.id != null) {
        const point = _copy(this.editedPoint);
        point.lon = coords[0].trim();
        point.lat = coords[1].trim();

        (this.edited.loading_point_places || []).splice(
            (this.edited.loading_point_places || []).findIndex((it) => it._id === this.editedPoint._id),
            1,
            point,
        );
      } else {
        this.edited.loading_point_places.push({
          title: this.editedPoint.title,
          _id: this.editedPoint._id,
          lon: coords[0].trim(),
          lat: coords[1].trim(),
        });
      }

      this.dialogPoint = false;

      this.editedPoint = {};
    },

    // Отмена редактирования точки координат
    cancelPointsDialog() {
      this.dialogPoint = false;
      this.editedPoint = {};
    },

    // Сохранение точки на карте
    onSaveDialogMap() {
      if (_marker.getLngLat() == null || _currentLngLat == null) {
        this.$q.notify({
          message: 'Не выбрана точка на карте',
        });

        return;
      }

      _currentLngLat = _marker.getLngLat();

      this.$emit('point_map', _marker.getLngLat());

      this.dialogMap = false;
    },

    // Сохранение точки на карте v2
    onSaveDialogMap2() {
      this.dialogMap2 = false;

      if (this.edited._coords != null) {
        if (typeof this.edited._coords === 'string') {
          this.edited.coords = this.edited._coords;
        } else {
          this.edited.coords = this.edited._coords.join(',').replace(' ', '');
        }

        const coords = this.edited.coords
            .split(',')
            .map((it) => parseFloat(it.trim()));

        this.edited.lon = coords[0];
        this.edited.lat = coords[1];
      }

      this.edited._coords = null;
    },

    // Закрытие диалога карты
    cancelDialogMap() {
      this.dialogMap = false;
      this.dialogMap2 = false;
    },

    // Сохранение данных
    onSave() {
      if (this.edited.id !== undefined && this.edited.id != null) {
        const item = _copy(this.edited);
        const coords = item.coords || null;

        if (coords != null) {
          const nCoords = coords.split(',')
              .map((it) => it.trim().replace(/[^\d.]/g, ''));

          item.lon = nCoords[0];
          item.lat = nCoords[1];
        }

        LoadingPointsService.update(this.edited.id, _copy(item))
            .then((response) => {
              if (response.success) {
                this.dialogPoint = false;
                this.edited = {};
                this.editedPoint = {};
                this.showDialog = false;

                this.loadData();
              }
            })
            .catch(() => {
              this.$q.notify({
                message: 'Не удалось обновить точку погрузки',
                color: 'red',
              });
            });
      } else {
        const item = _copy(this.edited);
        const coords = item.coords || null;

        if (coords != null) {
          const nCoords = coords.split(',')
              .map((it) => it.trim().replace(/[^\d.]/g, ''));

          item.lon = nCoords[0];
          item.lat = nCoords[1];
        }

        LoadingPointsService.add(_copy(item))
            .then((response) => {
              if (response.success) {
                this.dialogPoint = false;
                this.edited = {};
                this.editedPoint = {};
                this.showDialog = false;

                this.loadData();
              }
            })
            .catch(() => {
              this.$q.notify({
                message: 'Не удалось создать точку погрузки',
                color: 'red',
              });
            });
      }
    },

    // Показ диалога на редактирование записи
    onEditItem(item) {
      this.edited = _copy(item);
      this.showDialog = true;
    },

    // Удаление записи
    onDelete(item) {
      if (confirm('Уверены, что хотите удалить пункт загрузки?')) {
        this.loading = true;

        LoadingPointsService.delete(item.id)
            .then((response) => {
              if (response.success) {
                this.loadData();
              }
            })
            .catch(() => {
              this.$q.notify({
                message: 'Не удалось удалить пункт загрузки',
                color: 'red',
              });
            })
            .finally(() => {
              this.loading = false;
            });
      }
    },

    // Показ диалога на редактирование точки
    onPointEdit(item) {
      this.editedPoint = _copy(item.row);
      this.editedPoint._id = item.row.id;
      this.editedPoint.coords = `${item.row.lon}, ${item.row.lat}`;

      this.dialogPoint = true;
    },

    // Удаление точки из списка
    onPointDelete(item) {
      this.edited.loading_point_places.splice(
          (this.edited.loading_point_places || []).findIndex((it) => it._id === item.row._id),
          1,
      );
    },

    // Установка точки на карте, если такая уже была
    placeMarkerIfEdit() {
      if (this.editedPoint && !!this.editedPoint.id && this.editedPoint.id > 0) {
        _marker.setLngLat([this.editedPoint.lon, this.editedPoint.lat]).addTo(_map);
        _map.panTo([this.editedPoint.lon, this.editedPoint.lat]);
        _currentLngLat = _marker.getLngLat();
      }
    },

    // Нажатие "На карте" для всей записи
    onPointMapPlace() {
      this.dialogMap2 = true;
    },
  },
};
</script>
