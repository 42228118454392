<template>
  <div class="row q-col-gutter-md q-py-md"
       :style="{'max-width': maxWidth + 'px'}"
  >
    <div class="flex items-center q-mb-sm">
      <div>
        <q-btn flat
               icon="mdi-arrow-left"
               @click="prevMonth"
        >
          <q-tooltip>Предыдущая неделя</q-tooltip>
        </q-btn>
      </div>

      <div class="q-px-sm text-h6"> {{ currentWeek }} нед., {{ startWeek }} - {{ endWeek }}</div>

      <div>
        <q-btn flat
               icon="mdi-arrow-right"
               @click="nextMonth"
        >
          <q-tooltip>Следующий неделя</q-tooltip>
        </q-btn>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment/moment';

export default {
  name: 'WeekPicker',
  props: {
    startDate: {
      type: String,
    },
    endDate: {
      type: String,
    },
    // Максимальная ширина компонента
    maxWidth: {
      type: Number,
      default: 600,
    },
    // Инициализация входной переменной текущей датой
    autofill: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      year: null,
      month: null,
      week: null,
      currentWeek: 0,
      startWeek: null,
      endWeek: null,
      monthDays: 0,
      monthWeek: 0,
      monthName: '',
      monthNames: [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь',
      ],
    };
  },
  computed: {
    innerStartDate: {
      get() {
        return this.startDate;
      },
      set(val) {
        this.$emit('update:startDate', val);
      },
    },
    innerEndDate: {
      get() {
        return this.endDate;
      },
      set(val) {
        this.$emit('update:endDate', val);
      },
    },
  },
  created() {
    const initDate = new Date();
    this.month = initDate.getMonth();
    this.year = initDate.getFullYear();
    this.day = initDate.getDate();
    this.week = Math.ceil(this.day / 7);
    this.initCalendar();

  },
  methods: {
    // Инициализация календаря
    initCalendar() {
      this.monthName = this.monthNames[this.month];
      let startDate = moment().subtract(this.monthWeek, 'weeks').startOf('isoWeek').format('yyyy-MM-DD');
      let endDate = moment().subtract(this.monthWeek, 'weeks').endOf('isoWeek').format('yyyy-MM-DD');

      this.startWeek = moment(startDate).locale('ru').format('DD MMM');
      this.endWeek = moment(endDate).locale('ru').format('DD MMM');
      this.currentWeek = moment(endDate).isoWeek();
      this.innerStartDate = startDate;
      this.innerEndDate = endDate;
      this.$emit('change');
    },

    // Предыдущий месяц
    prevMonth() {
      if (this.week === 1) {
        this.month--;
        this.week = Math.ceil(new Date(2022, this.month, 0).getDate() / 7);
      } else {
        this.week--;
      }
      this.monthWeek++;

      this.initCalendar();
    },

    // Следующий месяц
    nextMonth() {
      if (this.week === 5) {
        this.week = 1;
        this.month++;
      } else {
        this.week++;
      }
      this.monthWeek--;

      this.initCalendar();
    },

    // Количество дней в месяце
    getMonthDays(year, month) {
      return (new Date(year, month + 1, 0)).getDate();
    },
  },
};
</script>
