<template>
  <div>
    <div class="q-gutter-sm">
      <q-radio v-model="periodType" val="month" label="Месяц"/>
      <q-radio v-model="periodType" val="range" label="Период"/>
    </div>

    <div v-if="periodType === 'range'"
         class="row items-center q-col-gutter-md q-py-md"
         style="max-width: 600px;"
    >
      <RangeDates class="col-8"
                  :startDate.sync="tripDateStart"
                  :endDate.sync="tripDateEnd"
      ></RangeDates>

      <div class="col-4">
        <q-btn class="q-mb-md"
               label="Показать отчет"
               @click="loadData"
               :loading="loading"
        ></q-btn>
      </div>
    </div>

    <MonthPicker v-else
                 :startDate.sync="tripDateStart"
                 :endDate.sync="tripDateEnd"
                 @change="loadData"
    ></MonthPicker>

    <q-table row-key="name"
             :data="data"
             :columns="columns"
             :loading="loading"
             :pagination="{rowsPerPage: 0}"
             hide-pagination
    >
      <template v-if="data.length > 0" #top-row="{cols}">
        <q-tr class="bg-grey-4">
          <q-td v-for="(col, index) of cols" :key="col.name">
            <template v-if="index === 0">Итого</template>
            <template v-if="index > 1">
              {{ _priceFormat(data.reduce((acc, val) => acc + val[col.name], 0)) }}
            </template>
          </q-td>
        </q-tr>
      </template>

      <template v-if="data.length > 0" #bottom-row="{cols}">
        <q-tr class="bg-grey-4">
          <q-td v-for="(col, index) of cols" :key="col.name">
            <template v-if="index === 0">Итого</template>
            <template v-if="index > 1">
              {{ _priceFormat(data.reduce((acc, val) => acc + val[col.name], 0)) }}
            </template>
          </q-td>
        </q-tr>
      </template>
    </q-table>
  </div>
</template>

<script>
import { getData, METHODS } from '@/utils/http';
import RangeDates from '@/components/fields/date/RangeDates';
import MonthPicker from '@/components/fields/date/MonthPicker';

export default {
  name: 'ReportByContractor',
  components: { MonthPicker, RangeDates },
  data() {
    return {
      tripDateStart: null,
      tripDateEnd: null,
      loading: false,
      periodType: 'month',
      data: [],
      columns: [
        {
          name: 'index',
          label: 'П/п',
          align: 'left',
          field: 'index',
        },
        {
          name: 'contractor_name',
          label: 'Контрагент',
          align: 'left',
          field: 'contractor_name',
        },
        {
          name: 'sum_price',
          label: 'Сумма дохода',
          align: 'left',
          field: (row) => _priceFormat(row.sum_price),
        },
      ],
    };
  },
  watch: {
    periodType() {
      this.loadData();
    },
  },
  methods: {
    loadData() {
      if (this.tripDateStart == null || this.tripDateEnd == null) {
        this.$q.notify({
          color: 'warning',
          message: 'Заполните поле с датой',
        });
        return;
      }

      this.loading = true;

      getData(`/reports/by/contractor?start_date=${this.tripDateStart}&end_date=${this.tripDateEnd}`, METHODS.GET)
          .then((res) => {
            this.data = res.result.map(
                (it, i) => Object.assign(it, { index: i, 'sum_price': parseFloat(it.sum_price) }),
            );
          })
          .catch((res) => {
            this.$q.notify({
              message: res.error,
              type: 'negative',
            });
          })
          .finally(() => {
            this.loading = false;
          });
    },
  },
};
</script>
