import { getData, METHODS } from '@/utils/http';
import router from '@/router/router';

const tokenKey = 'auth/token';
const profileKey = 'auth/profile';

const profile = JSON.parse(localStorage.getItem(profileKey));
const token = localStorage.getItem(tokenKey);
const isAuthenticated = !!token;

const state = {
  token,
  isAuthenticated,
  profile,
  loadingProfile: !profile,
};

const mutations = {
  setLoading: (state, payload) => state.loadingProfile = payload,
  setToken(state, token) {
    if (token) {
      state.isAuthenticated = true;
      state.token = token;
      localStorage.setItem(tokenKey, token);
    }
  },
  setProfile(state, payload) {
    state.profile = payload;
    localStorage.setItem('auth/profile', JSON.stringify(payload));
    state.loadingProfile = false;
  },
  removeCredentials(state) {
    state.isAuthenticated = false;
    state.token = null;
    state.profile = null;
    localStorage.removeItem(tokenKey);
    localStorage.removeItem(profileKey);

    router.push('/login');
  },
};

const getters = {
  isAuthenticated: (state) => state.isAuthenticated,
  isLoading: (state) => !state.profile && state.loadingProfile,
  isConnected: (state) => state.isConnected,

  getToken: (state) => state.token,
  getProfile: (state) => state.profile,
};

const actions = {
  /** Авторизация пользователя */
  async login({ commit }, { email, password }) {
    return new Promise((resolve, reject) => {
      getData(
        '/auth/login/email',
        METHODS.POST,
        {
          params: {
            email,
            password,
          },
        },
      ).then((res) => {
        commit('setToken', res?.result?.token);
        resolve(res);
      }).catch((err) => {
        reject(err);
      });
    });
  },

  /** Получение данных пользователя */
  async getAccount({ commit, dispatch }) {
    commit('setLoading', true);

    const res = await getData(
      '/auth/self',
      METHODS.GET,
    );
    commit('setProfile', res.result);
    dispatch('menu/loadMenu', null, { root: true });
    return res.result;
  },

  /**
   * Выход из аккаунта
   */
  logout({ commit }) {
    getData(
      '/auth/logout',
      METHODS.DELETE,
    ).finally(() => {
      commit('removeCredentials');
      commit('menu/clearMenu', null, { root: true });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
