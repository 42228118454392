import OrdersList from '@/modules/orders/OrdersList';
import OrdersModeration from '@/modules/orders/OrdersModeration/OrdersModeration';
import OrdersBids from '@/modules/orders/OrdersBids/OrdersBids';
import NestedMenu from '@/components/for_router/NestedMenu';

export default {
  name: 'orders',
  path: '/orders',
  component: NestedMenu,
  redirect: '/orders/list',
  children: [
    {
      path: 'list',
      component: NestedMenu,
      redirect: '/orders/list/on_moderate',
      props: {
        nestedLvl: 2,
      },
      children: [
        {
          path: ':status',
          component: OrdersList,
        },
      ],
    },
    {
      path: 'moderation',
      component: NestedMenu,
      redirect: '/orders/moderation/on_moderate',
      props: {
        nestedLvl: 2,
      },
      children: [
        {
          path: ':status',
          component: OrdersModeration,
        },
      ],
    },
    {
      path: 'moderation/:id',
      component: OrdersModeration,
    },
    {
      path: 'bids',
      component: OrdersBids,
    },
    {
      path: 'bids/:id',
      component: OrdersBids,
    },
  ],
};
