<template>
  <div>
    <div class="flex">
      <ref-select
          label="Прицепы"
          v-model="trailer"
          option-label="reg_number"
          url="/trailers"
          :autocomplete="true"
          @input="loadData"
      >
      </ref-select>
    </div>

    <div v-if="loading_all" class="text-center">
      <q-spinner size="48" />
    </div>

    <div v-else>
      <div
          v-for="type in data_all"
          :key="type.id"
          class="q-mb-md"
      >
        <h6 class="q-ma-none q-mb-sm">{{type.title}}</h6>
        <div
            class="row q-py-sm"
            v-for="(position, index) in type.node_positions"
            :key="position.id"
        >
          <div class="col-3">{{position.title}}</div>
          <div class="col-6">
            <template v-if="loading">
              <div v-if="index === 0" class="text-left">
                <q-spinner />
              </div>
            </template>

            <template v-else>
              <template v-if="loaded_data">
                <template v-if="positions_nodes[position.id]">
                  <div>
                    <div>
                      {{positions_nodes[position.id].title}}
                      ({{positions_nodes[position.id].pivot.distance || 0}}км)

                      <q-btn size="sm" class="q-ml-md" @click="detachNode(position.id)">
                        Убрать узел
                      </q-btn>
                    </div>
                    <div class="text-grey-6">
                      {{positions_nodes[position.id].inventory_number}}
                      {{positions_nodes[position.id].manufacture.title}}
                    </div>
                  </div>
                </template>

                <template v-else>
                  Не установлено
                  <q-btn size="sm" class="q-ml-md" @click="showEdit(position.id, position.type_of_node_id)">
                    Установить узел
                  </q-btn>
                </template>
              </template>
              <template v-else>
                Выберите прицеп
              </template>
            </template>
          </div>
        </div>
      </div>
    </div>

    <q-dialog
        v-if="addDialog"
        v-model="addDialog"
        @close="closeEdit"
    >
      <q-card style="width: 300px">
        <q-card-section>
          <ref-select
              label="Узел"
              v-model="addItem.inventory_node"
              url="/inventory_nodes/list/not-installed"
              :option-subtitle="row => row.inventory_number + ' ' + row.manufacture.title"
          >
          </ref-select>
        </q-card-section>

        <q-card-actions>
          <q-btn v-close-popup>Отмена</q-btn>

          <q-space />

          <q-btn
              @click="attachNode(addItem)"
              :loading="addLoading"
          >
            Сохранить
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import RefSelect from '@/components/fields/RefSelect';
import {getData, METHODS} from '@/utils/http';

export default {
  name: 'NodeOnTrailers',
  components: {RefSelect},
  data() {
    return {
      loading: false,
      loading_all: true,
      loaded_data: false,
      trailer: null,
      data_all: [],
      data_vehicle: [],
      positions_nodes: {},
      addDialog: false,
      addLoading: false,
      addItem: {},
    };
  },
  created() {
    this.loadPoistions();
  },
  methods: {
    loadPoistions() {
      getData('/refs/types_of_nodes/list/by/entity/TRAILER_TYPE', METHODS.GET)
          .then((res) => {
            this.data_all = res.result.items;
          })
          .finally(() => {
            this.loading_all = false;
          });
    },
    loadData() {
      if (!this.trailer || !this.trailer.id) {
        return;
      }

      getData(`/inventory_nodes/by/entity/TRAILER_TYPE/${this.trailer.id}`, METHODS.GET)
        .then((res) => {
          this.loaded_data = true;
          let positions_nodes = {};

          res.result.items.forEach((it) => {
            positions_nodes[it.pivot.node_position_id] = it;
          });

          this.positions_nodes = positions_nodes;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    attachNode(item) {
      this.addLoading = true;
      this.loading = true;

      getData('/inventory_nodes/attach', METHODS.POST, {
        params: {
          'inventory_node_id': item.inventory_node.id,
          'entity_type': 'TRAILER_TYPE',
          'entity_id': this.trailer.id,
          'node_position_id': item.position_id,
        },
      })
        .then(() => {
          this.loadData();
          this.closeEdit();
        })
        .catch(() => {
          this.loading = false;
        })
        .finally(() => {
          this.addLoading = false;
        });
    },
    detachNode(position_id) {
      const positionData = this.positions_nodes[position_id];

      if (confirm('Уверены, что хотите снять этот узел?')) {
        getData('/inventory_nodes/detach', METHODS.POST, {
          params: {
            'entity_type': 'VEHICLE_TYPE',
            'entity_id': this.trailer.id,
            'inventory_node_journal_id': positionData.pivot.id,
            'end_date': null,
          },
        })
          .then(() => {
            this.loadData();
          });
      }
    },
    closeEdit() {
      this.addItem = null;
      this.addDialog = false;
    },
    showEdit(position_id) {
      this.addItem = {
        position_id: position_id,
        inventory_node: null,
      };

      this.addDialog = true;
    },
  },
};
</script>
