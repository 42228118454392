<template>
  <div>
    <q-table
        :data="data"
        :columns="columnsList"
        row-key="name"
        :pagination.sync="pagination"
        hide-pagination
    >
      <template #body="props">
        <tr
            :props="props"
            @click="openOrder(props.row)"
            :class="getWeightIndicator(props.row)"
        >
          <q-td
              v-for="column in props.cols"
              :key="`${column.name}_${props.rowIndex}`"
          >
            <template v-if="column.name === 'active_status'">
              <template v-if="column.value && column.value.title">
                {{ column.value.title }}
                <div class="text-caption">
                  {{ column.value.date }} / <span class="text-bold">{{ column.value.time_diff }}</span>
                </div>
              </template>
            </template>

            <template v-else-if="column.name === 'driver'">
              <template v-if="column.value">
                {{ column.value }}
              </template>

              <q-icon v-else color="red" name="warning"></q-icon>
            </template>

            <template v-else-if="column.name === 'actions'">
              <q-btn
                  round flat
                  icon="edit"
                  size="sm"
                  @click.stop="showEdit(props.row)"
              >
                <q-tooltip>
                  Изменить параметры
                </q-tooltip>
              </q-btn>

              <q-btn
                  v-if="canClose"
                  round flat
                  text-color="red"
                  icon="close"
                  size="sm"
                  @click.stop="$emit('onClose', props.row)"
              >
                <q-tooltip>
                  Отменить заказ
                </q-tooltip>
              </q-btn>
            </template>

            <template v-else>
              {{ column.value }}
            </template>
          </q-td>
        </tr>
      </template>
    </q-table>

    <q-dialog v-if="editDialog && editItem" v-model="editDialog">
      <q-card>
        <!-- Заголовок -->
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">
            Изменить параметры заказа
          </div>

          <q-space/>

          <q-btn flat round dense
                 v-close-popup
                 icon="close"
                 class="q-ml-md"
          ></q-btn>
        </q-card-section>

        <!-- Формируем поля для редактирования-->
        <q-card-section style="max-height: 500px; overflow: auto">
          <div style="max-height: 500px">
            <q-input
                label="Назначено"
                v-model="editItem.cargo_count"
            />

            <q-input
                label="Загружено"
                v-model="editItem.loaded"
            />

            <q-input
                label="Выгружено"
                v-model="editItem.unloaded"
            />
            <q-input
                label="Цена за рейс"
                v-model="editItem.price"
                type="number"
                @blur="filterForNumbers"
            />
            <q-input
                label="Расстояние"
                v-model="editItem.distance"
                type="number"
            /> 
          </div>
        </q-card-section>

        <!-- Действия -->
        <q-card-actions align="right">
          <q-btn
              flat
              label="OK"
              color="primary"
              :loading="editLoading"
              @click="save"
          ></q-btn>

          <q-btn
              flat
              label="Отмена"
              color="negative"
              :disable="editLoading"
              v-close-popup
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { getData, METHODS } from '@/utils/http';

export default {
  name: 'TripTable',
  props: {
    order: {
      type: Object,
    },
    data: {
      type: Array,
    },
    columns: {
      type: Array,
    },
    canClose: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      editDialog: false,
      editLoading: false,
      editItem: null,
      columnsList: [],
      pagination: {
        rowsPerPage: 0,
      },
    };
  },
  created() {
    this.columnsList = this.columns.filter((it) => !(it.name === 'actions' && !this.canClose));
  },
  methods: {
    getWeightIndicator(row) {
      if (row.vehicle.trailers.length === 0 || !row.loaded) {
        return '';
      }

      const vehicle = row.vehicle;

      let capacity = vehicle.trailers[0].pivot.vehicle_load_capacity?.weight || 0;
      capacity -= row.vehicle.weight || 0;
      capacity -= vehicle.trailers.length ? (vehicle.trailers[0].weight || 0) : 0;
      capacity = (capacity > 0 ? capacity : 1) / 1000;
      const diff = (capacity - (row.loaded || 0)) * 100;

      if (diff / capacity >= 3) {
        return 'bg-orange-2';
      } else if (diff / capacity < 0) {
        return 'bg-red-2';
      }

      return '';
    },
    openOrder(order) {
      let newOrder = _copy(order);
      newOrder.organization_order = this.order;

      this.$emit('onOpen', newOrder);
    },
    showEdit(item) {
      this.editItem = _copy(item);
      this.editDialog = true;
    },
    save() {
      if (!this.editItem) {
        return;
      }

      this.editLoading = true;

      getData('/vehicle_orders/' + this.editItem.id, METHODS.PUT, {
        params: {
          loaded: this.editItem.loaded,
          unloaded: this.editItem.unloaded,
          cargo_count: this.editItem.cargo_count,
          price: this.editItem.price,
          distance: +this.editItem.distance,
        },
      })
          .then(() => {
            this.editItem = null;
            this.editDialog = false;
            this.$emit('onReload');
          })
          .catch((res) => {
            this.$q.notify({
              message: res.error,
              type: 'negative',
            });
          })
          .finally(() => {
            this.editLoading = false;
          });
    },
    filterForNumbers(e) {
      this.editItem.price = Math.abs(e.target.value);
    },
  },
};
</script>
