<template>
  <q-table
      :props="reportProps"
      :data="fuelData"
      :columns="expandedColumns"
      :loading="loading"
      :hide-pagination="true"
      :pagination.sync="pagination"
  ></q-table>
</template>

<script>
import {FORMAT_FOR_USER, formatDate} from '@/utils/date';
import {getData, METHODS} from '@/utils/http';

function formatFuel(fuel_amount) {
  switch (fuel_amount) {
    case '0':
      return fuel_amount = '0';
    case '0.25':
      return fuel_amount = '1/4';
    case '0.5':
      return fuel_amount = '1/2';
    case '0.75':
      return fuel_amount = '3/4';
    case '1':
      return fuel_amount = '1';
    default:
      return fuel_amount;
  }
}

export default {
  name: 'FuelTable',
  props: {
    reportProps: Object,
    tripDateStart: String,
    tripDateEnd: String,
  },
  data() {
    return {
      loading: false,
      fuelData: [],
      pagination: {
        page: 1,
        rowsPerPage: 0,
      },
      expandedColumns: [
        {
          name: 'date',
          label: 'Дата',
          align: 'left',
          field: (row) => formatDate(row.date, FORMAT_FOR_USER.ONLY_DATE),
        },
        {
          name: 'refueled',
          label: 'Заправлено',
          align: 'left',
          field: (row) => formatFuel(row.refueled),
        },
        {
          name: 'fuel_amount',
          label: 'Остаток в баке на начало недели',
          align: 'left',
          field: (row) => formatFuel(row.fuel_amount),
        },
      ],
    };
  },
  watch: {
    tripDateStart() {
      this.$emit('changeMonth');
    },
  },
  created() {
    this.getFuel();
  },
  methods: {
    getFuel() {
      this.loading = true;
      const driverId = this.reportProps.key;

      getData(
     `/reports/refills_vehicle?start_date=${this.tripDateStart}&end_date=${this.tripDateEnd}&vehicle_id=${driverId}`,
          METHODS.GET)
          .then((res) => {
            this.fuelData = [].concat(res.result);
          })
          .catch((res) => {
            this.$q.notify({
              message: res.error,
              type: 'negative',
            });
          })
          .finally(() => {
            this.loading = false;
          });

    },
  },
};
</script>

<style scoped>

</style>
