<template>
  <div>
    <div class="q-gutter-sm">
      <q-radio v-model="periodType" val="month" label="Месяц"/>
      <q-radio v-model="periodType" val="range" label="Период"/>
    </div>

    <div v-if="periodType === 'range'"
         class="row items-center q-col-gutter-md q-py-md"
         style="max-width: 600px;"
    >
      <RangeDates class="col-8"
                  :startDate.sync="tripDateStart"
                  :endDate.sync="tripDateEnd"
      ></RangeDates>

      <div class="col-4">
        <q-btn class="q-mb-md"
               label="Показать отчет"
               @click="loadData"
               :loading="loading"
        ></q-btn>
      </div>
    </div>

    <MonthPicker v-else
                 :startDate.sync="tripDateStart"
                 :endDate.sync="tripDateEnd"
                 @change="loadData"
    ></MonthPicker>

    <q-list class="text-h6">
      <q-item class="items-center">
        Вал доход:
        <q-spinner v-if="loading" color="primary" class="q-ml-sm"/>
        <template v-else>
          {{ data.gross | format }}
        </template>
      </q-item>
      <q-item class="items-center">
        Вал расход ТМЦ:
        <q-spinner v-if="loading" color="primary" class="q-ml-sm"/>
        <template v-else>
          {{ data.expenses | format }}
        </template>
      </q-item>
      <q-item class="column">
        Вал зарплаты:

        <q-spinner v-if="loading" color="primary" class="q-ml-sm"/>
        <template v-else>
          <div class="column">
            <div class="row items-center">
              Сумма на руки:

              {{ data.driverExpenses | format }}

              <q-checkbox v-model="includeSalary" @click.native="getSum"></q-checkbox>
            </div>

            <div class="row items-center">
              Сумма налога:

              {{ data.driverTaxExpenses | format }}

              <q-checkbox v-model="includeTaxSalary" @click.native="getSum"></q-checkbox>
            </div>
          </div>

        </template>
      </q-item>
      <q-item class="column">
        <div class="row items-center">
          Вал расход ГСМ:

          <q-checkbox v-model="includeFuel" @click.native="getSum"></q-checkbox>
        </div>
        <q-spinner v-if="loading" color="primary" class="q-ml-sm"/>
        <template v-else>
          <div class="column">
            <div class="row items-center">
              <q-radio label="Фактический" v-model="currentFuel" val="actual" @click.native="getSum"/>

              <div class="q-pl-sm">
                {{ data.actualExpenses | format  }}
              </div>
            </div>
            <div class="row items-center">
              <q-radio label="Расчётный" v-model="currentFuel" val="estimated" @click.native="getSum"/>

              <div class="q-pl-sm">
                {{ data.estimatedExpenses | format  }}
              </div>
            </div>
            <div class="row items-center">
              <q-radio label="Отчётный" v-model="currentFuel" val="reported" @click.native="getSum"/>

              <div class="q-pl-sm">
                {{ data.reportedExpenses | format  }}
              </div>
            </div>
          </div>
        </template>
      </q-item>
      <q-item class="items-center">
        Итого расход:
        <q-spinner v-if="loading" color="primary" class="q-ml-sm"/>
        <template v-else>
          {{ totalExpenses | format }}
        </template>
      </q-item>
      <q-item class="items-center">
        Вал прибыль:
        <q-spinner v-if="loading" color="primary" class="q-ml-sm"/>
        <template v-else>
          {{ totalProfit | format }}
        </template>
      </q-item>
    </q-list>
  </div>
</template>

<script>
import {getData, METHODS} from '@/utils/http';
import RangeDates from '@/components/fields/date/RangeDates';
import MonthPicker from '@/components/fields/date/MonthPicker';

export default {
  name: 'ReportTotal',
  components: {MonthPicker, RangeDates},
  filters: {
    format: function(value) {
      return _priceFormat(value);
    },
  },
  data() {
    return {
      tripDateStart: null,
      tripDateEnd: null,
      loading: false,
      includeSalary: false,
      includeTaxSalary: false,
      includeFuel: true,
      currentFuel: 'actual',
      currentExpenses: 0,
      periodType: 'month',
      totalExpenses: 0,
      totalProfit: 0,
      data: {
        gross: 0,
        expenses: 0,
        profit: 0,
        driverExpenses: 0,
        driverTaxExpenses: 0,
        actualExpenses: 0,
        estimatedExpenses: 0,
        reportedExpenses: 0,
      },
    };
  },
  watch: {
    periodType() {
      this.loadData();
    },
  },
  methods: {
    loadData() {
      if (this.tripDateStart == null || this.tripDateEnd == null) {
        this.$q.notify({
          color: 'warning',
          message: 'Заполните поле с датой',
        });
        return;
      }

      this.loading = true;

      getData(`/reports/total?start_date=${this.tripDateStart}&end_date=${this.tripDateEnd}`, METHODS.GET)
          .then((res) => {
            const {
              gross = 0,
              expenses = 0,
              profit = 0,
              expenses_driver = 0,
              expenses_driver_vat = 0,
              actual_fuel_expenses = 0,
              estimated_fuel_expenses = 0,
              reported_fuel_expenses = 0,
            } = res.result;

            this.data = {
              gross: gross,
              expenses: expenses,
              profit: profit,
              driverExpenses: expenses_driver,
              driverTaxExpenses: expenses_driver_vat,
              actualExpenses: actual_fuel_expenses,
              estimatedExpenses: estimated_fuel_expenses,
              reportedExpenses: reported_fuel_expenses,
            };
            this.getSum();

          })
          .catch((res) => {
            this.$q.notify({
              message: res.error,
              type: 'negative',
            });
          })
          .finally(() => {
            this.loading = false;
          });
    },
    getSum() {
      let gross = this.data.gross;
      let expenses = this.data.expenses;
      let driverExpenses = this.data.driverExpenses;
      let driverTaxExpenses = this.data.driverTaxExpenses;
      let actualExpenses = this.data.actualExpenses;
      let estimatedExpenses = this.data.estimatedExpenses;
      let reportedExpenses = this.data.reportedExpenses;

      switch (this.currentFuel) {
        case 'actual':
          this.currentExpenses = actualExpenses;
          break;
        case 'estimated':
          this.currentExpenses = estimatedExpenses;
          break;
        case 'reported':
          this.currentExpenses = reportedExpenses;
          break;
      }

      this.totalExpenses = expenses + (this.includeSalary ? driverExpenses : 0)
                                    + (this.includeTaxSalary ? driverTaxExpenses : 0)
                                    + (this.includeFuel ? this.currentExpenses : 0);
      this.totalProfit = gross - this.totalExpenses;
    },
  },
};
</script>
