import { getData, METHODS } from '@/utils/http';

/**
 *
 */
export default class CrudService {

  /**
   *
   * @param entityName
   * @param page
   * @param perPage
   * @param include
   * @returns {Promise<unknown>}
   */
  static list(entityName, page = 1, perPage = 1, include = '', filters = []) {
    let query = `/refs/${entityName}?page=${page}&perPage=${perPage}&include=${include}`;

    if (filters.length) {
      query += `&filters=${filters.join(',')}`;
    }

    return getData(query, METHODS.GET, {});
  }

  static read(entityName, id) {
    return getData('/refs/' + entityName + '/' + id, METHODS.GET, {});
  }

  static add(entityName, data) {
    return getData('/refs/' + entityName, METHODS.POST, {
      params: data,
    });
  }

  static edit(entityName, id, data) {
    return getData('/refs/' + entityName + '/' + id, METHODS.PUT, {
      params: data,
    });
  }

  static delete(entityName, id) {
    return getData('/refs/' + entityName + '/' + id, METHODS.DELETE, {});
  }
}
