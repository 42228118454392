<template>
  <div>
    <entity-manager
        :name="ref.name"
        :title="ref.title"
        :entity-url="ref.url"
        :list-columns="ref.listColumns"
        :include="ref.include"
        :filterBy="ref.filterBy"
        :perms="ref.perms"
        :access="ref.access"
        :use-native-add="false"
        :use-native-edit="false"
        :key="dataKey"
        @onAddClick="showEdit"
        @onEditClick="showEdit"
        @onDelete="remove"
    >
      <template #actions="props">
        <q-btn
          round flat
          icon="content_copy"
          size="sm"
          @click.stop="showEdit(props.row, true)"
        >
          <q-tooltip>
            Копировать
          </q-tooltip>
        </q-btn>
      </template>
    </entity-manager>

    <q-dialog
        v-if="editDialog"
        v-model="editDialog"
        @close="closeEdit"
    >
      <q-card style="width: 300px">
        <q-card-section>
          <q-input
              label="Наименование"
              v-model="editItem.title"
          />

          <q-input
              label="Инвентарный номер"
              v-model="editItem.inventory_number"
          />

          <q-input
            label="Начальный пробег"
            v-model="editItem.distance"
            type="number"
          />

          <ref-select
              label="Вид узла"
              v-model="editItem.type_of_node"
              option-label="title"
              url="/refs/types_of_nodes"
              :autocomplete="true"
              @input="loadManufacturers"
          >
          </ref-select>

          <ref-select
              label="Производитель"
              v-model="editItem.manufacture"
              :url="manufacturersUrl"
              :key="manufacturerKey"
              :disabled="!editItem.type_of_node"
          >
          </ref-select>

          <ref-select
              label="Поставщик"
              v-model="editItem.provider"
              option-label="title"
              url="/refs/providers"
              :autocomplete="true"
          >
          </ref-select>

          <q-input
            label="Стоимость"
            v-model="editItem.price"
            type="number"
          />
        </q-card-section>

        <q-card-actions>
          <q-btn v-close-popup>Отмена</q-btn>

          <q-space />

          <q-btn
              @click="save"
              :loading="editLoading"
          >
            Сохранить
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import HasPerms from '@/components/guard/mixins/HasPerms';
import EntityManager from '@/components/entity_manager/EntityManager';
import {getData, METHODS} from '@/utils/http';
import RefSelect from '@/components/fields/RefSelect';
import {inventory_nodes_ref} from '@/structures/inventory_node';

export default {
  name: 'AllList',
  components: {EntityManager, RefSelect},
  mixins: [HasPerms],
  data() {
    return {
      editDialog: false,
      editLoading: false,
      editItem: {},
      data: [],
      pagination: {
        rowsPerPage: 50,
        page: 1,
        rowsNumber: 0,
      },
      dataKey: 1,
      manufacturerKey: 1,
      loading: false,
      ref: _copy(inventory_nodes_ref),
      manufacturersUrl: '/refs/manufacturers',
    };
  },
  created() {
    this.ref.url = '/inventory_nodes/list/not-installed';
  },
  methods: {
    save() {
      this.editLoading = true;

      let editItem = _copy(this.editItem);
      const method = !editItem.isCopy && editItem.id ? METHODS.PUT : METHODS.POST;
      const URL = '/inventory_nodes' + (editItem.id ? `/${editItem.id}` : '');

      getData(URL, method, {
        params: editItem,
      })
          .then(() => {
            this.closeEdit();
            this.dataKey = (new Date()).getTime();
          })
          .catch((res) => {
            this.$q.notify({
              message: res.error,
              type: 'negative',
            });
          })
          .finally(() => {
            this.editLoading = false;
          });
    },
    remove(item) {
      if (!item) {
        return;
      }

      getData(`/inventory_nodes/${item.id}`, METHODS.DELETE)
          .then(() => {
            this.dataKey = (new Date()).getTime();
          })
          .catch((res) => {
            this.$q.notify({
              message: res.error,
              type: 'negative',
            });
          })
          .finally(() => {
            this.editLoading = false;
          });
    },
    closeEdit() {
      this.editItem = null;
      this.editDialog = false;
    },
    showEdit(item, isCopy) {
      const itemCopy = _copy(item) || {};

      if (isCopy) {
        itemCopy.isCopy = true;
        delete itemCopy.id;
        delete itemCopy.inventory_number;
        delete itemCopy.distance;
      }

      this.editItem = itemCopy;
      this.editDialog = true;
    },
    loadManufacturers() {
      this.manufacturersUrl = '/refs/manufacturers';

      if (this.editItem && this.editItem.type_of_node) {
        this.manufacturersUrl += `/by/types_of_node/${this.editItem.type_of_node.id}`;
      }

      this.manufacturerKey = (new Date()).getTime();
    },
    getPerms_() {
      return [this.ref.perms.list];
    },
    // При клике по записи
    onRowClick(item) {
      this.editDialog = true;

      this.editItem = _copy(item);
    },
    // При нажатии на кнопку "Добавить"
    onAddClick() {
      this.editDialog = true;

      this.editItem = {};
    },
    onEditClick(e) {
      this.onRowClick(e);
    },
    onRefresh() {
      this.refreshKey = (new Date()).getTime();
    },
  },
};
</script>
