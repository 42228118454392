<template>
  <div>
    <entity-manager
        :name="ref.name"
        :title="ref.title"
        :entity-url="ref.url"
        :list-columns="ref.listColumns"
        :list-edit="ref.editColumns"
        :include="ref.include"
        :filterBy="ref.filterBy"
        :perms="ref.perms"
        :access="ref.access"
    ></entity-manager>
  </div>
</template>

<script>
import EntityManager from '@/components/entity_manager/EntityManager';
import HasPerms from '@/components/guard/mixins/HasPerms';
import {node_report} from '@/structures/refs/node_report';

export default {
  name: 'TypesOfNode',
  components: {EntityManager},
  mixins: [HasPerms],
  data() {
    return {
      loading: true,
      ref: node_report,
    };
  },
  methods: {
    getPerms_() {
      if (!this.ref || !this.ref.perms || this.ref.perms.length === 0) {
        return [];
      }

      return [this.ref.perms.list];
    },
  },
};
</script>
