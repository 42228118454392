<template>
  <q-dialog v-model="showDialog" persistent>
    <q-card :style="{'min-width': minWidth + 'px'}">

      <!--Апп бар-->
      <q-banner class="bg-primary text-white">
        <div class="row items-center justify-between">
          <div class="col-auto">
            <q-toolbar-title>{{ header }}</q-toolbar-title>
          </div>
          <div class="col-auto">
            <q-btn flat round dense
                   v-close-popup
                   icon="close"
                   @click="$emit('clickCancel')"
            ></q-btn>
          </div>
        </div>
      </q-banner>

      <!--Контент-->
      <q-card-section>
        <slot name="default"/>
      </q-card-section>

      <!--Футер-->
      <q-card-actions align="between" class="bg-primary q-px-md">
        <q-btn
          color="white"
          :label="labelCancel || 'Отмена'"
          text-color="primary"
          v-close-popup
          @click="$emit('clickCancel')"
        ></q-btn>

        <q-btn
          :loading="loadingApply"
          color="white"
          :label="labelApply || 'Сохранить'"
          text-color="primary"
          @click="$emit('clickApply')"
        ></q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: 'Dialog',
  props: {
    value: Boolean,
    header: String,
    labelCancel: String,
    labelApply: String,
    height: Number,
    minWidth: {
      type: Number,
      default: 400,
    },
    loadingApply: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>
