<template>
  <q-dialog maximized v-model="show">
    <q-card class="full-width full-height" v-if="show">
      <!-- Toolbar -->
      <q-toolbar class="bg-primary bg-dark text-white">
        <q-toolbar-title>
          Направление: {{ edited.id != null ? 'Редактировать' : 'Новая' }} запись
        </q-toolbar-title>

        <q-space/>

        <q-btn
          color="white"
          text-color="black"
          :loading="isLoading"
          @click="onSaveRoute"
        >
          Сохранить
        </q-btn>

        <q-btn
          v-close-popup
          class="q-ml-md"
          color="red"
          text-color="white"
        >
          Отмена
        </q-btn>
      </q-toolbar>

      <!-- Основное содержимое -->
      <q-card-section class="row" style="height: 94%;">
        <div class="col-4 column q-pa-md" style="width: 330px;">
          <!-- Наименование -->
          <div class="q-my-md">
            <q-input
                label="Наименование"
                class="q-mt-sm"
                v-model="edited.title"
                :disable="!ready"
            ></q-input>
          </div>

          <!-- Пункт загрузки -->
          <div class="q-my-md">
            <ref-select
              label="Пункт загрузки"
              url="/loading_points"
              option-value="id"
              option-label="title"
              :disable="!ready"
              :value="edited.loading_point"
              :autocomplete="true"
              @input="onLoadingPointChange"
            ></ref-select>
          </div>

          <!-- Пункт выгрузки -->
          <div class="q-my-md">
            <ref-select
              label="Пункт выгрузки"
              url="/unloading_points"
              option-value="id"
              option-label="title"
              :disable="!ready"
              :value="edited.unloading_point"
              :autocomplete="true"
              @input="onUnloadingPointChange"
            ></ref-select>
          </div>

          <!-- Расстояние -->
          <div class="q-my-md">
            <q-input
              label="Расстояние, км"
              v-model="edited.distance"
              :disable="!ready"
            ></q-input>
          </div>
        </div>

        <div class="col">
          <div
            id="move_direction_map"
            class="full-width full-height"
          ></div>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import RefSelect from '@/components/fields/RefSelect';

// Utils
import Map from '@/utils/map';
import CrudService from '@/modules/refs/ref_modules/move_direction/CrudService';
import MapDirectionsService from '@/modules/refs/ref_modules/move_direction/MapDirectionsService';

const _source = 'move_directions';
const _layer = 'move_directions';
const _layerText = 'move_directions_text';

const _sourceRoute = 'move_directions_route';
const _layerRoute = 'move_directions_route';

let _map = null;
let _ne = [];

export default {
  name: 'MoveDirectionDialog',
  components: {
    RefSelect,
  },
  props: {
    // Входной параметр как флаг показа диалога
    value: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  data() {
    return {
      // Редактируемый объект
      edited: {
        title: '',
        loading_points_id: null,
        unloading_points_id: null,
        distance: 0,
      },
      // Флаг что карта загружена и готова к работе
      ready: false,
      // Флаг что происходит загрузка
      isLoading: false,
    };
  },
  computed: {
    // Флаг показа диалога
    show: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  watch: {
    // Когда карта загружена
    ready() {
      _ne.forEach((it) => {
        it();
      });

      _ne = [];
    },
  },
  created() {
    setTimeout(() => {
      Map.init({
        container: document.getElementById('move_direction_map'),
      }).then((map) => {
        _map = map;

        this.ready = true;

        // Источник точек погрузки/разгрузки
        Map.createOrDataSource(_source, {
          type: 'FeatureCollection',
          features: [],
        });

        // Слой для отображения точек погрузки/разгрузки
        Map.createOrDataLayer(_layer, {
          id: _layer,
          type: 'circle',
          source: _source,
          paint: {
            'circle-radius': 8,
            'circle-stroke-width': 2,
            'circle-color': '#1760dc',
            'circle-stroke-color': '#3b5d9c',
          },
        });

        // Слой для отображения текста погрузки/разгрузки
        Map.createOrDataLayer(_layerText, {
          id: _layerText,
          type: 'symbol',
          source: _source,
          layout: {
            'text-field': ['get', 'title'],
            'text-variable-anchor': ['bottom'],
            'text-radial-offset': 0.5,
            'text-justify': 'auto',
            'text-size': 14,
          },
        });

        // --------

        // Источник для маршрута движения
        Map.createOrDataSource(_sourceRoute, {
          type: 'FeatureCollection',
          features: [],
        });

        // Слой для отображения линии маршрута движения
        Map.createOrDataLayer(_layerRoute, {
          id: _layerRoute,
          type: 'line',
          source: _sourceRoute,
          paint: {
            'line-color': '#0f778e',
            'line-width': 2,
          },
          layout: {
            'line-cap': 'round',
            'line-join': 'round',
          },
        });
      });
    }, 300);
  },
  beforeDestroy() {
    this.ready = false;

    Map.clear();

    _map = null;
  },
  methods: {
    // При изменении пункта загрузки
    onLoadingPointChange(item) {
      const callback = () => {
        if (item != null) {
          this.edited.loading_point = _copy(item);

          this._findAndReplaceFeature('loading-point', this._feature('loading-point', item));
        }
      };

      if (this.ready) {
        callback();
      } else {
        _ne.push(callback);
      }
    },

    // При изменении пункта выгрузки
    onUnloadingPointChange(item) {
      const callback = () => {
        if (item != null) {
          this.edited.unloading_point = _copy(item);

          this._findAndReplaceFeature('unloading-point', this._feature('unloading-point', item));
        }
      };

      if (this.ready) {
        callback();
      } else {
        _ne.push(callback);
      }
    },

    // При сохранении маршрута
    onSaveRoute() {
      this.isLoading = true;

      const data = {
        title: this.edited.title,
        loading_points_id: this.edited.loading_point.id,
        unloading_points_id: this.edited.unloading_point.id,
        distance: this.edited.distance,
        route: this.edited.route,
      };

      CrudService.add('move_directions', data)
        .then((response) => {
          if (response.success) {
            this.$emit('on-route-created', _copy(response.result));
            this.$nextTick(() => {
              this.show = false;
            });
          }
        })
        .catch(() => {
          this.$q.notify({
            message: 'Не удалось создать маршрут',
            color: 'red',
          });
        })
        .finally(() => {
          this.isLoading = false;
          this.show = false;
        });
    },

    // Поиск и замена точки на карте, если сменили пункт поугрзки/выгрузки
    _findAndReplaceFeature(id, feature) {
      const sourceData = _map.getSource(_source)._data;
      const index = sourceData.features.findIndex((it) => it.id === id);

      if (index > -1) {
        sourceData.features.splice(index, 1, feature);
      } else {
        sourceData.features.push(feature);
      }

      Map.createOrDataSource(_source, sourceData);

      const coords = sourceData.features.map((it) => it.geometry.coordinates);

      if (coords.length === 2) {
        _map.fitBounds(coords, {
          padding: 40,
        });

        this._route(coords);
      } else {
        _map.panTo(coords[0]);
      }
    },

    // Создание фичи для точки на карте
    _feature(id, item) {
      return {
        type: 'Feature',
        id: id,
        properties: {
          title: item.title,
        },
        geometry: {
          type: 'Point',
          coordinates: [
            item.lon, item.lat,
          ],
        },
      };
    },

    // Автоматический путь
    async _route(coords) {
      const data = await MapDirectionsService.getDirection([
        { coordinates: coords[0], id: 1 },
        { coordinates: coords[1], id: 2 },
      ]);

      if (data.route.coords == null) {
        this.$q.notify({
          message: 'Не удалось построить маршрут между пунктами',
          color: 'red',
        });

        return;
      }

      if (this.edited.loading_point && this.edited.unloading_point) {
        const firstPart = this.edited.loading_point.title.split(',');
        const secondPart = this.edited.unloading_point.title.split(',');
        const nameArr = [firstPart[0], secondPart[0]];

        this.edited.title = nameArr.join(' - ');
      }

      // Дистанция приходит в метрах
      this.edited.distance = Math.ceil(data.raw?.distance / 1000);
      this.edited.route = data.raw;

      Map.createOrDataSource(_sourceRoute, {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'LineString',
              properties: {},
              coordinates: data.route.coords,
            },
          },
        ],
      });
    },
  },
};
</script>
