<template>
  <div>
    <div class="flex">
      <ref-select
          label="Водители"
          url="/drivers"
          v-model="driver_data"
          :option-label="(row) => row.user ? row.user.full_name : ''"
          option-value="id"
          :autocomplete="true"
          class="q-ml-md"
          @input="changeDriver"
      ></ref-select>
    </div>

    <q-table
        :data="data"
        :columns="ref.listColumns"
        row-key="id"
        :pagination.sync="pagination"
        :loading="loading"
        @request="loadData"
        binary-state-sort
        hide-pagination
        id="expenses_table_driver"
    >
      <template v-if="data.length > 0" #top-row="{cols}">
        <q-tr class="bg-grey-4">
          <q-td v-for="(col, index) of cols" :key="col.name">
            <template v-if="index === 0">Итого</template>
            <template v-if="index > 3">
              {{ _priceFormat(data.reduce((acc, val) => acc + val[col.name], 0)) }}
            </template>
          </q-td>
        </q-tr>
      </template>

      <template v-if="data.length > 0" #bottom-row="{cols}">
        <q-tr class="bg-grey-4">
          <q-td v-for="(col, index) of cols" :key="col.name">
            <template v-if="index === 0">Итого</template>
            <template v-if="index > 3">
              {{ _priceFormat(data.reduce((acc, val) => acc + val[col.name], 0)) }}
            </template>
          </q-td>
        </q-tr>
      </template>
    </q-table>
  </div>
</template>

<script>
import {expenses_driver} from '@/structures/expenses_driver';
import RefSelect from '@/components/fields/RefSelect';
import {getData, METHODS} from '@/utils/http';

export default {
  name: 'ExpensesByDriver',
  components: {RefSelect},
  props: {
    startDate: {
      type: String,
    },
    endDate: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      data: [],
      driver_data: null,
      url: '',
      ref: expenses_driver,
      tableKey: 1,
      pagination: {
        rowsPerPage: 0,
      },
      checker: false,
    };
  },
  watch: {
    startDate() {
      if (!this.checker) {
        this.checker = true;
        this.loadData();
      }
    },
    endDate() {
      if (!this.checker) {
        this.checker = true;
        this.loadData();
      }
    },
  },
  methods: {
    loadData() {
      this.loading = true;

      getData(this.url, METHODS.GET)
        .then((res) => {
          this.data = res.result.items;
        })
        .finally(() => {
          this.loading = false;
          this.checker = false;
        });
    },
    changeDriver(driver) {
      let url = `/expenses/by/driver/${driver.id}?include=*&perPage=10000`;
      url += `&start_date=${this.startDate}`;
      url += `&end_date=${this.endDate}`;
      this.url = url;

      this.loadData();
    },
    priceFormat(num) {
      return _priceFormat(num);
    },
  },
};
</script>
