<template>
  <div style="width: 600px">
    <div class="q-mb-md">
      <q-btn @click="loadData"
             round
             class="q-mr-lg"
             icon="refresh"
             :disable="loading"
      >
        <q-tooltip>Обновить</q-tooltip>
      </q-btn>

      <q-btn
          @click="odometerDialog = true"
          color="primary"
          :disable="loading"
      >
        Указать пробег
      </q-btn>
    </div>

    <template v-if="!loading">
      <q-card>
        <div
            v-if="odometers.length === 0"
            class="q-pa-md"
        >
          Нет ни одной записи о пробеге ТС
        </div>

        <q-card-section
            class="flex"
            v-for="(odometer, index) in odometers"
            :key="`odometer_${odometer.id}`"
            style="border-bottom: 1px solid #cecece"
        >
          <div class="col-auto q-mr-md">{{ index + 1 }}</div>
          <div class="col-auto q-mr-md">{{ odometer.distance }} км</div>
          <div class="col-auto">{{ odometer.date }}</div>

          <q-space/>

          <div>
            <q-btn round
                   size="xs"
                   icon="edit"
                   @click="changeOdometer(odometer)"
            >
              <q-tooltip>Изменить</q-tooltip>
            </q-btn>
          </div>
        </q-card-section>
      </q-card>

      <q-pagination
          v-if="pages > 0"
          v-model="page"
          color="black"
          class="q-pt-md"
          :max="pages"
          :max-pages="7"
          :boundary-numbers="false"
      />
    </template>

    <template v-else>
      <div class="text-center">
        <q-spinner size="48"/>
      </div>
    </template>

    <q-dialog v-if="editDialog && editItem" v-model="editDialog">
      <q-card>
        <!-- Заголовок -->
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">
            Изменить пробег
          </div>

          <q-space/>

          <q-btn flat round dense
                 v-close-popup
                 icon="close"
                 class="q-ml-md"
          ></q-btn>
        </q-card-section>

        <!-- Формируем поля для редактирования-->
        <q-card-section style="max-height: 500px; overflow: auto">
          <div style="max-height: 500px">

            <q-select
              v-model="selectedDriver"
              :options="vehicle.drivers"
              :option-label="(value) => value.user && value.user.full_name || ''"
              option-value="id"
              :display-value="selectedDriver.user.full_name"
            >

            </q-select>
            
            <q-input
                label="Пробег"
                v-model="editItem.distance"
            />
          </div>
        </q-card-section>

        <!-- Действия -->
        <q-card-actions align="right">
          <q-btn
              flat
              label="OK"
              color="primary"
              :loading="editLoading"
              @click="saveOdometer(editItem)"
          ></q-btn>

          <q-btn
              flat
              label="Отмена"
              color="negative"
              :disable="editLoading"
              v-close-popup
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <AddOdometer
        v-if="odometerDialog"
        :vehicle="vehicle"
        :odometerDialog="odometerDialog"
        @input="(state) => {odometerDialog = state}"
        @onRefresh="loadData"
    ></AddOdometer>
  </div>
</template>

<script>
import { getData, METHODS } from '@/utils/http';
import AddOdometer from '@/modules/organization/components/AddOdometer';

export default {
  name: 'Odometer',
  components: { AddOdometer },
  props: {
    vehicle: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      odometerDialog: false,
      editDialog: false,
      editLoading: false,
      editItem: null,
      page: 1,
      pages: 0,
      editItem_tmp: null,
      odometers: [],
      drivers: {},
      selectedDriver: {},
    };
  },
  watch: {
    page() {
      this.loadData();
    },
  },
  created() {
    this.loadData();

    this.selectedDriver = this.vehicle.drivers ? this.vehicle.drivers[0] : null;
  },
  methods: {
    loadData() {
      this.loading = true;
      let URL = `/odometers/by/vehicle/${this.vehicle.id}?page=${this.page}`;
       

      getData(URL, METHODS.GET, {})
        .then((res) => {
          this.odometers = res.result.items;
          this.pages = res.result.pages;
        })
        .catch((res) => {
          this.$q.notify({
            message: res.error,
            type: 'negative',
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    changeOdometer(item) {
      this.editItem = _copy(item);
      this.editItem_tmp = item;
      this.editDialog = true;
    },
    saveOdometer() {
      this.editLoading = true;

      getData(`/odometers/${this.editItem.id}`, METHODS.PUT, {
        params: {
          distance: this.editItem.distance,
          vehicle_id : this.editItem.vehicle_id,
          driver_id: this.selectedDriver.id,
        },
      })
          .then(() => {
            this.editItem_tmp.distance = this.editItem.distance;
            this.editItem = null;
            this.editItem_tmp = null;
            this.editDialog = false;
          })
          .catch((res) => {
            this.$q.notify({
              message: res.error,
              type: 'negative',
            });
          })
          .finally(() => {
            this.editLoading = false;
          });
    },
  },
};
</script>
