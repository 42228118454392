const roles_ref = {
    name: 'roles',
    title: 'Роли',
    url: '/rbac/roles',
    perms: {
        list: 'v1.roles.list',
        read: 'v1.roles.read',
        create: 'v1.roles.create',
        update: 'v1.roles.update',
        delete: 'v1.roles.delete',
    },
    listColumns: [
        {
            name: 'title',
            required: true,
            label: 'Наименование',
            align: 'left',
            field: 'title',
            sortable: true,
        },
    ],
    listEdit: [
        {
            name: 'title',
            required: true,
            label: 'Наименование',
            align: 'left',
            field: 'title',
            sortable: true,
        },
        {
            name: 'perms',
            required: true,
            label: 'Права доступа',
            align: 'left',
            field: 'perms',
            sortable: true,
            type: 'reference',
            meta: {
                url: '/rbac/perms',
                optionLabel: 'name',
                multiple: true,
                autocomplete: true,
            },
        },
    ],
};

export {
    roles_ref,
};
