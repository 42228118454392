<template>
  <q-dialog v-model="dialog" @input="$emit('input', false)">
    <q-card style="width: 400px">
      <q-toolbar>
        <q-toolbar-title>
          Установить количество топлива
        </q-toolbar-title>
      </q-toolbar>

      <q-card-section>
        <div class="q-gutter-sm">
          <q-radio v-model="fuel" val="0" label="0" />
          <q-radio v-model="fuel" val="0.25" label="1/4" />
          <q-radio v-model="fuel" val="0.5" label="1/2" />
          <q-radio v-model="fuel" val="0.75" label="3/4" />
          <q-radio v-model="fuel" val="1" label="1" />
        </div>
      </q-card-section>

      <q-card-actions>
        <q-btn
            :disable="loading"
            @click="$emit('input', false)"
        >
          Отмена
        </q-btn>

        <q-space />

        <q-btn
            @click="save"
            :loading="loading"
        >
          Сохранить
        </q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import {getData, METHODS} from '@/utils/http';
import {getDateToAPiFormat} from '@/utils/date';

export default {
  name: 'AddFuel',
  props: {
    fuelDialog: {
      type: Boolean,
      required: true,
    },
    trailer: {
      type: Array,
      required: true,
    },
    vehicle: {
      type: Object,
      required: true,
    },
    date: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      trailersLoading: true,
      trailer_data: null,
      fuel: '',
      current_date: '',
    };
  },
  created() {
    this.dialog = this.fuelDialog;

    if (this.trailer.length > 0) {
      this.trailer_data = this.trailer[0];
    }
  },
  methods: {
    save() {
      this.loading = true;
      let currentDate;

      if (this.date) {
        currentDate = this.date;
      } else {
        const curDate = new Date();
        const day = _setZero(curDate.getDate());
        const month = _setZero(curDate.getMonth() + 1);
        const year = curDate.getFullYear();
        this.current_date = `${day}/${month}/${year}`;
      }

      if (!confirm('Уверены, что установили верный остаток топлива?')) {
        this.loading = false;
        return false;
      }

      getData('/fuel/', METHODS.POST, {
        params: {
          vehicle: {
            id: this.vehicle.id,
          },
          driver_id: this.vehicle.drivers[0].id,
          relative_remain: Number(this.fuel),
        },
      })
          .then(() => {
            this.$emit('onRefresh');
          })
          .catch((res) => {
            this.$q.notify({
              message: res.error,
              type: 'negative',
            });
          })
          .finally(() => {
            this.loading = false;
            this.$emit('input', false);
          });
    },
  },
};
</script>

<style scoped>

</style>
